import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import "./AddEditRoleMaster.css"
import { getLocationdata, AddNewLocation, UpdateLocation } from "../../services/location-master-service";
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import seccessImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { getProjectsByLocation } from "../../services/project-service";

const AddEditLocationMaster = (props) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { userId: context.userId, userName: context.userName }
    let action = props.action;
    const [toggleClass, setToggleClass] = useState("activeToggleLabel");
    const [loading, setLoading] = useState(false);

    const [locationObj, setLocationObj] = useState(
        action === "Edit" || action === "View"
            ? props.rowdata
            : {
                Location: "",
                LocationType: "India",
                Country: "India",
                DeleteIndication: "Active"
            }
    );

    useEffect(() => {
        if (action === "Edit" || action === "View") {
            if (props.rowdata.DeleteIndication === "Active") {
                setToggleClass("activeToggleLabel")
            }
            else {
                setToggleClass("inactiveToggleLabel")
            }
        }
    }, []);

    const validateDetails = () => {
        let errorMsg = "";
        let isError = false;

        if (locationObj.LocationType.trim() === "") {
            locationObj.LocationType = "";
            isError = true;
            errorMsg = errorMsg + "Location Type is required. ";

        }
        if (locationObj.Location.trim() === "") {
            locationObj.Location = "";
            isError = true;
            errorMsg = errorMsg + "Location is required. ";
        }
        if (locationObj.Country.trim() === "") {
            locationObj.Country = "";
            isError = true;
            errorMsg = errorMsg + "Country is required. ";
        }
        if (locationObj.LocationType === "Overseas" && locationObj.Country.toLowerCase() === "india") {
            locationObj.Country = "";
            isError = true;
            errorMsg = errorMsg + "Invalid overseas country. ";
        }
        if (locationObj.DeleteIndication === "") {
            isError = true;
            errorMsg = errorMsg + "Status is required. ";
        }
        return { isError, errorMsg };
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLocationObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === "LocationType") {
            if (value === "India") {
                setLocationObj((prevState) => ({
                    ...prevState,
                    Country: value,
                }));
            }
            else {
                setLocationObj((prevState) => ({
                    ...prevState,
                    Country: "",
                }));
            }
        }
    };

    const handleCancel = () => {
        props.onHide();
    }

    const handleReset = () => {
        setLocationObj((prevState) => ({
            ...prevState,
            Location: "",
            LocationType: "India",
            Country: "India",
            DeleteIndication: "Active"
        }));
        setToggleClass("activeToggleLabel")
    }

    const handleSwitchChange = (e) => {
        if (e.target.checked) {
            setToggleClass("activeToggleLabel")
        } else {
            setToggleClass("inactiveToggleLabel")
        }

        setLocationObj((prevState) => ({
            ...prevState,
            DeleteIndication: e.target.checked ? "Active" : "Inactive",
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (action == "View") {
            props.onHide();
        }
        else {
            let result;
            let validationResult = validateDetails()

            if (!validationResult.isError) {
                setLoading(true);
                const result1 = await getLocationdata();
                console.log("result", result1)
                if (result1.length > 0 && result1.filter((item) => (action === "Add" || (action === "Edit" && item.LocationCode.toLowerCase() !== locationObj.LocationCode.toLowerCase())) && item.Location.toLowerCase() === locationObj.Location.toLowerCase()).length > 0) {
                    setLoading(false);
                    MySwal.fire({
                        text: "Location already exists.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
                if (action == "Edit" && locationObj.DeleteIndication === "Inactive") {
                    const locResult = await getProjectsByLocation(locationObj.LocationCode);
                    if (locResult?.length > 0) {
                        let activeUser = locResult.filter((item) => item.DeleteIndication === "Active").length;
                        if (activeUser > 0) {
                            setLoading(false);
                            MySwal.fire({
                                text: "Projects are already linked to " + locationObj.Location + " Location. Thus, selected Location cannot be updated.",
                                imageUrl: errorImage,
                                customClass: "swal-success"
                            });
                            return;
                        }
                    }
                }

                setLoading(false);
                let confirmBoxText = "";
                let confirmButtonText = "";
                if (action === "Add") {
                    confirmBoxText = "Are you sure, do you want to add new location?";
                    confirmButtonText = "Add";
                }
                else if (action == "Edit") {
                    confirmBoxText = "Are you sure, do you want to update location?";
                    confirmButtonText = "Update";
                }

                MySwal.fire({
                    text: confirmBoxText,
                    showDenyButton: true,
                    cancelButtonColor: "#fff",
                    confirmButtonColor: "#16911B",
                    confirmButtonText: confirmButtonText,
                    reverseButtons: true,
                    customClass: "swal-confirmation",
                    title: action + " Location"
                }).then(async (result2) => {
                    if (result2.isConfirmed) {
                        setLoading(true);
                        if (action === "Add") {
                            let maxLocationCode = "LOC00001";
                            if (result1.length > 0) {
                                let latestLocationCode = result1[result1.length - 1].LocationCode;
                                let locNo = latestLocationCode.substring(3, latestLocationCode.length)
                                maxLocationCode = "LOC" + ((parseInt(locNo) + 1).toString().padStart(5, '0'));
                            }
                            let todayDate = getCurrentDateTime("-");
                            locationObj.CreatedBy = userDetails.userId;
                            locationObj.CreatedByName = userDetails.userName;
                            locationObj.CreatedOn = todayDate;
                            locationObj.ModifiedBy = userDetails.userId;
                            locationObj.ModifiedByName = userDetails.userName;
                            locationObj.ModifiedOn = todayDate;
                            locationObj.LocationCode = maxLocationCode;
                            locationObj.PK = "LocationMaster";
                            locationObj.SK = maxLocationCode;

                            result = AddNewLocation(locationObj);
                        } else if (action == "Edit") {
                            let todayDate = getCurrentDateTime("-");
                            locationObj.ModifiedBy = userDetails.userId;
                            locationObj.ModifiedByName = userDetails.userName;
                            locationObj.ModifiedOn = todayDate;
                            // if(locationObj.DeleteIndication ? locationObj.DeleteIndication="Active":locationObj.DeleteIndication="Inactive");
                            console.log(locationObj);
                            result = UpdateLocation(locationObj);
                        }

                        result.then((resp) => {
                            if (resp.status) {
                                setLoading(false);
                                MySwal.fire({
                                    text: resp.message,
                                    imageUrl: seccessImage,
                                    customClass: "swal-success",
                                    didClose: () => {
                                        props.setReload(!props.reload);
                                        props.onHide();
                                    },
                                });
                            }
                            else {
                                setLoading(false);
                                MySwal.fire({
                                    text: resp.message,
                                    imageUrl: errorImage,
                                    customClass: "swal-success",
                                });
                            }
                        })
                            .catch((error) => {
                                setLoading(false);
                                MySwal.fire({
                                    text: "Unable to Process Request",
                                    imageUrl: errorImage,
                                    customClass: "swal-success",
                                    // didClose: () => {
                                    //   props.onHide();
                                    // },
                                });
                            })
                            .finally(() => {
                                props.setReload(!props.reload);
                            });
                    }
                });

            }
            else {
                MySwal.fire({
                    text: validationResult.errorMsg,
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
        }
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Modal
                {...props}
                size="lg"
                backdrop="static" centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {action} Location
                        <span className="req-input" >
                            {props.action !== "View" ? " (All fields are mandatory)" : ""}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="locationmaster"
                        name="locationmaster"
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <Row >
                            {(action === "Edit" || action === "View") && (<>
                                <Col md={4} xs={12}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id="LocationCode"
                                            type="text"
                                            name="LocationCode"
                                            maxLength="10"
                                            placeholder=" "
                                            required
                                            value={locationObj.LocationCode}
                                            readOnly
                                        />
                                        <Form.Label className="frm-label">Location Code</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4} xs={12}>
                                    <Row>
                                        <Col md={7} xs={6}><Form.Label className="fieldLabel">Location Type</Form.Label></Col>
                                        <Col md={5} xs={6}><Form.Label className="fieldLabel semiBoldToggleButton">{locationObj.LocationType}</Form.Label></Col>
                                    </Row>
                                </Col>
                            </>
                            )}
                            {action === "Add" && (
                                <Col md={4} xs={12}>
                                    <Row>
                                        <Form.Label className="fieldLabel locationType">Location Type</Form.Label>
                                    </Row>
                                    <Row>
                                        <Col md={6} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="LocationType"
                                                checked={locationObj.LocationType === "India" ? true : false}
                                                onChange={handleChange}
                                                label="India"
                                                value="India"
                                            ></Form.Check>
                                        </Col>
                                        <Col md={6} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="LocationType"
                                                checked={locationObj.LocationType === "Overseas" ? true : false}
                                                onChange={handleChange}
                                                label="Overseas"
                                                value="Overseas"
                                            ></Form.Check>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="Location"
                                        type="text"
                                        name="Location"
                                        maxLength="100"
                                        placeholder=" "
                                        required
                                        value={locationObj.Location}
                                        onChange={handleChange}
                                        readOnly={action === "View" ? true : false} />
                                    <Form.Label className="frm-label" for="Location">Location</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="Country"
                                        type="text"
                                        name="Country"
                                        maxLength="100"
                                        placeholder=" "
                                        required
                                        value={locationObj.Country}
                                        onChange={handleChange}
                                        readOnly={(action === "Edit" || action === "View" || locationObj.LocationType === "India") ? true : false} />
                                    <Form.Label className="frm-label" for="Country">Country</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}><Row>
                                <Col>
                                    <Form.Label className="fieldLabel">Status</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Switch className={"semiBoldToggleButton " + toggleClass}
                                        label={locationObj.DeleteIndication === "Active" ? "Active" : "Inactive"}
                                        name="DeleteIndication"
                                        checked={locationObj.DeleteIndication === "Active" ? true : false}
                                        onChange={(e) => {
                                            handleSwitchChange(e);
                                        }}
                                        disabled={action === "View" ? true : false}
                                    />
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={9} xs={6}><Row>
                                <Col md={3} xs={12}>{action === "View" || action === "Edit" ? null :
                                    <Button className="semiBoldToggleButton cancelBtn"
                                        variant="outline-success"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                }
                                </Col>
                                <Col xs={12} md={6}>{action === "View" || action === "Edit" ? <></> :
                                    <Button className="resetBtn semiBoldToggleButton"
                                        variant="link"
                                        onClick={handleReset}
                                    >Reset Fields
                                    </Button>
                                }
                                </Col>
                            </Row>
                            </Col>
                            <Col md={3} xs={6}>
                                {action === "View" ?
                                    <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" type="submit">
                                        Done
                                    </Button>
                                    :
                                    <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" type="submit">
                                        {action === "Edit" ? "Update" : "Add"}
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddEditLocationMaster;
