import { Button, Modal, Alert, Row, Col } from "react-bootstrap";

import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../masters/GridMaster.css";

import { BsX } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState, useEffect, useMemo, } from "react";

import {

  getActiveLocations,

} from "../../services/location-master-service";
import GolbalSearch from "./GolbalSearch";

const MultiValuePopUp = (props) => {

  let count = 1;
  let arr = props.masterName.split(":");
  let masterName = arr[0];
  const gridRef = useRef();
  const [dataMethod, setDataMethod] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [parameters, setParams] = useState(null);
  const [ready, setReady] = useState(false);
  const [filteredCol, setFilteredCol] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [data, setData] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [pageSize] = useState(3);

  const fetchData = async (param) => {

    const tmpResult = dataMethod && (await dataMethod("Overseas"));
    // const result = tmpResult
    const result = tmpResult?.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.Country === current.Country)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    setRowData(result);
    setFilteredResult(result);
    setData(result);
  };

  useEffect(() => {

          setParams(arr[1]);
          setDataMethod(() => getActiveLocations);

          setColumnDefs([
            {
              field: "Country",
              headerName: "Country",
              checkboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              headerCheckboxSelection: true,
              // suppressSizeToFit: true,
              editable: false,
              // width: '50%',
              cellRenderer: (params) => {

                if (props?.locationData && count === 1) {
                  let temp = props.locationData.split(",");

                  gridRef?.current?.api?.forEachNode((node) => {
                    if (temp.includes(node?.data.Country)) {
                      node.setSelected(true);
                      count = count + 1;
                    }
                  });
                }
                return params.value;
              },
              tooltipField: "Country",
            },
            {
              field: "LocationType",
              headerName: "Location Type",
              tooltipField: "LocationType",
              // width: '50%',
            }
          ]);
        
    fetchData(parameters);

  }, [dataMethod]);

  const defaultColDef = {
    resizable: true,
    suppressMovable: true,
    filter: true,
    sortable: true,
    filterParams: {
      buttons: ["reset", "apply"],
      closeOnApply: true,
      defaultJoinOperator: "OR",
    },
  };

  const resetData = () => {
    gridRef?.current?.api?.forEachNode((node) => {
      node.setSelected(false);
    });
  }
  const assignData = () => {
    // let selectedRows = gridRef.current.api.getSelectedRows();
    // console.log("gridRef.current.api.getSelectedRows()",gridRef.current.api.getSelectedRows())

    // let selectedRowsCount = gridRef.current.api.getSelectedRows().length;
    console.log("selectedData",selectedData)
    let selectedRows = selectedData;
    let selectedRowsCount = selectedData.length;
    if (selectedRowsCount > 0) {
      props.setSelected({ name: props.masterName, result: selectedRows });
      props.onHide();
    } else
      setAlertMessage("Please select record before clicking on Add button.");
  };

  const onRowSelected = (event) => {
    const Index = selectedData.findIndex((element) => element.Country === event.node.data.Country);
    if (event.node.isSelected()) {
      if( Index === -1 ){
        setSelectedData((data) => [...data, event.node.data]);
      }
    } else {
        if (Index !== -1) {
          selectedData.splice(Index, 1)
          console.log("selectedData",selectedData)
            setSelectedData(selectedData);
        }
    }
  };

  const onGridReady = (value) => {
    setReady(!ready);
    gridRef.current.api.sizeColumnsToFit();
  };

  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();
    if (searchText) {
      let result = rowData?.filter((item) => 
              (item.Country.toLowerCase().indexOf(searchText) >= 0 ||
              item.LocationType.toLowerCase().indexOf(searchText) >= 0)
      );
      setFilteredResult(result);
    } else {
      setFilteredResult(rowData);
    }
  };
  
  useEffect(() => {
    if (selectedData.length > 0 && count === 1) {
      gridRef?.current?.api?.forEachNode((node) => {
        if (
          selectedData.filter(
            (selectedData) =>
              selectedData.Country == node?.data.Country.toString()
          ).length > 0
        ) {
          node.setSelected(true);
          count = count + 1;
        }
      });
    }
  }, [filteredResult]);

  const resetAllFilter = () => {
    gridRef.current.api.setFilterModel(null);
  };

  function resetFilter(item) {
    let item1 = item.substring(0, item.indexOf(":") - 1);

    let col = gridRef.current.api.getFilterModel();
    for (const key of Object.keys(col)) {
      if (key === feildName(item1)) {
        delete col[key];
      }
    }
    gridRef.current.api.setFilterModel(col);
  }

  const headerName = (field) => {
    let name;
    switch (field) {
      case "LocationType":
        name = "Location Type";
        break;
      case "Country":
        name = "Country";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const feildName = (header) => {
    let name;
    switch (header) {
      case "Location Type":
        name = "LocationType";
        break;
      case "Country":
        name = "Country";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };
  const onFilterChanged = (param) => {
    let a = document.getElementById("filterDisplayPop");

    setFilteredCol([]);
    let col = param.api.getFilterModel();

    if (Object.keys(col).length === 0) {
      a.classList.add("d-none");
    } else {
      a.classList.remove("d-none");
      for (const key of Object.keys(col)) {
        let filter;
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName(key) + " : " + col[key].filter;
        }
        setFilteredCol((prev) => {
          return [...prev, filter];
        });
      }
    }
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setAlertMessage("");
  //   }, 10000);
  //   return () => clearTimeout(timer);
  // }, [alertMessage]);

  return (
    <>
      <Modal {...props} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{props.masterName.split(":")[0]} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="global-search-container ">
            <Row><Col></Col>
              <Col>
                <GolbalSearch filterFunc={handleGlobalSearch} />
              </Col>
            </Row>
          </div>
          <div>
            <div
              className="d-none mb-3 d-flex"
              id="filterDisplayPop"
              style={{ gap: "10px" }}
            >
              {filteredCol &&
                filteredCol.map((item) => {
                  return (
                    <div className="filter-col pr-0 me-2">
                      <div>
                        <span className="ps-2 btn-text">{item}</span>
                      </div>
                      <div onClick={() => resetFilter(item)}>
                        <BsX size={32} />
                      </div>
                    </div>
                  );
                })}
              <Button
                variant="outline-primary"
                className="ms-2 lh-1 clear-all-btn"
                onClick={resetAllFilter}
              >
                Clear All
              </Button>
            </div>

            <div className="ag-theme-alpine popup"
              //style={{ height: "auto", width: "auto", overflow: "hidden", gridTemplateColumns: "auto auto auto", dispay: "grid" }}
            >
              <AgGridReact
                ref={gridRef}
                onRowSelected={onRowSelected}
                rowData={filteredResult}
                columnDefs={columnDefs}
                rowSelection={"multiple"}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                headerHeight={50}
                rowHeight={50}
                pagination={true}
                paginationPageSize={5}
                onFilterChanged={onFilterChanged}
                enableBrowserTooltips={true}
                domLayout="autoHeight"
                rowMultiSelectWithClick={true}
              ></AgGridReact>
            </div>

          </div>
        </Modal.Body>

        {alertMessage && (
          <Alert className="alertBox" variant="warning">
            {alertMessage}
          </Alert>
        )}

        <Modal.Footer className="">
          <Row style={{ width: "100%" }}>
            <Col> <Button
              size="sm"
              variant="outline-success"
              className="semiBoldToggleButton cancelBtn"
              onClick={resetData}
            >Reset </Button></Col>

            <Col >  <Button
              variant="success"
              type="submit"
              className="btn-add-color semiBoldToggleButton rightAlignedButton"
              onClick={assignData}
            >
              Add
            </Button>

            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MultiValuePopUp;
