import React, { useEffect, useState, useRef, useMemo, useContext, forwardRef, useImperativeHandle } from "react";
import { Form, Modal, Row, Col, Container, Card, Stack, Button, Spinner } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../masters/GridMaster.css";
import "../../utils/AddEditProjectMaster.css";
import { BsPlusLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import ActionRenderer from "../ActionRenderer";
import { defaultEditableCol, getCurrentDateTime } from "../../../functions/gbcs-functions";
import { AddProjectOrder, getProjectDocuments, DeleteProjectOrder, AddOrderResponsive } from "../../../services/project-service";
import { getDocumentTypeData } from "../../../services/common-master-service";
import { DragDropRender } from "./DragDropRender";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import Pagination from "../../utils/Pagination";
import Dropzone from "react-dropzone";
import Upload from "../../../icons/Upload.svg";
import Validate from "../../utils/Validation";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { downloadAttachment } from "../../../services/designreviewtracker-service"
import { sortByPropertyInAscending } from "../../../utils/Utility";

const ProjectOrderDocuments = forwardRef((props, ref) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, dataControl: context.dataControl, userId: context.userId, displayContractFinInfo: context.displayContractFinInfo }
    let action = props.action;
    const inputFile = useRef(null);
    const [gridApi, setGridApi] = useState(null);
    const gridRef = useRef();
    const [tabData, setTabData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(3);
    const [documentResult, setDocumentResult] = useState();
    const [columnDefs, setColumnDefs] = useState([]);
    const [addPopUpResponsive, setAddPopUpResponsive] = useState(false);
    const [subAction, setSubAction] = useState();
    let projectcode_ = "";
    let projectname_ = "";
    const [files, setFiles] = useState([]);
    let todayDate = getCurrentDateTime("-");
    const [inactiveStatus, setInactiveStatus] = useState(false);
    const [loading, setLoading] = useState(true);

    const [actionButtons, setActionButtons] = useState(action === "View" ? {
        ViewAction: false,
        EditAction: false,
        DownloadAction: true,
        DeleteAction: false
    } : {
        ViewAction: false,
        EditAction: false,
        DownloadAction: true,
        DeleteAction: true
    });

    const [projectOrderObj, setProjectOrderObj] = useState(action === "Edit" || action === "View"
        ?
        {
            SK: "",
            PK: "",
            ProjectName: props.rowdata.ProjectName,
            ProjectCode: props.rowdata.ProjectCode,
            CreatedOn: "",
            CreatedBy: "",
            ModifiedOn: "",
            ModifiedBy: "",
            DeleteIndication: "Active"
        }
        : {
            SK: "",
            PK: "",
            ProjectName: "",
            ProjectCode: "",
            CreatedOn: "",
            CreatedBy: "",
            ModifiedOn: "",
            ModifiedBy: "",
            DeleteIndication: "Active"
        });

    const [orderObjResponsive, setOrderObjResponsive] = useState(
        action === "Edit" || action === "View" ?
            {
                SK: "",
                PK: "",
                ProjectName: props.rowdata.ProjectName,
                ProjectCode: props.rowdata.ProjectCode,
                CreatedOn: "",
                CreatedBy: "",
                CreatedByName: "",
                ModifiedOn: "",
                ModifiedBy: "",
                ModifiedByName: "",
                DeleteIndication: "Active",
                DocumentType: "",
                DocumentName: "",
                Attachment: "",
                SeqNo: 0
            } :
            {
                SK: "",
                PK: "",
                ProjectName: "",
                ProjectCode: "",
                CreatedOn: "",
                CreatedBy: "",
                CreatedByName: "",
                ModifiedOn: "",
                ModifiedBy: "",
                ModifiedByName: "",
                DeleteIndication: "Active",
                DocumentType: "",
                DocumentName: "",
                Attachment: "",
                SeqNo: 0
            }
    );
    const [respFile, setRespFiles] = useState();

    function onGridReady(params) {
        setGridApi(params.api);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api;// <= assigned gridApi value on Grid ready
    }

    const fetchDocumentMaster = async () => {
        setLoading(true);
        const result = await getDocumentTypeData();
        result.sort(sortByPropertyInAscending("ProjectTypeId"));
        let tmp = [];
        result.forEach(element => {
            tmp.push({ "Name": element.ProjectTypeName, "Code": element.SK });
        });
        setDocumentResult(tmp);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        if (action === "View") {
            setActionButtons({
                ViewAction: false,
                EditAction: false,
                DownloadAction: true,
                DeleteAction: false
            })
        }
        else if (action === "Edit") {
            setActionButtons({
                EditAction: false,
                DownloadAction: true,
                DeleteAction: true,
                ViewAction: false
            })
        }
        setColumnDefs([
            {
                headerName: "Sr No",
                field: "SeqNo",
                maxWidth: 100,
                tooltipField: "Sr No",
                editable: false,
                cellClass: (params) => {
                    if (params.data.RowInd === "New") {
                        return "inlineEdit"
                    }
                },
            },
            {
                headerName: "Document Type",
                field: "DocumentType",
                minWidth: 200,
                tooltipField: "Document Type",
                editable: false,
                cellClass: (params) => {
                    if (params.data.RowInd === "New") {
                        return "inlineEdit"
                    }
                },
                cellRenderer: function (params) {

                    //let i = params.node.rowIndex;
                    if (params.data.RowInd === "New") {
                        return (
                            <Form.Group className="ddinput">
                                <Form.Select
                                    className="ddinput"
                                    id="ddType"
                                    name="ddType"
                                    type="text"
                                    placeholder=" "
                                    required
                                    value={params.value}
                                    onChange={handleChangeOnDD}
                                    disabled={props.action === "View" ? true : props.disable}
                                >
                                    {documentResult ? documentResult.map((documentResult) => (
                                        <option key={documentResult.Code} value={documentResult.Name}>{documentResult.Name}</option>
                                    )) : null
                                    }
                                </Form.Select>
                            </Form.Group>
                        )
                    }
                    else {
                        return <span>{params.value}</span>
                    }
                }
            },
            {
                headerName: "Document Name",
                field: "DocumentName",
                minWidth: 200,
                cellClass: (params) => {
                    if (params.data.RowInd === "New") {
                        return "inlineEdit blockCss"
                    }
                    else {
                        return "blockCss"
                    }
                },
                cellEditorParams: { maxLength: 200 },
                tooltipField: "Document Name",
                editable: function (params) {
                    return params.data.RowInd === "New" ? true : false
                },
                valueGetter: (params) => {
                    return params.data.DocumentName;
                },
                valueSetter: (params) => {
                    let allData = getRowData();
                    let existInd = allData.filter((item) => item.SeqNo !== params.data.SeqNo && item.DocumentName.toLowerCase() === params.newValue.toLowerCase())

                    if (existInd?.length > 0) {
                        params.data.DocumentName = "";
                        MySwal.fire({
                            text: "Duplicate Document Name.",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                        return;
                    }
                    else {
                        params.data.DocumentName = params.newValue;
                    }
                    return;
                }
            },
            {
                headerName: "Attachment",
                field: "Attachment",
                tooltipField: "Attachment",
                minWidth: 200,
                resizable: false,
                editable: false,
                cellClass: (params) => {
                    if (params.data.RowInd === "New") {
                        return "inlineEdit"
                    }
                },
                cellRenderer: function (params) {
                    if (params.value === "") {
                        return <DragDropRender val={params.node.rowIndex} handleChangeEvent={handleChangeStatus} />
                    }
                    else {
                        return <span>{params.value}</span>
                    }
                }
            },
            {
                field: "",
                minWidth: 80,
                width: 80,
                editable: false,
                cellClass: (params) => {
                    if (params.data.RowInd === "New") {
                        return "inlineEdit"
                    }
                },
                cellRenderer: function (params) {
                    if (!inactiveStatus) {
                        let buttonDisplay = Object.assign({}, actionButtons);
                        if (params.data.RowInd === "New") {
                            buttonDisplay.DownloadAction = false;
                        }
                        return <ActionRenderer selectedData={params} actionName={buttonDisplay} handleEditEvent={() => inputFile.current.click()} handleDeleteEvent={(params) => handleActionEvent(params.data, "Delete")} handleDownloadEvent={(params) => handleDownload(params.data)} />
                    }
                },
                pinned: 'right'
            }
        ]);
        setLoading(false);
    }, [documentResult]);

    const handleDownload = (params) => {
        downloadAttachment(
            "ProjectOrder/" + params.ProjectCode + "/" + params.SeqNo + "/" + params.Attachment,
            params.Attachment
        ).catch((err) => {
            MySwal.fire({
                text: "Error in Fetching file.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        });
    }

    useEffect(() => {
        if (action === "Edit" || action === "View") {
            projectcode_ = props.rowdata.ProjectCode;
            projectname_ = props.rowdata.ProjectName;
        }
        else {
            projectcode_ = props.codedata;
            projectname_ = props.namedata;
        }
        setProjectOrderObj((prevState) => ({
            ...prevState,
            ProjectCode: projectcode_,
            ProjectName: projectname_,
        }));
        setOrderObjResponsive((prevState) => ({
            ...prevState,
            ProjectCode: projectcode_,
            ProjectName: projectname_,
        }));
        fetchProjectDocuments(projectcode_);
        fetchDocumentMaster();
        setLoading(false);
    }, [props.codedata]);

    useEffect(() => {
        if (action === "Add") {
            if (userDetails.displayContractFinInfo === "No") {
                setInactiveStatus(true);
            }
        }

        if (action === "Edit") {
            let projStatusCheck = props.rowdata.DeleteIndication;
            if (projStatusCheck === "Inactive" || projStatusCheck === "Closed" || userDetails.displayContractFinInfo === "No") {
                setInactiveStatus(true);
            }
        }
    }, [])

    const headerName = (field) => {
        let name;
        switch (field) {
            case "SeqNo":
                name = "Sr No";
                break;
            case "DocumentType":
                name = "Document Type";
                break;
            case "DocumentName":
                name = "Document Name";
                break;
            case "Attachment":
                name = "Attachemnt";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "SeqNo":
                name = "Sr No";
                break;
            case "DocumentType":
                name = "Document Type";
                break;
            case "DocumentName":
                name = "Document Name";
                break;
            case "Attachment":
                name = "Attachemnt";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fetchProjectDocuments = async (code) => {
        setLoading(true);
        const result = await getProjectDocuments(code);
        if (result.length > 0) {
            result.sort((a, b) => (a.SeqNo > b.SeqNo) ? 1 : -1);
            if (result.length !== 0) {
                setTabData(result);
                setProjectOrderObj(Object.assign({}, result[0]));
            }
        }
        else {
            setTabData([]);
        }
        setLoading(false);
    }

    const addNewRow = () => {
        gridRef.current.gridOptionsService.api.stopEditing();
        fetchDocumentMaster();
        if (projectOrderObj.ProjectCode === '' && orderObjResponsive.ProjectCode === '') {
            MySwal.fire({
                text: "Please add project first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        else {
            if (window.innerWidth > 768) {
                let add = true;
                tabData.forEach(element => {
                    if (element.Attachment === "") {
                        add = false;
                    }
                    else if (element.DocumentName === "") {
                        add = false;
                    }
                });
                if (add) {
                    let lastrow = { SeqNo: gridApi.getDisplayedRowCount() + 1, DocumentType: 'Final Offer', DocumentName: '', Attachment: '', DeleteIndication: "Active", CreatedBy: userDetails.userId, CreatedOn: todayDate, CreatedByName: userDetails.userName, RowInd: "New" }
                    gridApi.applyTransaction({ add: [lastrow], addIndex: gridApi.getDisplayedRowCount() });
                    let temp = getRowData();
                    setTabData(temp);
                    // props.setCustomClass1();
                }
                else {
                    MySwal.fire({
                        text: "Please enter all mandatory fields.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
            }
            else {
                setAddPopUpResponsive(true);
                setOrderObjResponsive((prevState) => ({
                    ...prevState,
                    CreatedOn: todayDate,
                    CreatedBy: userDetails.userId,
                    CreatedByName: userDetails.userName,
                    ModifiedOn: todayDate,
                    ModifiedBy: userDetails.userId,
                    DeleteIndication: "Active",
                    DocumentType: "",
                    DocumentName: "",
                    Attachment: "",
                    SeqNo: gridApi.getDisplayedRowCount() + 1
                }));
            }
            setSubAction("Add");
        }
    };

    const handleChangeOnDD = (event) => {
        let index = gridRef.current.gridOptionsService.api.getFocusedCell().rowIndex;
        let ids = [...tabData]
        ids[index].DocumentType = event.target.value;
        setTabData(ids);
    }

    const handleChangeOnAddRow = (event) => {
        let ids = [...tabData];
        let index = event.node.rowIndex;
        ids[index]["DocumentName"] = event.value;
        setTabData(ids);
    }

    const handleChangeResponsive = (e, val) => {
        if (val === "File") {
            let isError = false;
            const newFile = e[0];
            let fileError = Validate(newFile, "allFormats", 26214400);
            if (fileError === "") {
                setRespFiles(newFile);
                setOrderObjResponsive((prevState) => ({
                    ...prevState,
                    Attachment: e[0].name
                }));
            }
            else {
                isError = true;
                MySwal.fire({
                    text: fileError,
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
        }
        else {
            const { name, value } = e.target;
            setOrderObjResponsive((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const defaultColDef = useMemo(() => {
        return defaultEditableCol();
    }, []);

    const handleChangeStatus = async (file, index) => {
        let isError = false;
        const newFile = file[0];
        let fileError = Validate(newFile, "allFormats", 26214400);
        if (fileError === "") {
            const updatedList = [...files, newFile];
            setFiles(updatedList);
            let rowData = [...tabData];
            rowData[index].Attachment = file[0].name;
            setTabData(rowData);
        }
        else {
            isError = true;
            MySwal.fire({
                text: fileError,
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        return isError;
    };

    const filteredResult = useMemo(() => {
        let firstPageIndex = 0;
        if (currentPage > 1) {
            firstPageIndex = (currentPage - 1) * pageSize;
        }
        if (firstPageIndex >= tabData.length) {
            firstPageIndex = 0;
            const totalPageCount = Math.ceil(tabData.length / pageSize);
            if (totalPageCount > 0) {
                setCurrentPage(totalPageCount)
            } else {
                setCurrentPage(1)
            }
        }
        let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
        return tabData ? tabData.slice(firstPageIndex, lastPageIndex) : [];
    }, [currentPage, tabData, pageSize]);

    const handleActionEvent = async (params, event) => {
        if (params.RowInd === "New") {
            let tempData = getRowData();
            let tempFiles = files;
            let index = tempData.findIndex((element) => element.DocumentName === params.DocumentName && element.SeqNo === params.SeqNo && element.RowInd === "New");
            tempData.splice(index, 1);
            tempFiles.splice(index, 1);
            setTabData(tempData)
            setFiles(tempFiles)
        }
        else {
            let confirmBoxText = "Are you sure, do you want to delete Project Order Document?";
            let confirmButtonText = "Delete";
            let resultresp;
            MySwal.fire({
                text: confirmBoxText,
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonColor: "#16911B",
                confirmButtonText: confirmButtonText,
                reverseButtons: true,
                customClass: "swal-confirmation",
                title: "Delete Project Order document"
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    params.ModifiedBy = userDetails.userId;
                    params.ModifiedByName = userDetails.userName;
                    params.ModifiedOn = todayDate;
                    resultresp = DeleteProjectOrder(params);
                    let ids = tabData;
                    let index = ids.map(x => {
                        return x.SeqNo;
                    }).indexOf(params.SeqNo);
                    ids.splice(index, 1);
                    // setTabData(ids);
                    resultresp.then(async (resp) => {
                        if (resp.status !== 500) {
                            let len = ids.length;
                            for (let l = 0; l < ids.length; l++) {
                                let m = l + 1;
                                ids[l].SeqNo = m;
                                ids[l].SK = "ProjectOrder#SeqNo#" + m;
                            }
                            if (len > 0) {
                                await AddProjectOrder(ids);
                            }
                            const result1 = await getProjectDocuments(params.ProjectCode);
                            if (result1.length > len) {
                                for (let k = 0; k < result1.length; k++) {
                                    if (result1[k].SeqNo > len)
                                        DeleteProjectOrder(result1[k]);
                                }
                            }
                            setLoading(false);
                            MySwal.fire({
                                text: resp.message,
                                imageUrl: successImage,
                                customClass: "swal-success"
                            });
                        }
                    })
                        .catch((error) => {
                            setLoading(false);
                            MySwal.fire({
                                text: "Unable to Process Request",
                                imageUrl: errorImage,
                                customClass: "swal-success",
                            });
                        })
                        .finally(() => {
                            fetchProjectDocuments(params.ProjectCode);
                        });
                }
            })
        }
    }

    function getRowData() {
        let rowData = [];
        gridRef.current.gridOptionsService.api.forEachNode(node => rowData.push(node.data));
        return rowData;
    }

    const uploadFiles = async (signedURL) => {
        console.log("url ", signedURL);
        try {
            if (signedURL) {
                let i = 0;
                for (let url of signedURL) {
                    await axios.put(url, files[i]);
                    i++;
                }
            }
            return "";
        } catch (error) {
            console.log("Error in File Upload to S3: ", error);
            return " Error in file upload.";
        }
    }

    const validationMsg = (action) => {
        let isError = false;
        tabData.forEach(element => {
            if (element.DocumentName === "") {
                isError = true;
            }
            else if (element.Attachment === "") {
                isError = true;
            }
        });
        return isError;
    }

    const handleSubmit = () => {
        gridRef.current.gridOptionsService.api.stopEditing();
        // e.preventDefault();
        if (!validationMsg(action) && tabData.length > 0) {
            let resultresp;
            let confirmBoxText = "";
            let confirmButtonText = "";
            if (action === "Add") {
                confirmBoxText = "Are you sure, do you want to add new Project Order document?";
                confirmButtonText = "Add";
            }
            else if (action == "Edit") {
                confirmBoxText = "Are you sure, do you want to update Project Order document?";
                confirmButtonText = "Update";
            }
            MySwal.fire({
                text: confirmBoxText,
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonColor: "#16911B",
                confirmButtonText: confirmButtonText,
                reverseButtons: true,
                customClass: "swal-confirmation",
                title: action + " Project Order document"
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    let temp = getRowData();
                    setTabData(temp);
                    for (let i = 0; i < tabData.length; i++) {
                        let element = tabData[i];
                        element["SeqNo"] = i + 1;
                        element["ProjectCode"] = projectOrderObj.ProjectCode;
                        element["ProjectName"] = projectOrderObj.ProjectName;
                        element["PK"] = "ProjectCode#" + projectOrderObj.ProjectCode;
                        element["SK"] = "ProjectOrder#SeqNo#" + element.SeqNo;
                        element["ModifiedBy"] = userDetails.userId;
                        element["ModifiedOn"] = todayDate;
                        element["ModifiedByName"] = userDetails.userName;
                    }
                    resultresp = AddProjectOrder(tabData);
                    resultresp.then(async (resp) => {
                        if (resp.status !== 500) {
                            let fileUploadMsg = await uploadFiles(resp.signedURL);
                            // props.setCustomClass();
                            setLoading(false);
                            MySwal.fire({
                                text: resp.message,
                                imageUrl: successImage,
                                customClass: "swal-success"
                            });
                        }
                        return true;
                    })
                        .catch((error) => {
                            setLoading(false);
                            MySwal.fire({
                                text: "Unable to Process Request",
                                imageUrl: errorImage,
                                customClass: "swal-success"
                            });
                        })
                        .finally(() => {
                            fetchProjectDocuments(projectOrderObj.ProjectCode);
                        });
                }
            })
        }
        else {
            let errmsg = "";
            if (tabData.length === 0) {
                errmsg = "Please add at least one record first.";
            }
            else {
                errmsg = "Please enter all mandatory fields.";
            }
            MySwal.fire({
                text: errmsg,
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
    };

    useImperativeHandle(ref, () => ({
        funcOnNext: async () => {
            let res = false
            if (window.innerWidth > 768) {
                res = await handleSubmit();
                return res
            } else {
                return true;
            }
        }
    }));

    const saveOrderInfo = (e) => {
        let result;
        let confirmBoxText = "";
        let confirmButtonText = "";

        if (subAction === "Add") {
            let existInd = tabData.filter((item) => item.DocumentName.toLowerCase() === orderObjResponsive.DocumentName.toLowerCase())

            if (existInd?.length > 0) {
                setOrderObjResponsive((prevState) => ({
                    ...prevState,
                    DocumentName: ""
                }));
                MySwal.fire({
                    text: "Duplicate Document Name.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
        }
        if (orderObjResponsive.Attachment !== "" && orderObjResponsive.DocumentName !== "" && orderObjResponsive.DocumentType !== "") {
            if (subAction === "Add") {
                confirmBoxText = "Are you sure, do you want to add new Project Order document?";
                confirmButtonText = "Add";
            }
            else if (subAction == "Edit") {
                confirmBoxText = "Are you sure, do you want to update Project Order document?";
                confirmButtonText = "Update";
            }
            MySwal.fire({
                text: confirmBoxText,
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonColor: "#16911B",
                confirmButtonText: confirmButtonText,
                reverseButtons: true,
                customClass: "swal-confirmation",
                title: subAction + " Project Order document"
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    if (subAction === "Add" || tabData.length === 0) {
                        orderObjResponsive["ProjectCode"] = orderObjResponsive.ProjectCode;
                        orderObjResponsive["ProjectName"] = orderObjResponsive.ProjectName;
                        orderObjResponsive["PK"] = "ProjectCode#" + orderObjResponsive.ProjectCode;
                        orderObjResponsive["SK"] = "ProjectOrder#SeqNo#" + orderObjResponsive.SeqNo;
                        orderObjResponsive.ModifiedOn = todayDate;
                        orderObjResponsive.ModifiedBy = userDetails.userId;
                        orderObjResponsive.ModifiedByName = userDetails.userName;
                        result = AddOrderResponsive(orderObjResponsive);
                    }
                    result.then(async (resp) => {
                        if (resp.status !== 500) {
                            // props.setCustomClass();
                            try {
                                if (resp.signedURL) {
                                    await axios.put(resp.signedURL, respFile);
                                }
                            } catch (error) {
                                console.log("Error in File Upload to S3: ", error);
                            }
                            setLoading(false);
                            MySwal.fire({
                                text: resp.message,
                                imageUrl: successImage,
                                customClass: "swal-success"
                            });
                            setAddPopUpResponsive(false);
                        }
                    })
                        .catch((error) => {
                            setLoading(false);
                            MySwal.fire({
                                text: "Unable to Process Request",
                                imageUrl: errorImage,
                                customClass: "swal-success"
                            });
                        })
                        .finally(() => {
                            fetchProjectDocuments(projectOrderObj.ProjectCode);
                        });
                }
            });
        }
        else {
            MySwal.fire({
                text: "Please enter all mandatory fields.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
    }

    const onCloseResponsiveModal = () => {
        setAddPopUpResponsive(false);
    }

    const handleEdit = async (row) => {
        setOrderObjResponsive({
            SK: row.SK,
            PK: row.PK,
            ProjectName: row.ProjectName,
            ProjectCode: row.ProjectCode,
            DocumentName: row.DocumentName,
            DocumentType: row.DocumentType,
            Attachment: row.Attachment,
            SeqNo: row.SeqNo,
            DeleteIndication: row.DeleteIndication,
            CreatedOn: row.CreatedOn,
            CreatedBy: row.CreatedBy,
            CreatedByName: row.CreatedByName,
            ModifiedOn: row.ModifiedOn,
            ModifiedBy: row.ModifiedBy
        });
        setAddPopUpResponsive(true);
        setSubAction("Edit");
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Form
                id="projectorder"
                name="projectorder"
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Modal.Body>
                    <Row>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjectCode"
                                    type="text"
                                    name="ProjectCode"
                                    maxLength="50"
                                    placeholder=" "
                                    value={projectOrderObj.ProjectCode}
                                    readOnly
                                />
                                <Form.Label htmlFor="ProjectCode">Project Code</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjectName"
                                    type="text"
                                    name="ProjectName"
                                    maxLength="250"
                                    placeholder=" "
                                    value={projectOrderObj.ProjectName}
                                    readOnly
                                />
                                <Form.Label htmlFor="ProjectName">Project</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <div className={action === "View" || inactiveStatus ? "prevHideButton" : "addNewRow"}>
                            <BsPlusLg size={20} className="plusIcon" />
                            &nbsp;
                            <Link onClick={() => addNewRow()} className="blueColor">Add New</Link>
                        </div>
                    </Row>
                    {addPopUpResponsive ?
                        (
                            <div className="backdropModal">
                                <Modal
                                    {...props}
                                    size="sm"
                                    show={addPopUpResponsive}
                                    id="projectModal1"
                                    centered
                                    onHide={() => setAddPopUpResponsive(false)}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title className="modalTitle">
                                            Add Order Document
                                            <br />
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
                                        <Row>
                                            <Col xs={12}>
                                                <Form.Group className="g-input">
                                                    <Form.Control
                                                        id="SeqNo"
                                                        type="text"
                                                        name="SeqNo"
                                                        maxLength="50"
                                                        placeholder=" "
                                                        value={orderObjResponsive.SeqNo}
                                                        readOnly
                                                    />
                                                    <Form.Label htmlFor="SeqNo">Sr.No.</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group className="g-select">
                                                    <Form.Select
                                                        id="DocumentType"
                                                        name="DocumentType"
                                                        type="text"
                                                        placeholder=" "
                                                        value={orderObjResponsive.DocumentType}
                                                        onChange={handleChangeResponsive}
                                                        disabled={action === "View" ? true : false}
                                                    >
                                                        <option value="">Select</option>
                                                        {documentResult ? documentResult.map((documentResult) => (
                                                            <option key={documentResult.Code} value={documentResult.Name}>{documentResult.Name}</option>
                                                        )) : null
                                                        }
                                                    </Form.Select>
                                                    <Form.Label className="frm-label">Document Type</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group className="g-input">
                                                    <Form.Control
                                                        id="DocumentName"
                                                        type="text"
                                                        name="DocumentName"
                                                        maxLength="100"
                                                        placeholder=" "
                                                        value={orderObjResponsive.DocumentName}
                                                        onChange={handleChangeResponsive}
                                                    />
                                                    <Form.Label htmlFor="DocumentName">Document Name</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group className="g-input">
                                                    {orderObjResponsive.Attachment !== "" ?
                                                        <Form.Control
                                                            id="Attachment"
                                                            type="text"
                                                            name="Attachment"
                                                            maxLength="100"
                                                            placeholder=" "
                                                            value={orderObjResponsive.Attachment}
                                                            readOnly
                                                        /> :
                                                        <div>
                                                            <Dropzone
                                                                onDrop={(acceptedFiles) =>
                                                                    handleChangeResponsive(acceptedFiles, "File")
                                                                }
                                                                value={orderObjResponsive.Attachment}
                                                                multiple={false}
                                                                maxFiles={1}
                                                            >
                                                                {({ getRootProps, getInputProps }) =>
                                                                (
                                                                    <div {...getRootProps()} className="dropZoneContainer1">
                                                                        <input {...getInputProps()} />
                                                                        <img src={Upload} alt="Upload" />
                                                                        <div className="dropTextContainer1">
                                                                            <span className="dropText">
                                                                                Browse file here..
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    }
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button variant="outline-success" className="semiBoldToggleButton cancelBtn" onClick={onCloseResponsiveModal}>
                                                    Cancel
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button variant="success" type="button" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={saveOrderInfo}>
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        )
                        : <></>
                    }
                </Modal.Body>
            </Form >
            <Container fluid className="grid-comp-containerCss">
                <div className="ag-theme-alpine agGridContainer d-none d-md-block d-lg-block">
                    <AgGridReact
                        ref={gridRef}
                        rowData={tabData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        fieldNames={fieldName}
                        headerNames={headerName}
                        onGridReady={onGridReady}
                        // pagination={true}
                        // paginationPageSize={7}
                        rowSelection={"multiple"}
                        onCellValueChanged={e => handleChangeOnAddRow(e)}
                        enableBrowserTooltips={true}
                        singleClickEdit={true}
                    />
                </div>
                <div className="market-wrapper d-md-none d-lg-none cardContainerResponsive">
                    {
                        filteredResult.map((selection, index) =>
                            <Card className="cardContainer" key={index}>
                                <Card.Body className="cardContainerResponsive">
                                    <div style={{ textAlign: "right", marginBottom: "0.4rem" }}>
                                        <ActionRenderer className={inactiveStatus ? "hideDisplay" : "showDisplay"} selectedData={selection} actionName={actionButtons} handleEditEvent={(selection) => handleEdit(selection)} handleDeleteEvent={() => handleActionEvent(selection, "Delete")} handleDownloadEvent={() => handleDownload(selection)} />
                                    </div>
                                    {columnDefs.map((header, index) =>
                                        <Row key={index} className="responsive-row">
                                            <Col className="colHeader">{header.headerName}</Col>
                                            <Col className="colData">{selection[header.field]}</Col>
                                        </Row>
                                    )}
                                </Card.Body>
                            </Card>
                        )}
                    {filteredResult?.length > 0 && (
                        <Pagination
                            currentPage={currentPage}
                            totalCount={tabData ? tabData.length : 0}
                            data={tabData}
                            pageSize={pageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        />)}
                </div>
            </Container>
        </>
    );
});

export default ProjectOrderDocuments;
