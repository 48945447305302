import React, { useEffect, useState, useMemo, useRef, useContext, useImperativeHandle, forwardRef } from "react";
import { Form, Modal, Row, Col, Container, Card, Stack, Button, Spinner } from "react-bootstrap";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "../../utils/AddEditProjectMaster.css";
import "../../masters/GridMaster.css";
import { BsPlusLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import ActionRenderer from "../ActionRenderer";
import StatusRenderer from "../StatusRenderer";
import { AgGridReact } from "ag-grid-react";
import SingleValuePopUp from "../../utils/SingleValuePopUp";
import { defaultEditableCol, RenderIf, getCurrentDateTime } from "../../../functions/gbcs-functions";
import { getProjectServiceEqui, AddServiceEquipment, AddSrvcEqpmntResponsive, UpdateSrvcEqpmntResponsive, DeleteProjectService, inactiveProjectService } from "../../../services/project-service";
import { getActiveChklstVersion } from "../../../services/checklist-master-service";
import { BiSelectMultiple } from "react-icons/bi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import Pagination from "../../utils/Pagination";
import { AuthContext } from "../../../contexts/AuthContext";
import { isEmail, isMobNumber } from "../../../functions/validations";
import { getProjTranChklst } from "../../../services/project-transaction-service";
import { addLogDetails } from "../../../services/logger-entry-service";

const ProjectServicesEqpmnts = forwardRef((props, ref) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, dataControl: context.dataControl, userId: context.userId }
    let action = props.action;
    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [addPopUpResponsive, setAddPopUpResponsive] = useState(false);
    const [pageSize] = useState(3);
    const [subAction, setSubAction] = useState();
    const [tabData, setTabData] = useState([]);
    const [largestSeqNo, setLargestSeqNo] = useState(0);
    let todayDate = getCurrentDateTime("-");
    const inactiveStatus = (Object.keys(props.rowdata).length > 0 && props.rowdata.DeleteIndication !== "Active") ? true : false;
    const [loading, setLoading] = useState(true);

    let projectcode_ = "";
    let projectname_ = "";
    const [actionButtons, setActionButtons] = useState(action === "View" ?
        {
            ViewAction: false,
            EditAction: false,
            DownloadAction: false,
            DeleteAction: false
        } :
        {
            ViewAction: false,
            EditAction: false,
            DownloadAction: false,
            DeleteAction: true
        }
    );

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
        additionalParams: ""
    });

    const showPopUp = (name, type, key, value, additionalParams) => {
        setPopUp({ name, type, key, value, additionalParams });
        setShowForm(true);
    };

    function getRowData() {
        let rowData = [];
        gridRef.current.gridOptionsService.api.forEachNode(node => rowData.push(node.data));
        return rowData;
    }

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Project Equipment / Activity Status",
            headerTooltip: "Project Equipment / Activity Status",
            field: "DeleteIndication",
            tooltipField: "DeleteIndication",
            minWidth: 150,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit"
                }
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Active', 'Inactive'],
            }
        },
        {
            headerName: "Service Code",
            field: "ServiceCode",
            width: 150,
            tooltipField: "ServiceCode",
            hide: true
        },
        {
            headerName: "Service Name",
            headerTooltip: "Service Name",
            field: "ServiceName",
            tooltipField: "ServiceName",
            minWidth: 150,
            editable: false,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit blockCss"
                }
            },
            cellRenderer: function (params) {
                if (params.data.RowInd === "New") {
                    return <>{params.value}<BiSelectMultiple
                        className="modalPopUpIcon"
                        size={20}
                        onClick={() => {
                            showPopUp(
                                "Service Master",
                                "Single",
                                "ServiceName",
                                params.value,
                                [{}, { ind: "Web", index: params.rowIndex }]
                            );
                        }}
                    /></>
                }
                else {
                    return <>{params.value}</>
                }
            }
        },
        {
            headerName: "Equipment / Activity",
            headerTooltip: "Equipment / Activity",
            field: "EquipmentName",
            tooltipField: "EquipmentName",
            minWidth: 180,
            editable: false,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit blockCss"
                }
            },
            cellRenderer: function (params) {
                if (params.data.RowInd === "New") {
                    return <>{params.value}<BiSelectMultiple
                        className="modalPopUpIcon"
                        size={20}
                        onClick={() => {
                            showPopUp(
                                "Equipment Master",
                                "Single",
                                "EquipmentName",
                                params.value,
                                [{ ServiceCode: params.data.ServiceCode }, { ind: "Web", index: params.rowIndex }]
                            );
                        }}
                    /></>
                }
                else {
                    return <>{params.value}</>
                }
            }
        },
        {
            headerName: "Equipment / Activity Tag",
            headerTooltip: "Equipment / Activity Tag",
            field: "EquipmentTag",
            tooltipField: "EquipmentTag",
            minWidth: 180,
            cellEditorParams: { maxLength: 200 },
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit blockCss"
                }
                else {
                    return "blockCss"
                }
            },
            editable: function (params) {
                return params.data.RowInd === "New" ? true : false
            },
            valueGetter: (params) => {
                return params.data.EquipmentTag;
            },
            valueSetter: (params) => {
                let allData = getRowData();
                let existInd = allData.filter((item) => item.EquipmentTag.toLowerCase() === params.newValue.toLowerCase())

                if (existInd?.length > 0) {
                    params.data.EquipmentTag = "";
                    MySwal.fire({
                        text: "Duplicate Equipment / Activity Tags.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
                else {
                    params.data.EquipmentTag = params.newValue;
                }
                return;
            }
        },
        {
            headerName: "Contractor/Vendor",
            headerTooltip: "Contractor/Vendor",
            field: "Contractor",
            tooltipField: "Contractor",
            minWidth: 180,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit blockCss"
                }
                else {
                    return "blockCss"
                }
            },
            cellEditorParams: { maxLength: 200 },
            editable: function (params) {
                return params.data.RowInd === "New" ? true : false
            }
        },
        {
            headerName: "Email Id",
            headerTooltip: "Email Id",
            field: "EmailId",
            tooltipField: "EmailId",
            minWidth: 180,
            editable: !(action === "View" || inactiveStatus),
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit blockCss"
                }
                else {
                    return "blockCss"
                }
            },
            valueGetter: (params) => {
                return params.data.EmailId;
            },
            valueSetter: (params) => {
                let error = isEmail(params.newValue);
                if (!error.status) {
                    params.data.EmailId = params.newValue;
                    // props.setCustomClass1();
                }
                else {
                    MySwal.fire({
                        text: error.message,
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
                return;
            }
        },
        {
            headerName: "Mobile No.",
            headerTooltip: "Mobile No.",
            field: "MobileNo",
            tooltipField: "MobileNo",
            minWidth: 180,
            editable: !(action === "View" || inactiveStatus),
            resizable: false,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit blockCss"
                }
                else {
                    return "blockCss"
                }
            },
            valueGetter: (params) => {
                return params.data.MobileNo;
            },
            valueSetter: (params) => {
                let error = isMobNumber(params.newValue);
                if (!error) {
                    params.data.MobileNo = params.newValue;
                }
                else {
                    MySwal.fire({
                        text: "Enter valid Mobile No with country code. Eg. +91 8888888888",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
                return;
            }
        },
        {
            field: "",
            width: 60,
            minWidth: 60,
            editable: false,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit"
                }
            },
            cellRenderer: function (params) {
                if (!inactiveStatus) {
                    return <ActionRenderer selectedData={params} actionName={actionButtons} handleDeleteEvent={(params) => handleActionEvent(params.data, "Delete")} />
                }
            },
            pinned: 'right'
        }
    ]);

    const headerName = (field) => {
        let name;
        switch (field) {
            case "DeleteIndication":
                name = "Equipment / Activity Status";
                break;
            case "Service":
                name = "Service";
                break;
            case "Equipment":
                name = "Equipment / Activity";
                break;
            case "EquipmentTag":
                name = "Equipment / Activity Tag";
                break;
            case "Contractor":
                name = "Contractor";
                break;
            case "EmailId":
                name = "EmailId";
                break;
            case "MobileNo":
                name = "MobileNo";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Equipment / Activity Status":
                name = "DeleteIndication";
                break;
            case "Service":
                name = "Service";
                break;
            case "Equipment / Activity":
                name = "Equipment";
                break;
            case "Equipment / Activity Tag":
                name = "equipmenttag";
                break;
            case "Contractor":
                name = "Contractor";
                break;
            case "EmailId":
                name = "EmailId";
                break;
            case "MobileNo":
                name = "MobileNo";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const defaultColDef = useMemo(() => {
        return defaultEditableCol();
    }, []);

    function onGridReady(params) {
        setGridApi(params.api);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api;// <= assigned gridApi value on Grid ready
    }

    const [projectServiceObj, setProjectServiceObj] = useState(
        {
            SK: "",
            PK: "",
            ProjectName: "",
            ProjectCode: "",
            CreatedOn: "",
            CreatedBy: "",
            CreatedByName: "",
            ModifiedOn: "",
            ModifiedBy: "",
            ModifiedByName: "",
            DeleteIndication: "Active"
        }
    );

    const [serviceObjResponsive, setServiceObjResponsive] = useState({
        SK: "",
        PK: "",
        ProjectName: "",
        ProjectCode: "",
        ServiceName: "",
        ServiceCode: "",
        EquipmentName: "",
        EquipmentCode: "",
        EquipmentTag: "",
        DeleteIndication: "Active",
        Contractor: "",
        EmailId: "",
        MobileNo: "",
        CreatedOn: "",
        CreatedBy: "",
        CreatedByName: "",
        ModifiedOn: "",
        ModifiedBy: "",
        ModifiedByName: "",
        SeqNo: 0
    });

    const handleEdit = async (row) => {
        setServiceObjResponsive({
            SK: row.SK,
            PK: row.PK,
            SeqNo: row.SeqNo,
            ProjectName: row.ProjectName,
            ProjectCode: row.ProjectCode,
            ServiceName: row.ServiceName,
            ServiceCode: row.ServiceCode,
            EquipmentName: row.EquipmentName,
            EquipmentCode: row.EquipmentCode,
            EquipmentTag: row.EquipmentTag,
            DeleteIndication: row.DeleteIndication,
            Contractor: row.Contractor,
            EmailId: row.EmailId,
            MobileNo: row.MobileNo,
            CreatedOn: row.CreatedOn,
            CreatedBy: row.CreatedBy,
            CreatedByName: row.CreatedByName,
            ModifiedOn: row.ModifiedOn,
            ModifiedBy: row.ModifiedBy
        });
        setAddPopUpResponsive(true);
        setSubAction("Edit");
    }

    const onCloseResponsiveModal = () => {
        setAddPopUpResponsive(false);
    }

    const fetchProjectServiceEqui = async (code, logInd) => {
        setLoading(true);
        const result = await getProjectServiceEqui(code);
        if (result.length !== 0) {
            setTabData(result);
            setProjectServiceObj(Object.assign({}, result[0]));
            let max = 0;
            max = result[result.length - 1].SeqNo;
            result.forEach(element => {
                if (element.SeqNo > max) {
                    max = element.SeqNo;
                }
            });
            setLargestSeqNo(max);
            if (logInd && (action === "Edit" || action === "View")) {
                for (let item of result) {
                    await addLogDetails(item, "View", "PK#" + item.PK + "#SK#" + item.SK, "ProjectMasterLog", userDetails, "Entry");
                }
            }
        }
        else {
            setTabData([]);
        }
        setLoading(false);
    }

    const setPopUpData = async (data) => {
        if (data.value[1].ind === "Responsive") {
            if (data.name === "Service Master") {
                setServiceObjResponsive((prevState) => ({
                    ...prevState,
                    ServiceName: data.result.ServiceName,
                    ServiceCode: data.result.ServiceCode,
                    EquipmentName: "",
                    EquipmentCode: "",
                    EquipApplicableChecklist: ""
                }));
            }
            else if (data.name === "Equipment Master") {
                setServiceObjResponsive((prevState) => ({
                    ...prevState,
                    ServiceName: data.result.ServiceName,
                    ServiceCode: data.result.ServiceCode,
                    EquipmentName: data.result.EquipmentName,
                    EquipmentCode: data.result.EquipmentCode,
                    EquipApplicableChecklist: data.result.FATEquipApplicableChecklist + "," + data.result.SATEquipApplicableChecklist
                }));
            }
        }
        else {
            let ids = [...tabData];
            let index = data.value[1].index;
            if (data.name === "Service Master") {
                ids[index]["ServiceName"] = data.result.ServiceName;
                ids[index]["ServiceCode"] = data.result.ServiceCode;
                ids[index]["EquipmentName"] = "";
                ids[index]["EquipmentCode"] = "";
                ids[index]["EquipApplicableChecklist"] = "";
            }
            else if (data.name === "Equipment Master") {
                ids[index]["ServiceName"] = data.result.ServiceName;
                ids[index]["ServiceCode"] = data.result.ServiceCode;
                ids[index]["EquipmentName"] = data.result.EquipmentName;
                ids[index]["EquipmentCode"] = data.result.EquipmentCode;
                ids[index]["EquipApplicableChecklist"] = data.result.FATEquipApplicableChecklist + "," + data.result.SATEquipApplicableChecklist;
            }
            setTabData(ids);
        }
    };

    useEffect(() => {
        setLoading(true);
        if (window.innerWidth < 768) {
            setActionButtons({
                EditAction: true,
                DownloadAction: false,
                DeleteAction: true,
                ViewAction: false
            })
        }
        if (action === "Edit" || action === "View") {
            projectcode_ = props.rowdata.ProjectCode;
            projectname_ = props.rowdata.ProjectName;
        }
        else {
            projectcode_ = props.codedata;
            projectname_ = props.namedata;
        }
        setServiceObjResponsive((prev) => ({
            ...prev,
            ProjectCode: projectcode_,
            ProjectName: projectname_,
        }));
        setProjectServiceObj((prev) => ({
            ...prev,
            ProjectCode: projectcode_,
            ProjectName: projectname_,
        }));

        fetchProjectServiceEqui(projectcode_, true);
        setLoading(false);
    }, [props.codedata]);

    const saveServiceInfo = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!validateFields(serviceObjResponsive)) {
            if (subAction === "Add") {
                let existInd = tabData.filter((item) => item.EquipmentTag.toLowerCase() === serviceObjResponsive.EquipmentTag.toLowerCase());
                if (existInd?.length > 0) {
                    setServiceObjResponsive((prevState) => ({
                        ...prevState,
                        EquipmentTag: ""
                    }));
                    setLoading(false);
                    MySwal.fire({
                        text: "Duplicate Equipment / Activity Tags.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }

                let getFuncVer = false;
                let getPreFuncVer = false;
                if (serviceObjResponsive.EquipApplicableChecklist && serviceObjResponsive.EquipApplicableChecklist !== "") {
                    let checklists = serviceObjResponsive.EquipApplicableChecklist.split(",");
                    if (checklists.indexOf("Functional") !== -1) {
                        let exstFunc = tabData.filter((item) => item.EquipmentCode === serviceObjResponsive.EquipmentCode /*&& ! item.hasOwnProperty("RowInd")*/ && item.hasOwnProperty("ChklstFunctionalVersion"));
                        if (exstFunc?.length > 0) {
                            serviceObjResponsive.ChklstFunctionalVersion = exstFunc[0].ChklstFunctionalVersion;
                        }
                        else {
                            getFuncVer = true;
                        }
                    }

                    if (checklists.indexOf("Pre-Functional") !== -1) {
                        let exstPreFunc = tabData.filter((item) => item.EquipmentCode === serviceObjResponsive.EquipmentCode /*&& ! item.hasOwnProperty("RowInd")*/ && item.hasOwnProperty("ChklstPrefunctionalVersion"));
                        if (exstPreFunc?.length > 0) {
                            serviceObjResponsive.ChklstPrefunctionalVersion = exstPreFunc[0].ChklstPrefunctionalVersion;
                        }
                        else {
                            getPreFuncVer = true;
                        }
                    }

                    if (getFuncVer || getPreFuncVer) {
                        let invalidCategory = "";
                        let versionlst = await getActiveChklstVersion(serviceObjResponsive.EquipmentCode);
                        if (getFuncVer) {
                            let found1 = versionlst.filter((item) => item.ChecklistCategory === "Functional" && item.ChecklistVersion.startsWith("VER"));
                            if (found1?.length > 0) {
                                serviceObjResponsive.ChklstFunctionalVersion = found1[0].ChecklistVersion;
                            }
                            else {
                                invalidCategory = "Functional";
                            }
                        }
                        if (getPreFuncVer) {
                            let found1 = versionlst.filter((item) => item.ChecklistCategory === "Pre-Functional" && item.ChecklistVersion.startsWith("VER"));
                            if (found1?.length > 0) {
                                serviceObjResponsive.ChklstPrefunctionalVersion = found1[0].ChecklistVersion;
                            }
                            else {
                                if (invalidCategory !== "") {
                                    invalidCategory += " & ";
                                }
                                invalidCategory += "Pre-Functional";
                            }
                        }
                        if (invalidCategory !== "") {
                            setLoading(false);
                            MySwal.fire({
                                text: "Applicable Checklists Category " + invalidCategory + " of " + serviceObjResponsive.EquipmentName + " are not found in Checklist Master. Kindly first maintain the " + invalidCategory + " Checklists of " + serviceObjResponsive.EquipmentName + " before proceeding.",
                                imageUrl: errorImage,
                                customClass: "swal-success"
                            });
                            return;
                        }
                    }
                }
            }

            let result;
            let confirmBoxText = "";
            let confirmButtonText = "";
            if (action === "Add") {
                confirmBoxText = "Are you sure, do you want to add new Project Service?";
                confirmButtonText = "Add";
            }
            else if (action == "Edit") {
                confirmBoxText = "Are you sure, do you want to update Project Service?";
                confirmButtonText = "Update";
            }
            MySwal.fire({
                text: confirmBoxText,
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonColor: "#16911B",
                confirmButtonText: confirmButtonText,
                reverseButtons: true,
                customClass: "swal-confirmation",
                title: action + " Project Service"
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    if (subAction === "Add" || tabData.length === 0) {
                        let num = largestSeqNo;
                        num = num + 1;
                        setLargestSeqNo(num);
                        serviceObjResponsive.CreatedBy = userDetails.userId;
                        serviceObjResponsive.CreatedByName = userDetails.userName;
                        serviceObjResponsive.CreatedOn = todayDate;
                        serviceObjResponsive.ModifiedOn = todayDate;
                        serviceObjResponsive.ModifiedBy = userDetails.userId;
                        serviceObjResponsive.ModifiedByName = userDetails.userName;
                        serviceObjResponsive["PK"] = "ProjectCode#" + serviceObjResponsive.ProjectCode;
                        serviceObjResponsive["SK"] = "ProjectService#SeqNo#" + num;
                        serviceObjResponsive.SeqNo = num;
                        delete serviceObjResponsive.EquipApplicableChecklist;
                        result = await AddSrvcEqpmntResponsive(serviceObjResponsive);
                    }
                    else if (subAction === "Edit" || tabData.length > 0) {
                        serviceObjResponsive.ModifiedOn = todayDate;
                        serviceObjResponsive.ModifiedBy = userDetails.userId;
                        serviceObjResponsive.ModifiedByName = userDetails.userName;
                        result = await UpdateSrvcEqpmntResponsive(serviceObjResponsive);
                    }
                    if (result.status) {
                        await addLogDetails(serviceObjResponsive, subAction, "PK#" + serviceObjResponsive.PK + "#SK#" + serviceObjResponsive.SK, "ProjectMasterLog", userDetails, "Entry");
                        // props.setCustomClass();
                        setLoading(false);
                        MySwal.fire({
                            text: result.message,
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                fetchProjectServiceEqui(serviceObjResponsive.ProjectCode, false);
                            },
                        });
                        setAddPopUpResponsive(false);
                    }
                    else {
                        setLoading(false);
                        MySwal.fire({
                            text: "Unable to Process Request",
                            imageUrl: errorImage,
                            customClass: "swal-success"
                        });
                    }
                }
            });
            setLoading(false);
        } else {
            setLoading(false);
            MySwal.fire({
                text: "Please enter all mandatory fields or validate Email Id & Mobile No.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        setLoading(false);
    }

    const validateFields = (element) => {
        let isError = false;
        if (element.ServiceName === "") {
            isError = true;
        }
        else if (element.EquipmentName === "") {
            isError = true;
        }
        else if (element.EquipmentTag === "") {
            isError = true;
        }
        else if (element.EmailId !== "") {
            let error = isEmail(element.EmailId);
            if (error.status) {
                MySwal.fire({
                    text: error.message,
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return true;
            }
        }
        else if (element.MobileNo !== "") {
            let error = isMobNumber(element.MobileNo);
            if (error) {
                MySwal.fire({
                    text: "Please enter valid Mobile No.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return true;
            }
        }
        return isError;
    }

    const validationMsg = (action) => {
        let isError = false;
        tabData.forEach(element => {
            isError = validateFields(element);
            if (isError) {
                return isError;
            }
        });
        return isError;
    }

    const handleSubmit = async () => {
        // e.preventDefault();        
        gridRef.current.gridOptionsService.api.stopEditing();
        if (action == "View") {
            props.onHide();
        }
        else {
            if (!validationMsg(action) && tabData.length > 0) {
                setLoading(true);
                //Changes for checklist version
                for (let dt of tabData) {
                    let getFuncVer = false;
                    let getPreFuncVer = false;
                    if (dt.RowInd === "New" && dt.EquipApplicableChecklist !== "") {
                        let checklists = dt.EquipApplicableChecklist.split(",");
                        if (checklists.indexOf("Functional") !== -1) {
                            let exstFunc = tabData.filter((item) => item.EquipmentCode === dt.EquipmentCode /*&& ! item.hasOwnProperty("RowInd")*/ && item.hasOwnProperty("ChklstFunctionalVersion"));
                            if (exstFunc?.length > 0) {
                                dt.ChklstFunctionalVersion = exstFunc[0].ChklstFunctionalVersion;
                            }
                            else {
                                getFuncVer = true;
                            }
                        }

                        if (checklists.indexOf("Pre-Functional") !== -1) {
                            let exstPreFunc = tabData.filter((item) => item.EquipmentCode === dt.EquipmentCode /*&& ! item.hasOwnProperty("RowInd")*/ && item.hasOwnProperty("ChklstPrefunctionalVersion"));
                            if (exstPreFunc?.length > 0) {
                                dt.ChklstPrefunctionalVersion = exstPreFunc[0].ChklstPrefunctionalVersion;
                            }
                            else {
                                getPreFuncVer = true;
                            }
                        }

                        if (getFuncVer || getPreFuncVer) {
                            let invalidCategory = "";
                            let versionlst = await getActiveChklstVersion(dt.EquipmentCode);
                            if (getFuncVer) {
                                let found1 = versionlst.filter((item) => item.ChecklistCategory === "Functional" && item.ChecklistVersion.startsWith("VER"));
                                if (found1?.length > 0) {
                                    dt.ChklstFunctionalVersion = found1[0].ChecklistVersion;
                                }
                                else {
                                    invalidCategory = "Functional";
                                }
                            }
                            if (getPreFuncVer) {
                                let found1 = versionlst.filter((item) => item.ChecklistCategory === "Pre-Functional" && item.ChecklistVersion.startsWith("VER"));
                                if (found1?.length > 0) {
                                    dt.ChklstPrefunctionalVersion = found1[0].ChecklistVersion;
                                }
                                else {
                                    if (invalidCategory !== "") {
                                        invalidCategory += " & ";
                                    }
                                    invalidCategory += "Pre-Functional";
                                }
                            }
                            if (invalidCategory !== "") {
                                setLoading(false);
                                MySwal.fire({
                                    text: "Applicable Checklists Category " + invalidCategory + " of " + dt.EquipmentName + " are not found in Checklist Master. Kindly first maintain the " + invalidCategory + " Checklists of " + dt.EquipmentName + " before proceeding.",
                                    imageUrl: errorImage,
                                    customClass: "swal-success"
                                });
                                return;
                            }
                        }
                    }
                }

                let result;
                let confirmBoxText = "";
                let confirmButtonText = "";
                if (action === "Add") {
                    confirmBoxText = "Are you sure, do you want to add new Project Service?";
                    confirmButtonText = "Add";
                }
                else if (action == "Edit") {
                    confirmBoxText = "Are you sure, do you want to update Project Service?";
                    confirmButtonText = "Update";
                }
                MySwal.fire({
                    text: confirmBoxText,
                    showDenyButton: true,
                    cancelButtonColor: "#fff",
                    confirmButtonColor: "#16911B",
                    confirmButtonText: confirmButtonText,
                    reverseButtons: true,
                    customClass: "swal-confirmation",
                    title: action + " Project Service"
                }).then(async (result2) => {
                    if (result2.isConfirmed) {
                        setLoading(true);
                        for (let i = 0; i < tabData.length; i++) {
                            let element = tabData[i];
                            element["SeqNo"] = i + 1;
                            element["PK"] = "ProjectCode#" + projectServiceObj.ProjectCode;
                            element["SK"] = "ProjectService#SeqNo#" + element.SeqNo;
                            element["CreatedBy"] = userDetails.userId;
                            element["CreatedByName"] = userDetails.userName;
                            element["CreatedOn"] = todayDate;
                            element["ModifiedOn"] = todayDate;
                            element["ModifiedBy"] = userDetails.userId;
                            element["ModifiedByName"] = userDetails.userName;
                            element["ProjectCode"] = projectServiceObj.ProjectCode;
                            element["ProjectName"] = projectServiceObj.ProjectName;
                            if (element.hasOwnProperty("RowInd")) {
                                delete element.RowInd;
                                delete element.EquipApplicableChecklist
                            }
                        }

                        result = await AddServiceEquipment(tabData);
                        if (result.status) {
                            for (let item of tabData) {
                                await addLogDetails(item, action, "PK#" + item.PK + "#SK#" + item.SK, "ProjectMasterLog", userDetails, "Entry");
                            }
                            // props.setCustomClass();
                            let temp = await getProjectServiceEqui(projectServiceObj.ProjectCode);
                            for (let k = 0; k < temp.length; k++) {
                                if (tabData.length < temp[k].SeqNo) {
                                    DeleteProjectService(temp[k]);
                                }
                            }
                            setLoading(false);
                            MySwal.fire({
                                text: result.message,
                                imageUrl: successImage,
                                customClass: "swal-success",
                                didClose: () => {
                                    fetchProjectServiceEqui(projectServiceObj.ProjectCode, false);
                                },
                            });
                            return true;
                        }
                        else {
                            setLoading(false);
                            MySwal.fire({
                                text: "Unable to Process Request",
                                imageUrl: errorImage,
                                customClass: "swal-success"
                            });
                        }
                    }
                });
                setLoading(false);
            }
            else {
                let errmsg = "";
                if (tabData.length === 0) {
                    errmsg = "Please add at least one record first.";
                }
                else {
                    errmsg = "Please enter all mandatory fields or validate Email Id & Mobile No.";
                }
                setLoading(false);
                MySwal.fire({
                    text: errmsg,
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
        }
    };

    useImperativeHandle(ref, () => ({
        funcOnNext: async () => {
            let res = false
            if (window.innerWidth > 768) {
                res = await handleSubmit();
                return res
            } else {
                return true;
            }
        }
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProjectServiceObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        // props.setCustomClass1();
    };

    const handleChangeResponsive = (e) => {
        const { name, value } = e.target;
        setServiceObjResponsive((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const filteredResult = useMemo(() => {
        let firstPageIndex = 0;
        if (currentPage > 1) {
            firstPageIndex = (currentPage - 1) * pageSize;
        }
        if (firstPageIndex >= tabData.length) {
            firstPageIndex = 0;
            const totalPageCount = Math.ceil(tabData.length / pageSize);
            if (totalPageCount > 0) {
                setCurrentPage(totalPageCount)
            } else {
                setCurrentPage(1)
            }
        }
        let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
        return tabData ? tabData.slice(firstPageIndex, lastPageIndex) : [];
    }, [currentPage, tabData, pageSize]);

    const addNewRow = () => {
        gridRef.current.gridOptionsService.api.stopEditing();
        if (projectServiceObj.ProjectCode === '' || serviceObjResponsive.ProjectCode === '') {
            MySwal.fire({
                text: "Please add project first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        else {
            if (window.innerWidth > 768) {
                let add = true;
                tabData.forEach(element => {
                    if (element.EquipmentTag === "") {
                        add = false;
                    }
                    else if (element.ServiceName === "") {
                        add = false;
                    }
                    else if (element.EquipmentName === "") {
                        add = false;
                    }
                });
                if (add) {
                    setTabData((prev) => [...prev, { DeleteIndication: "Active", ServiceName: "", EquipmentName: "", EquipmentTag: "", Contractor: "", EmailId: "", MobileNo: "", CreatedBy: userDetails.userId, CreatedOn: todayDate, CreatedByName: userDetails.userName, RowInd: "New" }]);
                    // props.setCustomClass1();
                }
                else {
                    MySwal.fire({
                        text: "Please enter all mandatory fields.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
            }
            else {
                setServiceObjResponsive((prevState) => ({
                    ...prevState,
                    SK: "",
                    PK: "",
                    ServiceName: "",
                    ServiceCode: "",
                    EquipmentName: "",
                    EquipmentCode: "",
                    EquipmentTag: "",
                    DeleteIndication: "Active",
                    Contractor: "",
                    EmailId: "",
                    MobileNo: "",
                    CreatedOn: "",
                    CreatedBy: "",
                    CreatedByName: "",
                    ModifiedOn: "",
                    ModifiedBy: "",
                    SeqNo: 0
                }));
                setAddPopUpResponsive(true);
            }
            setSubAction("Add");
        }
    };

    const handleActionEvent = async (params, event) => {
        let result;
        if (event === "Edit") {
            params.api.startEditingCell({
                rowIndex: params.rowIndex,
                // gets the first columnKey
                colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
            });
        }
        else if (event === "Delete") {
            if (params.RowInd === "New") {
                let tempData = getRowData();
                let index = tempData.findIndex((element) => element.EquipmentTag === params.EquipmentTag && element.RowInd === "New");
                tempData.splice(index, 1);
                setTabData(tempData)
            }
            else {
                let confirmBoxText = "Are you sure, do you want to delete Project Equipment / Activity details?";
                let confirmButtonText = "Delete";
                MySwal.fire({
                    text: confirmBoxText,
                    showDenyButton: true,
                    cancelButtonColor: "#fff",
                    confirmButtonColor: "#16911B",
                    confirmButtonText: confirmButtonText,
                    reverseButtons: true,
                    customClass: "swal-confirmation",
                    title: "Delete Project Service"
                }).then(async (result2) => {
                    if (result2.isConfirmed) {
                        setLoading(true);
                        const [FATChklstData, SATChklstData] = await Promise.all([getProjTranChklst("FAT", params.ProjectCode), getProjTranChklst("SAT", params.ProjectCode)]);
                        let transEquipmentTagInd = false;
                        if (FATChklstData?.length > 0) {
                            let createdData = FATChklstData?.filter((item) => item.EquipmentTag === params.EquipmentTag);
                            if (createdData?.length > 0) {
                                transEquipmentTagInd = true;
                            }
                        }
                        if (SATChklstData?.length > 0) {
                            let createdData = SATChklstData?.filter((item) => item.EquipmentTag === params.EquipmentTag);
                            if (createdData?.length > 0) {
                                transEquipmentTagInd = true;
                            }
                        }
                        if (transEquipmentTagInd) {
                            params.DeleteIndication = "Inactive";
                            params.ModifiedBy = userDetails.userId;
                            params.ModifiedByName = userDetails.userName;
                            params.ModifiedOn = todayDate;
                            result = await inactiveProjectService(params);
                            if (result.status) {
                                await addLogDetails(params, "Delete", "PK#" + params.PK + "#SK#" + params.SK, "ProjectMasterLog", userDetails, "Entry");
                                setLoading(false);
                                MySwal.fire({
                                    text: "The Pre-Functional / Functional Checklist of Equipment – " + params.EquipmentTag + " is already in submitted stage within current project & cannot be Deleted permanently but said Equipment status could be made In-Active",
                                    imageUrl: successImage,
                                    customClass: "swal-success",
                                    didClose: () => {
                                        fetchProjectServiceEqui(params.ProjectCode, false);
                                    },
                                });
                            }
                            else {
                                setLoading(false);
                                MySwal.fire({
                                    text: "Unable to Process Request",
                                    imageUrl: errorImage,
                                    customClass: "swal-success",
                                    didClose: () => {
                                        fetchProjectServiceEqui(params.ProjectCode, false);
                                    },
                                });
                            }
                        }
                        else {
                            result = await DeleteProjectService(params);
                            if (result.status) {
                                await addLogDetails(params, "Delete", "PK#" + params.PK + "#SK#" + params.SK, "ProjectMasterLog", userDetails, "Entry");
                                setLoading(false);
                                MySwal.fire({
                                    text: "Project equipment / activity - " + params.EquipmentName + " details is deleted",
                                    imageUrl: successImage,
                                    customClass: "swal-success",
                                    didClose: () => {
                                        fetchProjectServiceEqui(params.ProjectCode, false);
                                    },
                                });
                            }
                            else {
                                setLoading(false);
                                MySwal.fire({
                                    text: "Unable to Process Request",
                                    imageUrl: errorImage,
                                    customClass: "swal-success"
                                });
                            }
                        }
                    }
                })
            }
        }
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Form
                id="projectservice"
                name="projectservice"
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Modal.Body>
                    <Row>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjectCode"
                                    type="text"
                                    name="ProjectCode"
                                    maxLength="50"
                                    placeholder=" "
                                    value={projectServiceObj.ProjectCode}
                                    onChange={handleChange}
                                    readOnly
                                />
                                <Form.Label htmlFor="ProjectCode">Project Code</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjectName"
                                    type="text"
                                    name="ProjectName"
                                    maxLength="100"
                                    placeholder=" "
                                    value={projectServiceObj.ProjectName}
                                    onChange={handleChange}
                                    readOnly
                                />
                                <Form.Label htmlFor="ProjectName">Project</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={action === "View" || inactiveStatus ? "prevHideButton" : "hstack"}>
                        <div className="addNewRow">
                            <BsPlusLg size={20} className="plusIcon" />
                            &nbsp;
                            <Link onClick={() => addNewRow()} className="blueColor">Add New</Link>
                        </div>
                    </Row>
                    {addPopUpResponsive ?
                        (
                            <div className="backdropModal">
                                <Modal
                                    {...props}
                                    size="sm"
                                    show={addPopUpResponsive}
                                    id="projectModal1"
                                    centered
                                    onHide={() => setAddPopUpResponsive(false)}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title className="modalTitle">
                                            Add Equipment / Activity
                                            <br />
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
                                        <Row>
                                            <Col xs={12}>
                                                <Form.Group className="g-select">
                                                    <Form.Select
                                                        id="DeleteIndication"
                                                        name="DeleteIndication"
                                                        type="text"
                                                        placeholder=" "
                                                        required
                                                        value={serviceObjResponsive.DeleteIndication}
                                                        onChange={(e) => { handleChangeResponsive(e) }}
                                                        disabled={action === "View" ? true : false}
                                                    >
                                                        <option value="">Select</option>
                                                        <option key="Active" value="Active">Active</option>
                                                        <option key="Inactive" value="Inactive">Inactive</option>
                                                    </Form.Select>
                                                    <Form.Label className="frm-label" htmlFor="DeleteIndication">Equipment / Activity Status</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group className="g-input">
                                                    <Form.Control
                                                        id="ServiceName"
                                                        type="text"
                                                        name="ServiceName"
                                                        maxLength="50"
                                                        placeholder=" "
                                                        value={serviceObjResponsive.ServiceName}
                                                        onChange={handleChangeResponsive}
                                                        readOnly
                                                    />
                                                    <Form.Label htmlFor="ServiceName">Service Name</Form.Label>
                                                    <BiSelectMultiple
                                                        className={["modalPopUpIconProjDetails",
                                                            action === "View" || subAction === "Edit" ? "d-none" : ""
                                                        ].join(" ")}
                                                        size={20}
                                                        onClick={() => {
                                                            showPopUp(
                                                                "Service Master",
                                                                "Single",
                                                                "ServiceName",
                                                                serviceObjResponsive.ServiceName,
                                                                [{}, { ind: "Responsive" }]
                                                            );
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group class="g-input">
                                                    <Form.Control
                                                        id="EquipmentName"
                                                        type="text"
                                                        name="EquipmentName"
                                                        maxLength="100"
                                                        placeholder=" "
                                                        value={serviceObjResponsive.EquipmentName}
                                                        onChange={handleChangeResponsive}
                                                        readOnly
                                                    />
                                                    <Form.Label htmlFor="EquipmentName">Equipment / Activity Code</Form.Label>
                                                    <BiSelectMultiple
                                                        className={["modalPopUpIconProjDetails",
                                                            action === "View" || subAction === "Edit" ? "d-none" : ""
                                                        ].join(" ")}
                                                        size={20}
                                                        onClick={() => {
                                                            showPopUp(
                                                                "Equipment Master",
                                                                "Single",
                                                                "EquipmentName",
                                                                serviceObjResponsive.EquipmentName,
                                                                [{ ServiceCode: serviceObjResponsive.ServiceCode }, { ind: "Responsive" }]
                                                            );
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group class="g-input">
                                                    <Form.Control
                                                        id="EquipmentTag"
                                                        type="text"
                                                        name="EquipmentTag"
                                                        maxLength="100"
                                                        placeholder=" "
                                                        value={serviceObjResponsive.EquipmentTag}
                                                        onChange={handleChangeResponsive}
                                                        readOnly={action === "View" || subAction === "Edit" || inactiveStatus ? true : false}
                                                    />
                                                    <Form.Label htmlFor="EquipmentTag">Equipment / Activity Tag</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group class="g-input">
                                                    <Form.Control
                                                        id="Contractor"
                                                        type="text"
                                                        name="Contractor"
                                                        maxLength="100"
                                                        placeholder=" "
                                                        value={serviceObjResponsive.Contractor}
                                                        onChange={handleChangeResponsive}
                                                        readOnly={action === "View" || subAction === "Edit" || inactiveStatus ? true : false}
                                                    />
                                                    <Form.Label htmlFor="Contractor">Contractor/Vendor<span className="italicFont"> (optional)</span></Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group class="g-input">
                                                    <Form.Control
                                                        id="EmailId"
                                                        type="text"
                                                        name="EmailId"
                                                        maxLength="100"
                                                        placeholder=" "
                                                        value={serviceObjResponsive.EmailId}
                                                        onChange={handleChangeResponsive}
                                                        readOnly={action === "View" || inactiveStatus ? true : false}
                                                    />
                                                    <Form.Label htmlFor="EmailId">Email Id<span className="italicFont"> (optional)</span></Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group class="g-input">
                                                    <Form.Control
                                                        id="MobileNo"
                                                        type="text"
                                                        name="MobileNo"
                                                        maxLength="20"
                                                        placeholder=" "
                                                        value={serviceObjResponsive.MobileNo}
                                                        onChange={handleChangeResponsive}
                                                        readOnly={action === "View" || inactiveStatus ? true : false}
                                                    />
                                                    <Form.Label htmlFor="MobileNo">Mobile No.<span className="italicFont"> (optional)</span></Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button variant="outline-success" className="semiBoldToggleButton cancelBtn" onClick={onCloseResponsiveModal}>
                                                    Cancel
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button variant="success" type="button" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={saveServiceInfo}>
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        )
                        : <></>
                    }
                </Modal.Body>
            </Form>
            <RenderIf isTrue={popUp.type === "Single" && showForm}>
                <SingleValuePopUp
                    show={showForm}
                    onHide={() => setShowForm(false)}
                    setSelected={setPopUpData}
                    mastername={popUp.name}
                    paramkey={popUp.key}
                    paramvalue={popUp.value}
                    additionalParams={popUp.additionalParams}
                    backdrop="static"
                />
            </RenderIf>
            <Container fluid className="grid-comp-containerCss">
                <div className="ag-theme-alpine agGridContainer d-none d-md-block d-lg-block">
                    <AgGridReact
                        ref={gridRef}
                        rowData={tabData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        fieldNames={fieldName}
                        headerNames={headerName}
                        onGridReady={onGridReady}
                        // pagination={true}
                        // paginationPageSize={7}
                        rowSelection={"multiple"}
                        enableBrowserTooltips={true}
                    />
                </div>
                <div className="market-wrapper d-md-none d-lg-none cardContainerResponsive">
                    {
                        filteredResult.map((selection, index) =>
                            <Card className="cardContainer" key={index}>
                                <Card.Body className="cardContainerResponsive">
                                    <div className={inactiveStatus ? "hideDisplay" : "actionIcons"}>
                                        <ActionRenderer selectedData={selection} actionName={actionButtons} handleEditEvent={(selection) => handleEdit(selection)} handleDeleteEvent={(selection) => handleActionEvent(selection, "Delete")} />
                                    </div>
                                    {columnDefs.map((header, index) =>
                                        <Row key={index} className="responsive-row">
                                            <Col className="colHeader">{header.headerName}</Col>
                                            {header.headerName === "Project Equipment / Activity Status" ?
                                                <Col className="colData"><StatusRenderer data={selection} colDef={{ field: header.field }} /></Col>
                                                : <Col className="colData">{selection[header.field]}</Col>
                                            }
                                        </Row>
                                    )}
                                </Card.Body>
                            </Card>
                        )}
                    {filteredResult?.length > 0 && (<Pagination
                        currentPage={currentPage}
                        totalCount={tabData ? tabData.length : 0}
                        data={tabData}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                    />)}
                </div>
            </Container>
        </>
    );
});

export default ProjectServicesEqpmnts;