import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../layouts/ActionComp";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import StatusRenderer from "../masters/StatusRenderer.jsx";
import ProjectChecklistVersionList from "../transaction/ProjectChecklistVersionList";
import GridMaster from "../masters/GridMaster";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import { exportToExcel } from "./ExcelCreation_projChklst";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getProjTranChklst, getProjTranChklstofEquip } from "../../services/project-transaction-service";
import { getChecklistMasterDetails } from "../../services/checklist-master-service";
import { getProjectServiceEqui } from "../../services/project-service";
import { getUserInitials } from "../../utils/Utility";
import "./ProjectChecklist.css";
import { getSelectedEquipDetails } from "../../services/equipmentactivity-master-service.js";
import { ServiceMasterData } from "../../services/common-master-service";
import { ChecklistCategoryStatusRenderer } from "./ChecklistCategoryStatusRenderer";
import { searchActions } from "../../store/grid-slice";
import errorImage from "../../icons/error-tick-logo.svg";

const ProjectChecklist = (props) => {
    let tmpSessionName = "";
    if (props.checklisttype === "FAT") {
        tmpSessionName = "Factory Acceptance Test";
    }
    if (props.checklisttype === "SAT") {
        tmpSessionName = "Site Acceptance Test";
    }
    const dispatch = useDispatch();
    dispatch(menuActions.setMenu(tmpSessionName));

    const [checklistType, setChecklistType] = useState(props.checklisttype);
    const MySwal = withReactContent(Swal);
    const [rowData, setRowData] = useState({});
    const [transData, setTransData] = useState([]);
    // const [parameterList, setParameterList] = useState([]);
    const [filteredResult, setFilteredResult] = useState([]);
    const [filteredResulttemp, setFilteredResulttemp] = useState([]);
    const [filterColLength, setFilterColLength] = useState(0);
    const [action, setAction] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [versionMismatchFlg, setVersionMismatchFlg] = useState(false);
    const [checklistMasterResult, setChecklistMasterResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [SelectedProject, setSelectedProject] = useState({});

    const AvatarCellRenderer = (params) => {
        let colourIndex = params.node.rowIndex % 5;
        let initials = getUserInitials(params.value);
        return (
            initials &&
            <div className="user-name-initials">
                <div className={"user-initials user-initials-color" + colourIndex}>
                    <span>{initials}</span>
                </div>
                <div className="user-info">
                    <span className="user-name">{params.value}</span>
                </div>
            </div>)
    }

    useEffect(() => {
        dispatch(searchActions.setSearch(""));
    }, []);

    const functionalChklst = async (params, category) => {
        setLoading(true);
        let versionchklst = "";
        let AppliedChecklist = "";
        let chklstStatus = params["Checklist" + category + "Status"];
        params.chklstStatus = chklstStatus;

        if (params["Chklst" + category + "Version"] && params["Chklst" + category + "Version"] !== "") {
            params.projCreationVersion = params["Chklst" + category + "Version"];    // Version available in Project Master - Service Tab 4
            params.CurrChklstVersion = params["Chklst" + category + "Version"];    // Version for which checklist data to be shown in pop-up
            versionchklst = params["Chklst" + category + "Version"];
        }
        else {
            params.projCreationVersion = "Not defined";
        }

        if (category === "Functional") {
            AppliedChecklist = "Functional";
        }
        if (category === "Prefunctional") {
            AppliedChecklist = "Pre-Functional";
        }
        params.ChecklistCategory = AppliedChecklist;

        let checklistFunMasterResult = [];
        let activeChecklist = [];

        // if (chklstStatus !== "Submitted") {
        let arrVer = await getChecklistMasterDetails(params.EquipmentCode, "ChecklistCategory#" + AppliedChecklist);
        if (arrVer?.length > 0) {
            checklistFunMasterResult = arrVer.filter((item) => item.ChecklistVersion.startsWith("VER"));
            activeChecklist = arrVer.filter((item) => item.ChecklistVersion.startsWith("VER") && item.DeleteIndication === "Active");
        }
        if (checklistFunMasterResult.length === 0) {
            setLoading(false);
            MySwal.fire({
                text: "Checklist is not maintained.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        // }

        setRowData(params);
        let resu = [];
        let tmpTransData = [];
        // let tmpParameterList = [];
        let tmpAction = "";
        if (chklstStatus === "Started" || chklstStatus === "Submitted") {
            const resu1 = await getProjTranChklstofEquip(checklistType, params.ProjectCode, AppliedChecklist, params.EquipmentTag);
            if (resu1?.length > 0) {
                resu = resu1;
                tmpTransData = resu1[0];
                // tmpParameterList = resultChklstParameter;
                tmpAction = "Edit";
                params.CurrChklstVersion = resu1[0].ChecklistVersion;
                // versionchklst = resu[0].ChecklistVersion;
            }
        }
        setTransData(tmpTransData);
        // setParameterList(tmpParameterList);
        setAction(tmpAction);

        //Project Transaction Checklist not submitted 
        // AND Version in Project Master - Service Tab 4 is deleted from Checklist Master
        // AND active checklist is available then set active version else throw error
        if (resu?.length == 0
            && checklistFunMasterResult?.filter(x => x.ChecklistVersion === versionchklst).length === 0) {
            if (activeChecklist?.length > 0) {
                params.CurrChklstVersion = activeChecklist[0].ChecklistVersion;
                versionchklst = activeChecklist[0].ChecklistVersion;
            }
            else {
                setLoading(false);
                MySwal.fire({
                    text: "Checklist is not available.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
        }

        if (chklstStatus !== "Submitted") {
            let arr = [];

            // Show all versions greater than Version available in Project Master - Service Tab 4 & active version in drop down of pop up
            for (let item of checklistFunMasterResult) {
                if (Number(item.ChecklistVersion.split(".")[1]) >= Number(versionchklst.split(".")[1])) {
                    arr.push(item);
                }
                else if (activeChecklist?.length > 0 && item.ChecklistVersion === activeChecklist[0].ChecklistVersion) {
                    arr.push(item);
                }
            }
            setChecklistMasterResult(arr);
            if (params.projCreationVersion === "Not defined" || arr.length > 1) {
                setVersionMismatchFlg(true);
                setLoading(false);
                MySwal.fire({
                    title: AppliedChecklist + " Checklist",
                    html: `Checklist version assigned to <span style="color:#0636A5", "fontWeight: 500">` + params.EquipmentName + `</span> 
                     and <span style="color:#0636A5", "fontWeight: 500">` + params.EquipmentTag + `</span> is <span style="color:#0636A5", "fontWeight: 500">` + params.projCreationVersion + `</span>
                     at the time of project creation, but now new checklist versions are available. <br/><br/>Kindly check if you wish to opt for latest Checklist Versions. You can change Checklist Version by clicking on Checklist Version drop down provided in the Checklist form.`,
                    showDenyButton: false,
                    confirmButtonText: "Ok",
                    customClass: "swal-notealert",
                }).then(async (result2) => {
                    if (result2.isConfirmed) {
                        setLoading(false);
                        setShowForm(true);
                        setRowData(params);
                    }
                });
            }
            else {
                setLoading(false);
                setVersionMismatchFlg(false);
                setShowForm(true);
                setRowData(params);
            }
        }
        if (chklstStatus === "Submitted") {
            setChecklistMasterResult(checklistFunMasterResult);
            setLoading(false);
            setVersionMismatchFlg(false);
            setShowForm(true);
            setRowData(params);
        }
    }


    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Sr.No.",
            field: "SrNo",
            valueFormatter: (params) => {
                return params.node.rowIndex + 1;
            },
            minWidth: 92,
            width: 92,
            resizable: false,
            flex: 0
        },
        {
            headerName: "Project Equipment / Activity Status",
            field: "DeleteIndication",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            minWidth: 145,
            width: 145,
            resizable: false,
            tooltipField: "DeleteIndication",
            cellRenderer: StatusRenderer,
        },
        {
            headerName: "Service Name",
            field: "ServiceName",
            minWidth: 122,
            width: 122,
            flex: 0,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            tooltipField: "ServiceName",
        },
        {
            headerName: "Service Type",
            field: "ServiceType",
            tooltipField: "ServiceType",
            minWidth: 120,
            width: 120,
            flex: 0,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        {
            headerName: "Equipment / Activity Name",
            field: "EquipmentName",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            minWidth: 132,
            tooltipField: "EquipmentName",
        },
        {
            headerName: "Equipment / Activity Tag",
            field: "EquipmentTag",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            minWidth: 132,
            tooltipField: "EquipmentTag",
            sortable: true
        },
        {
            headerName: "Contractor / Vendor",
            field: "Contractor",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            minWidth: 162,
            width: 160,
            cellClass: "agCellCss",
            tooltipField: "Contractor",
            resizable: false,
            cellRenderer: AvatarCellRenderer
        },
        {
            headerName: "",
            field: "CategoryStatus",
            minWidth: 290,
            width: 290,
            tooltipField: "",
            resizable: false,
            pinned: "right",
            cellClass: "agCellChklstCat",
            filter: false,
            sortable: false,
            cellRenderer: function (params) {
                return <ChecklistCategoryStatusRenderer selectedData={params.data} handlePrefunctionalEvent={(params) => functionalChklst(params, "Prefunctional")} handleFunctionalEvent={(params) => functionalChklst(params, "Functional")} />
            }
        }
    ]);

    const headerName = (field) => {
        let name;
        switch (field) {
            case "SrNo":
                name = "Sr.No.";
                break;
            case "DeleteIndication":
                name = "Project Equipment / Activity Status";
                break;
            case "ServiceName":
                name = "Service Name";
                break;
            case "ServiceType":
                name = "Service Type";
                break;
            case "EquipmentName":
                name = "Equipment / Activity Name";
                break;
            case "EquipmentTag":
                name = "Equipment/ Activity Tag";
                break;
            case "Contractor":
                name = "Contractor / Vendor";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Sr.No.":
                name = "SrNo";
                break;
            case "Project Equipment / Activity Status":
                name = "DeleteIndication";
                break;
            case "Service Name":
                name = "ServiceName";
                break;
            case "Service Type":
                name = "ServiceType";
                break;
            case "Equipment / Activity Name":
                name = "EquipmentName";
                break;
            case "Equipment/ Activity Tag":
                name = "EquipmentTag";
                break;
            case "Contractor / Vendor":
                name = "Contractor";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const handleGlobalExport = async () => {
        if (excelData.length > 0) {
            let filename = tmpSessionName + " Checklist";
            let excelDownoadData = [];
            let cnt = 1;
            for (let dt of excelData) {
                let pref = "Cancel";
                let func = "Cancel";
                let temp1 = dt.AppliedChecklist;
                if (temp1.length > 0) {
                    for (let i of temp1) {
                        if (i === "Pre-Functional") {
                            if (dt.ChecklistPrefunctionalStatus === "Started")
                                pref = "Pending";
                            if (dt.ChecklistPrefunctionalStatus === "Not started")
                                pref = "NotStarted";
                            if (dt.ChecklistPrefunctionalStatus === "Submitted")
                                pref = "Submitted";
                            if (dt.DeleteIndication === "Inactive")
                                pref = "Cancel";
                        }
                        if (i === "Functional") {
                            if (dt.ChecklistFunctionalStatus === "Started")
                                func = "Pending";
                            if (dt.ChecklistFunctionalStatus === "Not started")
                                func = "NotStarted";
                            if (dt.ChecklistFunctionalStatus === "Submitted")
                                func = "Submitted";
                            if (dt.DeleteIndication === "Inactive")
                                func = "Cancel";
                        }
                    }
                }
                excelDownoadData.push({
                    "SrNo": cnt,
                    "ProjectEquipmentActivityStatus": dt.DeleteIndication,
                    "ServiceName": dt.ServiceName,
                    "ServiceType": dt.ServiceType,
                    "EquipementActivityName": dt.EquipmentName,
                    "EquipmentActivityTag": dt.EquipmentTag,
                    "ContractorVendor": dt.Contractor,
                    "Prefunctional": pref,
                    "Functional": func
                });
                cnt++;
            }
            let colName = ["Sr.No.", "Project Equipment / Activity Status", "Service Name", "Service Type", "Equipement / Activity Name", "Equipement / Activity Tag", "Contractor / Vendor", "Prefunctional", "Functional"];
            let Fieldname = ["SrNo", "ProjectEquipmentActivityStatus", "ServiceName", "ServiceType", "EquipementActivityName", "EquipmentActivityTag", "ContractorVendor", "Prefunctional", "Functional"];
            exportToExcel(filename, excelDownoadData, colName, Fieldname);
        }
    };

    const SelectedPopUpProject = async (data) => {
        setLoading(true);
        setSelectedProject({
            ProjectCode: data.ProjectCode,
            ProjectName: data.ProjectName,
            DeleteIndication: data.DeleteIndication
        });
        let result = await getProjectServiceEqui(data.ProjectCode);
        let arr = [];
        for (let element of result) {
            if (arr.indexOf(element.EquipmentCode) === -1) {
                arr.push(element.EquipmentCode)
            }
        }
        // let res1 = await getProjTranChklst(data.ProjectCode);
        // let serviceResult = await ServiceMasterData();
        // const equipMasterResult = await getSelectedEquipDetails(arr);

        const [res1, serviceResult, equipMasterResult] = await Promise.all([
            getProjTranChklst(checklistType, data.ProjectCode),
            ServiceMasterData(),
            getSelectedEquipDetails(arr)
        ]);

        for (let element of result) {
            let temp = equipMasterResult.find(x => x.EquipmentCode === element.EquipmentCode);
            element["AppliedChecklist"] = temp?.hasOwnProperty(checklistType + "EquipApplicableChecklist") ? temp[checklistType + "EquipApplicableChecklist"].split(",") : [];
            element["ServiceType"] = temp?.ServiceType;
            element["Location"] = data.Location;
            let serviceFound = serviceResult.find(x => x.ServiceCode === element.ServiceCode);
            element["ServiceName"] = serviceFound.ServiceName;
            element["ProjectDeleteIndication"] = data.DeleteIndication;

            //need to check
            let pendingresPre = undefined, pendingresFun = undefined;
            for (let item of res1) {
                if (item.ChecklistCategory === "Pre-Functional" && element.EquipmentCode === item.EquipmentCode && element.EquipmentTag === item.EquipmentTag)
                    pendingresPre = item;
                if (item.ChecklistCategory === "Functional" && element.EquipmentCode === item.EquipmentCode && element.EquipmentTag === item.EquipmentTag)
                    pendingresFun = item;
            }

            element["ChecklistPrefunctionalStatus"] = "Not started";
            element["ChecklistFunctionalStatus"] = "Not started";

            if (pendingresPre) {
                if (pendingresPre.IsFreeze) {
                    element["ChecklistPrefunctionalStatus"] = "Submitted";
                }
                else {
                    element["ChecklistPrefunctionalStatus"] = "Started";
                }
            }
            if (pendingresFun) {
                if (pendingresFun.IsFreeze) {
                    element["ChecklistFunctionalStatus"] = "Submitted";
                }
                else {
                    element["ChecklistFunctionalStatus"] = "Started";
                }
            }
        }
        // setSelectedProject(data);
        setFilteredResult(result);
        setExcelData(result);
        setLoading(false);
    }

    const reloadfn = (params) => {
        SelectedPopUpProject(params);
        setShowForm(false);
    }

    useEffect(() => {
        setSelectedProject({})
        setChecklistType(props.checklisttype);
        setFilteredResult([]);
        setExcelData([]);
        setShowForm(false);
        setAction("");
        setVersionMismatchFlg(false);
        setChecklistMasterResult([]);
    }, [props.checklisttype])

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="main-blank-container">
                <BreadcrumbComp />
                {!showForm &&
                    (<>
                        <ActionComp label={"Add New"}
                            isProjectTrue={true}
                            exportEvent={() => handleGlobalExport()}
                            setSelectedProject={SelectedPopUpProject}
                            addshowflag={false}
                            setparams={SelectedProject}
                        />
                        <div className="grid-background-color">
                            <GridMaster
                                rowDetails={filteredResult}
                                colDetails={columnDefs}
                                fieldNames={fieldName}
                                headerNames={headerName}
                                getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
                                getFilterColLength={(getLength) => setFilterColLength(getLength)}
                                setExcelData={setExcelData}
                                handleView={functionalChklst}
                                handleEdit={functionalChklst}
                            />
                        </div>
                    </>)}
                {showForm && (
                    <ProjectChecklistVersionList rowdata={rowData}
                        action={action}
                        transData={transData}
                        // parameterdata={parameterList}
                        versionMismatchFlag={versionMismatchFlg}
                        chklistresult={checklistMasterResult}
                        checklistType={checklistType}
                        onHide={(params) => reloadfn(params)} />
                )}
            </Container>
        </>
    );

}

export default ProjectChecklist;
