import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import godrejLogo from "../../styles/images/Godrej-colour-logo.svg";
import godrejLeftImage from "../../styles/images/login-left-image.png";
import AEMLogo from "../../styles/images/AEM-LogoLogin.svg";
import { LOGIN_GODREJ_EMPLOYEE, LOGIN_DEALER } from "../../utils/constant";
import { useMsal } from "@azure/msal-react";
import { compose } from "redux";
import { loginRequest } from "./authConfig";

import { extLoginRequest } from './authConfigExternal';

const LoginPage = (props) => {
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const [userLoginType, setUserLoginType] = useState("");
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();

  let err = ""
  const [errorMessage, setErrorMessage] = useState(err ? err : "");

  useEffect(() => {
    const tmpLoginType = sessionStorage.getItem("LoginType");
    if (tmpLoginType === null) {
      sessionStorage.setItem("LoginType", "Godrej Employee");
      setUserLoginType("Godrej Employee");
    }
    else {
      setUserLoginType(tmpLoginType);
    }
  }, []);

  useEffect(() => {
    setErrorMessage(props.loginErrorMessage);
    if (props.loginErrorMessage) {
      const tmpLoginType = sessionStorage.getItem("LoginType");
      sessionStorage.clear();
      sessionStorage.setItem("LoginType", tmpLoginType);
    }

  }, [props.loginErrorMessage]);


  useEffect(() => {
    if (!isUserAuthorized) {
      setIsUserAuthorized(false);
    }
  }, [isUserAuthorized]);


  const loginFunction = async () => {
    setErrorMessage("");
    const tmpLoginType = sessionStorage.getItem("LoginType");
    if (tmpLoginType === LOGIN_GODREJ_EMPLOYEE || tmpLoginType === null) {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log("error in login", e)
      });
    }
    else if (tmpLoginType === LOGIN_DEALER) {
      instance.loginRedirect(extLoginRequest).catch(e => {
        console.log("error in login", e)
      });
    }

  };

  const onChangeLoginType = (e) => {
    sessionStorage.setItem("LoginType", e.target.value);
    setUserLoginType(e.target.value);
    window.location.href = "/";
  };

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <div className="login-container">
        {!isUserAuthorized && (
          <Row className="place-right">
            <Col xs={1} md={6} className="d-none d-md-block d-lg-block">
              <div className="loginLeftImage">
                <img
                  src={godrejLeftImage}
                  alt="godrejLeftImage"
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={4}
            >

              <div className="LoginRightImage text-center px-4">
                <div>
                  <img
                    src={godrejLogo}
                    alt="godrejLogo"
                    className="LoginRightImageGodrej mt-5"
                  />
                </div>
                <div>
                  <img
                    src={AEMLogo}
                    alt="AEMLogo"
                    className="LoginRightImageAem mt-5"
                  />
                </div>

                <div>
                  <div className="mt-3">
                    {errorMessage && (
                      <div className="loginErrorDiv">
                        <div className="loginErrorDivHeader">Login Failed</div>
                        <div className="loginErrorDivBody">{errorMessage}</div>
                      </div>
                    )}
                    <div className="LoginRightRadioDiv mt-5">
                      <div className="LoginRightRadioDivTitle">User Type</div>
                      <div className="LoginRightRadioLabel">
                        <Form.Check
                          label={LOGIN_GODREJ_EMPLOYEE}
                          name="loginType"
                          type="radio"
                          onChange={(e) => {
                            setErrorMessage("");
                            onChangeLoginType(e);
                          }}
                          value={LOGIN_GODREJ_EMPLOYEE}
                          checked={userLoginType === LOGIN_GODREJ_EMPLOYEE}
                          className="login-form-check"
                        />
                      </div>
                      <div className="LoginRightRadioLabel">
                        <Form.Check
                          label={LOGIN_DEALER}
                          name="loginType"
                          type="radio"
                          value={LOGIN_DEALER}
                          checked={userLoginType === LOGIN_DEALER}
                          className="login-form-check"
                          onChange={(e) => {
                            setErrorMessage("");
                            onChangeLoginType(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="LoginRightInput mt-4">
                    <Button variant="success"
                      onClick={async () => {
                        await loginFunction();
                      }}
                      className="forgotButton"
                    >
                      Login
                    </Button>
                  </div>
                </div>

              </div>

            </Col>
          </Row>)}
      </div>
    </>
  );
};
export default compose(LoginPage);
