import React, { useEffect, useState, useContext, useImperativeHandle, forwardRef } from "react";
import { Row, Container, Spinner, Form, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import ActionRenderer from "../masters/ActionRenderer";
import GridMaster from "../masters/GridMaster";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import download from "../../icons/greenDownload.svg";
import infoImage from "../../icons/infoblue.svg";
import IntegratedAddEditScript from "./IntegratedAddEditScript";
import {
    getIntegratedTabsData,
    getIstScriptDocumentsData,
    addIstScriptDocuments, deleteISTTabData, updatePowerAnalyzerDocuments
} from "../../services/integrated-system-testing-service";
import { sortByPropertyInAscending } from "../../utils/Utility";
import { downloadAttachment } from "../../services/designreviewtracker-service";
import DragDropRender from "../utils/DragDropRender";
import Validate from "../utils/Validation";
import axios from "axios";
import { deletS3Attachment } from "../../services/common-master-service";
import Autocomplete from "../../utils/Autocomplete";
import Delete from "../../icons/Delete.svg";
import Delete_green from "../../icons/Delete_green.svg";
import {
    generateDocument
} from "../../services/project-document-service";

const IntegratedScript = forwardRef((props, ref) => {
    const MySwal = withReactContent(Swal);
    const [showForm, setShowForm] = useState(false);
    const [filteredResulttemp, setFilteredResulttemp] = useState([]);
    const [filterColLength, setFilterColLength] = useState(0);
    const [excelData, setExcelData] = useState([]);
    const [tabData, setTabData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState({});
    const [error, setError] = useState({});
    const [istScriptTitles, setIstScriptTitles] = useState([]);
    const [action, setAction] = useState("Add");
    const selectedProject = props.selectedProject;
    const scriptLimitVal = props.limitValue.ScriptLimitValue;
    const istScriptTitleLimitValue = props.limitValue.IstScriptTitleLimitValue;

    const tempCEDocsArr = {
        "istScriptCETitle": [{ DocType: "Main", fieldName: "istScriptMainCETitle", isMandatory: true }, { DocType: "Ref", fieldName: "istScriptRefCETitle", isMandatory: false }],
        "istScriptCEActualTitle": [{ DocType: "Main", fieldName: "istScriptMainCEActualTitle", isMandatory: true }, { DocType: "Ref", fieldName: "istScriptRefCEActualTitle", isMandatory: false }],
        "istScriptCEActualCriticalTitle": [{ DocType: "Main", fieldName: "istScriptMainCEActualCriticalTitle", isMandatory: true }, { DocType: "Ref", fieldName: "istScriptRefCEActualCriticalTitle", isMandatory: false }],
        "istScriptAlarmTitle": [{ DocType: "Main", fieldName: "istScriptMainAlarmTitle", isMandatory: true }, { DocType: "Ref", fieldName: "istScriptRefAlarmTitle", isMandatory: false }],
        "istScriptAlarmCriticalTitle": [{ DocType: "Main", fieldName: "istScriptMainAlarmCriticalTitle", isMandatory: true }, { DocType: "Ref", fieldName: "istScriptRefAlarmCriticalTitle", isMandatory: false }]
    };

    const ISTdocsTitles = {
        istScript: { name: "IST Script", id: "istScript", DocType: "", fieldName: "istScriptUploadDoc", uploadFolder: "IstScript" },
        istScriptResult: { name: "IST Script Results", id: "istScriptResult", DocType: "", fieldName: "istScriptResultUploadDoc", uploadFolder: "IstScriptResult" }
    };

    const CEdocsTitles = [{ name: "C&E Matrix", id: "istScriptCETitle", uploadFolder: "CEMatrix", showApplicable: false, isCriticalField: false },
    { name: "C&E Matrix with Actual Results", id: "istScriptCEActualTitle", uploadFolder: "CEActualMatrix", showApplicable: false, isCriticalField: false },
    { name: "C&E Matrix with Actual Results for Critical", id: "istScriptCEActualCriticalTitle", uploadFolder: "CEActualMatrixCritical", showApplicable: false, isCriticalField: true },
    { name: "Alarm SMS List", id: "istScriptAlarmTitle", uploadFolder: "AlarmSmsList", showApplicable: true, isCriticalField: false },
    { name: "Alarm SMS List for Critical", id: "istScriptAlarmCriticalTitle", uploadFolder: "AlarmSmsListCritical", showApplicable: true, isCriticalField: true }];

    const tooltipMsg = {
        sampleTemplate: 'Kindly download Sample IST Script Document and ammend Project Specific information in Sample IST Script Document for document merging functionality.',
        blankTemplate: 'Kindly add the Titles which will update in the Blank IST Script Document and then download the default IST script document to ammend project specific information for document merging functionality.',
        scriptTitle: 'Before downloading the Sample IST Script Document, kindly add the Titles which will update in the sample IST Script Document and then download the sample IST script document to ammend information projectwise merging functionality.',
        scriptResult: 'Kindly download final uploaded copy of IST Script Document from above link and then update the Test Results so that Updated Document of "IST Script Document with Results" can be uploaded below upload option link.',
        istScriptCETitle: 'Kindly upload (in Image or Word format) the Cause & Effect Matrix given by Customer / PMC.',
        istScriptCEActualTitle: "Kindly upload (in Image or Word format) the final file of Cause & Effect Matrix with Test Results.",
        istScriptCEActualCriticalTitle: 'Kindly upload (in Image or Word format) the final file of Cause & Effect Matrix with Test Results for Critical Area.',
        istScriptAlarmTitle: 'Kindly upload (in Image or Word format) the Tested Alarm SMS List.',
        istScriptAlarmCriticalTitle: 'Kindly upload (in Image or Word format) the Tested Alarm SMS List for Critical Area.'

    }
    const [documentsArr, setDocumentsArr] = useState({});
    const [filesMatrixArr, setFilesMatrixArr] = useState([{ id: "", docflag: "", OldFile: "", NewFile: "", DelFlag: false }]);
    let supportedScriptFormats = "Supports: doc,docx formats Max File Size 20MB";
    let supportedFormats = "Supports: jpg,jpeg,png,doc,docx formats Max File Size 5MB/20MB";
    let referenceFormats = "Supports: xlxs,xls formats Max File Size - upto 20MB";

    const [actionButtons, setActionButtons] = useState({});

    const [integratedScriptDocObj, setIntegratedScriptDocObj] = useState({
        PK: "IntegratedSystemTesting#ProjectCode#" + selectedProject?.ProjectCode,
        SK: "ScriptDocuments",
        ProjectCode: selectedProject?.ProjectCode,
        ProjectName: selectedProject?.ProjectName,
        istScriptTitles: [],
        istScriptTitleName: "",
        istScriptUploadDoc: "",
        istScriptResultUploadDoc: "",
        istScriptCETitle: "",
        istScriptCEActualTitle: "",
        istScriptCEActualCriticalTitle: "",
        istScriptAlarmTitle: "",
        istScriptAlarmCriticalTitle: "",
        istScriptMainCETitle: "",
        istScriptMainCEActualTitle: "",
        istScriptMainCEActualCriticalTitle: "",
        istScriptMainAlarmTitle: "",
        istScriptMainAlarmCriticalTitle: "",
        istScriptRefCETitle: "",
        istScriptRefCEActualTitle: "",
        istScriptRefCEActualCriticalTitle: "",
        istScriptRefAlarmTitle: "",
        istScriptRefAlarmCriticalTitle: "",
        istScriptAlarmTitleRadio: "",
        istScriptAlarmCriticalTitleRadio: "",
    });

    const columnDefs = [
        {
            headerName: "Sr.No.",
            field: "SrNo",
            valueGetter: "node.rowIndex + 1",
            width: 100
        },
        {
            headerName: "Scenario",
            field: "Scenario",
            minWidth: 150
        },
        {
            headerName: "Pass / Fail",
            headerTooltip: "Pass / Fail",
            field: "ScriptStatus",
            minWidth: 180
        },
        {
            headerName: "Remarks",
            field: "Remarks",
            tooltipField: "Remarks",
            minWidth: 180
        },
        {
            field: "",
            width: 100,
            minWidth: 100,
            cellRenderer: function (params) {
                return <ActionRenderer selectedData={params}
                    actionName={actionButtons}
                    handleEditEvent={(params) => handleEdit(params.data)}
                    handleDeleteEvent={(params) => handleDelete(params.data)} />
            },
            sortable: false,
            filter: false,
            pinned: 'right'
        }
    ];

    const headerName = (field) => {
        let name;
        switch (field) {
            case "SrNo":
                name = "Sr.No.";
                break;
            case "Scenario":
                name = "Scenario";
                break;
            case "ScriptStatus":
                name = "Pass / Fail";
                break;
            case "Remarks":
                name = "Remarks";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Sr.No.":
                name = "SrNo";
                break;
            case "Scenario":
                name = "Scenario";
                break;
            case "Pass / Fail":
                name = "ScriptStatus";
                break;
            case "Remarks":
                name = "Remarks";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const addNewRow = () => {
        if (tabData?.length < scriptLimitVal) {
            setAction("Add");
            setShowForm(true);
        }
        else {
            MySwal.fire({
                text: "You can add only " + scriptLimitVal + " IST Script Summary per project.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    };

    const addSaveClick = async () => {
        if (!integratedScriptDocObj.istScriptTitleName) {
            MySwal.fire({
                text: "Title name is required.",
                imageUrl: errorImage,
                customClass: "swal-success"
            });
            return;
        }
        let titles = [...istScriptTitles];
        if (titles.length < istScriptTitleLimitValue) {
            console.log("titles", titles)
            let chk = titles.filter(x => x.toLowerCase() === integratedScriptDocObj.istScriptTitleName.toLowerCase());
            if (chk?.length > 0) {
                MySwal.fire({
                    text: "Title already exists.",
                    imageUrl: errorImage,
                    customClass: "swal-success"
                });
                return;
            }
            else {
                setLoading(true);
                let temp = [];
                let cnt = 1;
                for (let item of titles) {
                    temp.push({ SeqNo: cnt, title: item });
                    cnt++;
                }
                temp.push({ SeqNo: cnt, title: integratedScriptDocObj.istScriptTitleName });
                let tmpObj = {
                    PK: "IntegratedSystemTesting#ProjectCode#" + selectedProject?.ProjectCode,
                    SK: "ScriptDocuments",
                    ProjectCode: selectedProject?.ProjectCode,
                    ProjectName: selectedProject?.ProjectName,
                    istScriptTitles: temp
                }
                let result = await updatePowerAnalyzerDocuments(tmpObj);
                if (result?.status) {
                    titles.push(integratedScriptDocObj.istScriptTitleName);
                    setIstScriptTitles(titles);
                    setIntegratedScriptDocObj((prevState) => ({
                        ...prevState,
                        istScriptTitles: temp,
                        istScriptTitleName: ""
                    }));
                    MySwal.fire({
                        text: "IST Script title has been added successfully.",
                        imageUrl: successImage,
                        customClass: "swal-success"
                    });
                }
                else {
                    MySwal.fire({
                        text: "Unable to process request.",
                        imageUrl: errorImage,
                        customClass: "swal-success"
                    });
                }
                setLoading(false);
            }
        }
        else {
            MySwal.fire({
                text: "You can add only " + istScriptTitleLimitValue + " IST Script Titles per project.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    };

    const handleEdit = async (row) => {
        setRowData(row);
        setShowForm(true);
        setAction("Edit");
    };

    const handleChange = async (e) => {
        let { name, value } = e.target;
        setIntegratedScriptDocObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDelete = async (params) => {
        let result;
        let confirmBoxText = "Are you sure, do you want to delete IST Script Summary?";
        let confirmButtonText = "Delete";
        MySwal.fire({
            text: confirmBoxText,
            showDenyButton: true,
            cancelButtonColor: "#fff",
            confirmButtonColor: "#16911B",
            confirmButtonText: confirmButtonText,
            reverseButtons: true,
            customClass: "swal-confirmation",
            title: "Delete IST Script Summary"
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                setLoading(true);
                result = await deleteISTTabData(params);
                if (result?.status) {
                    setLoading(false);
                    MySwal.fire({
                        text: "IST Script Summary deleted successfully.",
                        imageUrl: successImage,
                        customClass: "swal-success",
                        didClose: () => {
                            fetchScriptSummaryData();
                        },
                    });
                }
                else {
                    MySwal.fire({
                        text: "Unable to Process Request",
                        imageUrl: errorImage,
                        customClass: "swal-success"
                    });
                }

            }
        })
    }

    const fetchScriptSummaryData = async () => {
        setLoading(true);
        let scriptISTresult = await getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": "Script" });
        if (scriptISTresult?.length <= 0) {
            setTabData([]);
        }
        else {
            scriptISTresult.sort(sortByPropertyInAscending("SeqNo"));
            setTabData(scriptISTresult);
        }
        setLoading(false);
    }

    const fetchScriptDocData = async () => {
        setLoading(true);
        let resultdocuments = await getIstScriptDocumentsData({ "ProjectCode": selectedProject.ProjectCode });
        if (resultdocuments?.length > 0) {
            if (resultdocuments[0].istScriptTitles?.length > 0) {
                let allTitles = resultdocuments[0].istScriptTitles.sort(sortByPropertyInAscending("SeqNo"));
                let tempTitles = [];
                for (let dt of allTitles) {
                    tempTitles.push(dt["title"]);
                }
                setIstScriptTitles(tempTitles);
            }

            setFilesMatrixArr([{ id: "istScriptCETitle", docflag: "Main", OldFile: resultdocuments[0]["istScriptMainCETitle"], NewFile: "", DelFlag: false, uploadFolder: "CEMatrix" },
            { id: "istScriptCEActualTitle", docflag: "Main", OldFile: resultdocuments[0]["istScriptMainCEActualTitle"], NewFile: "", DelFlag: false, uploadFolder: "CEActualMatrix" },
            { id: "istScriptCEActualCriticalTitle", docflag: "Main", OldFile: resultdocuments[0]["istScriptMainCEActualCriticalTitle"], NewFile: "", DelFlag: false, uploadFolder: "CEActualMatrixCritical" },
            { id: "istScriptAlarmTitle", docflag: "Main", OldFile: resultdocuments[0]["istScriptMainAlarmTitle"], NewFile: "", DelFlag: false, uploadFolder: "AlarmSmsList" },
            { id: "istScriptAlarmCriticalTitle", docflag: "Main", OldFile: resultdocuments[0]["istScriptMainAlarmCriticalTitle"], NewFile: "", DelFlag: false, uploadFolder: "AlarmSmsListCritical" },
            { id: "istScriptUploadDoc", docflag: "", OldFile: resultdocuments[0]["istScriptUploadDoc"], NewFile: "", DelFlag: false, uploadFolder: "IstScript" },
            { id: "istScriptResultUploadDoc", docflag: "", OldFile: resultdocuments[0]["istScriptResultUploadDoc"], NewFile: "", DelFlag: false, uploadFolder: "IstScriptResult" },
            ]);

            let temp = [];
            if (resultdocuments[0]["istScriptRefCETitle"] !== "") {
                temp.push({ id: "istScriptCETitle", docflag: "Ref", OldFile: resultdocuments[0]["istScriptRefCETitle"], NewFile: "", DelFlag: false, uploadFolder: "CEMatrix" });
            }
            if (resultdocuments[0]["istScriptRefCEActualTitle"] !== "") {
                temp.push({ id: "istScriptCEActualTitle", docflag: "Ref", OldFile: resultdocuments[0]["istScriptRefCEActualTitle"], NewFile: "", DelFlag: false, uploadFolder: "CEActualMatrix" });
            }
            if (resultdocuments[0]["istScriptRefCEActualCriticalTitle"] !== "") {
                temp.push({ id: "istScriptCEActualCriticalTitle", docflag: "Ref", OldFile: resultdocuments[0]["istScriptRefCEActualCriticalTitle"], NewFile: "", DelFlag: false, uploadFolder: "CEActualMatrixCritical" });
            }
            if (resultdocuments[0]["istScriptRefAlarmTitle"] !== "") {
                temp.push({ id: "istScriptAlarmTitle", docflag: "Ref", OldFile: resultdocuments[0]["istScriptRefAlarmTitle"], NewFile: "", DelFlag: false, uploadFolder: "AlarmSmsList" });
            }
            if (resultdocuments[0]["istScriptRefAlarmCriticalTitle"] !== "") {
                temp.push({ id: "istScriptAlarmCriticalTitle", docflag: "Ref", OldFile: resultdocuments[0]["istScriptRefAlarmCriticalTitle"], NewFile: "", DelFlag: false, uploadFolder: "AlarmSmsListCritical" });
            }
            setFilesMatrixArr((prevState) => ([...prevState, ...temp]));
            setIntegratedScriptDocObj(resultdocuments[0]);
        }
        setLoading(false);
    }

    const loadData = async () => {
        await fetchScriptSummaryData();
        await fetchScriptDocData();
    }

    useEffect(() => {
        if (props.tabNo === 2) {
            loadData()
        }
        if (selectedProject.DeleteIndication !== "Active") {
            setActionButtons({
                ViewAction: false,
                EditAction: false,
                DownloadAction: false,
                DeleteAction: false,
            });
        }
        else {
            setActionButtons({
                ViewAction: false,
                EditAction: true,
                DownloadAction: false,
                DeleteAction: true
            });
        }
    }, [props.tabNo]);

    const ValidateDocuments = async () => {
        let isError = false;
        const errorMsg = {};
        for (let dt of CEdocsTitles) {
            errorMsg[dt.id] = {}
            if (!dt.isCriticalField || (dt.isCriticalField && selectedProject.ISTApplicable === "Yes")) {
                if (dt.showApplicable && !integratedScriptDocObj[dt.id + "Radio"]) {
                    isError = true;
                    errorMsg[dt.id + "Radio"] = {
                        status: true,
                        message: "Applicable/Not Applicable is required.",
                    };
                }
                if (!dt.showApplicable || (dt.showApplicable && integratedScriptDocObj[dt.id + "Radio"] === "Applicable")) {
                    if (!integratedScriptDocObj[dt.id]) {
                        isError = true;
                        errorMsg[dt.id] = {
                            status: true,
                            message: "Title is required.",
                        };
                    }
                    for (let fileDt of tempCEDocsArr[dt.id]) {
                        if (fileDt.isMandatory && !integratedScriptDocObj[fileDt.fieldName]) {
                            isError = true;
                            errorMsg[fileDt.fieldName] = {
                                status: true,
                                message: "File is required.",
                            };
                        }
                    }
                }
            }
        }
        if (!integratedScriptDocObj.istScriptUploadDoc) {
            isError = true;
            errorMsg.istScript = {
                status: true,
                message: "File is required.",
            };
        }
        if (!integratedScriptDocObj.istScriptResultUploadDoc) {
            isError = true;
            errorMsg.istScriptResult = {
                status: true,
                message: "File is required.",
            };
        }
        setError(errorMsg);
        return isError;
    };

    const uploadFiles = async (signedURL, file) => {
        try {
            if (signedURL) {
                await axios.put(signedURL, file);
            }
            return "";
        } catch (error) {
            return " Error in file upload.";
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        let validationResult = await ValidateDocuments();
        if (!validationResult) {
            let result = await addIstScriptDocuments(integratedScriptDocObj);

            if (result?.status) {
                for (let item in result.allsignedURL) {
                    if (Object.keys(documentsArr).length > 0) {
                        await uploadFiles(result.allsignedURL[item], documentsArr[item]);
                    }
                    else {
                        break;
                    }
                }

                //check matrix delete flag
                for (let delchk of filesMatrixArr) {
                    if (delchk.DelFlag === true && delchk.OldFile !== delchk.NewFile) {
                        let tmpFileName = "IntegratedSystemTesting/" + selectedProject.ProjectCode + "/" + delchk.uploadFolder + "/";
                        if (delchk.docflag) {
                            tmpFileName += delchk.docflag + "/";
                        }
                        tmpFileName += delchk.OldFile;
                        let fileObj = { fileName: tmpFileName };
                        await deletS3Attachment(fileObj);
                    }
                }

                setLoading(false);
                MySwal.fire({
                    text: "IST Script has been added successfully.",
                    imageUrl: successImage,
                    customClass: "swal-success"
                });
            }
            else {
                setLoading(false);
                MySwal.fire({
                    text: "Unable to Process Request",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            }
            return true;
        }
        setLoading(false);
    }

    useImperativeHandle(ref, () => ({
        funcOnNext: async () => {
            await handleSubmit();
        }
    }));

    const handleChangeIstScriptUpload = (files, fieldName) => {
        if (selectedProject.DeleteIndication === "Active") {
            const newFile = files[0];
            const fileName = newFile.name;
            let fileError = "";
            fileError = Validate(newFile, "docsFormats", 20971520);

            if (fileError === "") {
                setIntegratedScriptDocObj((prevState) => ({
                    ...prevState,
                    [fieldName]: fileName
                }));
                setDocumentsArr((prevState) => ({
                    ...prevState,
                    [fieldName]: newFile
                }));
                let temp = [...filesMatrixArr]
                for (let dt of temp) {
                    if (dt.id === fieldName) {
                        dt.NewFile = fileName;
                        break;
                    }
                }
                setFilesMatrixArr(temp);
            } else {
                MySwal.fire({
                    text: fileError,
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            }
        }
    };

    const handleChangeStatus = async (files, item, docFlag) => {
        if (selectedProject.DeleteIndication === "Active") {
            let docType = docFlag.DocType;
            let docFieldName = docFlag.fieldName;
            const newFile = files[0];
            let fileError;
            if (docType === "Ref") {
                fileError = Validate(newFile, "excelFormats", 20971520);
            } else if (docType === "Main") {
                fileError = Validate(newFile, "imgFormats");
                if (fileError !== "") {
                    fileError = Validate(newFile, "docsFormats", 20971520);
                }
            }
            if (fileError !== "") {
                MySwal.fire({
                    text: fileError,
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }

            const fileName = newFile.name;
            setIntegratedScriptDocObj((prevState) => ({
                ...prevState,
                [docFieldName]: fileName
            }));
            setDocumentsArr((prevState) => ({
                ...prevState,
                [docFieldName]: newFile
            }));
            let temp = [...filesMatrixArr]
            for (let dt of temp) {
                if (dt.id === item && dt.docflag === docType) {
                    dt.NewFile = fileName;
                    break;
                }
            }
            setFilesMatrixArr(temp);
        }
    };

    const handleDeleteDocRef = async (item, docFlag) => {
        if (selectedProject.DeleteIndication === "Active") {
            let docType = docFlag.DocType;
            let docUploadKey = docFlag.fieldName;
            setIntegratedScriptDocObj((prevState) => ({
                ...prevState,
                [docUploadKey]: "",
            }));
            let temp = [...filesMatrixArr]
            for (let dt of temp) {
                if (dt.id === item && dt.docflag === docType) {
                    dt.DelFlag = true;
                    break;
                }
            }
            setFilesMatrixArr(temp);
        }
    }

    const handleDownloadDocument = async (fileName, folderName, subFolderName) => {
        let downloadURL = `IntegratedSystemTesting/${selectedProject.ProjectCode}/${folderName}/`;
        if (subFolderName) {
            downloadURL += `${subFolderName}/`;
        }
        downloadURL += `${fileName}`;

        downloadAttachment(
            downloadURL,
            fileName
        ).catch((err) => {
            MySwal.fire({
                text: "Error in Fetching file.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        });
    }

    const downloadSampleFile = async () => {
        downloadAttachment(
            "Templates/Sample IST Script Document.docx",
            "Sample IST Script Document.docx"
        ).catch((err) => {
            setLoading(false);
            MySwal.fire({
                text: "Error in Fetching file.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        });
    }

    const downloadBlankFile = async () => {
        let dataObj = {};
        dataObj.DocumentType = "ISTBlank";
        dataObj.DocumentDetailedType = "Blank IST Script Document";
        dataObj.titleData = integratedScriptDocObj.istScriptTitles;
        let documentresult = await generateDocument(selectedProject, dataObj);
        if (documentresult?.status) {
            downloadAttachment(
                "IntegratedSystemTesting/" + selectedProject.ProjectCode + "/IstScript/Blank IST Script Document " + selectedProject.ProjectName + ".docx",
                "Blank IST Script Document " + selectedProject.ProjectName + ".docx"
            ).catch((err) => {
                setLoading(false);
                MySwal.fire({
                    text: "Error in Fetching file.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            });
        }
        else {
            MySwal.fire({
                text: "Error in Fetching file.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    }

    const tooltipCEmatrix = (item) => {
        let msg = tooltipMsg[item];
        return (<Tooltip id={item} className="isttooltip">
            {msg}
        </Tooltip>)
    }

    const handleChangeTitles = (name, value) => {
        setIntegratedScriptDocObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleDeleteEvent = async (item) => {
        if (selectedProject.DeleteIndication === "Active") {
            setLoading(true);
            let temp = [...istScriptTitles];
            let index = temp.findIndex(obj => obj === item);
            temp.splice(index, 1);

            let cnt = 1;
            let titletemp = [];
            for (let dt of temp) {
                titletemp.push({ SeqNo: cnt, title: dt });
                cnt++;
            }
            let tmpObj = {
                PK: "IntegratedSystemTesting#ProjectCode#" + selectedProject?.ProjectCode,
                SK: "ScriptDocuments",
                ProjectCode: selectedProject?.ProjectCode,
                ProjectName: selectedProject?.ProjectName,
                istScriptTitles: titletemp
            }
            let result = await updatePowerAnalyzerDocuments(tmpObj);
            if (result?.status) {
                setIntegratedScriptDocObj((prevState) => ({
                    ...prevState,
                    istScriptTitles: titletemp
                }));
                setIstScriptTitles(temp);
                MySwal.fire({
                    text: "IST Script title has been deleted successfully.",
                    imageUrl: successImage,
                    customClass: "swal-success"
                });
            }
            else {
                MySwal.fire({
                    text: "Unable to process request.",
                    imageUrl: errorImage,
                    customClass: "swal-success"
                });
            }
            setLoading(false);
        }
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Form
                id="istScript"
                name="istScript"
                autoComplete="off"
            >
                <Container fluid className="ist-comp-container">
                    {selectedProject.DeleteIndication === "Active" &&
                        <Row>
                            <Col xs={6}>
                                <span className="projDocRightPanelEquipName">IST Script Summary</span>
                            </Col>
                            <Col xs={6}>
                                <div className="addNewRow">
                                    <BsPlusLg size={20} className="plusIcon" />
                                    &nbsp;
                                    <Link onClick={() => addNewRow()} className="blueColor">Add more</Link>
                                </div>
                            </Col>
                        </Row>
                    }
                    <GridMaster
                        rowDetails={tabData}
                        colDetails={columnDefs}
                        fieldNames={fieldName}
                        headerNames={headerName}
                        getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
                        getFilterColLength={(getLength) => setFilterColLength(getLength)}
                        setExcelData={setExcelData}
                        handleEdit={handleEdit}
                        openConfirmBox={handleDelete}
                        actionName={actionButtons}
                        resetind={true}
                    />
                </Container>
                <br />
                <Container fluid className="grid-comp-containerCss">
                    <Row>
                        <Col xs={12}>
                            <span className="projDocRightPanelEquipName">IST Script</span>
                        </Col>
                    </Row >
                    <Row>
                        <Col md={7} xs={12}>
                            <Row>
                                <Col md={8} xs={10}>
                                    <Form.Group className="g-input">
                                        <Autocomplete
                                            id="istScriptTitleName"
                                            type="text"
                                            name="istScriptTitleName"
                                            maxLength="50"
                                            placeholder=" "
                                            value={integratedScriptDocObj.istScriptTitleName}
                                            onChangeEv={handleChangeTitles}
                                            options={istScriptTitles}
                                            fieldName="Title"
                                            optionalField={false}
                                            readonly={selectedProject.DeleteIndication !== "Active"}
                                        />
                                        <OverlayTrigger placement="bottom-start" overlay={tooltipCEmatrix("scriptTitle")}>
                                            <img
                                                src={infoImage}
                                                id="info"
                                                className="IstScriptInfo"
                                            />
                                        </OverlayTrigger>
                                        {error.istScriptTitleName?.status && (
                                            <Form.Text className="text-danger">
                                                {error.istScriptTitleName?.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                {selectedProject.DeleteIndication === "Active" &&
                                    <Col md={4} xs={2} className="noLeftPadding">
                                        <div className="pt-2">
                                            <Link className="istSaveTitlebtn" onClick={() => addSaveClick()}>Save</Link>
                                        </div>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col lg={12} xs={12}>
                                    {istScriptTitles.length > 0 && (
                                        <div className="filterList">
                                            {istScriptTitles.map((item, index) => {
                                                return (
                                                    <div key={item + index} className="istScriptTitle pr-0 me-2 mb-2">
                                                        <span className="ps-2 btn-text">{item}</span>
                                                        <img
                                                            onMouseOver={e => (e.currentTarget.src = Delete_green)}
                                                            onMouseOut={e => (e.currentTarget.src = Delete)}
                                                            src={Delete}
                                                            alt="delete"
                                                            onClick={() => handleDeleteEvent(item)}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={5} xs={12}>
                            <div className="downloadISTsample">
                                <div className="istDownloadSample pb-2">
                                    <div className="downloadIstSample">
                                        <span>
                                            <img src={download} alt={download}></img>
                                        </span>
                                        &nbsp;
                                        <Link className="downloadIstLink" onClick={() => downloadSampleFile()}>
                                            Download Sample IST Script Document</Link>
                                        &nbsp;
                                        <OverlayTrigger placement="bottom-start" overlay={tooltipCEmatrix("sampleTemplate")}>
                                            <img
                                                src={infoImage}
                                                id="info"
                                                alt="info"
                                            />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className={istScriptTitles?.length === 0 ? "disableddiv" : ""}>
                                    <div className="downloadIstSample">
                                        <span>
                                            <img src={download} alt={download}></img>
                                        </span>
                                        &nbsp;
                                        <Link className="downloadIstLink" onClick={() => downloadBlankFile()}>
                                            Download Blank IST Script Document as per title</Link>
                                        &nbsp;
                                        <OverlayTrigger placement="bottom-start" overlay={tooltipCEmatrix("blankTemplate")}>
                                            <img
                                                src={infoImage}
                                                id="info"
                                                alt="info"
                                            />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={4} xs={12} className="istdropZoneMainDiv istdropTextContainer">
                            <DragDropRender
                                key={"istScript"}
                                val={integratedScriptDocObj.istScriptUploadDoc}
                                handleChangeEvent={(params) => handleChangeIstScriptUpload(params, "istScriptUploadDoc")}
                                handleDeleteDocEvent={(params) => handleDeleteDocRef("istScriptUploadDoc", ISTdocsTitles.istScript)}
                                handleDownloadDocEvent={(params) => handleDownloadDocument(params, "IstScript", "")}
                                action={action}
                                supportedFilesLabel={supportedScriptFormats}
                                disabled={selectedProject.DeleteIndication !== "Active"}
                            />
                            {error["istScript"]?.status && (
                                <Form.Text className="text-danger">
                                    {error["istScript"]?.message}
                                </Form.Text>
                            )}
                        </Col>
                    </Row>
                    <hr className="istseparatorLine" />

                    <Row>
                        <Col lg={6} xs={6}>
                            <span className="projDocRightPanelEquipName">IST Script Results</span>&nbsp;
                            <OverlayTrigger placement="bottom-start" overlay={tooltipCEmatrix("scriptResult")}>
                                <img
                                    src={infoImage}
                                    id="info"
                                    alt="info"
                                />
                            </OverlayTrigger>
                        </Col>
                    </Row >
                    <Row>
                        <Col lg={4} xs={12} className="istdropZoneMainDiv istdropTextContainer">
                            <DragDropRender
                                key={"istScriptResult"}
                                val={integratedScriptDocObj.istScriptResultUploadDoc}
                                handleChangeEvent={(params) => handleChangeIstScriptUpload(params, "istScriptResultUploadDoc")}
                                handleDeleteDocEvent={(params) => handleDeleteDocRef("istScriptResultUploadDoc", ISTdocsTitles.istScriptResult)}
                                handleDownloadDocEvent={(params) => handleDownloadDocument(params, "IstScriptResult", "")}
                                action={action}
                                supportedFilesLabel={supportedScriptFormats}
                                disabled={selectedProject.DeleteIndication !== "Active"}
                            />
                            {error["istScriptResult"]?.status && (
                                <Form.Text className="text-danger">
                                    {error["istScriptResult"]?.message}
                                </Form.Text>
                            )}
                        </Col>
                    </Row>
                    {
                        CEdocsTitles?.map((item, index) => {
                            return (
                                <>
                                    <hr className="istseparatorLine" />
                                    <Row>
                                        <Col md={item.showApplicable ? 3 : 6} xs={12}>
                                            <span className="projDocRightPanelEquipName">{item.name}</span>
                                            &nbsp;<OverlayTrigger placement="bottom-start" overlay={tooltipCEmatrix(item.id)}>
                                                <img
                                                    src={infoImage}
                                                    id="info"
                                                    alt="info"
                                                />
                                            </OverlayTrigger>
                                        </Col>
                                        {item.showApplicable &&
                                            <Col md={3} xs={12}>
                                                <Row>
                                                    <Col lg={6} xs={6}>
                                                        <Form.Check className="fieldLabel locationType"
                                                            type="radio"
                                                            name={item.id + "Radio"}
                                                            checked={integratedScriptDocObj[item.id + "Radio"] === "Applicable"}
                                                            onClick={(event) => handleChange(event)}
                                                            label="Applicable"
                                                            value="Applicable"
                                                            disabled={(item.isCriticalField && selectedProject.ISTApplicable === "No") || selectedProject.DeleteIndication !== "Active"}
                                                        ></Form.Check>
                                                    </Col>
                                                    <Col lg={6} xs={6}>
                                                        <Form.Check className="fieldLabel locationType"
                                                            type="radio"
                                                            name={item.id + "Radio"}
                                                            checked={integratedScriptDocObj[item.id + "Radio"] === "Not Applicable"}
                                                            onClick={(event) => handleChange(event)}
                                                            label="Not Applicable"
                                                            value="Not Applicable"
                                                            disabled={(item.isCriticalField && selectedProject.ISTApplicable === "No") || selectedProject.DeleteIndication !== "Active"}
                                                        ></Form.Check>
                                                    </Col>
                                                </Row>
                                                {error[item.id + "Radio"]?.status && (
                                                    <Form.Text className="text-danger">
                                                        {error[item.id + "Radio"]?.message}
                                                    </Form.Text>
                                                )}
                                            </Col>
                                        }
                                    </Row>
                                    <div className="d-none d-md-block d-lg-block">
                                        <Row>
                                            <Col md={8}>&nbsp;</Col>
                                            <Col md={4}>
                                                <span className="sectionTitle">Reference Document</span>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col md={4} xs={12}>
                                            <Form.Group className="g-input">
                                                <Form.Control
                                                    id={item.id}
                                                    type="Text"
                                                    name={item.id}
                                                    maxLength="50"
                                                    placeholder=" "
                                                    value={integratedScriptDocObj[item.id]}
                                                    onChange={handleChange}
                                                    disabled={(item.isCriticalField && selectedProject.ISTApplicable === "No") || selectedProject.DeleteIndication !== "Active"}
                                                />
                                                <Form.Label className="frm-label" htmlFor={item.id}>
                                                    Title
                                                </Form.Label>
                                                {error[item.id]?.status && (
                                                    <Form.Text className="text-danger">
                                                        {error[item.id]?.message}
                                                    </Form.Text>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        {
                                            tempCEDocsArr[item.id]?.map((element) => {
                                                return (
                                                    <>
                                                        {element.DocType === "Ref" &&
                                                            <Col xs={12} className="IstRefLabelResp">
                                                                <span className="sectionTitle">Reference Document</span>
                                                            </Col>
                                                        }
                                                        <Col lg={4} xs={12} className="pb-3 istdropZoneMainDiv istdropTextContainer">
                                                            <DragDropRender
                                                                val={integratedScriptDocObj[element.fieldName]}
                                                                handleChangeEvent={(params) => handleChangeStatus(params, item.id, element)}
                                                                handleDeleteDocEvent={() => handleDeleteDocRef(item.id, element)}
                                                                handleDownloadDocEvent={(params) => handleDownloadDocument(params, item.uploadFolder, element.DocType)}
                                                                action={action}
                                                                supportedFilesLabel={element.DocType === "Main" ? supportedFormats : referenceFormats}
                                                                disabled={(item.isCriticalField && selectedProject.ISTApplicable === "No") || selectedProject.DeleteIndication !== "Active"}
                                                            />
                                                            {error[element.fieldName]?.status && (
                                                                <Form.Text className="text-danger">
                                                                    {error[element.fieldName]?.message}
                                                                </Form.Text>
                                                            )}
                                                        </Col>
                                                    </>
                                                )
                                            })
                                        }
                                    </Row >
                                </>
                            )
                        })
                    }
                    <br />
                    <br />
                    {
                        showForm && (
                            <IntegratedAddEditScript
                                show={showForm}
                                rowData={rowData}
                                fetchScriptSummaryData={fetchScriptSummaryData}
                                selectedProject={selectedProject}
                                onHide={() => setShowForm(false)}
                                action={action}
                            />
                        )
                    }
                </Container >
            </Form>
        </>
    )
});

export default IntegratedScript;