import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import Swal from "sweetalert2";
import GridMaster from "../masters/GridMaster";
import StatusRenderer from "../masters/StatusRenderer.jsx";
import ActionRenderer from "../masters/ActionRenderer";
import Autocomplete from "../../utils/Autocomplete"
import {
    getDocumentReviewTracker,
    addDocumentReviewTracker,
    updateDocumentReviewTracker,
    getDocReviewEquipmentTagData,
    getExistingSnapShotData,
    addSnapShot
} from "../../services/documentreviewtracker-service";
import { getEquipmentParameterData } from "../../services/equipmentactivity-master-service";
import { getEquipmentTagDataForEquipment } from "../../services/project-service"
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import { formatDateInDDMMYYYY } from "../../functions/gbcs-functions";
import AddEditDocumentEquipmentTag from "./AddEditDocumentEquipmentTag";
import { getUserInitials } from "../../utils/Utility";

const AddEditDocumentReviewTracker = (props) => {
    const action = props.action;
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { userName: context.userName, userId: context.userId }
    const [rowData, setRowData] = useState({});
    const [filteredResult, setFilteredResult] = useState([]);
    const [filteredResulttemp, setFilteredResulttemp] = useState([]);
    const [filterColLength, setFilterColLength] = useState(0);
    const [excelData, setExcelData] = useState([]);
    const [loading, setLoading] = useState(false);
    let todayDate = getCurrentDateTime("-");
    const [showEquipmentTagForm, setShowEquipmentTagForm] = useState(false);
    const [childAction, setChildAction] = useState("");
    const [docCategory, setDocCategory] = useState([]);
    const [docNames, setDocNames] = useState([]);
    const [snapShotData, setSnapShotData] = useState([]);
    const [isSnapExist, setIsSnapExist] = useState(true);
    //let vendorFlag = props.rowData.hasOwnProperty("Vendor");
    const [isHeaderSaved, setIsHeaderSaved] = useState(
        action === "Edit" && props.rowdata.hasOwnProperty("Vendor") ? true : false
    );

    const AvatarCellRenderer = (params) => {
        if (params.value != undefined && params.value != "") {
            let coloutIndex = params.node.rowIndex % 5;
            let initials = getUserInitials(params.value);
            return (
                <div className="user-name-initials">
                    <div className={"user-initials user-initials-color" + coloutIndex}>
                        <span>{initials}</span>
                    </div>
                    <div className="user-info">
                        <span className="user-name">{params.value}</span>
                    </div>
                </div>)
        }
    }

    const [DocumentReviewTrackerObj, setDocumentReviewTrackerObj] = useState(
        action === "Edit" || action === "View"
            ? props.rowdata
            : {
                ProjectCode: props.SelectedProjEquipObj.ProjectCode,
                ProjectName: props.SelectedProjEquipObj.ProjectName,
                ServiceName: props.SelectedProjEquipObj.ServiceName,
                ServiceCode: props.SelectedProjEquipObj.ServiceCode,
                EquipmentCode: props.SelectedProjEquipObj.EquipmentCode,
                EquipmentName: props.SelectedProjEquipObj.EquipmentName,
                DocumentAvailable: "No",
            }
    );

    const [actionButtons, setActionButtons] = useState(
        action === "View" ? {
            ViewAction: false,
            EditAction: false,
            DownloadAction: false,
            DeleteAction: false,
        } :
            {
                ViewAction: false,
                EditAction: true,
                DownloadAction: false,
                DeleteAction: false,
            });

    const [error, setError] = useState({
        DocumentCategory: false,
        DocumentName: false,
        Vendor: false,
    });

    const columnDefs = [
        {
            headerName: "Sr.No.",
            field: "SrNo",
            valueFormatter: (params) => {
                return params.node.rowIndex + 1;
            },
            minWidth: 100
        },
        {
            headerName: "Equipment Tag",
            field: "EquipmentTag",
            tooltipField: "EquipmentTag",
            minWidth: 200,
        },
        {
            headerName: "Document Submission Status",
            field: "DocumentSubmissionStatus",
            flex: false,
            width: 200,
            resizable: false,
            tooltipField: "DocumentSubmissionStatus",
            cellRenderer: StatusRenderer,
        },
        {
            headerName: "Document Submission Reviewed by",
            field: "DocumentSubmissionReviewedBy",
            tooltipField: "DocumentSubmissionReviewedBy",
            minWidth: 200,
            cellRenderer: AvatarCellRenderer
        },
        {
            headerName: "Document Submission Reviewed Date",
            field: "DocumentSubmissionReviewedDate",
            tooltipField: "DocumentSubmissionReviewedDate",
            minWidth: 200,
            filter: "agDateColumnFilter",
            valueFormatter: (params) => {
                return formatDateInDDMMYYYY(params.data.DocumentSubmissionReviewedDate);
            }
        },
        {
            headerName: "Re Submission Status",
            field: "ReSubmissionStatus",
            flex: false,
            width: 200,
            resizable: false,
            tooltipField: "ReSubmissionStatus",
            cellRenderer: StatusRenderer,
        },
        {
            headerName: "Re Submission Reviewed by",
            field: "ReSubmissionReviewedBy",
            tooltipField: "ReSubmissionReviewedBy",
            minWidth: 200,
            cellRenderer: AvatarCellRenderer
        },
        {
            headerName: "Re Submission Reviewed Date",
            field: "ReSubmissionReviewedDate",
            tooltipField: "ReSubmissionReviewedDate",
            minWidth: 200,
            valueFormatter: (params) => {
                return formatDateInDDMMYYYY(params.data.ReSubmissionReviewedDate);
            },
        },
        {
            headerName: "Approval Status",
            field: "ApprovalStatus",
            flex: false,
            width: 200,
            resizable: false,
            tooltipField: "ApprovalStatus",
            cellRenderer: StatusRenderer,
        },
        {
            headerName: "Approval Reviewed by",
            field: "ApprovalReviewedBy",
            tooltipField: "ApprovalReviewedBy",
            minWidth: 200,
            cellRenderer: AvatarCellRenderer
        },
        {
            headerName: "Approval Reviewed Date",
            field: "ApprovalReviewedDate",
            tooltipField: "ApprovalReviewedDate",
            minWidth: 200,
            valueFormatter: (params) => {
                return formatDateInDDMMYYYY(params.data.ApprovalReviewedDate);
            }

        },
        {
            headerName: "Remarks",
            field: "Remarks",
            tooltipField: "Remarks",
            minWidth: 200,
        },
        {
            field: "",
            width: 70,
            cellRenderer: function (params) {
                return <ActionRenderer
                    selectedData={params}
                    actionName={actionButtons}
                    handleEditEvent={(params) => handleEdit(params.data)} />
            },
            sortable: false,
            filter: false,
            pinned: 'right'
        },
    ];

    const headerName = (field) => {
        let name;
        switch (field) {
            case "SrNo":
                name = "Sr.No.";
                break;
            case "EquipmentTag":
                name = "Equipment Tag";
                break;
            case "DocumentSubmissionStatus":
                name = "Document Submission Status";
                break;
            case "DocumentSubmissionReviewedBy":
                name = "Document Submission Reviewed by";
                break;
            case "DocumentSubmissionReviewedDate":
                name = "Document Submission Reviewed Date";
                break;
            case "ReSubmissionStatus":
                name = "Re Submission Status";
                break;
            case "ReSubmissionReviewedBy":
                name = "Re Submission Reviewed by";
                break;
            case "ReSubmissionStatusReviewedDate":
                name = "Re Submission Reviewed Date";
                break;
            case "ApprovalStatus":
                name = "Approval Status";
                break;
            case "ApprovalReviewedBy":
                name = "Approval Reviewed by";
                break;
            case "ApprovalReviewedDate":
                name = "Approval Reviewed Date";
                break;
        }
        return name;
    }

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Sr.No.":
                name = "SrNo";
                break;
            case "Equipment Tag":
                name = "EquipmentTag";
                break;
            case "Document Submission Status":
                name = "DocumentSubmissionStatus"
                break;
            case "Document Submission Reviewed by":
                name = "DocumentSubmissionReviewedBy"
                break;
            case "Document Submission Reviewed Date":
                name = "DocumentSubmissionReviewedDate"
                break;
            case "Re Submission Status":
                name = "ReSubmissionStatus"
                break;
            case "Re Submission Reviewed by":
                name = "ReSubmissionReviewedBy"
                break;
            case "Re Submission Reviewed Date":
                name = "ReSubmissionReviewedDate"
                break;
            case "Approval Status":
                name = "ApprovalStatus"
                break;
            case "Approval Reviewed by":
                name = "ApprovalReviewedBy"
                break;
            case "Approval Reviewed Date":
                name = "ApprovalReviewedDate"
                break;
            case "Remarks":
                name = "Remarks"
                break;
        }
        return name;
    }

    useEffect(() => {
        fetchEquipmentTagsData();
        fetchSnapShotData();
    }, []);

    const fetchSnapShotData = async () => {
        setLoading(true);
        let snapshotParams = {
            primekey: "DocumentReviewTracker#ProjectCode#" + props.SelectedProjEquipObj.ProjectCode,
            sortkey: "SnapShot#EquipmentCode#" + props.SelectedProjEquipObj.EquipmentCode,
            // EquipmentCode: props.SelectedProjEquipObj.EquipmentCode,
            // ServiceCode: props.SelectedProjEquipObj.ServiceCode
        }
        let snapShotDataArr = [];
        let tmpDocCategory = [];
        let tmpDocNames = [];
        snapShotDataArr = await getExistingSnapShotData(snapshotParams);
        if (snapShotDataArr.length <= 0) {
            setIsSnapExist(false);
            snapShotDataArr = await getEquipmentParameterData({ "code": props.SelectedProjEquipObj.EquipmentCode, "tabName": "DocumentReview" }, "master");
        }
        for (let dt of snapShotDataArr) {
            tmpDocCategory.push(dt["Document Category"])
            tmpDocNames.push(dt["Document Name"])
        }
        setSnapShotData(snapShotDataArr);
        setDocCategory([...new Set(tmpDocCategory)])
        setDocNames([...new Set(tmpDocNames)])
        setLoading(false);
    }

    const fetchEquipmentTagsData = async () => {
        let docReviewTgData = [];
        setLoading(true);
        let tgBody = {
            primekey: "DocumentReviewTracker#ProjectCode#" + props.SelectedProjEquipObj.ProjectCode + "#EquipmentCode#" + props.SelectedProjEquipObj.EquipmentCode,
            sortkey: "DocumentName#" + DocumentReviewTrackerObj.DocumentName + "#EquipmentTag#",
            // EquipmentCode: props.SelectedProjEquipObj.EquipmentCode,
            // ServiceCode: props.SelectedProjEquipObj.ServiceCode
        }
        docReviewTgData = await getDocReviewEquipmentTagData(tgBody);

        let tgSvcBody = {
            primekey: "ProjectCode#" + props.SelectedProjEquipObj.ProjectCode,
            sortkey: "ProjectService#SeqNo#",
            EquipmentCode: props.SelectedProjEquipObj.EquipmentCode
        }
        const projEqipTgData = await getEquipmentTagDataForEquipment(tgSvcBody);
        let finalData = [];
        if (projEqipTgData?.length > 0) {
            for (let element of projEqipTgData) {
                let existRecord = docReviewTgData?.filter((item) => item.EquipmentTag === element.EquipmentTag);
                if (existRecord?.length === 0) {
                    let equipTgObj = {};
                    equipTgObj.EquipmentTag = element.EquipmentTag;
                    equipTgObj.ProjectCode = props.SelectedProjEquipObj.ProjectCode;
                    equipTgObj.ProjectName = props.SelectedProjEquipObj.ProjectName;
                    equipTgObj.EquipmentCode = props.SelectedProjEquipObj.EquipmentCode;
                    equipTgObj.EquipmentName = props.SelectedProjEquipObj.EquipmentName;
                    equipTgObj.ServiceCode = props.SelectedProjEquipObj.ServiceCode;
                    equipTgObj.ServiceName = props.SelectedProjEquipObj.ServiceName;
                    equipTgObj.DocumentSubmissionStatus = "Pending";
                    equipTgObj.ReSubmissionStatus = "";
                    equipTgObj.ApprovalStatus = "";
                    equipTgObj.RecordType = "New";
                    finalData.push(equipTgObj);
                }
                else {
                    finalData.push(existRecord[0]);
                }
            };
        }
        setFilteredResult(finalData);
        setLoading(false);
    };

    const handleChange = (name, value) => {
        setDocumentReviewTrackerObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleEdit = async (row) => {
        debugger;
        if (isHeaderSaved) {
            setChildAction("Edit");
            setRowData(row);
            setShowEquipmentTagForm(true);
        }
        else {
            MySwal.fire({
                text: "Please fill all document details.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });

        }
    }

    const handleReset = () => {
        setDocumentReviewTrackerObj(
            action === "Edit" ? props.rowdata
                : {
                    ProjectCode: props.SelectedProjEquipObj.ProjectCode,
                    ProjectName: props.SelectedProjEquipObj.ProjectName,
                    ServiceName: props.SelectedProjEquipObj.ServiceName,
                    ServiceCode: props.SelectedProjEquipObj.ServiceCode,
                    EquipmentCode: props.SelectedProjEquipObj.EquipmentCode,
                    EquipmentName: props.SelectedProjEquipObj.EquipmentName,
                    DocumentAvailable: "No",
                }
        )
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (action == "View") {
            props.onHide();
        }
        else {
            let result;
            if (!validateDetails()) {
                let confirmBoxText = "";
                if (action === "Add") {
                    confirmBoxText = "Are you sure, so you want to add New Document?";
                }
                else if (action === "Edit") {
                    confirmBoxText = "Are you sure, so you want to update Document?";
                }
                MySwal.fire({
                    text: confirmBoxText,
                    showDenyButton: true,
                    cancelButtonColor: "#fff",
                    confirmButtonText: "Yes",
                    reverseButtons: true,
                    customClass: "swal-confirmation",
                    title: action + " Document Review Tracker"
                }).then(async (result2) => {
                    if (result2.isConfirmed) {
                        setLoading(true);
                        const result1 = await getDocumentReviewTracker(props.SelectedProjEquipObj);
                        if (result1?.length > 0 && result1.filter((item) => (action === "Add" || (action === "Edit" && item.SK.toLowerCase() !== DocumentReviewTrackerObj.SK.toLowerCase())) && item.DocumentName.toLowerCase() === DocumentReviewTrackerObj.DocumentName.toLowerCase()).length > 0) {
                            setLoading(false);
                            MySwal.fire({
                                text: "Document Name is already exists.",
                                imageUrl: errorImage,
                                customClass: "swal-success",
                            });
                            return;
                        }
                        if (action === "Add") {
                            DocumentReviewTrackerObj.CreatedOn = todayDate;
                            DocumentReviewTrackerObj.CreatedBy = userDetails.userId;
                            DocumentReviewTrackerObj.CreatedByName = userDetails.userName;
                            DocumentReviewTrackerObj.ModifiedBy = userDetails.userId;
                            DocumentReviewTrackerObj.ModifiedByName = userDetails.userName;
                            DocumentReviewTrackerObj.ModifiedOn = todayDate;
                            DocumentReviewTrackerObj.PK = "DocumentReviewTracker#ProjectCode#" + props.SelectedProjEquipObj.ProjectCode;
                            DocumentReviewTrackerObj.SK = "EquipmentCode#" + props.SelectedProjEquipObj.EquipmentCode + "#DocumentName#" + DocumentReviewTrackerObj.DocumentName;
                            DocumentReviewTrackerObj.DocumentSubmissionStatus = "Pending";
                            DocumentReviewTrackerObj.ReSubmissionStatus = "Pending";
                            DocumentReviewTrackerObj.ApprovalStatus = "Pending";
                            result = await addDocumentReviewTracker(DocumentReviewTrackerObj);
                        }
                        else if (action === "Edit") {
                            DocumentReviewTrackerObj.ModifiedBy = userDetails.userId;
                            DocumentReviewTrackerObj.ModifiedByName = userDetails.userName;
                            DocumentReviewTrackerObj.ModifiedOn = todayDate;
                            result = await updateDocumentReviewTracker(DocumentReviewTrackerObj);
                        }
                        if (result?.status) {
                            if (!isSnapExist) { addSnapShotEntry() };
                            setIsHeaderSaved(true);
                            setLoading(false);
                            MySwal.fire({
                                text: result.message,
                                imageUrl: successImage,
                                customClass: "swal-success",
                                didClose: () => {
                                    props.getdocumentreviewtracker(props.SelectedProjEquipObj);
                                },
                            });
                        }
                        else {
                            setLoading(false);
                            MySwal.fire({
                                text: "Unable to Process Request",
                                imageUrl: errorImage,
                                customClass: "swal-success",
                            });
                        }
                        setLoading(false);
                    }
                });
            }
        }

    }

    const addSnapShotEntry = async () => {
        let counter = 1;
        let array = [];
        for (let item of snapShotData) {
            counter++;
            let obj = {
                PK: "DocumentReviewTracker#ProjectCode#" + props.SelectedProjEquipObj.ProjectCode,
                SK: "SnapShot#EquipmentCode#" + props.SelectedProjEquipObj.EquipmentCode + "#SeqNo#" + counter,
                SeqNo: counter,
                "Document Category": item["Document Category"],
                "Document Name": item["Document Name"],
                CreatedOn: todayDate,
                CreatedBy: userDetails.userId,
                CreatedByName: userDetails.userName
            }
            let res = addSnapShot(obj);
            array.push(res);

            if (counter === 5) {
                await Promise.all(array);
                counter = 0;
                array = [];
            }
        }
        if (array.length > 0) {
            await Promise.all(array);
        }
    }

    const validateDetails = () => {
        let errorMsg = {
            DocumentCategory: false,
            DocumentName: false,
            Vendor: false
        };
        let isError = false;
        if (!DocumentReviewTrackerObj.DocumentCategory || DocumentReviewTrackerObj.DocumentCategory.trim() === "") {
            isError = true;
            errorMsg.DocumentCategory = { status: isError, message: "Document Category is required" };
        }
        if (!DocumentReviewTrackerObj.DocumentName || DocumentReviewTrackerObj.DocumentName.trim() === "") {
            isError = true;
            errorMsg.DocumentName = { status: isError, message: "Document Name is required" };
        }

        if (!DocumentReviewTrackerObj.Vendor || DocumentReviewTrackerObj.Vendor.trim() === "") {
            isError = true;
            errorMsg.Vendor = { status: isError, message: "Vendor is required" };
        }
        setError(errorMsg);
        return isError;
    }


    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Modal
                {...props}
                size="xl"
                backdrop="static" centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {action} Document Review Tracker
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="documentreviewtracker"
                        name="documentreviewtracker"
                        autoComplete="off"
                    >
                        <Row >
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="ProjectName"
                                        type="text"
                                        name="ProjectName"
                                        placeholder=" "
                                        value={DocumentReviewTrackerObj.ProjectName}
                                        readOnly
                                    />
                                    <Form.Label className="frm-label" for="ProjectName">Project</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="ServiceName"
                                        type="text"
                                        name="ServiceName"
                                        placeholder=" "
                                        value={DocumentReviewTrackerObj.ServiceName}
                                        readOnly
                                    />
                                    <Form.Label className="frm-label" for="ServiceName">Service Name</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="EquipmentName"
                                        type="text"
                                        name="EquipmentName"
                                        placeholder=" "
                                        value={DocumentReviewTrackerObj.EquipmentName}
                                        readOnly
                                    />
                                    <Form.Label className="frm-label" for="EquipmentName">Equipment / Activity Name</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Autocomplete
                                        id="DocumentCategory"
                                        type="text"
                                        name="DocumentCategory"
                                        maxLength="100"
                                        placeholder=" "
                                        value={DocumentReviewTrackerObj.DocumentCategory ? DocumentReviewTrackerObj.DocumentCategory : ""}
                                        onChangeEv={handleChange}
                                        readonly={action === "View" || action === "Edit"}
                                        options={docCategory} fieldName={"Document Category"}
                                    />
                                    {error.DocumentCategory.status && (
                                        <Form.Text className="text-danger">
                                            {error.DocumentCategory.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={8} xs={12}>
                                <Form.Group className="g-input">
                                    <Autocomplete
                                        id="DocumentName"
                                        type="text"
                                        name="DocumentName"
                                        maxLength="250"
                                        placeholder=" "
                                        value={DocumentReviewTrackerObj.DocumentName ? DocumentReviewTrackerObj.DocumentName : ""}
                                        onChangeEv={handleChange}
                                        readonly={action === "View" || action === "Edit"}
                                        options={docNames} fieldName={"Document Name"}
                                    />
                                    {error.DocumentName.status && (
                                        <Form.Text className="text-danger">
                                            {error.DocumentName.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="Vendor"
                                        type="text"
                                        name="Vendor"
                                        placeholder=" "
                                        maxLength="200"
                                        value={DocumentReviewTrackerObj.Vendor ? DocumentReviewTrackerObj.Vendor : ""}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        readOnly={action === "View"}
                                    />
                                    <Form.Label className="frm-label" for="Vendor">Vendor</Form.Label>
                                    {error.Vendor.status && (
                                        <Form.Text className="text-danger">
                                            {error.Vendor.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Row>
                                    <Form.Label className="fieldLabel locationType">Document Availbale</Form.Label>
                                </Row>
                                <Row>
                                    <Col md={6} xs={6}>
                                        <Form.Check className="fieldLabel locationType"
                                            type="radio"
                                            name="DocumentAvailable"
                                            checked={DocumentReviewTrackerObj.DocumentAvailable === "Yes"}
                                            onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            label="Yes"
                                            value="Yes"
                                            disabled={action === "View"}
                                        ></Form.Check>
                                    </Col>
                                    <Col md={6} xs={6}>
                                        <Form.Check className="fieldLabel locationType"
                                            type="radio"
                                            name="DocumentAvailable"
                                            checked={DocumentReviewTrackerObj.DocumentAvailable === "No"}
                                            onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            label="No"
                                            value="No"
                                            disabled={action === "View"}
                                        ></Form.Check>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} xs={12}>
                                {action === "View" ?
                                    <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={handleSubmit} >
                                        Done
                                    </Button>
                                    :
                                    <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={handleSubmit} >
                                        {action === "Edit" ? "Update" : "Add"}
                                    </Button>
                                }
                            </Col>
                        </Row>
                        <hr className="separatorLine" />
                        <Row>
                            <Form.Label className="semiBoldToggleButton">Equipment Tags</Form.Label>
                        </Row>
                        <Row>
                            <div>
                                <GridMaster
                                    rowDetails={filteredResult}
                                    colDetails={columnDefs}
                                    fieldNames={fieldName}
                                    headerNames={headerName}
                                    getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
                                    getFilterColLength={(getLength) => setFilterColLength(getLength)}
                                    handleEdit={handleEdit}
                                    setExcelData={setExcelData}
                                    pagination={false}
                                    actionName={actionButtons}
                                    resetind={true}
                                />
                            </div>
                        </Row>
                        {action === "Add" &&
                            <Row >
                                <Col md={9} xs={6}>
                                    <Row>
                                        <Col md={3} xs={12}>
                                            <Button className="semiBoldToggleButton cancelBtn"
                                                variant="outline-success"
                                                onClick={handleReset}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Modal.Body>
            </Modal>
            {showEquipmentTagForm && (
                <AddEditDocumentEquipmentTag
                    show={showEquipmentTagForm}
                    action={childAction}
                    rowdata={rowData}
                    headerRecord={DocumentReviewTrackerObj}
                    SelectedProjEquipObj={props.SelectedProjEquipObj}
                    onHide={() => setShowEquipmentTagForm(false)}
                    fetchEquipmentTagsData={fetchEquipmentTagsData}
                />
            )}
        </>
    );

}

export default AddEditDocumentReviewTracker;
