import React, { useEffect, useState, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../layouts/ActionComp";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import StatusRenderer from "./StatusRenderer.jsx";
import ActionRenderer from "./ActionRenderer";
import GridMaster from "./GridMaster";
import AddEditCompanyDivisionMaster from "./AddEditCompanyDivisionMaster";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import { exportToExcel } from "../utils/ExcelCreation";
import escapeExcelData from "../utils/customValidation";
import { getRolesForUnmaskedData } from "../../services/common-master-service";
import {
  getAllCompanyDivisionData,
  UpdateCompanyDivision,
  DeleteCompany
} from "../../services/companydivision-master-service";
import { getProjectsByCompany } from "../../services/project-service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { getCurrentDateTime, formatDateInDDMMYYYY } from "../../functions/gbcs-functions";
import { AuthContext } from "../../contexts/AuthContext";
import { sortByPropertyInAscending } from "../../utils/Utility";
import { searchActions } from "../../store/grid-slice";
import { addLogDetails } from "../../services/logger-entry-service";

const CompanyDivisionMaster = () => {
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = { userName: context.userName, role: context.role, userId: context.userId }
  const [rowData, setRowData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);
  const [CompanyMasterData, setCompanyMasterData] = useState([]);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [resetFilter, setResetFilter] = useState(true);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  dispatch(menuActions.setMenu("Company / Division Master"));

  const columnDefs = [
    {
      headerName: "Status",
      field: "DeleteIndication",
      flex: false,
      width: 150,
      resizable: false,
      tooltipField: "DeleteIndication",
      cellRenderer: StatusRenderer,
      sort: 'asc'
    },
    {
      headerName: "Company / Division Code",
      field: "CompanyCode",
      width: 150,
      tooltipField: "CompanyCode",
    },
    {
      headerName: "Company / Division Name",
      field: "CompanyName",
      tooltipField: "CompanyName",
      width: 150,
    },
    {
      headerName: "Location Type",
      field: "CompanyLocationType",
      width: 150,
      tooltipField: "CompanyLocationType",
    },
    {
      headerName: "Country",
      field: "CompanyCountry",
      width: 150,
      tooltipField: "CompanyCountry",
    },
    {
      headerName: "HO Address",
      field: "CompanyHOAddress",
      width: 150,
      tooltipField: "CompanyHOAddress",
    },
    {
      headerName: "Website Address",
      field: "CompanyWebsiteAddress",
      width: 150,
      tooltipField: "CompanyWebsiteAddress",
    },
    {
      headerName: "Sector",
      field: "CompanySectorName",
      width: 150,
      tooltipField: "CompanySector",
    },
    {
      field: "",
      width: 120,
      cellRenderer: function (params) {
        return <ActionRenderer selectedData={params.data} handleViewEvent={handleView} handleEditEvent={handleEdit} handleDeleteEvent={handleDelete} />
      },
      sortable: false,
      filter: false,
      pinned: 'right'
    },
  ];

  const headerName = (field) => {
    let name;
    switch (field) {
      case "DeleteIndication":
        name = "Status";
        break;
      case "CompanyCode":
        name = "Company / Division Code";
        break;
      case "CompanyName":
        name = "Company / Division Name";
        break;
      case "CompanyLocationType":
        name = "Location Type";
        break;
      case "CompanyCountry":
        name = "Country";
        break;
      case "CompanyHOAddress":
        name = "HO Address";
        break;
      case "CompanyWebsiteAddress":
        name = "Website Address";
        break;
      case "CompanySectorName":
        name = "Sector";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fieldName = (header) => {
    let name;
    switch (header) {
      case "Status":
        name = "DeleteIndication";
        break;
      case "Company / Division Code":
        name = "CompanyCode";
        break;
      case "Company / Division Name":
        name = "CompanyName";
        break;
      case "Location Type":
        name = "CompanyLocationType";
        break;
      case "Country":
        name = "CompanyCountry";
        break;
      case "HO Address":
        name = "CompanyHOAddress";
        break;
      case "Website Address":
        name = "CompanyWebsiteAddress";
        break;
      case "Sector":
        name = "CompanySectorName";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };


  useEffect(() => {
    fetchCompDivDataMaster();
  }, [reload]);

  const fetchCompDivDataMaster = async () => {
    setLoading(true);
    dispatch(searchActions.setSearch(""));
    const result = await getAllCompanyDivisionData();
    result?.sort(sortByPropertyInAscending("DeleteIndication"))
    setCompanyMasterData(result);
    setFilteredResult(result);
    setExcelData(result);
    setResetFilter(!resetFilter);
    setLoading(false);
  };

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  const handleView = async (row) => {
    setLoading(true);
    let compObj = {
      TelephoneNo: row.CompanyTelephoneNo
    }
    let tempUnique = "SK#" + row.SK;
    await addLogDetails(compObj, "View", tempUnique, "CompanyDivisionMasterLog", userDetails, "Master")
    setAction("View");
    setRowData(row);
    setShowForm(true);
    setLoading(false);
  }


  const handleEdit = async (row) => {
    setLoading(true);
    let compObj = {
      TelephoneNo: row.CompanyTelephoneNo
    }
    let tempUnique = "SK#" + row.SK;
    await addLogDetails(compObj, "View", tempUnique, "CompanyDivisionMasterLog", userDetails, "Master")
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
    setLoading(false);
  };

  const handleDelete = async (row) => {
    let result;
    const compCode = row.CompanyCode;
    const compName = row.CompanyName;
    try {
      MySwal.fire({
        text: "Are you sure, do you want to delete Company / Division " + compName + "?",
        showDenyButton: true,
        cancelButtonColor: "#fff",
        confirmButtonColor: "#16911B",
        confirmButtonText: "Delete",
        reverseButtons: true,
        customClass: "swal-confirmation",
        title: "Delete Company / Division"
      }).then(async (result2) => {
        if (result2.isConfirmed) {
          setLoading(true);
          let compData = await getProjectsByCompany(compCode);
          if (compData == undefined || compData.length == 0) {
            await DeleteCompany(row.SK).then(async (resp) => {
              console.log("resp", resp)
              if (resp.status) {
                let compObj = {
                  TelephoneNo: row.CompanyTelephoneNo
                }
                let tempUnique = "SK#" + row.SK;
                await addLogDetails(compObj, "Delete", tempUnique, "CompanyDivisionMasterLog", userDetails, "Master")
                setLoading(false);
                MySwal.fire({
                  text: resp.message,
                  imageUrl: successImage,
                  customClass: "swal-success",
                  didClose: () => {
                    setReload(!reload);
                  },
                });
              }
            })
              .catch((error) => {
                setLoading(false);
                MySwal.fire({
                  text: "Unable to process request.",
                  imageUrl: errorImage,
                  customClass: "swal-success",
                });
              })
              .finally(() => {
                setReload(!reload);
              });

          }
          else {
            let activeProjects = compData.filter((item) => item.DeleteIndication === "Active").length;
            if (activeProjects > 0) {
              setLoading(false);
              MySwal.fire({
                text: "Projects are already linked to " + compName + " Company / Division. Thus, selected Company / Division cannot be Deleted.",
                imageUrl: errorImage,
                customClass: "swal-success",
              });
            }
            else {
              let compObj = row;
              let todayDate = getCurrentDateTime("-");
              compObj.ModifiedBy = userDetails.userId;
              compObj.ModifiedByName = userDetails.userName;
              compObj.ModifiedOn = todayDate;
              compObj.DeleteIndication = "Inactive";
              result = UpdateCompanyDivision(compObj);

              result.then(async (resp) => {
                if (resp.status) {
                  let compObj = {
                    TelephoneNo: row.CompanyTelephoneNo
                  }
                  let tempUnique = "SK#" + row.SK;
                  await addLogDetails(compObj, "Delete", tempUnique, "CompanyDivisionMasterLog", userDetails, "Master")
                  setLoading(false);
                  MySwal.fire({
                    text: "Projects are already linked to " + compName + " Company / Division. Thus, selected Company / Division cannot be Permanently Deleted and is updated as Inactive.",
                    imageUrl: successImage,
                    customClass: "swal-success",
                    didClose: () => {
                      setReload(!reload);
                    },
                  });
                }
              })
                .catch((error) => {
                  setLoading(false);
                  MySwal.fire({
                    text: "Unable to process request.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                  });
                })
                .finally(() => {
                  setReload(!reload);
                });
            }
          }
        }
      });
    } catch (error) {
      MySwal.fire({
        text: "Unable to process request.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
    }
  }

  const handleGlobalExport = async () => {
    let filename = "CompanyDivisionMaster";
    let excelDownoadData = [];
    let rolesData = await getRolesForUnmaskedData().catch((err) => { console.log(err) });
    let ismasking = true;
    if (rolesData.length > 0) {
      let showMaskedDataInd = rolesData[0].RoleCode.trim().split(",").indexOf(userDetails.role);
      if (showMaskedDataInd !== -1) {
        ismasking = false;
      }
    }
    for (let dt of excelData) {
      if (!ismasking) {
        let tempUnique = "SK#" + dt.SK;
        let compObj = {
          TelephoneNo: dt.CompanyTelephoneNo
        }
        await addLogDetails(compObj, "Download", tempUnique, "CompanyDivisionMasterLog", userDetails, "Master")
      }

      excelDownoadData.push({
        "DeleteIndication": dt.DeleteIndication,
        "CompanyCode": dt.CompanyCode,
        "CompanyName": dt.CompanyName,
        "CompanyLocationType": dt.CompanyLocationType,
        "CompanyCountry": dt.CompanyCountry,
        "CompanyHOAddress": dt.CompanyHOAddress,
        "CompanyWebsiteAddress": dt.CompanyWebsiteAddress,
        "CompanyTelephoneNo": escapeExcelData(dt.CompanyTelephoneNo, ismasking),
        "CompanySector": dt.CompanySectorName,
        "CreatedBy": dt.CreatedByName,
        "CreatedOn": formatDateInDDMMYYYY(dt.CreatedOn),
        "ModifiedOn": formatDateInDDMMYYYY(dt.ModifiedOn),
        "ModifiedBy": dt.ModifiedByName
      });
    }
    let colName = ["Status", "Company / Division Code", "Company / Division", "Location Type", "Country", "HO Address", "Telephone No", "Website Address", "Sector", "Insterted By", "Inserted On", "Modified by", "Modified On"];
    let Fieldname = ["DeleteIndication", "CompanyCode", "CompanyName", "CompanyLocationType", "CompanyCountry", "CompanyHOAddress", "CompanyTelephoneNo", "CompanyWebsiteAddress", "CompanySector", "CreatedBy", "CreatedOn", "ModifiedBy", "ModifiedOn"];
    exportToExcel(filename, "Data", excelDownoadData, "A1:M1", colName, Fieldname);
  };

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <BreadcrumbComp />
      <Container fluid className="main-container">
        <ActionComp label={"Add New Company / Division"} exportEvent={() => handleGlobalExport()} handleAdd={handleAdd} />
        <GridMaster
          rowDetails={filteredResult}
          colDetails={columnDefs}
          fieldNames={fieldName}
          headerNames={headerName}
          getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
          getFilterColLength={(getLength) => setFilterColLength(getLength)}
          setExcelData={setExcelData}
          handleView={handleView}
          handleEdit={handleEdit}
          openConfirmBox={handleDelete}
          resetind={resetFilter}
        />
        {showForm && (
          <AddEditCompanyDivisionMaster
            show={showForm}
            action={action}
            rowdata={rowData}
            CompanyMasterData={CompanyMasterData}
            onHide={() => setShowForm(false)}
            setReload={setReload}
            reload={reload}
          />
        )}
      </Container>
    </>
  );

}

export default CompanyDivisionMaster;
