import React, { useState, useEffect, useContext } from "react";
import "./RoleMaster.css";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../layouts/ActionComp";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import GridMaster from "./GridMaster";
import StatusRenderer from "./StatusRenderer.jsx";
import ActionRenderer from "./ActionRenderer";
import { getAllRoles, deleteRole, updateRoleId } from "../../services/role-service";
import { getUsersForRole } from "../../services/user-service";
import { exportToExcel } from "../utils/ExcelCreation";
import dataMenu from "../../data/sessionmaster.json";
import { getCurrentDateTime, formatDateInDDMMYYYY } from "../../functions/gbcs-functions";
import 'react-toastify/dist/ReactToastify.css';
import AddEditRoleMaster from "./AddEditRoleMaster";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { menuActions } from "../../store/menu-slice";
import { useDispatch } from "react-redux";
import { sortByPropertyInAscending } from "../../utils/Utility";
import { searchActions } from "../../store/grid-slice";
import { addLogDetails } from "../../services/logger-entry-service";

const RoleMaster = () => {
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = { userName: context.userName, role: context.role, userId: context.userId }

  const dispatch = useDispatch();
  dispatch(menuActions.setMenu("Role & Authorization Master"));

  const [rowData, setRowData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [resetFilter, setResetFilter] = useState(true);
  const [loading, setLoading] = useState(true);

  const columnDefs = [
    {
      headerName: "Status",
      field: "DeleteIndication",
      flex: false,
      width: 150,
      resizable: false,
      tooltipField: "DeleteIndication",
      cellRenderer: StatusRenderer,
      sort: 'asc'
    },
    {
      headerName: "Role Code",
      field: "RoleCode",
      width: 150,
      tooltipField: "RoleCode",
    },
    {
      headerName: "Role Name",
      field: "RoleName",
      tooltipField: "RoleName",
      width: 150,
      sort: 'asc'
    },
    {
      headerName: "Data Control",
      field: "DataControl",
      tooltipField: "DataControl",
      width: 150
    },
    {
      headerName: "Authorized Sessions",
      field: "AuthorizedSessions",
      width: 150,
      tooltipField: "AuthorizedSessions",
    },
    {
      field: "",
      width: 120,
      cellRenderer: function (params) {
        return <ActionRenderer selectedData={params.data} handleViewEvent={handleView} handleEditEvent={handleEdit} handleDeleteEvent={handleDelete} />
      },
      sortable: false,
      filter: false,
      pinned: 'right'
    },
  ];

  const headerName = (field) => {
    let name;
    switch (field) {
      case "DeleteIndication":
        name = "Role Status";
        break;
      case "RoleCode":
        name = "Role Code";
        break;
      case "RoleName":
        name = "Role Name";
        break;
      case "DataControl":
        name = "Data Control";
        break;
      case "AuthorizedSessions":
        name = "Authorized Sessions";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fieldName = (header) => {
    let name;
    switch (header) {
      case "Status":
        name = "DeleteIndication";
        break;
      case "Role Code":
        name = "RoleCode";
        break;
      case "Role Name":
        name = "RoleName";
        break;
      case "Data Control":
        name = "DataControl";
        break;
      case "Authorized Sessions":
        name = "AuthorizedSessions";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fetchRoleMaster = async () => {
    setLoading(true);
    dispatch(searchActions.setSearch(""));
    const result = await getAllRoles();
    result.forEach(element => {
      let sessionArr = [];
      element.AuthorizedSessions.forEach(ele => {
        sessionArr.push(ele.SessionName);
      })
      element.AuthorizedSessions = sessionArr.toString();
    });
    result?.sort(sortByPropertyInAscending("DeleteIndication"));
    setFilteredResult(result);
    setExcelData(result);
    setResetFilter(!resetFilter);
    setLoading(false);
  };

  useEffect(() => {
    fetchRoleMaster();
  }, [reload]);

  const handleView = async (row) => {
    setLoading(true);
    const result = await getAllRoles();
    result.forEach(element => {
      if (element.SK === row.SK && element.PK === row.PK)
        row = element;
    });
    setAction("View");
    let tempUnique = "SK#" + row.SK;
    await addLogDetails(row, "View", tempUnique, "RoleMasterLog", userDetails, "Master")
    setRowData(row);
    setShowForm(true);
    setLoading(false);
  }

  const handleEdit = async (row) => {
    setLoading(true);
    const result = await getAllRoles();
    result.forEach(element => {
      if (element.SK === row.SK && element.PK === row.PK)
        row = element;
    });
    setAction("Edit");
    let tempUnique = "SK#" + row.SK;
    await addLogDetails(row, "View", tempUnique, "RoleMasterLog", userDetails, "Master")
    setRowData(row);
    setShowForm(true);
    setLoading(false);
  }

  const handleDelete = async (row) => {
    const name = row.RoleName;
    let tempUnique = "SK#" + row.SK;
    let result;
    MySwal.fire({
      text: "Are you sure, do you want to delete Role " + name + "?",
      showDenyButton: true,
      cancelButtonColor: "#fff",
      confirmButtonColor: "#16911B",
      confirmButtonText: "Delete",
      reverseButtons: true,
      customClass: "swal-confirmation",
      title: "Delete Role"
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        setLoading(true);

        //1st delete condition
        let userList = await getUsersForRole(row.RoleCode);
        if (userList == undefined || userList.length == 0) {
          result = deleteRole(row.SK)
          result.then(async (resp) => {
            if (resp.status) {
              await addLogDetails(row, "Delete", tempUnique, "RoleMasterLog", userDetails, "Master")
              setLoading(false);
              MySwal.fire({
                text: "The Role is Permanently Deleted!",
                imageUrl: successImage,
                customClass: "swal-success",
                didClose: () => {
                  setReload(!reload);
                },
              });
            }
          })
            .catch((error) => {
              setLoading(false);
              MySwal.fire({
                text: "Unable to process request.",
                imageUrl: errorImage,
                customClass: "swal-success",
              });
            })
            .finally(() => {
              setReload(!reload);
            });

        }
        else {

          let activeUser = userList.filter((item) => item.DeleteIndication === "Active").length;
          if (activeUser > 0) {
            setLoading(false);
            MySwal.fire({
              text: "Users are already linked to " + name + " Role. Thus, selected Role cannot be Deleted.",
              imageUrl: errorImage,
              customClass: "swal-success"
            });
          }
          else {
            let roleObj = row;
            let todayDate = getCurrentDateTime("-");
            roleObj.ModifiedBy = userDetails.userId;
            roleObj.ModifiedByName = userDetails.userName;
            roleObj.ModifiedOn = todayDate;
            roleObj.DeleteIndication = "Inactive";
            result = updateRoleId(roleObj);
            result.then(async (resp) => {
              if (resp.status) {
                await addLogDetails(row, "Delete", tempUnique, "RoleMasterLog", userDetails, "Master")
                setLoading(false);
                MySwal.fire({
                  text: "Users are already linked to " + name + " Role. Thus, selected Role cannot be Permanently Deleted and is updated as Inactive.",
                  imageUrl: successImage,
                  customClass: "swal-success",
                  didClose: () => {
                    setReload(!reload);
                  },
                });
              }
            })
              .catch((error) => {
                setLoading(false);
                MySwal.fire({
                  text: "Unable to process request.",
                  imageUrl: errorImage,
                  customClass: "swal-success",
                });
              })
              .finally(() => {
                setReload(!reload);
              });
          }
        }
      }
    });
  }

  const handleGlobalExport = async () => {
    let filename = "Rolemaster";
    let excelDownoadData = [];
    let res = await getAllRoles();
    let temp = [];
    excelData.forEach(element => {
      const found = res.find(test => test.RoleCode === element.RoleCode);
      if (found !== undefined) {
        temp.push(found);
      }
    })

    for (let k of temp) {
      for (let z of dataMenu) {
        let dataEntry = []
        let linkedSessions = k.AuthorizedSessions.filter((item) => item.SessionName === z.ParentMenu || z.SubMenu === item.SessionName)

        dataEntry["DeleteIndication"] = k.DeleteIndication;
        dataEntry["RoleCode"] = k.RoleCode;
        dataEntry["RoleName"] = k.RoleName;
        dataEntry["DataControl"] = k.DataControl;

        if (linkedSessions.length > 0) {
          dataEntry["Session"] = z.ParentMenu + " session";
          dataEntry["Sub-Session"] = z.SubMenu + " session";
          dataEntry["Display"] = linkedSessions[0].Display ? "Yes" : "No";
          dataEntry["Add"] = linkedSessions[0].Add ? "Yes" : "No";
          dataEntry["Modify"] = linkedSessions[0].Modify ? "Yes" : "No";
          dataEntry["Delete"] = linkedSessions[0].Delete ? "Yes" : "No";
          dataEntry["Export"] = linkedSessions[0].Export ? "Yes" : "No";
          dataEntry.CreatedByName = k.CreatedByName;
          dataEntry.ModifiedByName = k.ModifiedByName;
          dataEntry.CreatedOn = formatDateInDDMMYYYY(k.CreatedOn.toString())
          dataEntry.ModifiedOn = formatDateInDDMMYYYY(k.ModifiedOn.toString());
          excelDownoadData.push(dataEntry);
        } else {
          dataEntry["Session"] = z.ParentMenu + " session";
          dataEntry["Sub-Session"] = z.SubMenu + " session";
          dataEntry["Display"] = "No";
          dataEntry["Add"] = "No";
          dataEntry["Modify"] = "No";
          dataEntry["Delete"] = "No";
          dataEntry["Export"] = "No";
          dataEntry.CreatedByName = k.CreatedByName;
          dataEntry.ModifiedByName = k.ModifiedByName;
          dataEntry.CreatedOn = formatDateInDDMMYYYY(k.CreatedOn.toString())
          dataEntry.ModifiedOn = formatDateInDDMMYYYY(k.ModifiedOn.toString());
          excelDownoadData.push(dataEntry);
        }
      }
    }
    let colName = ["Role Status", "Role Code", "Role Name", "Data Control", "Session", "Sub-Session", "Display", "Add", "Modify", "Delete", "Export", "Inserted By", "Inserted On", "Modified By", "Modified On"];
    let Fieldname = ["DeleteIndication", "RoleCode", "RoleName", "DataControl", "Session", "Sub-Session", "Display", "Add", "Modify", "Delete", "Export", "CreatedByName", "CreatedOn", "ModifiedByName", "ModifiedOn"];
    exportToExcel(filename, "Data", excelDownoadData, "A1:O1", colName, Fieldname);
  };

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <BreadcrumbComp />
      <Container fluid className="main-container">
        <ActionComp label={"Add New Role"} exportEvent={() => handleGlobalExport()} handleAdd={handleAdd} />
        <GridMaster
          rowDetails={filteredResult}
          colDetails={columnDefs}
          fieldNames={fieldName}
          headerNames={headerName}
          getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
          getFilterColLength={(getLength) => setFilterColLength(getLength)}
          setExcelData={setExcelData}
          handleView={handleView}
          handleEdit={handleEdit}
          openConfirmBox={handleDelete}
          resetind={resetFilter}
        />
        {showForm && (
          <AddEditRoleMaster
            show={showForm}
            action={action}
            rowdata={rowData}
            onHide={() => setShowForm(false)}
            setreload={setReload}
            reload={reload}
          />
        )}
      </Container>
    </>
  );
};

export default RoleMaster;
