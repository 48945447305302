import React, { useState, useEffect, useContext } from "react";
import "./RoleMaster.css";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../layouts/ActionComp";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import GridMaster from "./GridMaster";
import StatusRenderer from "./StatusRenderer.jsx";
import ActionRenderer from "./ActionRenderer";
import { DeleteUser, getAllUsers, UpdateUserStatus } from "../../services/user-service";
import { getProjectsByUser } from "../../services/project-service";
import { exportToExcel } from "../utils/ExcelCreation";
import { getCurrentDateTime, formatDateInDDMMYYYY } from "../../functions/gbcs-functions";
import AddEditUserMaster from "./AddEditUserMaster";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import { searchActions } from "../../store/grid-slice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import escapeExcelData from "../utils/customValidation.js";
import { getUserInitials, sortByPropertyInAscending } from "../../utils/Utility";
import { AuthContext } from "../../contexts/AuthContext";
import { getRolesForUnmaskedData } from "../../services/common-master-service";
import { addLogDetails } from "../../services/logger-entry-service";

const UserMaster = () => {
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = { email: context.userEmail, userName: context.userName, role: context.role, userId: context.userId }
  const [rowData, setRowData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [resetFilter, setResetFilter] = useState(true);
  const [loading, setLoading] = useState(true);

  const AvatarCellRenderer = (params) => {
    let coloutIndex = params.node.rowIndex % 5;
    let initials = getUserInitials(params.value);
    return (
      <div className="user-name-initials">
        <div className={"user-initials user-initials-color" + coloutIndex}>
          <span>{initials}</span>
        </div>
        <div className="user-info">
          <span className="user-name">{params.value}</span>
        </div>
      </div>)
  }

  const dispatch = useDispatch();
  dispatch(menuActions.setMenu("User Master"));

  const columnDefs = [
    {
      headerName: "Status",
      field: "DeleteIndication",
      flex: false,
      width: 150,
      resizable: false,
      tooltipField: "DeleteIndication",
      cellRenderer: StatusRenderer
    },
    {
      headerName: "User Type",
      field: "UserType",
      minWidth: 150,
      tooltipField: "UserType",
    },
    {
      headerName: "User ID",
      field: "UserId",
      tooltipField: "UserId",
      minWidth: 150,
    },
    {
      headerName: "User Name",
      field: "UserName",
      minWidth: 150,
      tooltipField: "UserName",
      cellRenderer: AvatarCellRenderer
    },
    {
      headerName: "Company/Division",
      field: "CompanyName",
      minWidth: 150,
      tooltipField: "CompanyName",
    },
    {
      headerName: "User Role",
      field: "RoleName",
      minWidth: 150,
      tooltipField: "RoleName",
    },
    {
      headerName: "Designation",
      field: "Designation",
      minWidth: 150,
      tooltipField: "Designation",
    },
    {
      headerName: "Official Email ID",
      field: "EmailId",
      minWidth: 150,
      tooltipValueGetter: (params) => escapeExcelData(params.data.EmailId, true),
      valueFormatter: (params) => {
        return escapeExcelData(params.data.EmailId, true);
      },
    },
    {
      headerName: "Official Mobile No",
      field: "MobileNo",
      minWidth: 150,
      tooltipValueGetter: (params) => escapeExcelData(params.data.MobileNo, true),
      valueFormatter: (params) => {
        return escapeExcelData(params.data.MobileNo, true);
      },
    },
    {
      headerName: "Display Contract Financial Information",
      field: "DisplayContractFinInfo",
      minWidth: 150,
      tooltipField: "DisplayContractFinInfo",
    },
    {
      field: "",
      width: 120,
      cellRenderer: function (params) {
        return <ActionRenderer selectedData={params.data} handleViewEvent={handleView} handleEditEvent={handleEdit} handleDeleteEvent={handleDelete} />
      },
      sortable: false,
      filter: false,
      pinned: 'right'
    },
  ];

  const headerName = (field) => {
    let name;
    switch (field) {
      case "DeleteIndication":
        name = "Status";
        break;
      case "UserType":
        name = "User Type";
        break;
      case "UserId":
        name = "User ID";
        break;
      case "UserName":
        name = "User Name";
        break;
      case "CompanyName":
        name = "Company/Division";
        break;
      case "RoleName":
        name = "User Role";
        break;
      case "EmailId":
        name = "Official Email ID";
        break;
      case "MobileNo":
        name = "Official Mobile No";
        break;
      case "Designation":
        name = "Designation";
        break;
      case "DisplayContractFinInfo":
        name = "Display Contract Financial Information";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fieldName = (header) => {
    let name;
    switch (header) {
      case "Status":
        name = "DeleteIndication";
        break;
      case "User Type":
        name = "UserType";
        break;
      case "User ID":
        name = "UserId";
        break;
      case "User Name":
        name = "UserName";
        break;
      case "Company/Division":
        name = "CompanyName";
        break;
      case "User Role":
        name = "RoleName";
        break;
      case "Official Email ID":
        name = "EmailId";
        break;
      case "Official Mobile No":
        name = "MobileNo";
        break;
      case "Designation":
        name = "Designation";
        break;
      case "Display Contract Financial Information":
        name = "DisplayContractFinInfo";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fetchUserMaster = async () => {
    setLoading(true);
    dispatch(searchActions.setSearch(""));

    const result = await getAllUsers();

    result?.sort(sortByPropertyInAscending("DeleteIndication"));
    setFilteredResult(result);
    setExcelData(result);
    setResetFilter(!resetFilter);
    setLoading(false);
  };


  useEffect(() => {
    fetchUserMaster();
  }, [reload]);

  const handleView = async (row) => {
    setLoading(true);
    let tempUnique = "SK#" + row.SK;
    await addLogDetails(row, "View", tempUnique, "UserMasterLog", userDetails, "Master");
    setAction("View");
    setRowData(row);
    setShowForm(true);
    setLoading(false);
  }

  const handleEdit = async (row) => {
    setLoading(true);
    let tempUnique = "SK#" + row.SK;
    await addLogDetails(row, "View", tempUnique, "UserMasterLog", userDetails, "Master");
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
    setLoading(false);
  }

  const handleDelete = async (params) => {
    let result;
    let tempUnique = "SK#" + params.SK;

    let userName = params.UserName
    MySwal.fire({
      text: "Are you sure, do you want to delete User " + userName + "?",
      showDenyButton: true,
      cancelButtonColor: "#fff",
      confirmButtonColor: "#16911B",
      confirmButtonText: "Delete",
      reverseButtons: true,
      customClass: "swal-confirmation",
      title: "Delete User"
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        setLoading(true);
        let user_projectData = await getProjectsByUser(params.UserId);
        console.log("user_projectData", user_projectData)

        if (user_projectData == undefined || user_projectData.length == 0) {
          result = DeleteUser(params.UserId)
          result.then(async (resp) => {
            if (resp.status) {
              await addLogDetails(params, "Delete", tempUnique, "UserMasterLog", userDetails, "Master")

              setLoading(false);
              MySwal.fire({
                text: "The User is Permanently Deleted!",
                imageUrl: successImage,
                customClass: "swal-success",
                didClose: () => {
                  setReload(!reload);
                },
              });
            }
          })
            .catch((error) => {
              setLoading(false);
              MySwal.fire({
                text: "Unable to process request.",
                imageUrl: errorImage,
                customClass: "swal-success",
              });
            })
            .finally(() => {
              setReload(!reload);
            });

        }
        else {

          let activeProjects = user_projectData.filter((item) => item.DeleteIndication === "Active").length;

          if (activeProjects > 0) {
            setLoading(false);
            MySwal.fire({
              text: "The User is already linked to Projects. Do you still wish to delete User " + userName + "?",
              showDenyButton: true,
              cancelButtonColor: "#fff",
              confirmButtonColor: "#16911B",
              confirmButtonText: "Delete",
              reverseButtons: true,
              customClass: "swal-confirmation",
              title: "Delete User"
            }).then(async (result2) => {
              if (result2.isConfirmed) {
                setLoading(true);
                let userObj = params;
                let todayDate = getCurrentDateTime("-");
                userObj.ModifiedBy = userDetails.userId;
                userObj.ModifiedByName = userDetails.userName;
                userObj.ModifiedOn = todayDate;
                userObj.DeleteIndication = "Inactive";
                result = UpdateUserStatus(userObj);
                result.then(async (resp) => {
                  if (resp.status) {
                    await addLogDetails(params, "Delete", tempUnique, "UserMasterLog", userDetails, "Master")
                    setLoading(false);
                    MySwal.fire({
                      text: "Projects are already linked to  User. Thus, selected User cannot be Permanently Deleted and is updated as Inactive.",
                      imageUrl: successImage,
                      customClass: "swal-success",
                      didClose: () => {
                        setReload(!reload);
                      },
                    });
                  }
                  else {
                    setLoading(false);
                    MySwal.fire({
                      text: "Unable to process request.",
                      imageUrl: errorImage,
                      customClass: "swal-success",
                    });
                  }
                })
                  .catch((error) => {
                    setLoading(false);
                    MySwal.fire({
                      text: "Unable to process request.",
                      imageUrl: errorImage,
                      customClass: "swal-success",
                    });
                  })
                  .finally(() => {
                    setReload(!reload);
                  });



              }
            })
          }
          else {
            let userObj = params;
            let todayDate = getCurrentDateTime("-");
            userObj.ModifiedBy = userDetails.userId;
            userObj.ModifiedByName = userDetails.userName;
            userObj.ModifiedOn = todayDate;
            userObj.DeleteIndication = "Inactive";
            result = UpdateUserStatus(userObj);
            result.then(async (resp) => {
              if (resp.status) {
                await addLogDetails(params, "Delete", tempUnique, "UserMasterLog", userDetails, "Master")
                setLoading(false);
                MySwal.fire({
                  text: "Projects are already linked to  User. Thus, selected User cannot be Permanently Deleted and is updated as Inactive.",
                  imageUrl: successImage,
                  customClass: "swal-success",
                  didClose: () => {
                    setReload(!reload);
                  },
                });
              }
              else {
                setLoading(false);
                MySwal.fire({
                  text: "Unable to process request.",
                  imageUrl: errorImage,
                  customClass: "swal-success",
                });
              }
            })
              .catch((error) => {
                setLoading(false);
                MySwal.fire({
                  text: "Unable to process request.",
                  imageUrl: errorImage,
                  customClass: "swal-success",
                });
              })
              .finally(() => {
                setReload(!reload);
              });
          }
        }
      }
    });
  }

  const handleGlobalExport = async () => {
    let ismasking = true;
    let filename = "UserMaster";
    let excelDownoadData = [];
    let temp = Object.assign([], excelData);

    let rolesData = await getRolesForUnmaskedData().catch((err) => { console.log(err) });
    if (rolesData.length > 0) {
      let showMaskedDataInd = rolesData[0].RoleCode.trim().split(",").indexOf(userDetails.role);
      if (showMaskedDataInd !== -1) {
        ismasking = false;
      }
    }
    for (const element of temp) {
      if (!ismasking) {
        let tempUnique = "#SK#" + element.SK;
        await addLogDetails(element, "Download", tempUnique, "UserMasterLog", userDetails, "Master")
      }
      excelDownoadData.push({
        "DeleteIndication": element.DeleteIndication,
        "UserType": element.UserType,
        "UserId": element.UserId,
        "UserName": element.UserName,
        "CompanyCode": element.CompanyName,
        "RoleCode": element.RoleName,
        "Designation": element.Designation,
        "EmailId": escapeExcelData(element.EmailId, ismasking),
        "MobileNo": escapeExcelData(element.MobileNo, ismasking),
        "DisplayContractFinInfo": element.DisplayContractFinInfo,
        "CreatedByName": element.CreatedByName,
        "CreatedOn": formatDateInDDMMYYYY(element.CreatedOn),
        "ModifiedByName": element.ModifiedByName,
        "ModifiedOn": formatDateInDDMMYYYY(element.ModifiedOn),
      });
    }

    let colName = ["User Status", "User Type", "User ID", "User Name", "Company/Division", "User Role", "Designation", "Official Email ID", "Official Mobile No", "Dispaly Contract Financial Information", "Inserted By", "Inserted On", "Modified By", "Modified On"];
    let Fieldname = ["DeleteIndication", "UserType", "UserId", "UserName", "CompanyCode", "RoleCode", "Designation", "EmailId", "MobileNo", "DisplayContractFinInfo", "CreatedByName", "CreatedOn", "ModifiedByName", "ModifiedOn"];
    exportToExcel(filename, "Data", excelDownoadData, "A1:N1", colName, Fieldname);
  };

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <BreadcrumbComp />
      <Container fluid className="main-container">
        <ActionComp label={"Add New User"} exportEvent={() => handleGlobalExport()} handleAdd={handleAdd} />
        <GridMaster
          rowDetails={filteredResult}
          colDetails={columnDefs}
          fieldNames={fieldName}
          headerNames={headerName}
          getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
          getFilterColLength={(getLength) => setFilterColLength(getLength)}
          setExcelData={setExcelData}
          handleView={handleView}
          handleEdit={handleEdit}
          openConfirmBox={handleDelete}
          resetind={resetFilter}
        />
        {showForm && (
          <AddEditUserMaster
            show={showForm}
            action={action}
            rowdata={rowData}
            onHide={() => setShowForm(false)}
            setReload={setReload}
            reload={reload}
          />
        )}
      </Container>
    </>
  );
};

export default UserMaster;
