import React, { useEffect, useState, useRef, useMemo, useContext, forwardRef, useImperativeHandle } from "react";
import { Form, Modal, Row, Col, Container, Card, Spinner } from "react-bootstrap";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "../masters/GridMaster.css";
import { AgGridReact } from "ag-grid-react";
import { defaultEditableCol, getCurrentDateTime } from "../../functions/gbcs-functions";
import {
    getEquipmentParameterData,
    deleteEquipmentDetails,
    addEquipmentParameterDetails,
} from "../../services/equipmentactivity-master-service";
import { BsPlusLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import ActionRenderer from "./ActionRenderer";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import Pagination from "../utils/Pagination";
import { AuthContext } from "../../contexts/AuthContext";
import AddEquipmentParam from "./AddEquipmentParameters";
import { sortByPropertyInAscending } from "../../utils/Utility";

const EquipmentParameters = forwardRef((props, ref) => {
    const MySwal = withReactContent(Swal);
    let action = props.action;
    const [gridApi, setGridApi] = useState(null);
    const gridRef = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(3);
    const [columnDefs, setColumnDefs] = useState([]);
    let todayDate = getCurrentDateTime("-");
    const [loading, setLoading] = useState(true);
    const [showParameterForm, setShowParameterForm] = useState(false);
    const [paramGridData, setParamGridData] = useState([]);
    const [prereqData, setPrereqData] = useState([]);
    const [rowData, setRowData] = useState({});
    const [parameteraction, setParameterAction] = useState("");
    let tabName = props.paramtabname;
    const EquipmentCode = props.equipData.EquipmentCode;
    const EquipmentName = props.equipData.EquipmentName;

    const fieldTemp = {
        "Prerequisite": [
            {
                "FieldName": "Pre-Requisite Description",
                "DataType": "Text",
                "MaxLen": "500",
                "UniqueInd": true,
                "isMandatory": true
            },
            {
                "FieldName": "FAT/SAT Applicable",
                "DataType": "Checkbox",
                "optionValues": ["FAT Applicable", "SAT Applicable"],
                "validation": { "FAT Applicable": "FATEquipApplicableChecklist", "SAT Applicable": "SATEquipApplicableChecklist" }
            }
        ],
        "Instruments": [
            {
                "FieldName": "Testing Instrument Name",
                "DataType": "Text",
                "MaxLen": "200",
                "UniqueInd": true,
                "isMandatory": true
            }
        ],
        "Testing": [
            {
                "FieldName": "Test Description",
                "DataType": "Text",
                "MaxLen": "500",
                "UniqueInd": true,
                "isMandatory": true
            },
            {
                "FieldName": "FAT/SAT Applicable",
                "DataType": "Checkbox",
                "optionValues": ["FAT Applicable", "SAT Applicable"],
                "validation": { "FAT Applicable": "FATEquipApplicableChecklist", "SAT Applicable": "SATEquipApplicableChecklist" }
            },
        ],
        "DocumentReview": [
            {
                "FieldName": "Document Category",
                "DataType": "Text",
                "MaxLen": "100",
                "UniqueInd": false,
                "isMandatory": true
            },
            {
                "FieldName": "Document Name",
                "DataType": "Text",
                "MaxLen": "250",
                "UniqueInd": true,
                "isMandatory": true
            }
        ]
    }

    const [tabFields, setTabFields] = useState(fieldTemp[tabName]);
    const [actionButtons, setActionButtons] = useState(
        action === "View" ? {
            ViewAction: false,
            EditAction: false,
            DownloadAction: false,
            DeleteAction: false,
        } :
            {
                ViewAction: false,
                EditAction: true,
                DownloadAction: false,
                DeleteAction: true,
            });

    ///Check grid master
    function onGridReady(params) {
        setGridApi(params.api);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api;// <= assigned gridApi value on Grid ready
    }

    const fetchEquipmentParameterData = async () => {
        setLoading(true);
        const result = await getEquipmentParameterData({ "code": props.equipData.EquipmentCode, "tabName": tabName }, "master");
        if (result?.length > 0) {
            setParamGridData(result.sort(sortByPropertyInAscending("SeqNo")));
        }
        else {
            setParamGridData([]);
        }
        setLoading(false);
    }

    const handleEdit = async (row) => {
        setParameterAction("Edit");
        setRowData(row);
        setShowParameterForm(true);
    };

    const handleDelete = async (row) => {
        MySwal.fire({
            text: "Are you sure, do you want to delete Equipment / Activity " + props.headername + "?",
            showDenyButton: true,
            cancelButtonColor: "#fff",
            confirmButtonColor: "#16911B",
            confirmButtonText: "Delete",
            reverseButtons: true,
            customClass: "swal-confirmation",
            title: "Delete Equipment / Activity"
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                setLoading(true);
                let delObj = { PK: row.PK, SK: row.SK };
                let result = deleteEquipmentDetails(delObj);
                result
                    .then((resp) => {
                        if (resp.status) {
                            setLoading(false);
                            MySwal.fire({
                                text: "Equipment / Activity " + props.headername + " has beed deleted successfully.",
                                imageUrl: successImage,
                                customClass: "swal-success",
                                didClose: () => {
                                    fetchEquipmentParameterData();
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        MySwal.fire({
                            text: "Unable to Process Request",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    });
            }
        })
    }

    useEffect(() => {
        setLoading(true);
        let tempArr = [];
        let counter = 0;
        if (tabFields !== undefined) {
            for (let dt of tabFields) {
                if (dt.DataType === "Text") {
                    tempArr.push({
                        headerName: dt.FieldName,
                        field: dt.FieldName,
                        width: 220,
                        editable: false,
                        rowDrag: counter === 0 ? true : false
                    });
                }
                else if (dt.DataType === "Checkbox") {
                    for (let item of dt.optionValues) {
                        tempArr.push({
                            headerName: item,
                            field: item,
                            maxWidth: 160,
                            editable: false,
                            cellRenderer: function (params) {
                                return (
                                    <input
                                        type="checkbox"
                                        disabled
                                        checked={params.data[item]}
                                    />
                                )
                            }
                        });
                    }
                }
                counter++;
            }

            tempArr.push({
                field: "",
                width: 120,
                cellRenderer: function (params) {
                    return <ActionRenderer
                        selectedData={params}
                        actionName={actionButtons}
                        handleEditEvent={(params) => handleEdit(params.data)}
                        handleDeleteEvent={(params) => handleDelete(params.data)}
                    />
                },
                sortable: false,
                filter: false,
                pinned: 'right'
            });
        }
        setColumnDefs(tempArr);
        fetchEquipmentParameterData();
        setLoading(false);
    }, [tabFields]);

    const headerName = (field) => {
        let name;
        switch (field) {
            case "PrerequisireDesc":
                name = "Pre-Requisite Description";
                break;
            case "FatApplicable":
                name = "FAT Applicable";
                break;
            case "SatApplicable":
                name = "SAT Applicable";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const defaultColDef = useMemo(() => {
        return defaultEditableCol();
    }, []);

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "PrerequisireDesc":
                name = "Pre-Requisite Description";
                break;
            case "FatApplicable":
                name = "FAT Applicable";
                break;
            case "SatApplicable":
                name = "SAT Applicable";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    function getRowData() {
        let tabData = [];
        gridRef.current.gridOptionsService.api.forEachNode((node) =>
            tabData.push(node.data)
        );
        return tabData;
    }

    const handleSubmit = async () => {
        setLoading(true);
        let gridData = getRowData();
        if (gridData?.length > 0) {
            let counter = 1;
            let updatedData = [];
            for (let dt of gridData) {
                let obj = Object.assign({}, dt);
                let delObj = { PK: dt.PK, SK: dt.SK, SeqNo: dt.SeqNo };
                let updateInd = false;
                let deleteInd = false;

                if (counter !== obj.SeqNo) {
                    obj.SeqNo = counter;
                    obj.SK = tabName + "#" + counter;
                    updateInd = true;
                    deleteInd = true;
                }
                updatedData.push(obj);
                if (updateInd) {
                    let tmpRes = await addEquipmentParameterDetails(obj, tabName, props.equipData.EquipmentCode, "existing");
                    if (tmpRes?.status) {
                        if (deleteInd && delObj.SeqNo > gridData?.length) {
                            let delResult = await deleteEquipmentDetails(delObj);
                        }
                    }
                }
                counter++;
            }
            setParamGridData(updatedData);
            MySwal.fire({
                text: props.headername + " details added successfully.",
                imageUrl: successImage,
                customClass: "swal-success"
            });
        }
        else {
            MySwal.fire({
                text: "Please add at least one record first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        setLoading(false);
    };

    useImperativeHandle(ref, () => ({
        funcOnNext: async () => {
            if (window.innerWidth > 768) {
                await handleSubmit();
            }
        }
    }));

    const addNewRow = () => {
        if (EquipmentCode === '') {//use prop var
            MySwal.fire({
                text: "Please add equipment first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        else if (props.hasOwnProperty("limitValue")) {
            if (paramGridData.length < props.limitValue) {
                setParameterAction("Add");
                setShowParameterForm(true);
            } else {
                MySwal.fire({
                    text: "You can add only " + props.limitValue + " records for " + tabName + ".",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            }
        }
        else {
            setParameterAction("Add");
            setShowParameterForm(true);
        }
    };

    const filteredResult = useMemo(() => {
        let firstPageIndex = 0;
        if (currentPage > 1) {
            firstPageIndex = (currentPage - 1) * pageSize;
        }
        if (firstPageIndex >= paramGridData.length) {
            firstPageIndex = 0;
            const totalPageCount = Math.ceil(paramGridData.length / pageSize);
            if (totalPageCount > 0) {
                setCurrentPage(totalPageCount)
            } else {
                setCurrentPage(1)
            }
        }
        let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
        return paramGridData ? paramGridData.slice(firstPageIndex, lastPageIndex) : [];
    }, [currentPage, paramGridData, pageSize]);

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Form
                id={"EquipmentParameter" + tabName}
                name={"EquipmentParameter" + tabName}
                // onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Modal.Body>
                    <Row>
                        <Col lg={6} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="EquipmentCode"
                                    type="text"
                                    name="EquipmentCode"
                                    maxLength="50"
                                    placeholder=" "
                                    value={EquipmentCode}
                                    readOnly
                                />
                                <Form.Label htmlFor="EquipmentCode">Equipment / Activity Code</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={6} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="EquipmentName"
                                    type="text"
                                    name="EquipmentName"
                                    maxLength="100"
                                    placeholder=" "
                                    value={EquipmentName}
                                    readOnly
                                />
                                <Form.Label htmlFor="EquipmentName">Equipment / Activity Name</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={action === "View" || props.equipData.DeleteIndication === "Inactive" ? "prevHideButton" : "hstack"}>
                        <Col xs={12} className="addNewRow">
                            <BsPlusLg size={20} className="plusIcon" />
                            &nbsp;
                            <Link onClick={() => addNewRow()} className="blueColor">Add More</Link>
                        </Col>
                    </Row>
                </Modal.Body>
            </Form>
            <Container fluid className="grid-comp-containerCss">
                <div className="ag-theme-alpine agGridContainerEquip d-none d-md-block d-lg-block">
                    <AgGridReact
                        ref={gridRef}
                        rowData={paramGridData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        fieldNames={fieldName}
                        headerNames={headerName}
                        enableBrowserTooltips={true}
                        onGridReady={onGridReady}
                        rowDragManaged={true}
                    />
                </div>
                <div className="market-wrapper d-md-none d-lg-none cardContainerResponsive">
                    {
                        filteredResult.map((selection, index) =>
                            <Card className="cardContainer" key={index}>
                                <Card.Body className="cardContainerResponsive">
                                    <div className="actionIcons">
                                        <ActionRenderer selectedData={selection}
                                            actionName={actionButtons}
                                            handleEditEvent={(selection) => handleEdit(selection)}
                                            handleDeleteEvent={(selection) => handleDelete(selection)} />
                                    </div>
                                    {columnDefs.map((header, index) =>
                                        <Row key={index} className="responsive-row">
                                            <Col className="colHeader">{header.headerName}</Col>

                                            {header.headerName === "FAT Applicable" || header.headerName === "SAT Applicable" ?
                                                <Col className="colData">
                                                    <input type="checkbox" disabled checked={selection[header.field]} />
                                                </Col>
                                                :
                                                <Col className="colData">{selection[header.field]}</Col>
                                            }
                                        </Row>
                                    )}
                                </Card.Body>
                            </Card>
                        )}
                    {filteredResult?.length > 0 && (
                        <Pagination
                            currentPage={currentPage}
                            totalCount={paramGridData ? paramGridData.length : 0}
                            data={paramGridData}
                            pageSize={pageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        />)}
                </div>
            </Container>
            {showParameterForm && (
                <AddEquipmentParam
                    show={showParameterForm}
                    rowdata={rowData}
                    action={parameteraction}
                    tabflds={tabFields}
                    paramTabName={props.paramtabname}
                    onHide={() => setShowParameterForm(false)}
                    fetchEquipmentParameterData={fetchEquipmentParameterData}
                    equipcode={EquipmentCode}
                    headername={props.headername}
                    equipData={props.equipData}
                />
            )}
        </>
    );
});

export default EquipmentParameters;
