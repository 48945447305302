import React, { forwardRef, useState, useEffect, useRef, useImperativeHandle } from "react";
import { Container, Form, Col, Spinner } from "react-bootstrap";
import { formatDateInDDMMYYYY, DashboardProjDataContainer } from "../../functions/gbcs-functions";
import GridMaster from "../masters/GridMaster";
import { getSiteObservationReportData } from "../../services/siteobservationreport-service";
import { ServiceMasterAllData } from "../../services/common-master-service"
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { exportToExcel } from "../utils/ExcelCreation";
import { sortByPropertyInAscending } from "../../utils/Utility";
import { exportToExcelDashboardSOR } from "./ExcelCreation_Dashboard";
import "../masters/GridMaster.css";


const DashboardSORView = forwardRef((props, ref) => {
    const [isToggleActive, setIsToggleActive] = useState(false);
    const [filteredResult, setFilteredResult] = useState([]);
    const [filteredResult1, setFilteredResult1] = useState([]);
    const [filteredResulttemp, setFilteredResulttemp] = useState([]);
    const [filterColLength, setFilterColLength] = useState(0);
    const [excelData, setExcelData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [columnDefs1, setcolumnDefs1] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const gridRef = useRef();


    function getRowStyling(params) {
        if (params.data.ActivityName.toString().toLocaleLowerCase().includes("total")) {
            return { 'background-color': '#FAFAFA' }
        }
    }
    const columnDefsSummary = [
        {
            headerName: "Services",
            tooltipField: "Services",
            children: [
                { field: 'ActivityName', headerName: "Equipment / Activity", pinned: 'left', minWidth: 210, headerClass: "grid-sor-columns" }
            ],
            headerClass: "grid-sor-columns",
            pinned: 'left',
            getRowStyle: getRowStyling
        },
    ]

    const columnDefs = [
        {
            headerName: "Issue Date",
            field: "IssueDate",
            minWidth: 150,
            tooltipField: "IssueDate",
            filter: "agDateColumnFilter",
            headerClass: "grid-sor-columns",
            valueFormatter: (params) => {
                return formatDateInDDMMYYYY(params.data.IssueDate);
            },
            filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                    const dateAsString = cellValue;
                    if (dateAsString == null) {
                        return 0;
                    }
                    const dateCell = new Date(cellValue + " 00:00:00");

                    if (dateCell < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (dateCell > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                },
            }
        },
        {
            headerName: "Raised By",
            field: "RaisedBy",
            tooltipField: "RaisedBy",
            minWidth: 150,
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Raised By Name",
            field: "RaisedByName",
            minWidth: 130,
            tooltipField: "RaisedByName",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Floor No.",
            field: "FloorNo",
            minWidth: 150,
            tooltipField: "FloorNo",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Area",
            field: "Area",
            minWidth: 150,
            tooltipField: "Area",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Service Name",
            field: "ServiceName",
            minWidth: 150,
            tooltipField: "ServiceName",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Service Type",
            field: "ServiceType",
            minWidth: 150,
            tooltipField: "ServiceType",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Checklist Category",
            field: "ChecklistCategory",
            minWidth: 150,
            tooltipField: "ChecklistCategory",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Equipment / Activity Name",
            field: "EquipmentActivityName",
            minWidth: 150,
            tooltipField: "EquipmentActivityName",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Equipment / Activity Tag (Serial No.)",
            field: "EquipmentActivityTagSrNo",
            minWidth: 150,
            tooltipField: "EquipmentActivityTagSrNo",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Equipment Activity Capacity",
            field: "EquipmentActivityCapacity",
            minWidth: 150,
            tooltipField: "EquipmentActivityCapacity",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Responsible Contractor OEM",
            field: "ResponsibleContractorOEM",
            minWidth: 150,
            tooltipField: "ResponsibleContractorOEM",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Issue Description",
            field: "IssueDescription",
            minWidth: 150,
            tooltipField: "IssueDescription",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Remarks",
            field: "Remarks",
            minWidth: 150,
            tooltipField: "Remarks",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Criticality",
            field: "Criticality",
            minWidth: 150,
            tooltipField: "Criticality",
            headerClass: "grid-sor-columns",
        },
        {
            //date 
            headerName: "Anticipated Closure Date",
            field: "AnticipatedClosureDate",
            minWidth: 150,
            tooltipField: "AnticipatedClosureDate",
            headerClass: "grid-sor-columns",
            filter: "agDateColumnFilter",
            valueFormatter: (params) => {
                return formatDateInDDMMYYYY(params.data.AnticipatedClosureDate);
            },
            filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                    const dateAsString = cellValue;
                    if (dateAsString == null) {
                        return 0;
                    }
                    const dateCell = new Date(cellValue + " 00:00:00");

                    if (dateCell < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (dateCell > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                },
            }
        },
        {
            headerName: "Fire Integration Service",
            field: "FireIntegrationService",
            minWidth: 150,
            tooltipField: "FireIntegrationService",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "IST Observations",
            field: "ISTObservations",
            minWidth: 150,
            tooltipField: "ISTObservations"
        },
        {
            headerName: "Corrective Action",
            field: "CorrectiveAction",
            minWidth: 150,
            tooltipField: "CorrectiveAction",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Corrected Status",
            field: "CorrectedStatus",
            minWidth: 150,
            tooltipField: "CorrectedStatus",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Verified By",
            field: "VerifiedBy",
            minWidth: 150,
            tooltipField: "VerifiedBy",
            headerClass: "grid-sor-columns",
        },
        {
            headerName: "Verified By Name",
            field: "VerifiedByName",
            minWidth: 150,
            tooltipField: "VerifiedByName",
            headerClass: "grid-sor-columns",
        },
        {
            //date
            headerName: "Verified Date",
            field: "VerifiedDate",
            minWidth: 150,
            tooltipField: "VerifiedDate",
            headerClass: "grid-sor-columns",
            filter: "agDateColumnFilter",
            valueFormatter: (params) => {
                return formatDateInDDMMYYYY(params.data.VerifiedDate);
            },
            filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                    const dateAsString = cellValue;
                    if (dateAsString == null) {
                        return 0;
                    }
                    const dateCell = new Date(cellValue + " 00:00:00");

                    if (cellValue === "" || dateCell < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (dateCell > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                },
            }
        },
        {
            headerName: "Uploads",
            field: "Uploads",
            minWidth: 150,
            tooltipField: "Uploads",
            headerClass: "grid-sor-columns",
        },
    ];

    const headerName = (field) => {
        let name;
        switch (field) {
            case "IssueDate":
                name = "Issue Date";
                break;
            case "RaisedBy":
                name = "Raised By";
                break;
            case "RaisedByName":
                name = "Raised By Name";
                break;
            case "FloorNo":
                name = "Floor No";
                break;
            case "Area":
                name = "Area";
                break;
            case "ServiceName":
                name = "Service Name";
                break;
            case "ServiceType":
                name = "Service Type";
                break;
            case "ChecklistCategory":
                name = "Checklist Category";
                break;
            case "EquipmentActivityName":
                name = "Equipment / Activity Name";
                break;
            case "EquipmentActivityTagSrNo":
                name = "Equipment / Activity Tag (Serial No.)";
                break;
            case "EquipmentActivityCapacity":
                name = "Equipment Activity Capacity";
                break;
            case "ResponsibleContractorOEM":
                name = "Responsible Contractor OEM";
                break;
            case "IssueDescription":
                name = "Issue Description";
                break;
            case "Remarks":
                name = "Remarks";
                break;
            case "Criticality":
                name = "Criticality";
                break;
            case "AnticipatedClosureDate":
                name = "Anticipated Closure Date";
                break;
            case "FireIntegrationService":
                name = "Fire Integration Service";
                break;
            case "ISTObservations":
                name = "IST Observations";
                break;
            case "CorrectiveAction":
                name = "Corrective Action";
                break;
            case "CorrectedStatus":
                name = "Corrected Status";
                break;
            case "VerifiedBy":
                name = "Verified By";
                break;
            case "VerifiedByName":
                name = "Verified By Name";
                break;
            case "VerifiedDate":
                name = "Verified Date";
                break;
            case "Uploads":
                name = "Uploads";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Issue Date":
                name = "IssueDate";
                break;
            case "Raised By":
                name = "RaisedBy";
                break;
            case "Raised By Name":
                name = "RaisedByName";
                break;
            case "Floor No":
                name = "FloorNo";
                break;
            case "Area":
                name = "Area";
                break;
            case "Service Name":
                name = "ServiceName";
                break;
            case "Service Type":
                name = "ServiceType";
                break;
            case "Checklist Category":
                name = "ChecklistCategory";
                break;
            case "Equipment / Activity Name":
                name = "EquipmentActivityName";
                break;
            case "Equipment / Activity Tag (Serial No.)":
                name = "EquipmentActivityTagSrNo";
                break;
            case "Equipment Activity Capacity":
                name = "EquipmentActivityCapacity";
                break;
            case "Responsible Contractor OEM":
                name = "ResponsibleContractorOEM";
                break;
            case "Issue Description":
                name = "IssueDescription";
                break;
            case "Remarks":
                name = "Remarks";
                break;
            case "Criticality":
                name = "Criticality";
                break;
            case "Anticipated Closure Date":
                name = "AnticipatedClosureDate";
                break;
            case "Fire Integration Service":
                name = "FireIntegrationService";
                break;
            case "IST Observations":
                name = "ISTObservations";
                break;
            case "Corrective Action":
                name = "CorrectiveAction";
                break;
            case "Corrected Status":
                name = "CorrectedStatus";
                break;
            case "Verified By":
                name = "VerifiedBy";
                break;
            case "Verified By Name":
                name = "VerifiedByName";
                break;
            case "Verified Date":
                name = "VerifiedDate";
                break;
            case "Uploads":
                name = "Uploads";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    useEffect(() => {
        if (props.tabNo === 2) {
            fetchSiteObservationReportData();
        }
    }, [props.tabNo]);

    const fetchSiteObservationReportData = async () => {
        setLoading(true);
        let distComb = [];
        let addedServices = [];
        let summaryData = [];
        let sorResult = await getSiteObservationReportData(props.SelectedProject.ProjectCode);
        if (sorResult?.length > 0) {
            let serviceMaster = await ServiceMasterAllData();
            for (let dt of sorResult) {
                let serviceData = serviceMaster.filter((item) => item.ServiceCode === dt.ServiceCode);
                if (serviceData?.length > 0) {
                    dt.ServiceName = serviceData[0].ServiceName;
                }

                if (distComb.indexOf(dt.ServiceName + dt.EquipmentActivityName) === -1) {
                    distComb.push(dt.ServiceName + dt.EquipmentActivityName);
                    addedServices.push({ ServiceName: dt.ServiceName, EquipmentActivityName: dt.EquipmentActivityName })
                }
            }
            //for summary
            let summaryReportHead1 = ["Services"];
            let summaryReportHead2 = ["Equipment / Activity"];

            let CriticalSnagsOpen = { ActivityName: "Critical Snags (Open)", SeqNo: 1 };
            let CriticalSnagsClosed = { ActivityName: "Critical Snags (Closed)", SeqNo: 2 };
            let CriticalSnagsTotal = { ActivityName: "Critical Snags (Total)", SeqNo: 3 };

            let NonCriticalSnagsOpen = { ActivityName: "Non-Critical Snags (Open)", SeqNo: 4 };
            let NonCriticalSnagsClosed = { ActivityName: "Non-Critical Snags (Closed)", SeqNo: 5 };
            let NonCriticalSnagsTotal = { ActivityName: "Non-Critical Snags (Total)", SeqNo: 6 };

            let totalOpenSnags = { ActivityName: "Snags (Open)", SeqNo: 7 };
            let totalCloseSnags = { ActivityName: "Snags (Closed)", SeqNo: 8 };
            let totalSnags = { ActivityName: "Snags (Total)", SeqNo: 9 };

            let openPerc = { ActivityName: "Snags (Open) %", SeqNo: 10 };
            let closedPerc = { ActivityName: "Snags (Closed) %", SeqNo: 11 };

            let summaryColHeader = Object.assign([], columnDefsSummary);

            addedServices.sort(sortByPropertyInAscending("ServiceName"));
            for (let dt of addedServices) {
                summaryReportHead1.push(dt.ServiceName);
                summaryReportHead2.push(dt.EquipmentActivityName);

                //total observations for service & equipment/Activity
                let obsList = sorResult.filter((item) => item.ServiceName === dt.ServiceName && item.EquipmentActivityName === dt.EquipmentActivityName);
                totalSnags[dt.ServiceName + dt.EquipmentActivityName] = obsList.length;

                //critical snags Open
                let openCriticalObs = obsList.filter((item) => item.Criticality === "Critical" && item.CorrectedStatus === "No");
                CriticalSnagsOpen[dt.ServiceName + dt.EquipmentActivityName] = openCriticalObs.length;

                //critical snag closed
                let closedCriticalObs = obsList.filter((item) => item.Criticality === "Critical" && (item.CorrectedStatus === "Yes" || item.CorrectedStatus === "NA"));
                CriticalSnagsClosed[dt.ServiceName + dt.EquipmentActivityName] = closedCriticalObs.length;


                //total critical snags
                CriticalSnagsTotal[dt.ServiceName + dt.EquipmentActivityName] = closedCriticalObs.length + openCriticalObs.length;
                //noncritical open snags
                let openNonCriticalObs = obsList.filter((item) => item.Criticality === "Non-Critical" && item.CorrectedStatus === "No");
                NonCriticalSnagsOpen[dt.ServiceName + dt.EquipmentActivityName] = openNonCriticalObs.length;

                //non-critical closed snags
                let closedNonCriticalObs = obsList.filter((item) => item.Criticality === "Non-Critical" && (item.CorrectedStatus === "Yes" || item.CorrectedStatus === "NA"));
                NonCriticalSnagsClosed[dt.ServiceName + dt.EquipmentActivityName] = closedNonCriticalObs.length;

                //total non-critical snags
                NonCriticalSnagsTotal[dt.ServiceName + dt.EquipmentActivityName] = closedNonCriticalObs.length + openNonCriticalObs.length;

                //total open snags
                totalOpenSnags[dt.ServiceName + dt.EquipmentActivityName] = openCriticalObs.length + openNonCriticalObs.length;

                //total closed snags
                totalCloseSnags[dt.ServiceName + dt.EquipmentActivityName] = closedCriticalObs.length + closedNonCriticalObs.length;

                //total all snags
                totalSnags[dt.ServiceName + dt.EquipmentActivityName] = obsList.length;

                //closed snags percentage
                closedPerc[dt.ServiceName + dt.EquipmentActivityName] = parseFloat((parseFloat(closedCriticalObs.length + closedNonCriticalObs.length)) * 100 / parseFloat(obsList.length)).toFixed(2);

                //open snags percentage
                openPerc[dt.ServiceName + dt.EquipmentActivityName] = parseFloat((parseFloat(openCriticalObs.length + openNonCriticalObs.length)) * 100 / parseFloat(obsList.length)).toFixed(2);

                let index = summaryColHeader.findIndex((item) => item.headerName.toLowerCase() === dt.ServiceName.toLowerCase());
                if (index === -1) {
                    summaryColHeader.push({
                        headerName: dt.ServiceName,
                        minWidth: 210,
                        tooltipField: dt.ServiceName,
                        children: [
                            {
                                field: dt.ServiceName + dt.EquipmentActivityName, minWidth: 210, headerName: dt.EquipmentActivityName, headerClass: "grid-sor-columns", cellClass: "grid-dashboard-cells"
                            }
                        ],
                        headerClass: "grid-sor-columns",
                    })
                }
                else {
                    summaryColHeader[index].children.push({
                        field: dt.ServiceName + dt.EquipmentActivityName, minWidth: 210, headerName: dt.EquipmentActivityName, headerClass: "grid-sor-columns", cellClass: "grid-dashboard-cells"
                    })
                }
            }
            setcolumnDefs1(summaryColHeader);

            summaryData.push(CriticalSnagsOpen);
            summaryData.push(CriticalSnagsClosed);
            summaryData.push(CriticalSnagsTotal);

            summaryData.push(NonCriticalSnagsOpen);
            summaryData.push(NonCriticalSnagsClosed);
            summaryData.push(NonCriticalSnagsTotal);

            summaryData.push(totalOpenSnags);
            summaryData.push(totalCloseSnags);
            summaryData.push(totalSnags);

            summaryData.push(openPerc);
            summaryData.push(closedPerc);

            summaryData.sort(sortByPropertyInAscending("SeqNo"));
            setFilteredResult1(summaryData);
        }
        let sorData = sorResult.filter((x) => x.CorrectedStatus === "Yes" || x.CorrectedStatus === "NA")
        setFilteredResult(sorData);
        setExcelData(sorData);
        setLoading(false);
    }

    const defaultColDef1 = {
        resizable: true,
        cellClass: 'cell-wrap-textDashboard',
        // sortable: true,
    };

    function onGridReady(params) {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api; // <= assigned gridApi value on Grid ready
    }

    const handleSwitchChange = (e) => {
        setIsToggleActive(e.target.checked);
        props.hideUnhideSearch(e.target.checked);
    };

    useImperativeHandle(ref, () => ({
        funcOnExport: async () => {
            await handleExportExcel();
        }
    }));

    const handleExportExcel = async () => {
        setLoading(true);
        let filename = "";

        if (isToggleActive) {
            if (excelData.length > 0) {
                filename = "Snags Detail View.xlsx";
                let excelDownoadData = [];
                for (let dt of excelData) {
                    excelDownoadData.push({
                        "IssueDate": formatDateInDDMMYYYY(dt.IssueDate),
                        "RaisedBy": dt.RaisedBy,
                        "RaisedByName": dt.RaisedByName,
                        "FloorNo": dt.FloorNo,
                        "Area": dt.Area,
                        "ServiceName": dt.ServiceName,
                        "ServiceType": dt.ServiceType,
                        "ChecklistCategory": dt.ChecklistCategory,
                        "EquipmentActivityName": dt.EquipmentActivityName,
                        "EquipmentActivityTagSrNo": dt.EquipmentActivityTagSrNo,
                        "EquipmentActivityCapacity": dt.EquipmentActivityCapacity,
                        "ResponsibleContractorOEM": dt.ResponsibleContractorOEM,
                        "IssueDescription": dt.IssueDescription,
                        "Remarks": dt.Remarks,
                        "Criticality": dt.Criticality,
                        "AnticipatedClosureDate": formatDateInDDMMYYYY(dt.AnticipatedClosureDate),
                        "FireIntegrationService": dt.FireIntegrationService,
                        "ISTObservations": dt.ISTObservations,
                        "CorrectiveAction": dt.CorrectiveAction,
                        "CorrectedStatus": dt.CorrectedStatus,
                        "VerifiedBy": dt.VerifiedBy,
                        "VerifiedByName": dt.VerifiedByName,
                        "VerifiedDate": formatDateInDDMMYYYY(dt.VerifiedDate),
                        "Uploads": dt.Uploads
                    });
                }

                let colName = ["Issue Date", "Raised By", "Raised By Name", "Floor No.", "Area", "Service Name", "Service Type", "Checklist Category", "Equipment / Activity Name", "Equipment / Activity Tag (Serial No.)", "Equipment / Activity Capacity", " Responsible Contractor / OEM", "Issue Description", "Remarks", "Criticality", "Anticipated Closure Date", "Fire Integration Service", "IST Observations", "Corrective Action", "Corrected Status", "Verified By", " Verified By Name", "Verified Date", "Uploads"];
                let Fieldname = ["IssueDate", "RaisedBy", "RaisedByName", "FloorNo", "Area", "ServiceName", "ServiceType", "ChecklistCategory", "EquipmentActivityName", "EquipmentActivityTagSrNo", "EquipmentActivityCapacity", "ResponsibleContractorOEM", "IssueDescription", "Remarks", "Criticality", "AnticipatedClosureDate", "FireIntegrationService", "ISTObservations", "CorrectiveAction", "CorrectedStatus", "VerifiedBy", "VerifiedByName", "VerifiedDate", "Uploads"];
                exportToExcel(filename, "Details", excelDownoadData, "A1:X1", colName, Fieldname);
            }
        }
        else {

            if (filteredResult1?.length > 0) {
                console.log("columnDefs1 ===> ", columnDefs1)
                let projectDataValue = "SITE OBSERVATION REPORT " + props.SelectedProject.CustomerName + ", " + props.SelectedProject.Location + " - " + props.SelectedProject.ProjectName;
                projectDataValue = projectDataValue.toString().toUpperCase();
                filename = "Snags Summary.xlsx";
                exportToExcelDashboardSOR(filename, "Summary", filteredResult1, projectDataValue, columnDefs1);
            }

        }
        setLoading(false);
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="main-blank-container">
                <DashboardProjDataContainer
                    TabString={"Site Observation Report"}
                    SelectedProject={props.SelectedProject}
                    handleInfoClick={props.handleInfoClick}
                />
                <div className="grid-comp-containerDashboard">
                    <div className="dashboard-grid-container">
                        <Col lg={12} md={12} xs={12}>
                            <span className={!isToggleActive ? "active-dashboardToggleLabel" : "inactive-dashboardToggleLabel"}>Summary View</span>
                            <Form.Switch className="dashboardToggle"
                                onChange={(e) => {
                                    handleSwitchChange(e);
                                }}
                                checked={isToggleActive}
                            />
                            <span className={isToggleActive ? "active-dashboardToggleLabel" : "inactive-dashboardToggleLabel"}>Deatil View</span>
                        </Col>
                    </div>
                    {
                        isToggleActive ?
                            <GridMaster
                                rowDetails={filteredResult}
                                colDetails={columnDefs}
                                fieldNames={fieldName}
                                headerNames={headerName}
                                getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
                                getFilterColLength={(getLength) => setFilterColLength(getLength)}
                                setExcelData={setExcelData}
                                resetind={true}
                            />
                            :
                            <div className="ag-theme-alpine agGridContainer">
                                <AgGridReact
                                    columnDefs={columnDefs1}
                                    defaultColDef={defaultColDef1}
                                    rowData={filteredResult1}
                                    suppressRowTransform={true}
                                    getRowStyle={getRowStyling}
                                    enableBrowserTooltips={true}
                                    onGridReady={onGridReady}
                                />
                            </div>

                    }
                </div>
            </Container>
        </>
    )

});

export default DashboardSORView;
