import React, { useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./CSS/ActionComp.css";
import { useDispatch } from "react-redux";
import { searchActions } from "../../store/grid-slice";
import downloadicon from "../../icons/downloadicon.svg";
import updateicon from "../../icons/updateicon.svg"

const CurrencyActionComp = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const dispatch = useDispatch();

  const onFilterTextChange = (e) => {
    dispatch(searchActions.setSearch(e.target.value));
  };

  const onExportClick = () => {
    // dispatch(searchActions.setSearch());
  };

  return (
    <Container fluid className="action-comp-container">
       <Row className="center-align">  
      {/* <div className="action-main">       */}
        <Col md={6} xs={6}>
        <div className="session-name">
          {props.label}
        </div>
        </Col> 
        <Col md={6} xs={6}>
        { props.FlagForDisable ? (
        <div className="parent-search-download" style={{ float: "right"}}>
            <img src={updateicon}
              alt="updateimg"
              className="currency-icon-border"
             onClick={props.editEvent}/>
            <img src={downloadicon}
              alt="downloadimg"
              className="currency-icon-border"
              onClick={props.exportEvent}
              />
        </div>
        ) : (<></>)
        }
        </Col>
       
      {/* </div> */}
      </Row>  
    </Container>
  );
};

export default CurrencyActionComp;
