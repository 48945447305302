import React, { useEffect, useState, useRef, useContext, useMemo, useImperativeHandle, forwardRef } from "react";
import { Form, Row, Col, Container, Card } from "react-bootstrap";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../utils/AddEditProjectMaster.css";
import "../../masters/GridMaster.css";
import { AgGridReact } from "ag-grid-react";
import Pagination from "../../utils/Pagination";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ActionRenderer from "../ActionRenderer";
import { AuthContext } from "../../../contexts/AuthContext";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import AddEditParameterDetail from "./AddEditParameterDetail";
import { BsPlusLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import ParameterCheckboxComponent from "./ParameterCheckboxComponent";
import { ConstParameterDetailsHeader } from "./ParameterFile";
import {
  AddChecklistParameterDetails,
  UpdateParameterDetailsHeader,
  DeleteChecklistParameterDetails,
} from "../../../services/checklist-master-service";
import {
  getCurrentDateTime,
} from "../../../functions/gbcs-functions";
import { sortByPropertyInAscending, sortByPropertyInDescending } from "../../../utils/Utility";

const ParameterDetails = forwardRef((props, ref) => {
  let action = props.action;
  let limitValue = props.limitValue;
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const pageSize = 3;
  const [currentPage, setCurrentPage] = useState(1);

  const MySwal = withReactContent(Swal);
  const [showParameterForm, setShowParameterForm] = useState(false);
  const [rowNo, setRowNo] = useState(0);
  const [rowData, setRowData] = useState({});
  const [parameteraction, setParameterAction] = useState("");

  const [parameterDetailsHeader, setParameterDetailsHeader] = useState(
    (action === "Edit" || action === "View") &&
      props.rowdata.hasOwnProperty("ParameterDetailsHeader")
      ? props.rowdata.ParameterDetailsHeader
      : ConstParameterDetailsHeader.filter(item => item.FieldType === "Default").map(o => o.FieldName)
  );

  const [parameterDetailsHeaderMandatory, setParameterDetailsHeaderMandatory] = useState(
    (action === "Edit" || action === "View") &&
      props.rowdata.hasOwnProperty("ParameterDetailsHeaderMandatory")
      ? props.rowdata.ParameterDetailsHeaderMandatory :
      ConstParameterDetailsHeader.filter(item => item.FieldType === "Default").map(o => o.FieldName)
  );

  const [isHeaderSaved, setIsHeaderSaved] = useState(
    (action === "Edit" || action === "View") &&
    props.rowdata.hasOwnProperty("ParameterDetailsHeader"));
  const [parameterDetailsData, setParameterDetailsData] = useState([]);

  useEffect(() => {
    let sortedResult = JSON.parse(JSON.stringify(props.parameterDetailsDt));
    sortedResult.sort(sortByPropertyInAscending("SeqNo"));
    setParameterDetailsData(sortedResult);
  }, [props.parameterDetailsDt]);

  const context = useContext(AuthContext);
  const userDetails = {
    userName: context.userName,
    userId: context.userId,
  };

  const menuAccess = context.accessibleMenus.filter(
    (item) => item.SessionName === "Checklist Master"
  );

  const actionButtons =
    (action === "View" || props.checklistVersion.startsWith("VER")) ? {
      ViewAction: false,
      EditAction: false,
      DownloadAction: false,
      DeleteAction: false,
    } :
      {
        ViewAction: false,
        EditAction: menuAccess[0]?.Modify,
        DownloadAction: false,
        DeleteAction: menuAccess[0]?.Delete,
      };

  function getRowData() {
    let rowData = [];
    gridRef.current.gridOptionsService.api.forEachNode((node) =>
      rowData.push(node.data)
    );
    return rowData;
  }

  const handleSubmit = async () => {
    // e.preventDefault();    
    let successMsg = "";
    let errMsg = "";
    let res = false;
    try {

      if (props.primeKey === "" || props.secondKey === "") {
        MySwal.fire({
          text: "Checklist code is blank. Kindly maintain Checklist Details.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }
      let headerInd = false;
      let missingParamErr = "";
      let duplicateLines = "";
      for (let dt of parameterDetailsData) {
        for (let pm of ConstParameterDetailsHeader) {
          if (parameterDetailsHeader.indexOf(pm.FieldName) !== -1) {
            if (pm.entryInd && !dt.hasOwnProperty(pm.FieldName)) {
              missingParamErr += dt.SeqNo + ", ";
            }
          }
        }

        if (parameterDetailsHeader.indexOf("Parameter Category") !== -1 && parameterDetailsData.filter((item) => item.hasOwnProperty("Parameter Category") && item["Parameter Category"].toLowerCase() === dt["Parameter Category"].toLowerCase() && item["Parameter"].toLowerCase() === dt["Parameter"].toLowerCase() && item.SeqNo !== dt.SeqNo).length > 0) {
          duplicateLines += dt.SeqNo + ", ";
        }
        else if (parameterDetailsHeader.indexOf("Parameter Category") === -1 && parameterDetailsData.filter((item) => item["Parameter"].toLowerCase() === dt["Parameter"].toLowerCase() && item.SeqNo !== dt.SeqNo).length > 0) {
          duplicateLines += dt.SeqNo + ", ";
        }
      }
      if (missingParamErr !== "") {
        MySwal.fire({
          text:
            "Kindly maintain all parameters for Sr No. " +
            missingParamErr.substring(0, missingParamErr.length - 2),
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }
      if (duplicateLines !== "") {
        MySwal.fire({
          text:
            "Duplicate parameters for Sr No. " +
            duplicateLines.substring(0, duplicateLines.length - 2),
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }
      if (!isHeaderSaved) {
        let result;
        let todayDate = getCurrentDateTime("-");
        let headerObject = {
          PK: props.primeKey,
          SK: props.secondKey,
          ParameterDetailsHeader: parameterDetailsHeader,
          ParameterDetailsHeaderMandatory: parameterDetailsHeaderMandatory,
          ModifiedOn: todayDate,
          ModifiedBy: userDetails.userId,
          ModifiedByName: userDetails.userName,
        };
        console.log("header object is : ", headerObject);
        result = await UpdateParameterDetailsHeader(headerObject);
        if (result?.status) {
          setIsHeaderSaved(true);
          headerInd = true;
          successMsg += result.message;
        } else {
          errMsg += "Unable to update Parameter Header Details";
        }
      } else {
        headerInd = true;
      }

      if (headerInd) {
        let successInd = false;
        let errMsgLine = "";
        let updatedData = [];
        let gridData = getRowData();
        if (gridData?.length > 0) {
          let counter = 1;
          for (let dt of gridData) {
            let delObj = { PK: dt.PK, SK: dt.SK, SeqNo: dt.SeqNo };
            let updateInd = false;
            let deleteInd = false;
            let tmpSavedData = parameterDetailsData.filter(
              (item) => item.SeqNo === dt.SeqNo
            );
            let savedData = Object.assign({}, tmpSavedData[0]);
            for (let pm of ConstParameterDetailsHeader) {
              if (parameterDetailsHeader.indexOf(pm.FieldName) === -1) {
                if (pm.FieldName !== "Attachment" && pm.entryInd && savedData.hasOwnProperty(pm.FieldName)) {
                  delete savedData[pm.FieldName];
                  updateInd = true;
                }
              }
            }
            if (counter !== savedData?.SeqNo) {
              savedData.SeqNo = counter;
              savedData.SK = "SeqNo#" + counter;
              updateInd = true;
              deleteInd = true;
            }
            updatedData.push(savedData);
            if (updateInd) {
              let result = await AddChecklistParameterDetails(savedData);
              if (result?.status) {
                if (deleteInd && delObj.SeqNo > gridData?.length) {
                  let delResult = await DeleteChecklistParameterDetails(delObj);
                }
                successInd = true;
                console.log("successInd in", successInd)
              } else {
                errMsgLine += counter + ", ";
              };
            }
            counter++;
          }
        }
        setParameterDetailsData(updatedData);
        props.getDistinctCategories(updatedData);
        if (errMsgLine !== "") {
          errMsg += "Parameter details updation failed for Sr No " + errMsgLine.substring(0, errMsgLine.length - 2) + ". ";
        }
      }
      console.log("successMsg", successMsg, "errMsg", errMsg)
      if (errMsg !== "") {
        res = false;
        MySwal.fire({
          text: successMsg + " " + errMsg,
          imageUrl: errorImage,
          customClass: "swal-success",
        });
      }
      else {
        res = true;
        MySwal.fire({
          text: successMsg + " Parameter details updated successfully.",
          imageUrl: successImage,
          customClass: "swal-success",
        });
      }
    } catch (err) {
      res = false;
      console.log(err); MySwal.fire({
        text: "Unable to process request",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
    }
    return res;
  };

  useImperativeHandle(ref, () => ({
    funcOnNext: async () => {
      let res = false
      res = await handleSubmit();
      return res
    }
  }));

  const handleEdit = async (row) => {
    setParameterAction("Edit");
    setRowData(row);
    setShowParameterForm(true);
  };

  const handleDelete = async (data) => {
    MySwal.fire({
      text:
        "Are you sure, do you want to delete parameter " + data.Parameter + "?",
      showDenyButton: true,
      cancelButtonColor: "#fff",
      confirmButtonColor: "#16911B",
      confirmButtonText: "Delete",
      reverseButtons: true,
      customClass: "swal-confirmation",
      title: "Delete Parameter",
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        let obj = { PK: data.PK, SK: data.SK };
        let result = DeleteChecklistParameterDetails(obj);
        result
          .then((resp) => {
            if (resp.status) {
              setParameterDetailsData((current) =>
                current.filter((obj) => {
                  return obj.SeqNo !== data.SeqNo;
                })
              );
              MySwal.fire({
                text: resp.message,
                imageUrl: successImage,
                customClass: "swal-success",
              });
            }
          })
          .catch((error) => {
            MySwal.fire({
              text: "Unable to Process Request",
              imageUrl: errorImage,
              customClass: "swal-success",
            });
          });
      }
    });
  };
  const addNewRow = () => {
    if (props.primeKey === "" || props.secondKey === "") {
      MySwal.fire({
        text: "Checklist code is blank. Kindly maintain Checklist Details.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
      return;
    }
    if (!isHeaderSaved) {
      let result;
      let todayDate = getCurrentDateTime("-");
      let headerObject = {
        PK: props.primeKey,
        SK: props.secondKey,
        ParameterDetailsHeader: parameterDetailsHeader,
        ParameterDetailsHeaderMandatory: parameterDetailsHeaderMandatory,
        ModifiedOn: todayDate,
        ModifiedBy: userDetails.userId,
        ModifiedByName: userDetails.userName,
      };
      result = UpdateParameterDetailsHeader(headerObject);
      result
        .then((resp) => {
          if (resp.status) {
            setIsHeaderSaved(true);
            if (parameterDetailsData.length < limitValue) {
              let latestSeqNo = 1;
              if (parameterDetailsData.length > 0) {
                let tmpDt = Object.assign([], parameterDetailsData);
                tmpDt.sort(sortByPropertyInDescending("SeqNo"));
                latestSeqNo = Number(tmpDt[0].SeqNo) + 1;
              }
              setRowNo(latestSeqNo);
              setParameterAction("Add");
              setShowParameterForm(true);
            } else {
              MySwal.fire({
                text: "You can add only " + limitValue + " parameters.",
                imageUrl: errorImage,
                customClass: "swal-success",
              });
            }
          }
        })
        .catch((error) => {
          MySwal.fire({
            text: "Unable to Process Request",
            imageUrl: errorImage,
            customClass: "swal-success",
          });
        });
    }
    if (isHeaderSaved) {
      if (parameterDetailsData.length < limitValue) {
        let latestSeqNo = 1;
        if (parameterDetailsData.length > 0) {
          let tmpDt = Object.assign([], parameterDetailsData);
          tmpDt.sort(sortByPropertyInDescending("SeqNo"));
          latestSeqNo = Number(tmpDt[0].SeqNo) + 1;
        }
        setRowNo(latestSeqNo);
        setParameterAction("Add");
        setShowParameterForm(true);
      } else {
        MySwal.fire({
          text: "You can add only " + limitValue + " parameters.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
      }
    }
  };

  const setCheckBoxValue = async (data) => {
    let newArr = [...parameterDetailsHeader];
    let newArr1 = [...parameterDetailsHeaderMandatory];
    if (data.IsMandatory) {
      newArr1.push(data.FieldName);
    } else {
      let index = newArr1.indexOf(data.FieldName);
      index >= 0 && newArr1.splice(index, 1);
    }
    console.log("newArr1", newArr1)
    setParameterDetailsHeaderMandatory(newArr1);

    if (data.IsSelected) {
      if (newArr.indexOf(data.FieldName) === -1) {
        newArr.push(data.FieldName);
      }
    } else {
      let index = newArr.indexOf(data.FieldName);
      index >= 0 && newArr.splice(index, 1);
    }
    console.log("newArr", newArr)
    setParameterDetailsHeader(newArr);
    setIsHeaderSaved(false);

    if (data.FieldName === "Parameter Category") {
      props.isParameterCategorySelected(data.IsSelected);
      //  setParameterCategoryIndication(data.IsSelected);
    }
    let colArr = [...columnDefs];
    let colIndex = colArr.findIndex((obj) => obj.field === data.FieldName);
    colArr[colIndex].hide = !data.IsSelected;
    setColumnDefs(colArr);
  };

  const setAddNewParameter = (data) => {
    let colArr = [...parameterDetailsData];
    if (parameteraction === "Add") {
      colArr.push(data);
    } else {
      let colIndex = colArr.findIndex((obj) => obj.SeqNo === data.SeqNo);
      colArr[colIndex] = data;
    }
    setParameterDetailsData(colArr);
    props.getDistinctCategories(colArr);
  };

  function onGridReady(params) {
    setGridApi(params.api);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.resetRowHeights();
    gridRef.current = params.api; // <= assigned gridApi value on Grid ready
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Sr No.",
      field: "SeqNo",
      tooltipField: "SeqNo",
      minWidth: 100,
      rowDrag: true,
      sortable: false,
      filter: false,
      resizable: true
    },
    {
      headerName: "Parameter Category",
      field: "Parameter Category",
      minWidth: 200,
      tooltipField: "Parameter Category",
      hide:
        parameterDetailsHeader.indexOf("Parameter Category") !== -1
          ? false
          : true,
      sortable: true,
      filter: true,
      resizable: true
    },
    {
      headerName: "Parameter",
      field: "Parameter",
      minWidth: 200,
      tooltipField: "Parameter",
      hide: false,
      sortable: true,
      filter: true,
      resizable: true
    },
    {
      headerName: "UOM",
      field: "UOM",
      tooltipField: "UOM",
      minWidth: 100,
      hide: parameterDetailsHeader.indexOf("UOM") !== -1 ? false : true,
      sortable: false,
      filter: false,
      resizable: true
    },
    {
      headerName: "Status",
      field: "Status",
      tooltipField: "Status",
      minWidth: 100,
      hide: false,
      sortable: false,
      filter: false,
      resizable: true
    },
    {
      headerName: "Fire Integration Service",
      field: "Fire Integration Service",
      tooltipField: "Fire Integration Service",
      minWidth: 250,
      hide: false,
      sortable: true,
      filter: true,
      resizable: true
    },
    {
      headerName: "Result",
      field: "Result",
      minWidth: 100,
      tooltipField: "Result",
      hide: parameterDetailsHeader.indexOf("Result") !== -1 ? false : true,
      sortable: false,
      filter: false,
      resizable: true
    },
    {
      headerName: "Remarks",
      field: "Remarks",
      minWidth: 100,
      tooltipField: "Remarks",
      hide: false,
      sortable: false,
      filter: false,
      resizable: true
    },
    {
      headerName: "Readings",
      field: "Readings",
      minWidth: 100,
      tooltipField: "Readings",
      hide: parameterDetailsHeader.indexOf("Readings") !== -1 ? false : true,
      sortable: false,
      filter: false,
      resizable: true
    },
    {
      headerName: "Threshold Min",
      field: "Threshold Min",
      minWidth: 170,
      tooltipField: "Threshold Min",
      hide:
        parameterDetailsHeader.indexOf("Threshold Min") !== -1 ? false : true,
      sortable: true,
      filter: true,
      resizable: true
    },
    {
      headerName: "Threshold Max",
      field: "Threshold Max",
      minWidth: 170,
      tooltipField: "Threshold Max",
      hide:
        parameterDetailsHeader.indexOf("Threshold Max") !== -1 ? false : true,
      sortable: true,
      filter: true,
      resizable: true
    },
    {
      headerName: "Attachment",
      field: "Attachment",
      minWidth: 100,
      tooltipField: "Attachment",
      hide: parameterDetailsHeader.indexOf("Attachment") !== -1 ? false : true,
      sortable: false,
      filter: false,
      resizable: true
    },
    {
      field: "",
      width: 150,
      cellRenderer: function (params) {
        return (
          <ActionRenderer
            selectedData={params}
            actionName={actionButtons}
            handleEditEvent={(params) => handleEdit(params.data)}
            handleDeleteEvent={(params) => handleDelete(params.data)}
          />
        );
      },
      sortable: false,
      filter: false,
      pinned: "right",
    },
  ]);

  const headerName = (field) => {
    let name;
    switch (field) {
      case "Parameter Category":
        name = "Parameter Category";
        break;
      case "Parameter":
        name = "Parameter";
        break;
      case "Threshold Max":
        name = "Threshold Max";
        break;
      case "Threshold Min":
        name = "Threshold Min";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fieldName = (header) => {
    let name;
    switch (header) {
      case "Parameter Category":
        name = "Parameter Category";
        break;
      case "Parameter":
        name = "Parameter";
        break;
      case "Threshold Max":
        name = "Threshold Max";
        break;
      case "Threshold Min":
        name = "Threshold Min";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const filteredResult = useMemo(() => {
    let firstPageIndex = 0;
    if (currentPage > 1) {
      firstPageIndex = (currentPage - 1) * pageSize;
    }
    if (firstPageIndex >= parameterDetailsData.length) {
      firstPageIndex = 0;
      const totalPageCount = Math.ceil(parameterDetailsData.length / pageSize);
      if (totalPageCount > 0) {
        setCurrentPage(totalPageCount);
      } else {
        setCurrentPage(1);
      }
    }
    let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
    return parameterDetailsData
      ? parameterDetailsData.slice(firstPageIndex, lastPageIndex)
      : [];
  }, [currentPage, parameterDetailsData, pageSize]);

  return (
    <Form
      id="parameterdetails"
      name="parameterdetails"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <div>
        <div className="parameterNote">
          <Row>
            <div>
              <span className="modalTitle">Parameter Settings</span>
              &nbsp;&nbsp;
              <span className="subtext">
                {" "}
                Choose whether to show or hide fields for parameters
              </span>
            </div>
          </Row>
          <Row>
            {ConstParameterDetailsHeader.map((selection, index) => {
              return selection.FieldType === "Default" &&
                <ParameterCheckboxComponent
                  action={props.action}
                  paramdata={selection}
                  PatameterType="ParameterDetails"
                  setCheckBoxValue={setCheckBoxValue}
                  selectedParameters={parameterDetailsHeader}
                />
            })}
          </Row>
          <Row>
            {ConstParameterDetailsHeader.map((selection, index) => {
              return selection.FieldType !== "Default" &&
                <ParameterCheckboxComponent
                  action={props.action}
                  paramdata={selection}
                  PatameterType="ParameterDetails"
                  setCheckBoxValue={setCheckBoxValue}
                  selectedParameters={parameterDetailsHeader}
                  selectedParametersMandatory={parameterDetailsHeaderMandatory}
                />
            })}
          </Row>
        </div>
        <br />
        {(action !== "View" && props.checklistVersion.startsWith("TMP")) &&
          <Row>
            <div className="addNewRow">
              <BsPlusLg size={20} className="plusIcon" />
              &nbsp;
              <Link onClick={() => addNewRow()} className="add-new-link">
                Add more
              </Link>
            </div>
          </Row>
        }
        <Container fluid className="grid-comp-containerCss">
          <div className="ag-theme-alpine agGridContainer d-none d-md-block d-lg-block">
            <AgGridReact
              ref={gridRef}
              rowData={parameterDetailsData}
              columnDefs={columnDefs}
              fieldNames={fieldName}
              headerNames={headerName}
              enableBrowserTooltips={true}
              onGridReady={onGridReady}
              rowDragManaged={true}
              onColumnResized={((params) => {
                //fill remaining blank space in row by increasing width of Parameter column
                if (params.source === 'uiColumnResized' && params.finished) {
                  let colArr = [...columnDefs];
                  colArr[2].flex = 1;
                  setColumnDefs(colArr);
                }
              })}
            />
          </div>
          <div className="market-wrapper d-md-none d-lg-none cardContainerResponsive">
            {filteredResult.map((selection, index) => (
              <Card className="cardContainer" key={index}>
                <Card.Body className="cardContainerResponsive">
                  <div
                    className={
                      action === "View" ? "hideDisplay" : "actionIcons"
                    }
                  >
                    <ActionRenderer
                      selectedData={selection}
                      actionName={actionButtons}
                      handleEditEvent={(selection) => handleEdit(selection)}
                      handleDeleteEvent={(selection) =>
                        handleDelete(selection)
                      }
                    />
                  </div>
                  {columnDefs.map((header, index) => {
                    return (!header.hide && (
                      <Row key={index} className="responsive-row">
                        <Col className="colHeader">{header.headerName}</Col>
                        <Col className="colData">{selection[header.field]}</Col>
                      </Row>
                    ))
                  })}
                </Card.Body>
              </Card>
            ))}
            {filteredResult?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalCount={
                  parameterDetailsData ? parameterDetailsData.length : 0
                }
                data={parameterDetailsData}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </div>
        </Container>
        <br />
        {showParameterForm && (
          <AddEditParameterDetail
            show={showParameterForm}
            masterData={parameterDetailsData}
            onHide={() => setShowParameterForm(false)}
            setAddNewParameter={setAddNewParameter}
            rowdata={rowData}
            action={parameteraction}
            selectedParameters={parameterDetailsHeader}
            primaryKey={
              "ChecklistCode#" +
              props.checklistCode +
              "#ChecklistVersion#" +
              props.checklistVersion
            }
            rowNo={rowNo}
          />
        )}
      </div>
    </Form>
  );
}
);

export default ParameterDetails;
