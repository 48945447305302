import React, { useState, useRef, useContext, useEffect } from "react";
import { Tabs, Tab, Button, Container, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import { BiSelectMultiple } from "react-icons/bi";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import { RenderIf } from "../../functions/gbcs-functions";
import TabButtons from "../utils/TabButtons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorImage from "../../icons/error-tick-logo.svg";
import DashboardDetails from "./DashboardDetails";
import DashboardSORView from "./DashboardSORView";
import { fetchProjectDashboard } from "../../services/dashboard-service";
import DashboardWeightage from "./DashboardWeightage";
import ProjectProgressDetails from "./ProjectProgressDetails";
import downloadicon from "../../icons/downloadicon.svg";
import { BsSearch } from "react-icons/bs";
import { searchActions } from "../../store/grid-slice";
import { useSelector } from "react-redux";
import AddEditProjectMaster from "../masters/ProjectMaster/AddEditProjectMaster";
import { getprojectdatabyprojectcode } from "../../services/project-service";

const Dashboard = (props) => {

    let sessionName = props.sessionName ? props.sessionName : "Dashboard";
    const dispatch = useDispatch();
    dispatch(menuActions.setMenu(sessionName));
    const MySwal = withReactContent(Swal);
    const [loading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [showPopUpForm, setshowPopUpForm] = useState(false);
    const [SelectedProject, setSelectedProject] = useState();
    const [calculatedFinalData, setCalculatedFinalData] = useState([]);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const searchReText = useSelector((state) => state.grid.searchQuery);
    const [isSearch, setIsSearch] = useState(false);
    const [isExport, setIsExport] = useState(true);
    const [showProjMasterForm, setShowProjMasterForm] = useState(false);
    const [projectData, setProjectData] = useState({});
    const projMasterAction = "View";


    const tabsData = {
        0: { tabName: "Dashboard", isSearch: false, isExport: true },
        1: { tabName: "Project Progress Detail", isSearch: false, isExport: true },
        2: { tabName: "SOR View", isSearch: false, isExport: true },
        3: { tabName: "Weightage", isSearch: false, isExport: true },
    };

    let tab0Ref = useRef();
    let tab1Ref = useRef();
    let tab2Ref = useRef();
    let tab3Ref = useRef();

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
    });

    const showPopUp = (name, type, key, value, additionalParams) => {
        setPopUp({ name, type, key, value, additionalParams });
        setshowPopUpForm(true);
    };

    const setPopUpData = async (data) => {
        setSelectedProject(data.result);
        setIsSubmitClicked(false);
    }

    const handleSubmit = async () => {
        setLoading(true);
        let finaldata = await fetchProjectDashboard(SelectedProject);
        setCalculatedFinalData(finaldata);
        setIsSubmitClicked(true);
        setLoading(false);
    }



    const handleSelect = async (key, direction) => {
        if (!isSubmitClicked) {
            MySwal.fire({
                text: "Please Submit Project details first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }

        if (direction == "prev") {
            if (currentTab !== 0) setCurrentTab(currentTab - 1)
        }
        else if (direction == "next") {
            if (currentTab !== 3) setCurrentTab(currentTab + 1)
        }
        else if (direction == "save") {
            setCurrentTab(currentTab)
        }
        else {
            setCurrentTab(Number(key))
        }
        let saveFlag = true;
        if (direction !== "save") {
            saveFlag = false;
        }

        if (saveFlag) {
            switch (currentTab) {
                case 0:
                    await tab0Ref.current.funcOnNext();
                    break;
                case 1:
                    await tab1Ref.current.funcOnNext();
                    break;
                case 2:
                    await tab2Ref.current.funcOnNext();
                    break;
                case 3:
                    await tab3Ref.current.funcOnNext();
                    break;
                default:
                    break;
            }
        }
        setIsSearch(tabsData[Number(key)].isSearch);
        setIsExport(tabsData[Number(key)].isExport);
    }

    const onSearchTextChange = async (e) => {
        dispatch(searchActions.setSearch(e.target.value));
        switch (currentTab) {
            case 1:
                await tab1Ref.current.funcOnGlobalSearch(e.target.value);
                break;
            default:
                break;
        }
    };

    const handleGlobalExport = async () => {
        if (isSubmitClicked) {
            switch (currentTab) {
                case 0:
                    await tab0Ref.current.funcOnExport();
                    break;
                case 1:
                    await tab1Ref.current.funcOnExport();
                    break;
                case 2:
                    await tab2Ref.current.funcOnExport();
                    break;
                case 3:
                    await tab3Ref.current.funcOnExport();
                    break;
                default:
                    break;
            }
        }
    }

    const hideUnhideSearch = (flag) => {
        tabsData[currentTab].isSearch = flag;
        setIsSearch(tabsData[currentTab].isSearch);
    }

    const handleProjectInfoClick = async () => {
        let result = await getprojectdatabyprojectcode({ "ProjectCode": SelectedProject.ProjectCode })
        if (result?.length > 0) {
            setProjectData(result[0]);
            setShowProjMasterForm(true);
        }
        //sweet alert 
        // else {

        // }
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="main-blank-container">
                <BreadcrumbComp />
                <div className="action-comp-container">
                    <Row className="docRevTopMargin">
                        <Col md={3} xs={12}>
                            <Form.Group class="g-input docRevInputMargin">
                                <Form.Control className="inputHeight"
                                    id="ProjectName"
                                    type="text"
                                    name="ProjectName"
                                    placeholder=" "
                                    required
                                    value={SelectedProject?.ProjectName}
                                    readOnly
                                />
                                <Form.Label className="frm-label input-label" for="ProjectName">Project</Form.Label>
                                <BiSelectMultiple className="modalPopUpIconActionComp"
                                    size={18}
                                    onClick={() => showPopUp("Project Master",
                                        "Single",
                                        "ProjectName",
                                        SelectedProject?.ProjectName,
                                        {})}
                                    color={"gray"}
                                    style={{ marginLeft: "-2rem" }}
                                    backdrop="static"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3} xs={12} className="project-doc-buttons">
                            <Button
                                className="btn-add-color semiBoldToggleButton filterSubmit right-margin-button"
                                variant="success"
                                onClick={handleSubmit}
                                disabled={SelectedProject?.ProjectCode ? false : true}
                            >
                                Submit
                            </Button>
                        </Col>

                        <Col lg={3} md={4}>&nbsp;</Col>
                        <Col lg={3} md={4} xs={12}>
                            <div className="parent-search-download dashboard-search-export">
                                {
                                    isSearch ?
                                        <div className="records-search">
                                            <BsSearch size={16} color="#767980" />
                                            <input
                                                type="text"
                                                placeholder="Search here..."
                                                className="records-search-input"
                                                onChange={onSearchTextChange}
                                                value={searchReText}
                                            ></input>
                                        </div>
                                        : <></>
                                }
                                {
                                    isExport ?
                                        <img src={downloadicon}
                                            alt="downloadimg"
                                            className="icon-border"
                                            onClick={handleGlobalExport}
                                        />
                                        : <></>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="add-container">
                    <div>
                        <Tabs
                            activeKey={currentTab}
                            onSelect={(e) => { handleSelect(e, "") }}
                            className="tabCssheader"
                        >
                            <Tab
                                className="headerTabKey"
                                eventKey={0}
                                title={
                                    <>
                                        <span className="numberbg">1</span>
                                        <span>Dashboard</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked ?
                                        <DashboardDetails
                                            calculatedData={calculatedFinalData}
                                            ref={tab0Ref}
                                            SelectedProject={SelectedProject}
                                            tabNo={currentTab}
                                            handleInfoClick={handleProjectInfoClick}
                                        />
                                        :
                                        <div className="noSelection">Please select Project to view the data</div>
                                }
                            </Tab>
                            <Tab
                                className="headerTabKey"
                                eventKey={1}
                                title={
                                    <>
                                        <span className="numberbg">2</span>
                                        <span>Project Progress Detail</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked && (
                                        <ProjectProgressDetails
                                            ref={tab1Ref}
                                            SelectedProject={SelectedProject}
                                            tabKey={() => handleSelect(currentTab, "next")}
                                            tabNo={currentTab}
                                            calculatedData={calculatedFinalData}
                                            handleInfoClick={handleProjectInfoClick}
                                        />
                                    )
                                }
                            </Tab>
                            <Tab
                                className="headerTabKey"
                                eventKey={2}
                                title={
                                    <>
                                        <span className="numberbg">3</span>
                                        <span>SOR View</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked && (
                                        <DashboardSORView
                                            ref={tab2Ref}
                                            SelectedProject={SelectedProject}
                                            tabKey={() => handleSelect(currentTab, "next")}
                                            tabNo={currentTab}
                                            hideUnhideSearch={(e) => hideUnhideSearch(e)}
                                            handleInfoClick={handleProjectInfoClick}
                                        />
                                    )
                                }
                            </Tab>
                            {sessionName === "Dashboard (Godrej)" &&
                                <Tab
                                    className="headerTabKey"
                                    eventKey={3}
                                    title={
                                        <>
                                            <span className="numberbg">4</span>
                                            <span>Weightage</span>
                                        </>
                                    }
                                >
                                    {
                                        isSubmitClicked && (
                                            <DashboardWeightage
                                                ref={tab3Ref}
                                                SelectedProject={SelectedProject}
                                                tabKey={() => handleSelect(currentTab, "next")}
                                                tabNo={currentTab}
                                                calculatedData={calculatedFinalData}
                                                handleInfoClick={handleProjectInfoClick}
                                            />
                                        )
                                    }
                                </Tab>
                            }
                        </Tabs>
                        {/* <br />
                        {isSubmitClicked &&
                            <Row className="tabButtonPosition">
                                <TabButtons
                                    currentTab={currentTab}
                                    lastTab={4}
                                    handleSelect={handleSelect}
                                    showSave={false}
                                />
                            </Row>
                        } */}
                    </div>
                </div>
                <RenderIf isTrue={showPopUpForm}>
                    <SingleValuePopUp
                        show={showPopUpForm}
                        onHide={() => setshowPopUpForm(false)}
                        setSelected={setPopUpData}
                        mastername={popUp.name}
                        paramkey={popUp.key}
                        paramvalue={popUp.value}
                        additionalParams={popUp.additionalParams}
                        backdrop="static"
                    />
                </RenderIf>
                {showProjMasterForm && (
                    <AddEditProjectMaster
                        show={showProjMasterForm}
                        action={projMasterAction}
                        rowdata={projectData}
                        onHide={() => setShowProjMasterForm(false)}
                    />
                )}
            </Container >

        </>
    );
}

export default Dashboard; 