import React, { useEffect, useState, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../layouts/ActionComp";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import StatusRenderer from "./StatusRenderer";
import ActionRenderer from "./ActionRenderer";
import GridMaster from "./GridMaster";
import AddEditEquipmentActivityMaster from "./AddEditEquipmentActivityMaster";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import { exportToExcel } from "../utils/ExcelCreation";
import { getCurrentDateTime, formatDateInDDMMYYYY } from "../../functions/gbcs-functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { ServiceMasterAllData } from "../../services/common-master-service"
import { searchActions } from "../../store/grid-slice";
import {
  getAllServiceEquipmentData,
  updateserviceequipment,
  deleteEquipmentDetails,
  getEquipmentParameterData
} from "../../services/equipmentactivity-master-service";
import { getProjectsByEquipment } from "../../services/project-service";
import { sortByPropertyInAscending } from "../../utils/Utility";

const EquipmentActivityMaster = () => {
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = { userId: context.userId, userName: context.userName }
  const [rowData, setRowData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);
  const [ServiceEquipmentMasterData, setServiceEquipmentMasterData] = useState([]);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [resetFilter, setResetFilter] = useState(true);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  dispatch(menuActions.setMenu("Equipment & Activity Master"));

  const columnDefs = [
    {
      headerName: "Status",
      field: "DeleteIndication",
      flex: false,
      width: 150,
      resizable: false,
      tooltipField: "DeleteIndication",
      cellRenderer: StatusRenderer
    },
    {
      headerName: "Service Name",
      field: "ServiceName",
      minWidth: 150,
      tooltipField: "ServiceName",
    },
    {
      headerName: "Service Type",
      field: "ServiceType",
      minWidth: 150,
      tooltipField: "ServiceType",
    },
    {
      headerName: "Equipment / Activity Code",
      field: "EquipmentCode",
      tooltipField: "EquipmentCode",
      minWidth: 150,
    },
    {
      headerName: "Equipment / Activity Name",
      field: "EquipmentName",
      minWidth: 150,
      tooltipField: "EquipmentName",
    },
    {
      headerName: "FAT Applicable Checklist Category",
      field: "FATEquipApplicableChecklist",
      minWidth: 150,
      tooltipField: "FATEquipApplicableChecklist",
    },
    {
      headerName: "SAT Applicable Checklist Category",
      field: "SATEquipApplicableChecklist",
      minWidth: 150,
      tooltipField: "SATEquipApplicableChecklist",
    },
    {
      headerName: "Method Statement",
      field: "MethodStatement",
      minWidth: 150,
      tooltipField: "MethodStatement",
      valueGetter: params => {
        if (params.data.MethodStatement) {
          return "Yes";
        }
        else {
          return "No";
        }
      },
      valueFormatter: (params) => {
        if (params.data.MethodStatement) {
          return "Yes";
        }
        else {
          return "No";
        }
      },
    },
    {
      headerName: "Detailed Report",
      field: "DetailedReport",
      minWidth: 150,
      tooltipField: "DetailedReport",
      valueGetter: params => {
        if (params.data.DetailedReport) {
          return "Yes";
        }
        else {
          return "No";
        }
      },
      valueFormatter: (params) => {
        if (params.data.DetailedReport) {
          return "Yes";
        }
        else {
          return "No";
        }
      },
    },
    {
      headerName: "Fire Integration Service",
      field: "FireIntegrationService",
      minWidth: 150,
      tooltipField: "FireIntegrationService",
      valueGetter: params => {
        if (params.data.FireIntegrationService) {
          return "Yes";
        }
        else {
          return "No";
        }
      },
      valueFormatter: (params) => {
        if (params.data.FireIntegrationService) {
          return "Yes";
        }
        else {
          return "No";
        }
      },
    },
    {
      headerName: "Integration System Test (IST)",
      field: "IntegrationSystemTest",
      minWidth: 150,
      tooltipField: "IntegrationSystemTest",
      valueGetter: params => {
        if (params.data.IntegrationSystemTest) {
          return "Yes";
        }
        else {
          return "No";
        }
      },
      valueFormatter: (params) => {
        if (params.data.IntegrationSystemTest) {
          return "Yes";
        }
        else {
          return "No";
        }
      },
    },
    {
      headerName: "Sample for Detailed Report",
      field: "AttachedDocument",
      minWidth: 150,
      tooltipField: "Sample for Detailed Report"
    },
    {
      field: "",
      width: 120,
      cellRenderer: function (params) {
        return <ActionRenderer selectedData={params.data} handleViewEvent={handleView} handleEditEvent={handleEdit} handleDeleteEvent={handleDelete} />
      },
      sortable: false,
      filter: false,
      pinned: 'right'
    },
  ];


  const headerName = (field) => {
    let name;
    switch (field) {
      case "DeleteIndication":
        name = "Status";
        break;
      case "ServiceName":
        name = "Service Name";
        break;
      case "ServiceType":
        name = "Service Type";
        break;
      case "EquipmentCode":
        name = "Equipment / Activity Code";
        break;
      case "EquipmentName":
        name = "Equipment / Activity Name";
        break;
      case "FATEquipApplicableChecklist":
        name = "FAT Applicable Checklist";
        break;
      case "SATEquipApplicableChecklist":
        name = "SAT Applicable Checklist";
        break;
      case "MethodStatement":
        name = "Method Statement";
        break;
      case "DetailedReport":
        name = "Detailed Report";
        break;
      case "FireIntegrationService":
        name = "Fire Integration Service";
        break;
      case "IntegrationSystemTest":
        name = "Integration System Test (IST)";
        break;
      case "AttachedDocument":
        name = "Sample for Detailed Report";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fieldName = (header) => {
    let name;
    switch (header) {
      case "Status":
        name = "DeleteIndication";
        break;
      case "Service Name":
        name = "ServiceName";
        break;
      case "Service Type":
        name = "ServiceType";
        break;
      case "Equipment / Activity Code":
        name = "EquipmentCode";
        break;
      case "Equipment / Activity Name":
        name = "EquipmentName";
        break;
      case "FAT Applicable Checklist Category":
        name = "FATEquipApplicableChecklist";
        break;
      case "SAT Applicable Checklist Category":
        name = "SATEquipApplicableChecklist";
        break;
      case "Method Statement":
        name = "MethodStatement";
        break;
      case "Detailed Report":
        name = "DetailedReport";
        break;
      case "Fire Integration Service":
        name = "FireIntegrationService";
        break;
      case "Integration System Test (IST)":
        name = "IntegrationSystemTest";
        break;
      case "Sample for Detailed Report":
        name = "AttachedDocument";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  useEffect(() => {
    fetchServiceEquipmentMaster();
  }, [reload]);

  const fetchServiceEquipmentMaster = async () => {
    dispatch(searchActions.setSearch(""));
    setLoading(true);
    const result = await getAllServiceEquipmentData();
    if (result?.length > 0) {
      const serviceMaster = await ServiceMasterAllData();
      for (let dt of result) {
        let serviceData = serviceMaster.filter((item) => item.ServiceCode === dt.ServiceCode);
        if (serviceData.length > 0) {
          dt.ServiceName = serviceData[0].ServiceName;
        } else {
          dt.ServiceName = "";
        }
      }
    }
    result?.sort(sortByPropertyInAscending("DeleteIndication"));
    setServiceEquipmentMasterData(result);
    setFilteredResult(result);
    setExcelData(result);
    setResetFilter(!resetFilter);
    setLoading(false);
  };

  const handleView = async (row) => {
    // const result = await getAllServiceEquipmentData();
    // result.forEach(element => {
    //   if (element.SK === row.SK && element.PK === row.PK)
    //     row = element;
    // });
    setAction("View");
    setRowData(row);
    setShowForm(true);
  }

  const handleEdit = async (row) => {
    // const result = await getAllServiceEquipmentData();
    // result.forEach(element => {
    //   if (element.SK === row.SK && element.PK === row.PK)
    //     row = element;
    // });
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  }

  const handleDelete = async (row) => {
    let result;
    const equipCode = row.EquipmentCode;
    const equipName = row.EquipmentName;
    MySwal.fire({
      text: "Are you sure, do you want to delete equipment / activity " + equipName + "?",
      showDenyButton: true,
      cancelButtonColor: "#fff",
      confirmButtonColor: "#16911B",
      confirmButtonText: "Delete",
      reverseButtons: true,
      customClass: "swal-confirmation",
      title: "Delete Equipment / Activity"
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        setLoading(true);
        let equipData = await getProjectsByEquipment(equipCode);
        console.log("equipData", equipData)

        if (equipData == undefined || equipData.length == 0) {

          const [preReqData, instrumentData, testingData, docData] = await Promise.all([
            getEquipmentParameterData({ "code": equipCode, "tabName": "Prerequisite" }, "master"),
            getEquipmentParameterData({ "code": equipCode, "tabName": "Instruments" }, "master"),
            getEquipmentParameterData({ "code": equipCode, "tabName": "Testing" }, "master"),
            getEquipmentParameterData({ "code": equipCode, "tabName": "DocumentReview" }, "master")
          ]);

          let addData = preReqData;
          addData = [...addData, ...instrumentData];
          addData = [...addData, ...testingData];
          addData = [...addData, ...docData];

          let counter = 0;
          let array = [];
          for (let item of addData) {
            counter++;
            let obj = { PK: item.PK, SK: item.SK };
            const delRes = deleteEquipmentDetails(obj);
            array.push(delRes);

            if (counter === 5) {
              await Promise.all(array);
              counter = 0;
              array = [];
            }
          }
          if (array.length > 0) {
            await Promise.all(array);
          }

          let delObj = { PK: row.PK, SK: row.SK };
          await deleteEquipmentDetails(delObj).then((resp) => {
            setLoading(false);
            MySwal.fire({
              text: "Equipment / Activity Deleted Successfully.",
              imageUrl: successImage,
              customClass: "swal-success",
              didClose: () => {
                setReload(!reload);
              },
            });
          }).catch((error) => {
            setLoading(false);
            MySwal.fire({
              text: "Unable to process request.",
              imageUrl: errorImage,
              customClass: "swal-success",
            });
          })
            .finally(() => {
              setReload(!reload);
            });
        }
        else {
          let activeProjects = equipData.filter((item) => item.DeleteIndication === "Active").length;
          if (activeProjects > 0) {
            setLoading(false);
            MySwal.fire({
              text: "Projects are already linked to " + equipName + " Equipment / Activity. Thus, selected Equipment / Activity cannot be Deleted.",
              imageUrl: successImage,
              customClass: "swal-success",
            });
          }
          else {
            let equipObj = row;
            const todayDate = getCurrentDateTime("-");
            equipObj.ModifiedBy = userDetails.userId;
            equipObj.ModifiedByName = userDetails.userName;
            equipObj.ModifiedOn = todayDate;
            equipObj.DeleteIndication = "Inactive";
            result = updateserviceequipment(equipObj);

            result.then((resp) => {
              setLoading(false);
              MySwal.fire({
                text: "Projects are already linked to " + equipName + " Equipment / Activity. Thus, selected Equipment / Activity cannot be Permanently Deleted and is updated as Inactive.",
                imageUrl: successImage,
                customClass: "swal-success",
                didClose: () => {
                  setReload(!reload);
                },
              });
            })
              .catch((error) => {
                setLoading(false);
                MySwal.fire({
                  text: "Unable to process request.",
                  imageUrl: errorImage,
                  customClass: "swal-success",
                });
              })
              .finally(() => {
                setReload(!reload);
              });
          }
        }
      }
    })
  }

  const handleGlobalExport = async () => {
    setLoading(true);
    let excelDownoadData = [];
    let filename = "EquipmentAndActivityMaster";
    let temp = [];

    // excelData.forEach(element => {
    //   const found = ServiceEquipmentMasterData.find(test => test.EquipmentCode === element.EquipmentCode);
    //   if (found !== undefined) {
    //     temp.push(found);
    //   }
    // })

    // for (let i = 0; i < temp.length; i++) {
    for (let dt of excelData) {
      excelDownoadData.push({
        "DeleteIndication": dt.DeleteIndication,
        "ServiceName": dt.ServiceName,
        "ServiceType": dt.ServiceType,
        "EquipmentCode": dt.EquipmentCode,
        "EquipmentName": dt.EquipmentName,
        "FATEquipApplicableChecklist": dt.FATEquipApplicableChecklist,
        "SATEquipApplicableChecklist": dt.SATEquipApplicableChecklist,
        "MethodStatement": dt.MethodStatement ? "Yes" : "No",
        "DetailedReport": dt.DetailedReport ? "Yes" : "No",
        "FireIntegrationService": dt.FireIntegrationService ? "Yes" : "No",
        "IntegrationSystemTest": dt.IntegrationSystemTest ? "Yes" : "No",
        "AttachedDocument": dt.AttachedDocument,
        "CreatedBy": dt.CreatedByName,
        "CreatedOn": formatDateInDDMMYYYY(dt.CreatedOn),
        "ModifiedOn": formatDateInDDMMYYYY(dt.ModifiedOn),
        "ModifiedBy": dt.ModifiedByName
      });
    }

    let colName = ["Equipment / Activity Status", "Service Name", "Service Type", "Equipment / Activity Code", "Equipment / Activity Name", "FAT Applicable Checklist Category", "SAT Applicable Checklist Category", "Method Statement", "Detailed Report", "Fire Integration Service", "Integration System Test", "Sample for Detailed Report", "Insterted By", "Inserted On", "Modified by", "Modified On"];
    let Fieldname = ["DeleteIndication", "ServiceName", "ServiceType", "EquipmentCode", "EquipmentName", "FATEquipApplicableChecklist", "SATEquipApplicableChecklist", "MethodStatement", "DetailedReport", "FireIntegrationService", "IntegrationSystemTest", "AttachedDocument", "CreatedBy", "CreatedOn", "ModifiedBy", "ModifiedOn"];
    exportToExcel(filename, "Data", excelDownoadData, "A1:P1", colName, Fieldname);
    setLoading(false);
  };

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <BreadcrumbComp />
      <Container fluid className="main-container">
        <ActionComp label={"Add New Equipment / Activity"} exportEvent={() => handleGlobalExport()} handleAdd={handleAdd} />
        <GridMaster
          rowDetails={filteredResult}
          colDetails={columnDefs}
          fieldNames={fieldName}
          headerNames={headerName}
          getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
          getFilterColLength={(getLength) => setFilterColLength(getLength)}
          setExcelData={setExcelData}
          handleView={handleView}
          handleEdit={handleEdit}
          openConfirmBox={handleDelete}
          resetind={resetFilter}
        />
        {showForm && (
          <AddEditEquipmentActivityMaster
            show={showForm}
            action={action}
            rowdata={rowData}
            ServiceEquipmentMasterData={ServiceEquipmentMasterData}
            onHide={() => setShowForm(false)}
            setReload={setReload}
            reload={reload}
          />
        )}
      </Container>
    </>
  );
}

export default EquipmentActivityMaster;
