import React, { useState, useEffect, useContext } from "react";
import "./RoleMaster.css";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../layouts/ActionComp";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import GridMaster from "./GridMaster";
import StatusRenderer from "./StatusRenderer.jsx";
import ActionRenderer from "./ActionRenderer";
import { getLocationdata, DeleteLocation, UpdateLocation } from "../../services/location-master-service";
import { getProjectsByLocation } from "../../services/project-service";
import { exportToExcel } from "../utils/ExcelCreation";
import { getCurrentDateTime, formatDateInDDMMYYYY } from "../../functions/gbcs-functions";
import AddEditLocationMaster from "./AddEditLocationMaster";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { sortByPropertyInAscending } from "../../utils/Utility";
import { searchActions } from "../../store/grid-slice";

const LocationMaster = () => {
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = { userId: context.userId, userName: context.userName, role: context.role }
  const [rowData, setRowData] = useState({});
  const [masterData, setMasterData] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [globalText, setGlobalText] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [resetFilter, setResetFilter] = useState(true);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  dispatch(menuActions.setMenu("Location Master"));

  const columnDefs = [
    {
      headerName: "Status",
      field: "DeleteIndication",
      flex: false,
      width: 150,
      resizable: false,
      tooltipField: "DeleteIndication",
      cellRenderer: StatusRenderer,
      sort: 'asc'
    },
    {
      headerName: "Location Code",
      field: "LocationCode",
      width: 150,
      tooltipField: "LocationCode",
    },
    {
      headerName: "Location Type",
      field: "LocationType",
      tooltipField: "LocationType",
      width: 150,
    },
    {
      headerName: "Location",
      field: "Location",
      width: 150,
      tooltipField: "Location",
    },
    {
      headerName: "Country",
      field: "Country",
      width: 150,
      tooltipField: "Country",
    },
    {
      field: "",
      width: 120,
      cellRenderer: function (params) {
        return <ActionRenderer selectedData={params.data} handleViewEvent={handleView} handleEditEvent={handleEdit} handleDeleteEvent={handleDelete} />
      },
      sortable: false,
      filter: false,
      pinned: 'right'
    },
  ];

  const headerName = (field) => {
    let name;
    switch (field) {
      case "DeleteIndication":
        name = "Status";
        break;
      case "LocationCode":
        name = "Location Code";
        break;
      case "LocationType":
        name = "Location Type";
        break;
      case "Location":
        name = "Location";
        break;
      case "Country":
        name = "Country";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fieldName = (header) => {
    let name;
    switch (header) {
      case "Status":
        name = "DeleteIndication";
        break;
      case "Location Code":
        name = "LocationCode";
        break;
      case "Location Type":
        name = "LocationType";
        break;
      case "Location":
        name = "Location";
        break;
      case "Country":
        name = "Country";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fetchLocationMaster = async () => {
    setLoading(true);
    dispatch(searchActions.setSearch(""));
    const result = await getLocationdata();
    result?.sort(sortByPropertyInAscending("DeleteIndication"))
    setMasterData(result);
    setFilteredResult(result);
    setExcelData(result);
    setResetFilter(!resetFilter);
    setLoading(false);
  };


  useEffect(() => {
    console.log("inside useeffect")
    fetchLocationMaster();
  }, [reload]);

  // useEffect(() => {
  //   masterData && globalText && handleGlobalSearch(globalText);
  // }, [masterData]);


  const handleView = async (row) => {
    // const result = await getLocationdata();
    // result.forEach(element => {
    //   if (element.SK === row.SK && element.PK === row.PK)
    //     row = element;
    //   console.log(row);
    // });
    setAction("View");
    setRowData(row);
    setShowForm(true);
  }

  const handleEdit = async (row) => {
    // const result = await getLocationdata();
    // result.forEach(element => {
    //   if (element.SK === row.SK && element.PK === row.PK)
    //     row = element;
    // });
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  }

  const handleDelete = async (params) => {
    let result;
    const location = params.Location;
    MySwal.fire({
      text: "Are you sure, do you want to delete location " + location + "?",
      showDenyButton: true,
      cancelButtonColor: "#fff",
      confirmButtonColor: "#16911B",
      confirmButtonText: "Delete",
      reverseButtons: true,
      customClass: "swal-confirmation",
      title: "Delete Location"
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        setLoading(true);
        let location_projectData = await getProjectsByLocation(params.LocationCode);

        if (location_projectData == undefined || location_projectData.length == 0) {
          result = DeleteLocation(params.LocationCode)
          result.then((resp) => {
            setLoading(false);
            MySwal.fire({
              text: resp.message,
              imageUrl: successImage,
              customClass: "swal-success",
              didClose: () => {
                setReload(!reload);
              },
            });
          })
            .catch((error) => {
              setLoading(false);
              MySwal.fire({
                text: "Unable to process request.",
                imageUrl: errorImage,
                customClass: "swal-success",
              });
            })
            .finally(() => {
              setReload(!reload);
            });

        }
        else {
          let activeProjects = location_projectData.filter((item) => item.DeleteIndication === "Active").length;
          console.log("activeProjects", activeProjects);
          if (activeProjects > 0) {
            setLoading(false);
            MySwal.fire({
              text: "Projects are already linked to " + location + " Location. Thus, selected Location cannot be Deleted.",
              imageUrl: errorImage,
              customClass: "swal-success",
            });
          }
          else {
            let locObj = params;
            let todayDate = getCurrentDateTime("-");
            locObj.ModifiedBy = userDetails.userId;
            locObj.ModifiedByName = userDetails.userName;
            locObj.ModifiedOn = todayDate;
            locObj.DeleteIndication = "Inactive";
            result = UpdateLocation(locObj);

            result.then((resp) => {
              setLoading(false);
              MySwal.fire({
                text: "Projects are already linked to " + location + " Location. Thus, selected Location cannot be Permanently Deleted and is updated as Inactive.",
                imageUrl: successImage,
                customClass: "swal-success",
                didClose: () => {
                  setReload(!reload);
                },
              });
            })
              .catch((error) => {
                setLoading(false);
                MySwal.fire({
                  text: "Unable to process request.",
                  imageUrl: errorImage,
                  customClass: "swal-success",
                });
              })
              .finally(() => {
                setReload(!reload);
              });
          }
        }
      }
    });
  }

  const handleGlobalExport = async () => {
    let filename = "LocationMaster";
    let excelDownoadData = [];
    // let temp = [];
    // excelData.forEach(element => {
    //   const found = masterData.find(test => test.LocationCode === element.LocationCode);
    //   if (found !== undefined) {
    //     temp.push(found);
    //   }
    // })
    for (let temp of excelData) {

      excelDownoadData.push({
        "LocationCode": temp.LocationCode,
        "LocationType": temp.LocationType,
        "Location": temp.Location,
        "Country": temp.Country,
        "DeleteIndication": temp.DeleteIndication,
        "CreatedById": temp.CreatedBy,
        "CreatedByName": temp.CreatedByName,
        "CreatedOn": formatDateInDDMMYYYY(temp.CreatedOn),
        "ModifiedById": temp.ModifiedBy,
        "ModifiedByName": temp.ModifiedByName,
        "ModifiedOn": formatDateInDDMMYYYY(temp.ModifiedOn),
      });
    }

    let colName = ["Location Status", "Location Code", "Location Type", "Location", "Country", "Created By", "Created By Name", "CreatedOn", "Modified By", "Modified By Name", "Modified On"];
    let Fieldname = ["DeleteIndication", "LocationCode", "LocationType", "Location", "Country", "CreatedById", "CreatedByName", "CreatedOn", "ModifiedById", "ModifiedByName", "ModifiedOn"];
    exportToExcel(filename, "Data", excelDownoadData, "A1:K1", colName, Fieldname);
  };

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <BreadcrumbComp />
      <Container fluid className="main-container">
        <ActionComp label={"Add New Location"} exportEvent={() => handleGlobalExport()} handleAdd={handleAdd} />
        <GridMaster
          rowDetails={filteredResult}
          colDetails={columnDefs}
          fieldNames={fieldName}
          headerNames={headerName}
          getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
          getFilterColLength={(getLength) => setFilterColLength(getLength)}
          setExcelData={setExcelData}
          handleView={handleView}
          handleEdit={handleEdit}
          openConfirmBox={handleDelete}
          resetind={resetFilter}
        />
        {showForm && (
          <AddEditLocationMaster
            show={showForm}
            action={action}
            rowdata={rowData}
            masterData={masterData}
            onHide={() => setShowForm(false)}
            setReload={setReload}
            reload={reload}
          />
        )}
      </Container>
    </>
  );
};

export default LocationMaster;
