import React, { useState, useEffect, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../../layouts/ActionComp";
import BreadcrumbComp from "../../layouts/BreadcrumbComp";
import GridMaster from "../GridMaster";
import StatusRenderer from "../StatusRenderer.jsx";
import ActionRenderer from "../ActionRenderer";
import { AddChecklistDetails, AddChecklistParameterDetails, DeleteChecklistDetails, DeleteChecklistParameterDetails, RenewChecklistEdit, getChecklistMasterDetails, getChecklistParameterDetails } from "../../../services/checklist-master-service";
import AddEditChecklistMaster from "./AddEditChecklistMaster";
import { useDispatch } from "react-redux";
import { menuActions } from "../../../store/menu-slice";
import { searchActions } from "../../../store/grid-slice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthContext } from "../../../contexts/AuthContext";
import { sortByPropertyInAscending } from "../../../utils/Utility";
import { ServiceMasterAllData } from "../../../services/common-master-service"
import { formatDateInDDMMYYYY, getCurrentDateTime } from "../../../functions/gbcs-functions";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import { getTransactionForChecklistVersion } from "../../../services/project-transaction-service";
import { exportToExcel } from "../../utils/ExcelCreation";
import { dateDiffInMins } from "../../../functions/validations";
import ChecklistPDF from "./ChecklistPDF";
import { getAllServiceEquipmentData } from "../../../services/equipmentactivity-master-service.js";

const ChecklistMaster = () => {
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = {
    userName: context.userName,
    userId: context.userId,
  };
  let todayDate = getCurrentDateTime("-");
  const [rowData, setRowData] = useState({});
  const [masterData, setMasterData] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [displayPDFData, setDisplayPDFData] = useState({});
  const [excelData, setExcelData] = useState([]);
  const dispatch = useDispatch();

  dispatch(menuActions.setMenu("Checklist Master"));
  const menuAccess = context.accessibleMenus.filter(item => item.SessionName === "Checklist Master");

  const [actionButtons, setActionButtons] = useState({
    ViewAction: menuAccess[0]?.Display,//check with rolemaster
    EditAction: menuAccess[0]?.Modify,
    DownloadAction: true,
    DeleteAction: menuAccess[0]?.Delete
  });
  const [resetFilter, setResetFilter] = useState(true);
  const [loading, setLoading] = useState(true);

  const columnDefs = [
    // {
    //   headerName: "Sr No.",
    //   valueGetter: "node.rowIndex + 1",
    //   minWidth: 100,
    // },
    {
      headerName: "Status",
      field: "DeleteIndication",
      flex: false,
      width: 150,
      resizable: false,
      tooltipField: "DeleteIndication",
      cellRenderer: StatusRenderer
    },
    {
      headerName: "Checklist Code",
      field: "ChecklistCode",
      minWidth: 150,
      tooltipField: "ChecklistCode",
    },
    {
      headerName: "Checklist Name",
      field: "ChecklistName",
      tooltipField: "ChecklistName",
      minWidth: 150,
    },

    {
      headerName: "Checklist Version",
      field: "ChecklistVersion",
      minWidth: 150,
      tooltipField: "ChecklistVersion",
    },
    {
      headerName: "Checklist Category",
      field: "ChecklistCategory",
      minWidth: 150,
      tooltipField: "ChecklistCategory",
    },
    {
      headerName: "Service Name",
      field: "ServiceName",
      minWidth: 150,
      tooltipField: "ServiceName",
    },
    {
      headerName: "Service Type",
      field: "ServiceType",
      minWidth: 150,
      tooltipField: "ServiceType",
    },
    {
      headerName: "Equipment / Activity Name",
      field: "EquipmentName",
      minWidth: 150,
      tooltipField: "EquipmentName",
    },

    {
      field: "",
      width: 150,
      cellRenderer: function (params) {
        return <ActionRenderer selectedData={params.data} handleViewEvent={handleView} handleEditEvent={handleEdit} actionName={actionButtons} handleDeleteEvent={handleDelete} handleDownloadEvent={handleDownload} />
      },
      sortable: false,
      filter: false,
      pinned: 'right'
    }
  ];

  const headerName = (field) => {
    let name;
    switch (field) {
      case "DeleteIndication":
        name = "Status";
        break;
      case "ChecklistCode":
        name = "Checklist Code";
        break;
      case "ChecklistName":
        name = "Checklist Name";
        break;
      case "ChecklistCategory":
        name = "Checklist Category";
        break;
      case "ChecklistVersion":
        name = "Checklist Version";
        break;
      case "ServiceName":
        name = "Service Name";
        break;
      case "ServiceType":
        name = "Service Type";
        break;
      case "EquipmentName":
        name = "Equipment / Activity Name";
        break;

      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fieldName = (header) => {
    let name;
    switch (header) {
      case "Status":
        name = "DeleteIndication";
        break;
      case "Checklist Code":
        name = "ChecklistCode";
        break;
      case "Checklist Name":
        name = "ChecklistName";
        break;
      case "Checklist Category":
        name = "ChecklistCategory";
        break;
      case "Checklist Version":
        name = "ChecklistVersion";
        break;
      case "Service Name":
        name = "ServiceName";
        break;
      case "Service Type":
        name = "ServiceType";
        break;
      case "Equipment / Activity Name":
        name = "EquipmentName";
        break;

      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fetchChecklistData = async () => {
    setLoading(true);
    dispatch(searchActions.setSearch(""));

    let result = [];
    const [serviceMaster, equipMaster] = await Promise.all([
      ServiceMasterAllData(),
      getAllServiceEquipmentData()
    ]);
    let tmpServiceObj = {};

    let results = []
    let counter = 0;
    if (equipMaster?.length > 0) {
      for (let dt of equipMaster) {
        counter++;

        if (!tmpServiceObj[dt.ServiceCode]) {
          let serviceData = serviceMaster.filter((item) => item.ServiceCode === dt.ServiceCode);

          if (serviceData.length > 0) {
            tmpServiceObj[dt.ServiceCode] = serviceData[0].ServiceName
          }
        }
        let obj = {
          PK: "EquipmentCode#" + dt.EquipmentCode,
          SK: "ChecklistCategory#"
        }
        let tmpRes = getChecklistParameterDetails(obj);
        results.push(tmpRes)

        if (counter === 5) {
          for (let item of results) {
            let tmpRes1 = await Promise.resolve(item)
            result = [...result, ...tmpRes1];
          }
          counter = 0;
          results = [];
        }
      }
    }
    if (results.length > 0) {
      for (let item of results) {
        let tmpRes1 = await Promise.resolve(item)
        result = [...result, ...tmpRes1];
      }
    }

    if (result?.length > 0) {
      for (let dt of result) {
        dt.ServiceName = tmpServiceObj[dt.ServiceCode];
      }
      result.sort(sortByPropertyInAscending("DeleteIndication"))
      setMasterData(result);
      setFilteredResult(result);
      setExcelData(result);
      setResetFilter(!resetFilter);
      setLoading(false);
    }
    setLoading(false);
  };


  useEffect(() => {
    fetchChecklistData();
  }, [reload]);


  const handleDownload = async (row) => {
    setLoading(true);
    let pdfData = {};
    let nextVersionData = await getChecklistMasterDetails(row.EquipmentCode, row.SK);
    if (nextVersionData?.length > 0) {
      pdfData.header = nextVersionData[0];
      pdfData.header.ServiceName = row.ServiceName;
      let objs = {
        PK:
          "ChecklistCode#" +
          row.ChecklistCode +
          "#ChecklistVersion#" +
          row.ChecklistVersion,
        SK: "SeqNo#",
      };
      let nextParamData = await getChecklistParameterDetails(objs);
      if (nextParamData?.length > 0) {
        pdfData.paramData = nextParamData;
      }
      else {
        pdfData.paramData = [];
      }
      console.log("pdfData", pdfData)
      setDisplayPDFData(pdfData);
      setShowPdf(true);
      setLoading(false);
    }
    else {
      setLoading(false);
      MySwal.fire({
        text: "Unable to load details. Kindly try again.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
      return;
    }
  }

  const handleView = async (row) => {

    setAction("View");
    setRowData(row);
    setShowForm(true);
  }

  const handleEdit = async (row) => {

    console.log(row);

    if (row.ChecklistVersion.startsWith("VER")) {

      MySwal.fire({
        text: "Do you wish to create new version or wish to update the status?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "New Version",
        cancelButtonText: "Cancel",
        denyButtonText: "Update Status",
        reverseButtons: true,
        // customClass: "swal-confirmation",
        customClass: {
          popup: 'swal-confirmation',
          cancelButton: 'order-3 cancelChecklistBtn',
          confirmButton: 'order-1',
          denyButton: 'order-2 updateChecklistBtn',
        },
        title: "Edit Checklist"
      }).then(async (result2) => {
        if (result2.isConfirmed) {
          setLoading(true);
          console.log("New Version")
          let allChecklist = await getChecklistMasterDetails(row.EquipmentCode, "ChecklistCategory#" + row.ChecklistCategory + "#ChecklistVersion#");
          let currentChecklistData = allChecklist?.filter((item) => item.ChecklistVersion === "TMP.0001");

          if (currentChecklistData?.length > 0) {
            setLoading(false);
            MySwal.fire({
              text: "Checklist is already checked out by " + currentChecklistData[0].CreatedByName + " and is not yet frozen. You are not allowed to create revision in Checked Out mode.",
              imageUrl: errorImage,
              customClass: "swal-success",
            });
            return;
          }
          else {
            let obj = Object.assign({}, row);
            obj.SK = "ChecklistCategory#" + obj.ChecklistCategory + "#ChecklistVersion#TMP.0001";
            obj.ChecklistVersion = "TMP.0001";
            obj.DeleteIndication = "Active"
            obj.CreatedOn = todayDate;
            obj.CreatedBy = userDetails.userId;
            obj.CreatedByName = userDetails.userName;
            obj.ModifiedBy = userDetails.userId;
            obj.ModifiedByName = userDetails.userName;
            obj.ModifiedOn = todayDate;
            let newVerObjResult = await AddChecklistDetails(obj);
            console.log("obj", obj)
            if (newVerObjResult?.status) {
              let objs = {
                PK: "ChecklistCode#" + row.ChecklistCode + "#ChecklistVersion#" + row.ChecklistVersion,
                SK: "SeqNo#",
              };
              let nextParamData = await getChecklistParameterDetails(objs);
              for (let dt of nextParamData) {
                let newParam = Object.assign({}, dt);
                newParam.PK = "ChecklistCode#" + row.ChecklistCode + "#ChecklistVersion#TMP.0001";
                newParam.CreatedOn = todayDate;
                newParam.CreatedBy = userDetails.userId;
                newParam.CreatedByName = userDetails.userName;
                newParam.ModifiedBy = userDetails.userId;
                newParam.ModifiedByName = userDetails.userName;
                newParam.ModifiedOn = todayDate;
                let newParamResult = await AddChecklistParameterDetails(newParam);
              }
            }

            setAction("Edit");
            setRowData(obj);
            setShowForm(true);
            setLoading(false);
          }
        }
        else if (result2.isDenied) {
          console.log("Update Status")

          setAction("Edit");
          setRowData(row);
          setShowForm(true);
        }
      })
    }
    else {
      setLoading(true);
      let checklistDt = await getChecklistMasterDetails(row.EquipmentCode, row.SK);
      if (checklistDt?.length > 0) {
        if (!checklistDt[0].hasOwnProperty("ChecklistEditRenewOn") || checklistDt[0].ChecklistEditRenewOn === "" ||
          (checklistDt[0].hasOwnProperty("ChecklistEditRenewOn") && dateDiffInMins(checklistDt[0].ChecklistEditRenewOn, getCurrentDateTime("-")) > 30)) {
          let obj = {
            PK: row.PK,
            SK: row.SK,
            RenewDateTime: getCurrentDateTime("-"),
            ModifiedBy: userDetails.userId,
            ModifiedByName: userDetails.userName
          }
          let result = await RenewChecklistEdit(obj);
          if (result.status) {
            setAction("Edit");
            setRowData(checklistDt[0]);
            setShowForm(true);
            setLoading(false);
          }
          else {
            setLoading(false);
            MySwal.fire({
              text: "Unable to update session start time.",
              imageUrl: errorImage,
              customClass: "swal-success",
            });
            return;
          }
        }
        else {
          setLoading(false);
          MySwal.fire({
            text: "Checklist is already open in EDIT mode by " + checklistDt[0].ModifiedByName + ", so you can't edit the checklist. Kindly try after sometime.",
            imageUrl: errorImage,
            customClass: "swal-success",
          });
          return;
        }
      }
      else {
        setLoading(false);
        MySwal.fire({
          text: "Unable to fetch data.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }
      setLoading(false);
    }
  }

  const handleDelete = async (params) => {
    MySwal.fire({
      text: "Are you sure, do you want to delete Checklist?",
      showDenyButton: true,
      cancelButtonColor: "#fff",
      confirmButtonColor: "#16911B",
      confirmButtonText: "Delete",
      reverseButtons: true,
      customClass: "swal-confirmation",
      title: "Delete Checklist"
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        setLoading(true);
        console.log("params", params)
        let obj = {
          EquipmentCode: params.EquipmentCode,
          ChecklistCategory: params.ChecklistCategory,
          ChecklistVersion: params.ChecklistVersion
        }
        let tranData = await getTransactionForChecklistVersion(obj)
        console.log("tranData", tranData)

        if (tranData?.message !== 0) {
          setLoading(false);
          MySwal.fire({
            text: "Checklist is already used in project transaction. Thus, selected checklist version cannot be deleted.",
            imageUrl: errorImage,
            customClass: "swal-success",
          });
          return;
        }
        else {

          let delSuccessInd = false;
          let delErrMsgLine = "";
          let objs = {
            PK:
              "ChecklistCode#" +
              params.ChecklistCode +
              "#ChecklistVersion#" +
              params.ChecklistVersion,
            SK: "SeqNo#",
          };
          let nextParamData = await getChecklistParameterDetails(objs);

          for (let dt of nextParamData) {
            let delParamResult = await DeleteChecklistParameterDetails(dt);
            if (delParamResult?.status) {
              delSuccessInd = true;
            } else {
              delErrMsgLine += dt.SeqNo + ", ";
            }
          }

          if (delSuccessInd || nextParamData?.length === 0) {
            let detailObj = { PK: params.PK, SK: params.SK }
            let delResult = await DeleteChecklistDetails(detailObj);
            if (delResult?.status) {
              setLoading(false);
              MySwal.fire({
                text: "Checklist Details are deteted successfully for selected version.",
                imageUrl: successImage,
                customClass: "swal-success",
                didClose: () => {
                  setReload(!reload);
                },
              });
            } else {
              setLoading(false);
              MySwal.fire({
                text: "Unable to delete checklist details for selected version",
                imageUrl: errorImage,
                customClass: "swal-success",
              });
            }
          }
          setLoading(false);
        }
      }
    });
  }

  const handleGlobalExport = async () => {
    let filename = "ChecklistMaster";
    let excelDownoadData = [];
    for (const element of excelData) {

      excelDownoadData.push({
        "DeleteIndication": element.DeleteIndication,
        "ChecklistCode": element.ChecklistCode,
        "ChecklistName": element.ChecklistName,
        "ChecklistCategory": element.ChecklistCategory,
        "ChecklistVersion": element.ChecklistVersion,
        "ServiceName": element.ServiceName,
        "ServiceType": element.ServiceType,
        "EquipmentName": element.EquipmentName,
        "CreatedByName": element.CreatedByName,
        "CreatedOn": formatDateInDDMMYYYY(element.CreatedOn),
        "ModifiedByName": element.ModifiedByName,
        "ModifiedOn": formatDateInDDMMYYYY(element.ModifiedOn),
      });
    }

    let colName = ["Status", "Checklist Code", "Checklist Name", "Checklist Category", "Checklist Version", "Service Name", "Service Type", "Equipment Name", "Inserted By", "Inserted On", "Modified By", "Modified On"];
    let Fieldname = ["DeleteIndication", "ChecklistCode", "ChecklistName", "ChecklistCategory", "ChecklistVersion", "ServiceName", "ServiceType", "EquipmentName", "CreatedByName", "CreatedOn", "ModifiedByName", "ModifiedOn"];
    exportToExcel(filename, "Data", excelDownoadData, "A1:L1", colName, Fieldname);

  };

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <Container fluid className="main-blank-container">
        <BreadcrumbComp />
        {(!showForm) && (<>
          <ActionComp label={"Add New Checklist"} exportEvent={() => handleGlobalExport()} handleAdd={handleAdd} handleEdit={handleEdit} />
          <GridMaster
            rowDetails={filteredResult}
            colDetails={columnDefs}
            fieldNames={fieldName}
            headerNames={headerName}
            getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
            getFilterColLength={(getLength) => setFilterColLength(getLength)}
            setExcelData={setExcelData}
            handleView={handleView}
            handleEdit={handleEdit}
            handleDownload={handleDownload}
            openConfirmBox={handleDelete}
            actionName={actionButtons}
            resetind={resetFilter}
          />
        </>
        )}
        {showForm && (
          <AddEditChecklistMaster
            show={showForm}
            action={action}
            rowdata={rowData}
            masterData={masterData}
            onHide={() => setShowForm(false)}
            setReload={setReload}
            reload={reload}
          />
        )}
      </Container>

      {showPdf && (
        <ChecklistPDF
          show={showPdf}
          onHide={() => setShowPdf(false)}
          displayPDFData={displayPDFData}
          calledFrom={"ChecklistMaster"}
        />
      )}
    </>
  );
};

export default ChecklistMaster;
