import React, { useEffect, useState, useRef, useMemo, useContext, forwardRef, useImperativeHandle } from "react";
import { Form, Modal, Row, Col, Container, Card, Button, Spinner } from "react-bootstrap";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "../../utils/AddEditProjectMaster.css";
import "../../masters/GridMaster.css";
import { AgGridReact } from "ag-grid-react";
import SingleValuePopUp from "../../utils/SingleValuePopUp";
import { isEmail, isMobNumber } from "../../../functions/validations";
import { defaultEditableCol, RenderIf, getCurrentDateTime } from "../../../functions/gbcs-functions";
import { getProjectTeamData, AddProjectTeam, DeleteProjectTeam, AddTeamResponsive, UpdateTeamResponsive, inactiveProjectTeam } from "../../../services/project-service";
import { getStakeholderData } from "../../../services/common-master-service";
import { BsPlusLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import ActionRenderer from "../ActionRenderer";
import StatusRenderer from "../StatusRenderer";
import { BiSelectMultiple } from "react-icons/bi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import Pagination from "../../utils/Pagination";
import { AuthContext } from "../../../contexts/AuthContext";
import { getDesignReviewTrackerData } from "../../../services/designreviewtracker-service";
import { getSiteObservationReportData } from "../../../services/siteobservationreport-service";
import { getProjTranChklst } from "../../../services/project-transaction-service";
import { addLogDetails } from "../../../services/logger-entry-service";

const ProjectTeam = forwardRef((props, ref) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, dataControl: context.dataControl, userId: context.userId }
    let action = props.action;
    const [gridApi, setGridApi] = useState(null);
    const gridRef = useRef();
    const [showForm, setShowForm] = useState(false);
    let projectcode_ = "";
    let projectname_ = "";
    const [tabData, setTabData] = useState(props.paramData !== undefined ? props.paramData : []);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(3);
    const [stakeholderResult, setStakeholderResult] = useState();
    const [columnDefs, setColumnDefs] = useState([]);
    const [addPopUpResponsive, setAddPopUpResponsive] = useState(false);
    const [subAction, setSubAction] = useState();
    const [largestSeqNo, setLargestSeqNo] = useState(0);
    let todayDate = getCurrentDateTime("-");
    const inactiveStatus = (Object.keys(props.rowdata).length > 0 && props.rowdata.DeleteIndication !== "Active") ? true : false;
    const [loading, setLoading] = useState(true);

    const [actionButtons, setActionButtons] = useState({
        ViewAction: false,
        EditAction: false,
        DownloadAction: false,
        DeleteAction: true
    });

    const [projectTeamObj, setProjectTeamObj] = useState(
        {
            SK: "",
            PK: "",
            ProjectName: "",
            ProjectCode: "",
            CreatedOn: "",
            CreatedBy: "",
            ModifiedOn: "",
            ModifiedBy: "",
            ModifiedByName: "",
            CreatedByName: ""
        }
    );

    const [teamObjResponsive, setTeamObjResponsive] = useState({
        SK: "",
        PK: "",
        ProjectName: "",
        ProjectCode: "",
        CreatedOn: "",
        CreatedBy: "",
        CreatedByName: "",
        ModifiedOn: "",
        ModifiedBy: "",
        ModifiedByName: "",
        DeleteIndication: "Active",
        StakeholderType: "PMC",
        UserName: "",
        UserId: "",
        Designation: "",
        CompanyOrDivision: "",
        CompanyCode: "",
        Role: "",
        EmailId: "",
        MobileNo: "",
        SeqNo: 0
    }
    );

    function onGridReady(params) {
        setGridApi(params.api);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api;// <= assigned gridApi value on Grid ready
    }

    const fetchStakeholderMaster = async () => {
        setLoading(true);
        const result = await getStakeholderData();
        let tmp = [];
        result.forEach(element => {
            tmp.push({ "Name": element.StakeholderName, "Code": element.SK })
        });
        setStakeholderResult(tmp);
        setLoading(false);
    };

    const fetchProjectTeam = async (code, logInd) => {
        setLoading(true);
        const result = await getProjectTeamData(code);
        if (result !== undefined) {
            if (result.length !== 0) {
                setTabData(result);
                setProjectTeamObj(Object.assign({}, result[0]));
                let max = result[result.length - 1].SeqNo;
                result.forEach(element => {
                    if (element.SeqNo > max) {
                        max = element.SeqNo;
                    }
                });
                setLargestSeqNo(max);
                if (logInd && (action === "Edit" || action === "View")) {
                    for (let item of result) {
                        let logresult = await addLogDetails(item, "View", "PK#" + item.PK + "#SK#" + item.SK, "ProjectMasterLog", userDetails, "Entry");
                    }
                }
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        setColumnDefs([{
            headerName: "User Project Status",
            field: "DeleteIndication",
            tooltipField: "DeleteIndication",
            minWidth: 180,
            cellEditor: 'agSelectCellEditor',
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit"
                }
            },
            cellEditorParams: {
                values: ['Active', 'Inactive'],
            }
        },
        {
            headerName: "Stakeholder Type",
            field: "StakeholderType",
            tooltipField: "StakeholderType",
            minWidth: 180,
            editable: false,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit blockCss"
                }
            },
            cellRenderer: function (params) {
                if (params.data.RowInd === "New") {
                    return (
                        <Form.Group className="ddinput">
                            <Form.Select
                                className="ddinput"
                                id="ddType"
                                name="ddType"
                                type="text"
                                placeholder=" "
                                required
                                value={params.value}
                                onChange={(params) => handleChangeOnDD(params)}
                                disabled={props.action === "View" ? true : props.disable}
                            >
                                {stakeholderResult ? stakeholderResult.map((stakeholderResult) => (
                                    <option key={stakeholderResult.Code} value={stakeholderResult.Name}>{stakeholderResult.Name}</option>
                                )) : null
                                }
                            </Form.Select>
                        </Form.Group>
                    )
                }
                else {
                    return <>{params.value}</>
                }
            }
        },
        {
            headerName: "User Name",
            field: "UserName",
            tooltipField: "UserName",
            minWidth: 180,
            editable: false,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit blockCss"
                }
            },
            cellRenderer: function (params) {
                if (params.data.RowInd === "New") {
                    let stakeVal = params.data.StakeholderType;
                    return <>{params.value}<BiSelectMultiple
                        className="modalPopUpIcon"
                        size={20}
                        onClick={() => {
                            if (stakeVal === "Godrej ENE Employee") {
                                showPopUp(
                                    "Godrej Employee Details",
                                    "Single",
                                    "UserName",
                                    params.value,
                                    "Web"
                                );
                            }
                            else {
                                showPopUp(
                                    "User Details without ENE",
                                    "Single",
                                    "UserName",
                                    params.value,
                                    "Web"
                                );
                            }
                        }}
                    /></>
                }
                else {
                    return <>{params.value}</>
                }
            }
        },
        {
            headerName: "Designation",
            field: "Designation",
            tooltipField: "Designation",
            minWidth: 180,
            editable: false,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit"
                }
            },
        },
        {
            headerName: "Company/Division",
            field: "CompanyOrDivision",
            tooltipField: "CompanyOrDivision",
            minWidth: 180,
            editable: false,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit"
                }
            },
        },
        {
            headerName: "Role",
            field: "Role",
            tooltipField: "Role",
            minWidth: 180,
            editable: false,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit"
                }
            },
        },
        {
            headerName: "Mobile No.",
            field: "MobileNo",
            tooltipField: "MobileNo",
            minWidth: 180,
            editable: !(action === "View" || inactiveStatus),
            cellEditorParams: { maxLength: 20 },
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit blockCss"
                }
                else {
                    return "blockCss"
                }
            },
            valueGetter: (params) => {
                return params.data.MobileNo;
            },
            valueSetter: (params) => {
                let error = isMobNumber(params.newValue);
                if (!error) {
                    params.data.MobileNo = params.newValue;
                    //props.setCustomClass1();
                }
                else {
                    MySwal.fire({
                        text: "Enter valid Mobile No with country code. Eg. +91 8888888888",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
                return;
            }
        },
        {
            headerName: "Email ID",
            field: "EmailId",
            tooltipField: "EmailId",
            minWidth: 180,
            cellEditorParams: { maxLength: 100 },
            resizable: false,
            editable: !(action === "View" || inactiveStatus),
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit blockCss"
                }
                else {
                    return "blockCss"
                }
            },
            valueGetter: (params) => {
                return params.data.EmailId;
            },
            valueSetter: (params) => {
                let error = isEmail(params.newValue);
                if (!error.status) {
                    params.data.EmailId = params.newValue;
                    // props.setCustomClass1();
                }
                else {
                    MySwal.fire({
                        text: error.message,
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
                return;
            }
        },
        {
            field: "",
            width: 80,
            minWidth: 80,
            editable: false,
            cellClass: (params) => {
                if (params.data.RowInd === "New") {
                    return "inlineEdit"
                }
            },
            cellRenderer: function (params) {
                if (!inactiveStatus) {
                    return <ActionRenderer selectedData={params} actionName={actionButtons} handleEditEvent={(params) => handleActionEvent(params, "Edit")} handleDeleteEvent={(params) => handleActionEvent(params.data, "Delete")} />
                }
            },
            pinned: 'right'
        }]);
        setLoading(false);
    }, [stakeholderResult]);

    useEffect(() => {
        setLoading(true);
        if (action === "View") {
            setActionButtons({
                ViewAction: false,
                EditAction: false,
                DownloadAction: false,
                DeleteAction: false
            })
        }
        else if (action === "Edit") {
            setActionButtons({
                EditAction: false,
                DownloadAction: false,
                DeleteAction: true,
                ViewAction: false
            })
            if (window.innerWidth < 768) {
                setActionButtons({
                    EditAction: true,
                    DownloadAction: false,
                    DeleteAction: true,
                    ViewAction: false
                })
            }
        }
        if (action === "Edit" || action === "View") {
            projectcode_ = props.rowdata.ProjectCode;
            projectname_ = props.rowdata.ProjectName;
        }
        else {
            projectcode_ = props.codedata;
            projectname_ = props.namedata;
        }
        setTeamObjResponsive((prev) => ({
            ...prev,
            ProjectCode: projectcode_,
            ProjectName: projectname_,
        }));
        setProjectTeamObj((prev) => ({
            ...prev,
            ProjectCode: projectcode_,
            ProjectName: projectname_,
        }));
        fetchStakeholderMaster();
        fetchProjectTeam(projectcode_, true);
        setLoading(false);
    }, [props.codedata]);

    const headerName = (field) => {
        let name;
        switch (field) {
            case "DeleteIndication":
                name = "User Project Status";
                break;
            case "StakeholderType":
                name = "Stakeholder Type";
                break;
            case "UserName":
                name = "User Name";
                break;
            case "Designation":
                name = "Designation";
                break;
            case "CompanyOrDivision":
                name = "CompanyOrDivision";
                break;
            case "Role":
                name = "Role";
                break;
            case "MobileNo":
                name = "MobileNo";
                break;
            case "EmailId":
                name = "EmailId";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const defaultColDef = useMemo(() => {
        return defaultEditableCol();
    }, []);

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "DeleteIndication":
                name = "User Project Status";
                break;
            case "StakeholderType":
                name = "Stakeholder Type";
                break;
            case "UserName":
                name = "User Name";
                break;
            case "Designation":
                name = "Designation";
                break;
            case "CompanyOrDivision":
                name = "Company / Division";
                break;
            case "Role":
                name = "Role";
                break;
            case "MobileNo":
                name = "Mobile No";
                break;
            case "EmailId":
                name = "Email Id";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
        additionalParams: ""
    });

    const showPopUp = (name, type, key, value, additionalParams) => {
        setPopUp({ name, type, key, value, additionalParams });
        setShowForm(true);
    };

    const handleChangeOnDD = (params) => {
        let index = gridRef.current.gridOptionsService.api.getFocusedCell().rowIndex;
        let temp = getRowData();
        temp[index].StakeholderType = params.target.value;
        temp[index].UserName = "";
        temp[index].UserId = "";
        temp[index].CompanyOrDivision = "";
        temp[index].Role = "";
        temp[index].MobileNo = "";
        temp[index].EmailId = "";
        temp[index].Designation = "";
        temp[index].CompanyCode = "";
        setTabData(temp);
    }

    const RearrangeSeqNo = async () => {
        let temp = await getProjectTeamData(projectTeamObj.ProjectCode);
        for (let k = 0; k < temp.length; k++) {
            temp[k].SeqNo = k + 1;
        }
        let result = await AddProjectTeam(temp);
        if (result.status) {
            let ids = await getProjectTeamData(projectTeamObj.ProjectCode);
            let aggridData = getRowData();
            for (let k = 0; k < ids.length; k++) {
                if (aggridData.length < ids[k].SeqNo) {
                    DeleteProjectTeam(ids[k]);
                }
            }
        };
    }

    const handleActionEvent = async (params, event) => {
        let result;
        if (event === "Edit") {
            params.api.startEditingCell({
                rowIndex: params.rowIndex,
                // gets the first columnKey
                colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
            });
        }
        else if (event === "Delete") {
            if (params.RowInd === "New") {
                let tempData = getRowData();
                let index = tempData.findIndex((element) => element.UserId === params.UserId && element.SeqNo === params.SeqNo && element.RowInd === "New");
                tempData.splice(index, 1);
                setTabData(tempData)
            }
            else {
                let confirmBoxText = "Are you sure, do you want to delete Project Team User?";
                let confirmButtonText = "Delete";
                MySwal.fire({
                    text: confirmBoxText,
                    showDenyButton: true,
                    cancelButtonColor: "#fff",
                    confirmButtonColor: "#16911B",
                    confirmButtonText: confirmButtonText,
                    reverseButtons: true,
                    customClass: "swal-confirmation",
                    title: "Delete Project Team User"
                }).then(async (result2) => {
                    if (result2.isConfirmed) {
                        setLoading(true);
                        const [dsrData, sorData, FATChklstData, SATChklstData] = await Promise.all([
                            getDesignReviewTrackerData(params.ProjectCode),
                            getSiteObservationReportData(params.ProjectCode),
                            getProjTranChklst("FAT", params.ProjectCode),
                            getProjTranChklst("SAT", params.ProjectCode)]);
                        let transCreatedByUserInd = false;
                        if (dsrData?.length > 0) {
                            let createdData = dsrData?.filter((item) => item.CreatedBy === params.UserId);
                            if (createdData?.length > 0) {
                                transCreatedByUserInd = true;
                            }
                        }
                        if (sorData?.length > 0) {
                            let createdData = sorData?.filter((item) => item.CreatedBy === params.UserId);
                            if (createdData?.length > 0) {
                                transCreatedByUserInd = true;
                            }
                        }
                        if (FATChklstData?.length > 0) {
                            let createdData = FATChklstData?.filter((item) => item.CreatedBy === params.UserId);
                            if (createdData?.length > 0) {
                                transCreatedByUserInd = true;
                            }
                        }
                        if (SATChklstData?.length > 0) {
                            let createdData = SATChklstData?.filter((item) => item.CreatedBy === params.UserId);
                            if (createdData?.length > 0) {
                                transCreatedByUserInd = true;
                            }
                        }

                        if (transCreatedByUserInd) {
                            params.DeleteIndication = "Inactive";
                            params.ModifiedBy = userDetails.userId;
                            params.ModifiedByName = userDetails.userName;
                            params.ModifiedOn = todayDate;
                            result = await inactiveProjectTeam(params);
                            if (result.status) {
                                let logresult = await addLogDetails(params, "Delete", "PK#" + params.PK + "#SK#" + params.SK, "ProjectMasterLog", userDetails, "Entry");
                                setLoading(false);
                                MySwal.fire({
                                    text: "Project Team user name - " + params.UserName + " status updated as Inactive.",
                                    imageUrl: successImage,
                                    customClass: "swal-success",
                                    didClose: () => {
                                        fetchProjectTeam(params.ProjectCode, false);
                                    },
                                });
                            }
                            else {
                                setLoading(false);
                                MySwal.fire({
                                    text: "Unable to Process Request",
                                    imageUrl: errorImage,
                                    customClass: "swal-success"
                                });
                            }
                        }
                        else {
                            params.ModifiedBy = userDetails.userId;
                            params.ModifiedOn = todayDate;
                            if (params.RowInd !== "New" || params.ProjectCode !== undefined) {
                                result = DeleteProjectTeam(params);
                                result.then(async (resp) => {
                                    RearrangeSeqNo();
                                    let logresult = await addLogDetails(params, "Delete", "PK#" + params.PK + "#SK#" + params.SK, "ProjectMasterLog", userDetails, "Entry");
                                    if (resp.status !== 500) {
                                        setLoading(false);
                                        MySwal.fire({
                                            text: "Project Team user name - " + params.UserName + " details is deleted",
                                            imageUrl: successImage,
                                            customClass: "swal-success",
                                            didClose: () => {
                                                fetchProjectTeam(params.ProjectCode, false);
                                            },
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        setLoading(false);
                                        MySwal.fire({
                                            text: "Unable to Process Request",
                                            imageUrl: errorImage,
                                            customClass: "swal-success"
                                        });
                                    })
                                    .finally(() => {
                                        fetchProjectTeam(params.ProjectCode, false);
                                    });
                            }
                            else {
                                let temp = getRowData();
                                let index = temp.map(x => {
                                    return x.SeqNo;
                                }).indexOf(params.SeqNo);
                                temp.splice(index, 1);
                                setTabData(temp);
                            }
                        }
                        setLoading(false);
                    }
                })

            }
        }
    }

    const handleEditResposive = async (row) => {
        setTeamObjResponsive({
            SK: row.SK,
            PK: row.PK,
            ProjectName: row.ProjectName,
            ProjectCode: row.ProjectCode,
            StakeholderType: row.StakeholderType,
            UserName: row.UserName,
            UserId: row.UserId,
            Designation: row.Designation,
            CompanyOrDivision: row.CompanyOrDivision,
            CompanyCode: row.CompanyCode,
            Role: row.Role,
            EmailId: row.EmailId,
            MobileNo: row.MobileNo,
            DeleteIndication: row.DeleteIndication,
            CreatedOn: row.CreatedOn,
            CreatedBy: row.CreatedBy,
            CreatedByName: row.CreatedByName,
            ModifiedOn: row.ModifiedOn,
            ModifiedBy: row.ModifiedBy
        });
        setAddPopUpResponsive(true);
        setSubAction("Edit");
    }

    const setPopUpData = (data) => {
        if (data.value === "Responsive") {
            let existInd = tabData.filter((item) => item.UserId.toLowerCase() === data.result.UserId.toLowerCase())
            if (existInd?.length > 0) {
                MySwal.fire({
                    text: "Duplicate User Name.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            } else {
                setTeamObjResponsive((prevState) => ({
                    ...prevState,
                    UserName: data.result.UserName,
                    UserId: data.result.UserId,
                    CompanyOrDivision: data.result.CompanyName,
                    Role: data.result.RoleCode,
                    MobileNo: data.result.MobileNo,
                    EmailId: data.result.EmailId,
                    Designation: data.result.Designation,
                    CompanyCode: data.result.CompanyCode
                }));
            }
        }
        else {
            let ids = [...tabData];
            let index = gridRef.current.gridOptionsService.api.getFocusedCell().rowIndex;
            if (data.name === "Godrej Employee Details" || data.name === "User Details without ENE") {
                let allData = getRowData();
                let existInd = allData.filter((item) => item.SeqNo !== (index + 1) && item.UserId.toLowerCase() === data.result.UserId.toLowerCase())
                let newItem = {}
                if (existInd?.length > 0) {
                    newItem = {
                        UserName: "",
                        UserId: "",
                        CompanyOrDivision: "",
                        Role: "",
                        MobileNo: "",
                        EmailId: "",
                        Designation: "",
                        CompanyCode: ""
                    }
                    MySwal.fire({
                        text: "Duplicate User Name.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                }
                else {
                    newItem = {
                        UserName: data.result.UserName,
                        UserId: data.result.UserId,
                        CompanyOrDivision: data.result.CompanyName,
                        Role: data.result.RoleCode,
                        MobileNo: data.result.MobileNo,
                        EmailId: data.result.EmailId,
                        Designation: data.result.Designation,
                        CompanyCode: data.result.CompanyCode
                    }
                }
                ids[index] = Object.assign({}, ids[index], newItem);
                setTabData(ids);
            }
        }
    };

    function getRowData() {
        let rowData = [];
        gridRef.current.gridOptionsService.api.forEachNode(node => rowData.push(node.data));
        return rowData;
    }

    const validateFields = (element) => {
        let isError = false;
        if (element.StakeholderType === "") {
            isError = true;
        }
        if (element.UserName === "") {
            isError = true;
        }
        if (element.MobileNo === "") {
            isError = true;
        }
        if (element.MobileNo !== "") {
            let error = isMobNumber(element.MobileNo);
            if (error) {
                isError = true;
            }
        }
        if (element.EmailId === "") {
            isError = true;
        }
        if (element.EmailId !== "") {
            let error = isEmail(element.EmailId);
            if (error.status) {
                isError = true;
            }
        }
        return isError;
    }

    const validationMsg = (action) => {
        let isError = false;
        tabData.forEach(element => {
            isError = validateFields(element);
            if (isError) {
                return isError;
            }
        });
        return isError;
    }

    const handleSubmit = async () => {
        // e.preventDefault();
        setLoading(true);
        gridRef.current.gridOptionsService.api.stopEditing();
        if (!validationMsg(action) && tabData.length > 0) {
            let result;
            let temp = getRowData();
            setTabData(temp);
            for (let i = 0; i < tabData.length; i++) {
                let element = tabData[i];
                element["SeqNo"] = i + 1;
                element["ProjectCode"] = projectTeamObj.ProjectCode;
                element["ProjectName"] = projectTeamObj.ProjectName;
                element["ModifiedBy"] = userDetails.userId;
                element["ModifiedByName"] = userDetails.userName;
                element["ModifiedOn"] = todayDate;
                element["PK"] = "ProjectCode#" + projectTeamObj.ProjectCode;
                element["SK"] = "ProjectTeam#SeqNo#" + element.SeqNo;
                if (element.hasOwnProperty("RowInd")) {
                    delete element.RowInd;
                }
            }
            result = await AddProjectTeam(tabData);
            if (result.status) {
                for (let item of tabData) {
                    let logresult = await addLogDetails(item, action, "PK#" + item.PK + "#SK#" + item.SK, "ProjectMasterLog", userDetails, "Entry");
                }
                // props.setCustomClass();
                setLoading(false);
                MySwal.fire({
                    text: result.message,
                    imageUrl: successImage,
                    customClass: "swal-success",
                    didClose: () => {
                        fetchProjectTeam(projectTeamObj.ProjectCode, false);
                    },
                });
                return true;
            }
            else {
                setLoading(false);
                MySwal.fire({
                    text: "Unable to Process Request",
                    imageUrl: errorImage,
                    customClass: "swal-success"
                });
            }
        }
        else {
            let errmsg = "";
            if (tabData.length === 0) {
                errmsg = "Please add at least one record first.";
            }
            else {
                errmsg = "Please enter all mandatory fields.";
            }
            setLoading(false);
            MySwal.fire({
                text: errmsg,
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        setLoading(false);
    };

    useImperativeHandle(ref, () => ({
        funcOnNext: async () => {
            let res = false
            if (window.innerWidth > 768) {
                res = await handleSubmit();
                return res
            } else {
                return true;
            }
        }
    }));

    const saveTeamInfo = (e) => {
        e.preventDefault();
        setLoading(true);
        if (!validateFields(teamObjResponsive)) {

            let result;
            let confirmBoxText = "";
            let confirmButtonText = "";
            if (action === "Add") {
                confirmBoxText = "Are you sure, do you want to add new Project Team member?";
                confirmButtonText = "Add";
            }
            else if (action == "Edit") {
                confirmBoxText = "Are you sure, do you want to update Project Team member?";
                confirmButtonText = "Update";
            }
            MySwal.fire({
                text: confirmBoxText,
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonColor: "#16911B",
                confirmButtonText: confirmButtonText,
                reverseButtons: true,
                customClass: "swal-confirmation",
                title: action + " Project Team User"
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    let msglbl;
                    if (subAction === "Add" || tabData.length === 0) {
                        //need to discuss
                        let num = largestSeqNo;
                        num = num + 1;
                        setLargestSeqNo(num);
                        teamObjResponsive.CreatedBy = userDetails.userId;
                        teamObjResponsive.CreatedByName = userDetails.userName;
                        teamObjResponsive.CreatedOn = todayDate;
                        teamObjResponsive.ModifiedOn = todayDate;
                        teamObjResponsive.ModifiedBy = userDetails.userId;
                        teamObjResponsive.ModifiedByName = userDetails.userName;
                        teamObjResponsive["PK"] = "ProjectCode#" + teamObjResponsive.ProjectCode;
                        teamObjResponsive["SK"] = "ProjectTeam#SeqNo#" + num;
                        teamObjResponsive.SeqNo = num;
                        msglbl = "Project Team Member is added successfully.";
                    }
                    else if (subAction === "Edit" || tabData.length > 0) {
                        teamObjResponsive.ModifiedOn = todayDate;
                        teamObjResponsive.ModifiedBy = userDetails.userId;
                        teamObjResponsive.ModifiedByName = userDetails.userName;
                        msglbl = "Project Team Member is updated successfully.";
                    }
                    result = await AddTeamResponsive(teamObjResponsive);
                    if (result.status) {
                        let logresult = await addLogDetails(teamObjResponsive, subAction, "PK#" + teamObjResponsive.PK + "#SK#" + teamObjResponsive.SK, "ProjectMasterLog", userDetails, "Entry");
                        // props.setCustomClass();
                        setLoading(false);
                        MySwal.fire({
                            text: msglbl,
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                fetchProjectTeam(teamObjResponsive.ProjectCode, false);
                            },
                        });
                        setAddPopUpResponsive(false);
                    }
                    else {
                        setLoading(false);
                        MySwal.fire({
                            text: "Unable to Process Request",
                            imageUrl: errorImage,
                            customClass: "swal-success"
                        });
                    }
                }
            });
            setLoading(false);
        } else {
            setLoading(false);
            MySwal.fire({
                text: "Please enter all mandatory fields or validate Email Id & Mobile No.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        setLoading(false);
    }

    const onCloseResponsiveModal = () => {
        setAddPopUpResponsive(false);
    }

    const handleChangeResponsive = (e) => {
        const { name, value } = e.target;
        setTeamObjResponsive((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const addNewRow = () => {
        gridRef.current.gridOptionsService.api.stopEditing();
        if (projectTeamObj.ProjectCode === '' || teamObjResponsive.ProjectCode === '') {
            MySwal.fire({
                text: "Please add project first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        else {
            if (window.innerWidth > 768) {
                let add = true;
                tabData.forEach(element => {
                    if (element.UserName === "") {
                        add = false;
                    }
                });
                if (add) {
                    let lastrow = { SeqNo: gridApi.getDisplayedRowCount() + 1, DeleteIndication: "Active", StakeholderType: 'PMC', UserName: '', Designation: '', CompanyOrDivision: '', Role: '', MobileNo: '', EmailId: '', CreatedBy: userDetails.userId, CreatedOn: todayDate, CreatedByName: userDetails.userName, RowInd: "New" }
                    gridApi.applyTransaction({ add: [lastrow], addIndex: gridApi.getDisplayedRowCount() });
                    let temp = getRowData();
                    setTabData(temp);
                    // props.setCustomClass1();
                }
                else {
                    MySwal.fire({
                        text: "Please enter all mandatory fields.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
            }
            else {
                setTeamObjResponsive((prevState) => ({
                    ...prevState,
                    SK: "",
                    PK: "",
                    CreatedOn: "",
                    CreatedBy: "",
                    CreatedByName: "",
                    ModifiedOn: "",
                    ModifiedBy: "",
                    DeleteIndication: "Active",
                    StakeholderType: "PMC",
                    UserName: "",
                    UserId: "",
                    Designation: "",
                    CompanyOrDivision: "",
                    Role: "",
                    EmailId: "",
                    MobileNo: "",
                    SeqNo: 0
                }));
                setAddPopUpResponsive(true);
            }
            setSubAction("Add");
        }
    };

    const filteredResult = useMemo(() => {
        let firstPageIndex = 0;
        if (currentPage > 1) {
            firstPageIndex = (currentPage - 1) * pageSize;
        }
        if (firstPageIndex >= tabData.length) {
            firstPageIndex = 0;
            const totalPageCount = Math.ceil(tabData.length / pageSize);
            if (totalPageCount > 0) {
                setCurrentPage(totalPageCount)
            } else {
                setCurrentPage(1)
            }
        }
        let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
        return tabData ? tabData.slice(firstPageIndex, lastPageIndex) : [];
    }, [currentPage, tabData, pageSize]);

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Form
                id="projectteam"
                name="projectteam"
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Modal.Body>
                    <Row>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjectCode"
                                    type="text"
                                    name="ProjectCode"
                                    maxLength="50"
                                    placeholder=" "
                                    value={projectTeamObj.ProjectCode}
                                    readOnly
                                />
                                <Form.Label htmlFor="ProjectCode">Project Code</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjectName"
                                    type="text"
                                    name="ProjectName"
                                    maxLength="100"
                                    placeholder=" "
                                    value={projectTeamObj.ProjectName}
                                    readOnly
                                />
                                <Form.Label htmlFor="ProjectName">Project</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={action === "View" || inactiveStatus ? "prevHideButton" : "hstack"}>
                        <Col xs={12} className="addNewRow">
                            <BsPlusLg size={20} className="plusIcon" />
                            &nbsp;
                            <Link onClick={() => addNewRow()} className="blueColor">Add New</Link>
                        </Col>
                    </Row>
                    {addPopUpResponsive ?
                        <div className="market-wrapper d-md-none d-lg-none cardContainerResponsive backdropModal">
                            <Modal
                                {...props}
                                size="sm"
                                show={true}
                                id="projectteamAdd"
                                centered
                                onHide={() => setAddPopUpResponsive(false)}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title className="modalTitle">
                                        Add Team Member
                                        <br />
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className="g-select">
                                                <Form.Select
                                                    id="UserProjectStatus"
                                                    name="UserProjectStatus"
                                                    type="text"
                                                    placeholder=" "
                                                    required
                                                    value={teamObjResponsive.DeleteIndication}
                                                    onChange={(e) => { handleChangeResponsive(e) }}
                                                    disabled={action === "View" ? true : false}
                                                >
                                                    <option value="">Select</option>
                                                    <option key="Active" value="Active">Active</option>
                                                    <option key="Inactive" value="Inactive">Inactive</option>
                                                </Form.Select>
                                                <Form.Label className="frm-label" htmlFor="UserProjectStatus">User Project Status</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group className="g-select">
                                                <Form.Select
                                                    id="StakeholderType"
                                                    name="StakeholderType"
                                                    type="text"
                                                    placeholder=" "
                                                    value={teamObjResponsive.StakeholderType}
                                                    onChange={handleChangeResponsive}
                                                    disabled={action === "View" || subAction === "Edit" ? true : false}
                                                >
                                                    <option value="">Select</option>
                                                    {stakeholderResult ? stakeholderResult.map((stakeholderResult) =>
                                                        <option key={stakeholderResult.Code} value={stakeholderResult.Name}>{stakeholderResult.Name}</option>
                                                    ) : null
                                                    }
                                                </Form.Select>
                                                <Form.Label className="frm-label">Stakeholder Type</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group className="g-input">
                                                <Form.Control
                                                    id="UserName"
                                                    type="text"
                                                    name="UserName"
                                                    maxLength="100"
                                                    placeholder=" "
                                                    value={teamObjResponsive.UserName}
                                                    onChange={handleChangeResponsive}
                                                    disabled={action === "View" || subAction === "Edit" ? true : false}
                                                />
                                                <Form.Label htmlFor="UserName">User Name</Form.Label>
                                                <BiSelectMultiple
                                                    className={["modalPopUpIconProjDetails",
                                                        action === "View" || subAction === "Edit" ? "d-none" : ""
                                                    ].join(" ")}
                                                    size={20}
                                                    onClick={() => {
                                                        if (teamObjResponsive.StakeholderType === "Godrej ENE Employee") {
                                                            showPopUp(
                                                                "Godrej Employee Details",
                                                                "Single",
                                                                "UserName",
                                                                teamObjResponsive.UserName,
                                                                "Responsive"
                                                            )
                                                        }
                                                        else {
                                                            showPopUp(
                                                                "User Details without ENE",
                                                                "Single",
                                                                "UserName",
                                                                teamObjResponsive.UserName,
                                                                "Responsive"
                                                            );
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group className="g-input">
                                                <Form.Control
                                                    id="Designation"
                                                    type="text"
                                                    name="Designation"
                                                    maxLength="100"
                                                    value={teamObjResponsive.Designation}
                                                    onChange={handleChangeResponsive}
                                                    disabled={action === "View" || subAction === "Edit" ? true : false}
                                                />
                                                <Form.Label htmlFor="Designation">Designation</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group className="g-input">
                                                <Form.Control
                                                    id="CompanyOrDivision"
                                                    type="text"
                                                    name="CompanyOrDivision"
                                                    maxLength="100"
                                                    placeholder=" "
                                                    value={teamObjResponsive.CompanyOrDivision}
                                                    onChange={handleChangeResponsive}
                                                    disabled={action === "View" || subAction === "Edit" ? true : false}
                                                />
                                                <Form.Label htmlFor="CompanyOrDivision">Company / Division</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group className="g-input">
                                                <Form.Control
                                                    id="Role"
                                                    type="text"
                                                    name="Role"
                                                    maxLength="100"
                                                    placeholder=" "
                                                    value={teamObjResponsive.Role}
                                                    onChange={handleChangeResponsive}
                                                    disabled={action === "View" || subAction === "Edit" ? true : false}
                                                />
                                                <Form.Label htmlFor="Role">Role</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group className="g-input">
                                                <Form.Control
                                                    id="EmailId"
                                                    type="text"
                                                    name="EmailId"
                                                    maxLength="100"
                                                    placeholder=" "
                                                    value={teamObjResponsive.EmailId}
                                                    onChange={handleChangeResponsive}
                                                />
                                                <Form.Label htmlFor="EmailId">Email Id</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group className="g-input">
                                                <Form.Control
                                                    id="MobileNo"
                                                    type="text"
                                                    name="MobileNo"
                                                    maxLength="20"
                                                    placeholder=" "
                                                    value={teamObjResponsive.MobileNo}
                                                    onChange={handleChangeResponsive}
                                                />
                                                <Form.Label htmlFor="MobileNo">Mobile No.</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button variant="outline-success" className="semiBoldToggleButton cancelBtn" onClick={onCloseResponsiveModal}>
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button variant="success" type="button" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={saveTeamInfo}>
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                            </Modal>
                        </div>
                        : <></>
                    }
                </Modal.Body>
            </Form>
            <RenderIf isTrue={popUp.type === "Single" && showForm}>
                <SingleValuePopUp
                    show={showForm}
                    onHide={() => setShowForm(false)}
                    setSelected={setPopUpData}
                    mastername={popUp.name}
                    paramkey={popUp.key}
                    paramvalue={popUp.value}
                    additionalParams={popUp.additionalParams}
                    backdrop="static"
                />
            </RenderIf>
            <Container fluid className="grid-comp-containerCss">
                <div className="ag-theme-alpine agGridContainer d-none d-md-block d-lg-block">
                    <AgGridReact
                        ref={gridRef}
                        rowData={tabData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        fieldNames={fieldName}
                        headerNames={headerName}
                        // enableRangeSelection={true}
                        // pagination={true}
                        // paginationPageSize={7}
                        enableBrowserTooltips={true}
                        onGridReady={onGridReady}
                    />
                </div>
                <div className="market-wrapper d-md-none d-lg-none cardContainerResponsive">
                    {
                        filteredResult.map((selection, index) =>
                            <Card className="cardContainer" key={index}>
                                <Card.Body className="cardContainerResponsive">
                                    <div className={inactiveStatus ? "hideDisplay" : "actionIcons"}>
                                        <ActionRenderer selectedData={selection} actionName={actionButtons} handleEditEvent={(selection) => handleEditResposive(selection)} handleDeleteEvent={(selection) => handleActionEvent(selection, "Delete")} />
                                    </div>
                                    {columnDefs.map((header, index) =>
                                        <Row key={index} className="responsive-row">
                                            <Col className="colHeader">{header.headerName}</Col>
                                            {header.headerName === "User Project Status" ?
                                                <Col className="colData"><StatusRenderer data={selection} colDef={{ field: header.field }} /></Col>
                                                : <Col className="colData">{selection[header.field]}</Col>
                                            }
                                        </Row>
                                    )}
                                </Card.Body>
                            </Card>
                        )}
                    {filteredResult?.length > 0 && (
                        <Pagination
                            currentPage={currentPage}
                            totalCount={tabData ? tabData.length : 0}
                            data={tabData}
                            pageSize={pageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        />)}
                </div>
            </Container>
        </>
    );
});

export default ProjectTeam;
