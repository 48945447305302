import React, { useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorImage from "../../icons/error-tick-logo.svg";
import successImage from "../../icons/success-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import { addIntegratedSystemTestingDetails, getIntegratedTabsData } from "../../services/integrated-system-testing-service";
import { sortByPropertyInDescending } from "../../utils/Utility";

const IntegratedAddEditScript = (props) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { userId: context.userId, userName: context.userName };
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const selectedProject = props.selectedProject;
    let action = props.action;
    const [integratedScriptObj, setIntegratedScriptObj] = useState(action === "Edit" ? props.rowData : {
        Scenario: "",
        ScriptStatus: "",
        Remarks: ""
    });

    const validateDetails = () => {
        let isError = false;
        const errorMsg = {
            Scenario: {},
            ScriptStatus: {},
            Remarks: {}
        };
        if (integratedScriptObj.Scenario === "") {
            isError = true;
            errorMsg.Scenario = {
                status: true,
                message: "Scenario is required.",
            };
        }
        if (integratedScriptObj.ScriptStatus === "") {
            isError = true;
            errorMsg.ScriptStatus = {
                status: true,
                message: "Pass / Fail is required.",
            };
        }
        if (integratedScriptObj.Remarks.trim() === "") {
            isError = true;
            errorMsg.Remarks = {
                status: true,
                message: "Remarks is required.",
            };
        }

        setError(errorMsg);
        return isError;
    };

    const handleChange = (e) => {
        let { name, value, type } = e.target;
        // if (type.toLowerCase() === "checkbox") {
        //     value = e.target.checked;
        // }
        setIntegratedScriptObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationResult = validateDetails();
        if (!validationResult) {
            setLoading(true);
            const allData = await await getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": "Script" });
            if (allData.length > 0) {
                if (allData.filter((item) => ((action === "Add" || (action === "Edit" && item.SK.toLowerCase() !== integratedScriptObj.SK.toLowerCase())) && (item.Scenario.toLowerCase() === integratedScriptObj.Scenario.toLowerCase()))).length > 0) {
                    setLoading(false);
                    MySwal.fire({
                        text: "Scenario already exists.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
            }

            setLoading(false);
            let confirmBoxText = "";
            let confirmButtonText = "";
            if (action === "Add") {
                confirmBoxText = "Are you sure, do you want to add new IST Script Summary?";
                confirmButtonText = "Add";
            }
            else if (action == "Edit") {
                confirmBoxText = "Are you sure, do you want to update IST Script Summary?";
                confirmButtonText = "Update";
            }

            MySwal.fire({
                text: confirmBoxText,
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonColor: "#16911B",
                confirmButtonText: confirmButtonText,
                reverseButtons: true,
                customClass: "swal-confirmation",
                title: action + " IST Script Summary"
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    if (action === "Add") {
                        let maxSeqNo = 0;
                        if (allData?.length > 0) {
                            let sortedArr = allData.sort(sortByPropertyInDescending("SeqNo"));
                            maxSeqNo = sortedArr[0].SeqNo
                        }

                        let NewMaxSeqNo = parseInt(maxSeqNo) + 1;
                        integratedScriptObj.CreatedOn = getCurrentDateTime("-");
                        integratedScriptObj.CreatedBy = userDetails.userId;
                        integratedScriptObj.CreatedByName = userDetails.userName;
                        integratedScriptObj.SeqNo = NewMaxSeqNo;
                        integratedScriptObj.ProjectCode = selectedProject.ProjectCode;
                        integratedScriptObj.ProjectName = selectedProject.ProjectName;
                        integratedScriptObj.PK = "IntegratedSystemTesting#ProjectCode#" + selectedProject.ProjectCode;
                        integratedScriptObj.SK = "Script#SeqNo#" + NewMaxSeqNo;
                    }
                    integratedScriptObj.ModifiedOn = getCurrentDateTime("-");
                    integratedScriptObj.ModifiedBy = userDetails.userId;
                    integratedScriptObj.ModifiedByName = userDetails.userName;
                    let result = await addIntegratedSystemTestingDetails(integratedScriptObj);
                    if (result?.status) {
                        let msg = "";
                        if (action === "Add")
                            msg = "IST Script Summary has been added successfully."
                        else
                            msg = "IST Script Summary has been updated successfully."
                        setLoading(false);
                        MySwal.fire({
                            text: msg,
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                props.fetchScriptSummaryData();
                                props.onHide();
                            },
                        });
                    }
                    else {
                        setLoading(false);
                        MySwal.fire({
                            text: "Unable to Process Request",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                }
            });
        }
    };

    const handleReset = () => {
        setIntegratedScriptObj({
            Scenario: "",
            ScriptStatus: "",
            Remarks: ""
        });
        setError({
            Scenario: {},
            ScriptStatus: {},
            Remarks: {}
        });
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Modal {...props} size="lg" backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{action} IST Script Summary</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="integratedScript" name="integratedScript" autoComplete="off">
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="Scenario"
                                        type="Text"
                                        name="Scenario"
                                        maxLength="500"
                                        placeholder=" "
                                        value={integratedScriptObj.Scenario}
                                        onChange={handleChange}
                                    />
                                    <Form.Label className="frm-label" htmlFor="Scenario">
                                        Scenario
                                    </Form.Label>
                                    {error.Scenario?.status && (
                                        <Form.Text className="text-danger">
                                            {error.Scenario?.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Row>
                                    <Form.Label className="fieldLabel locationType">Pass / Fail</Form.Label>
                                </Row>
                                <Row>
                                    <Col md={2} xs={6}>
                                        <Form.Check className="fieldLabel locationType"
                                            type="radio"
                                            name="ScriptStatus"
                                            checked={integratedScriptObj.ScriptStatus === "Pass" ? true : false}
                                            onChange={handleChange}
                                            label="Pass"
                                            value="Pass"
                                        ></Form.Check>
                                    </Col>
                                    <Col md={2} xs={6}>
                                        <Form.Check className="fieldLabel locationType"
                                            type="radio"
                                            name="ScriptStatus"
                                            checked={integratedScriptObj.ScriptStatus === "Fail" ? true : false}
                                            onChange={handleChange}
                                            label="Fail"
                                            value="Fail"
                                        ></Form.Check>
                                    </Col>
                                    {error.ScriptStatus?.status && (
                                        <Form.Text className="text-danger">
                                            {error.ScriptStatus?.message}
                                        </Form.Text>
                                    )}
                                </Row>
                                <br />
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="Remarks"
                                        type="Text"
                                        name="Remarks"
                                        maxLength="500"
                                        placeholder=" "
                                        value={integratedScriptObj.Remarks}
                                        onChange={handleChange}
                                    />
                                    <Form.Label className="frm-label" htmlFor="Remarks">
                                        Remarks
                                    </Form.Label>
                                    {error.Remarks?.status && (
                                        <Form.Text className="text-danger">
                                            {error.Remarks?.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Button variant="outline-success"
                                    className="semiBoldToggleButton cancelBtn"
                                    onClick={(e) => handleReset(e)}>
                                    Reset
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    variant="success"
                                    className="btn-add-color semiBoldToggleButton rightAlignedButton"
                                    onClick={handleSubmit}>
                                    {action === "Edit" ? "Update" : "Add"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body >
            </Modal >
        </>
    );
};

export default IntegratedAddEditScript;
