import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import "../layouts/CSS/CurrencyMaster.css";
import CurrencyActionComp from "../layouts/CurrencyActionComp";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import {
  getCurrencydata,
  UpdateCurrencyMaster,
} from "../../services/currency-master-service";
import { exportToExcel } from "../utils/ExcelCreation";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import {
  formatDateInDDMMYYYY,
  getCurrentDateTime,
} from "../../functions/gbcs-functions";
import { AuthContext } from "../../contexts/AuthContext";

const CurrencyMaster = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  dispatch(menuActions.setMenu("Currency Master"));
  const context = useContext(AuthContext);
  const userDetails = {
    userId: context.userId,
    userName: context.userName
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCurrencyData();
  }, []);

  const [CurrencyData, setCurrencyData] = useState([]);
  const [PK, setPK] = useState("");
  const [SK, setSK] = useState("");
  const [FromCountry1, setFromCountry1] = useState("");
  const [FromCountry2, setFromCountry2] = useState("");
  const [ToCountry1, setToCountry1] = useState("");
  const [ToCountry2, setToCountry2] = useState("");
  const [FromCurrency1, setFromCurrency1] = useState("");
  const [FromCurrency2, setFromCurrency2] = useState("");
  const [ToCurrency1, setToCurrency1] = useState("");
  const [ToCurrency2, setToCurrency2] = useState("");
  const [ConversionRate1, setConversionRate1] = useState("");
  const [ConversionRate2, setConversionRate2] = useState("");
  const [tmpConversionRate1, settmpConversionRate1] = useState("");
  const [tmpConversionRate2, settmpConversionRate2] = useState("");
  const [FlagForDisable, setFlagForDisable] = useState(true);
  const [RevisionNo, setRevisionNo] = useState(0);
  const ModifiedBy = userDetails.userId;
  const [CreatedBy, setCreatedBy] = useState("");
  const [CreatedOn, setCreatedOn] = useState("");
  const ModifiedByName = userDetails.userName;
  const ModifiedOn = getCurrentDateTime("-");

  const fetchCurrencyData = async () => {
    setLoading(true);
    const result = await getCurrencydata();
    console.log(result[0]);
    setCurrencyData(result);
    setConversionRate1(result[0].ConversionRate1);
    setConversionRate2(result[0].ConversionRate2);
    settmpConversionRate1(result[0].ConversionRate1);
    settmpConversionRate2(result[0].ConversionRate2);
    setRevisionNo(result[0].RevisionNo + 1);
    setFromCountry1(result[0].FromCountry1);
    setFromCountry2(result[0].FromCountry2);
    setToCountry1(result[0].ToCountry1);
    setToCountry2(result[0].ToCountry2);
    setFromCurrency1(result[0].FromCurrency1);
    setFromCurrency2(result[0].FromCurrency2);
    setToCurrency1(result[0].ToCurrency1);
    setToCurrency2(result[0].ToCurrency2);
    setCreatedBy(result[0].CreatedBy);
    setCreatedOn(result[0].CreatedOn);
    setPK(result[0].PK);
    setSK(result[0].SK);
    setLoading(false);
  };

  const handleGlobalExport = async () => {
    let filename = "CurrencyMaster";
    const data = [];
    for (let i = 0; i < CurrencyData.length; i++) {
      for (let k = 0; k <= 1; k++) {
        if (k == 0) {
          data.push({
            FromCountry: CurrencyData[i].FromCountry1,
            ToCountry: CurrencyData[i].ToCountry1,
            From: CurrencyData[i].FromCurrency1,
            To: CurrencyData[i].ToCurrency1,
            ConversionRate: CurrencyData[i].ConversionRate1,
            CreatedBy: CurrencyData[i].CreatedBy,
            CreatedOn: formatDateInDDMMYYYY(CurrencyData[i].CreatedOn),
            ModifiedBy: CurrencyData[i].ModifiedByName,
            ModifiedOn: formatDateInDDMMYYYY(CurrencyData[i].ModifiedOn),
          });
        } else {
          data.push({
            FromCountry: CurrencyData[i].FromCountry2,
            ToCountry: CurrencyData[i].ToCountry2,
            From: CurrencyData[i].FromCurrency2,
            To: CurrencyData[i].ToCurrency2,
            ConversionRate: CurrencyData[i].ConversionRate2,
            CreatedBy: CurrencyData[i].CreatedBy,
            CreatedOn: formatDateInDDMMYYYY(CurrencyData[i].CreatedOn),
            ModifiedBy: CurrencyData[i].ModifiedByName,
            ModifiedOn: formatDateInDDMMYYYY(CurrencyData[i].ModifiedOn),
          });
        }
      }
    }

    let colName = [
      "From Country",
      "To Country",
      "From",
      "To",
      "Conversion Rate",
      "Inserted By",
      "Inserted On",
      "Modified By",
      "Modified On",
    ];
    let Fieldname = [
      "FromCountry",
      "ToCountry",
      "From",
      "To",
      "ConversionRate",
      "CreatedBy",
      "CreatedOn",
      "ModifiedBy",
      "ModifiedOn",
    ];
    exportToExcel(filename, "Data", data, "A1:I1", colName, Fieldname);
  };
  const btnCancelClick = async (e) => {
    // e.preventDefault()
    setFlagForDisable(true);
    btnResetClick();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (ConversionRate1 == "") {
      MySwal.fire({
        text: "Please Enter Conversion Rate.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
      return;
    }
    MySwal.fire({
      text: "Are you sure, do you want to update Currency Rate?",
      showDenyButton: true,
      cancelButtonColor: "#fff",
      confirmButtonColor: "#16911B",
      confirmButtonText: "Update",
      reverseButtons: true,
      customClass: "swal-confirmation",
      title: "Update Currency Rates"
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        setLoading(true);
        const CurrencymasterData = {
          PK,
          SK,
          FromCountry1,
          FromCountry2,
          ToCountry1,
          ToCountry2,
          FromCurrency1,
          FromCurrency2,
          ToCurrency1,
          ToCurrency2,
          ConversionRate1,
          ConversionRate2,
          RevisionNo,
          ModifiedBy,
          ModifiedByName,
          ModifiedOn,
          CreatedBy,
          CreatedOn,
        };

        let result = UpdateCurrencyMaster(CurrencymasterData);
        result
          .then((resp) => {
            if (resp.status) {
              setLoading(false);
              MySwal.fire({
                text: "Great! You’ve Updated Currency Rate successfully.",
                imageUrl: successImage,
                customClass: "swal-success",
                didClose: () => {
                  let updatedData = [];
                  updatedData.push(CurrencymasterData);
                  setCurrencyData(updatedData);
                  setFlagForDisable(true);
                  settmpConversionRate1(ConversionRate1);
                  settmpConversionRate2(ConversionRate2);
                },
              });
            }
          })
          .catch((error) => {
            setLoading(false);
            MySwal.fire({
              text: "Unable to Process Request",
              imageUrl: errorImage,
              customClass: "swal-success",
            });
          })
          .finally(() => {
            let updatedData = [];
            updatedData.push(CurrencymasterData);
            setCurrencyData(updatedData);
            setFlagForDisable(true);
            settmpConversionRate1(ConversionRate1);
            settmpConversionRate2(ConversionRate2);
          });
      }
    });
  };

  const btnResetClick = async (e) => {
    // e.preventDefault();
    setConversionRate1(tmpConversionRate1);
    setConversionRate2(tmpConversionRate2);
  };

  const handleCancelEdit = async (act) => {
    if (act == "Edit") {
      setFlagForDisable(false);
    } else {
      setFlagForDisable(true);
    }
  };

  const getConversionRate = (UsdValue) => {
    if (Math.sign(UsdValue) == -1) {
      alert("Please Enter Positive value of USD ");
      setConversionRate1(0);
      return;
    }
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,4})/s;
    let tmpval = UsdValue.match(regex)[0];
    setConversionRate1(tmpval);
    let ind_conversionrate = "";
    if (tmpval > 0) {
      ind_conversionrate = (1 / Number(tmpval)).toFixed(4);
    }
    setConversionRate2(ind_conversionrate);
  };

  return (<>
    {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
    <BreadcrumbComp Breadcrummenu="Currency Master" />
    <Container fluid className="currency-master-container">
      <CurrencyActionComp
        label={"Currency Rates"}
        editEvent={() => handleCancelEdit("Edit")}
        exportEvent={() => handleGlobalExport()}
        FlagForDisable={FlagForDisable}
      />

      {CurrencyData.map((Cdata, index) => (
        <Form className="CurrencyForm" onSubmit={handleSubmit}>
          <br></br>
          <div className="CurrencyFormDiv">
            <Row>
              <Col className="mb-2" lg={3} md={3} sm={6} xs={12}>
                <Form.Group className="g-input">
                  <Form.Control
                    id="from_country_usa"
                    type="text"
                    className="txt_text"
                    readOnly
                    placeholder=" "
                    value={Cdata.FromCountry1}
                  />
                  <Form.Label className="frm-label" for="from_country_usa">
                    From Country{" "}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col className="mb-2" lg={3} md={3} sm={6} xs={12}>
                <Form.Group className="g-input">
                  <Form.Control
                    id="to_country_ind"
                    type="text"
                    className="txt_text"
                    readOnly
                    placeholder=" "
                    value={Cdata.ToCountry1}
                  />
                  <Form.Label className="frm-label" for="to_country_ind">
                    To Country{" "}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col className="mb-2">
                <Form.Group className="g-input">
                  <Form.Control
                    id="from_usd"
                    type="text"
                    className="txt_text"
                    readOnly
                    placeholder=" "
                    value={Cdata.FromCurrency1}
                  />
                  <Form.Label className="frm-label" for="from_usd">
                    From{" "}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col className="mb-2">
                <Form.Group className="g-input">
                  <Form.Control
                    id="to_inr"
                    type="text"
                    className="txt_text"
                    readOnly
                    placeholder=" "
                    value={Cdata.ToCurrency1}
                  />
                  <Form.Label className="frm-label" for="to_inr">
                    To{" "}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col className="mb-2" lg={3} md={2} sm={6} xs={12}>
                <Form.Group className="g-input">
                  <Form.Control
                    id="Conversionrate_usd"
                    readOnly={FlagForDisable}
                    type="number"
                    className="txt_number"
                    placeholder=" "
                    pattern="^[0-9]{4}$"
                    value={ConversionRate1}
                    onChange={(e) => {
                      setConversionRate1(e.target.value);
                      getConversionRate(e.target.value);
                    }}
                  />
                  <Form.Label className="frm-label" for="Conversionrate_usd">
                    Conversion Rate{" "}
                  </Form.Label>
                </Form.Group>
              </Col>
              {/* </Row>
          <Row> */}
              <Col className="mb-2" lg={3} md={3} sm={6} xs={12}>
                <Form.Group className="g-input">
                  <Form.Control
                    id="from_country_ind"
                    type="text"
                    className="txt_text"
                    readOnly
                    placeholder=" "
                    value={Cdata.FromCountry2}
                  />
                  <Form.Label className="frm-label" for="from_country_ind">
                    From Country{" "}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col className="mb-2" lg={3} md={3} sm={6} xs={12}>
                <Form.Group className="g-input">
                  <Form.Control
                    id="to_country_usa"
                    type="text"
                    className="txt_text"
                    readOnly
                    placeholder=" "
                    value={Cdata.ToCountry2}
                  />
                  <Form.Label className="frm-label" for="to_country_usa">
                    To Country{" "}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col className="mb-2">
                <Form.Group className="g-input">
                  <Form.Control
                    id="from_inr"
                    type="text"
                    className="txt_text"
                    readOnly
                    placeholder=" "
                    value={Cdata.FromCurrency2}
                  />
                  <Form.Label className="frm-label" for="from_inr">
                    From{" "}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col className="mb-2">
                <Form.Group className="g-input">
                  <Form.Control
                    id="to_usd"
                    type="text"
                    className="txt_text"
                    readOnly
                    placeholder=" "
                    value={Cdata.ToCurrency2}
                  />
                  <Form.Label className="frm-label" for="to_usd">
                    To{" "}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col className="mb-2" lg={3} md={2} sm={6} xs={12}>
                <Form.Group className="g-input">
                  <Form.Control
                    id="Conversionrate_inr"
                    readOnly
                    type="number"
                    className="txt_number"
                    placeholder=" "
                    value={ConversionRate2}
                  />
                  <Form.Label className="frm-label" for="Conversionrate_inr">
                    Conversion Rate{" "}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row className={!FlagForDisable ? "visible" : "invisible"}>
              <Col md={9} xs={6}>
                <Row>
                  <Col md={2} xs={12}>
                    <Button
                      className="semiBoldToggleButton cancelBtn"
                      variant="outline-success"
                      //onClick={props.onHide}
                      onClick={(e) => btnCancelClick(e)}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={6} xs={12}>
                    <Button
                      className="resetBtn semiBoldToggleButton"
                      variant="link"
                      onClick={(e) => btnResetClick(e)}
                    >
                      Reset Fields
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={3} xs={6}>
                <Button
                  variant="success"
                  className="btn-add-color semiBoldToggleButton rightAlignedButton"
                  type="submit"
                >
                  Update
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      ))}
    </Container>
  </>
  );
};

export default CurrencyMaster;
