import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../layouts/ActionComp";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import ActionRenderer from "../masters/ActionRenderer";
import GridMaster from "../masters/GridMaster";
import { menuActions } from "../../store/menu-slice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { exportToExcel } from "./ExcelCreation_SOR";
import { getSiteObservationReportData, deleteSiteObservationRecord } from "../../services/siteobservationreport-service";
import { ServiceMasterAllData } from "../../services/common-master-service"
import { formatDateInDDMMYYYY } from "../../functions/gbcs-functions";
import AddEditSiteObservationReport from "./AddEditSiteObservationReport";
import { sortByPropertyInAscending, getUserInitials } from "../../utils/Utility";
import { useLocation } from 'react-router-dom';

const SiteObservationReport = (props) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  dispatch(menuActions.setMenu("Site Observation Report"));
  const MySwal = withReactContent(Swal);

  const [rowData, setRowData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [action, setAction] = useState("");
  const [showForm, setShowForm] = useState(false);;
  const [excelData, setExcelData] = useState([]);
  const [SelectedProject, setSelectedProject] = useState([]);
  const [resetFilter, setResetFilter] = useState(true);
  const [loading, setLoading] = useState(false);
  const [actionButtons, setActionButtons] = useState({});

  const AvatarCellRenderer = (params) => {
    let coloutIndex = params.node.rowIndex % 5;
    let initials = getUserInitials(params.value);
    return (
      <div className="user-name-initials">
        <div className={"user-initials user-initials-color" + coloutIndex}>
          <span>{initials}</span>
        </div>
        <div className="user-info">
          <span className="user-name">{params.value}</span>
        </div>
      </div>)
  }

  const columnDefs = [
    {
      headerName: "Issue Date",
      field: "IssueDate",
      minWidth: 150,
      tooltipField: "IssueDate",
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        return formatDateInDDMMYYYY(params.data.IssueDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return 0;
          }
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateCell = new Date(cellValue + " 00:00:00");

          if (dateCell < filterLocalDateAtMidnight) {
            return -1;
          } else if (dateCell > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      }
    },
    {
      headerName: "Raised By",
      field: "RaisedBy",
      tooltipField: "RaisedBy",
      minWidth: 150,
    },
    {
      headerName: "Raised By Name",
      field: "RaisedByName",
      minWidth: 130,
      tooltipField: "RaisedByName",
      cellRenderer: AvatarCellRenderer
    },
    {
      headerName: "Floor No.",
      field: "FloorNo",
      minWidth: 150,
      tooltipField: "FloorNo",
    },
    {
      headerName: "Area",
      field: "Area",
      minWidth: 150,
      tooltipField: "Area",
    },
    {
      headerName: "Service Name",
      field: "ServiceName",
      minWidth: 150,
      tooltipField: "ServiceName",
    },
    {
      headerName: "Service Type",
      field: "ServiceType",
      minWidth: 150,
      tooltipField: "ServiceType",
    },
    {
      headerName: "Checklist Category",
      field: "ChecklistCategory",
      minWidth: 150,
      tooltipField: "ChecklistCategory",
    },
    {
      headerName: "Equipment / Activity Name",
      field: "EquipmentActivityName",
      minWidth: 150,
      tooltipField: "EquipmentActivityName",
    },
    {
      headerName: "Equipment / Activity Tag (Serial No.)",
      field: "EquipmentActivityTagSrNo",
      minWidth: 150,
      tooltipField: "EquipmentActivityTagSrNo",
    },
    {
      headerName: "Equipment Activity Capacity",
      field: "EquipmentActivityCapacity",
      minWidth: 150,
      tooltipField: "EquipmentActivityCapacity",
    },
    {
      headerName: "Responsible Contractor OEM",
      field: "ResponsibleContractorOEM",
      minWidth: 150,
      tooltipField: "ResponsibleContractorOEM",
    },
    {
      headerName: "Issue Description",
      field: "IssueDescription",
      minWidth: 150,
      tooltipField: "IssueDescription",
    },
    {
      headerName: "Remarks",
      field: "Remarks",
      minWidth: 150,
      tooltipField: "Remarks",
    },
    {
      headerName: "Criticality",
      field: "Criticality",
      minWidth: 150,
      tooltipField: "Criticality",
    },
    {
      //date 
      headerName: "Anticipated Closure Date",
      field: "AnticipatedClosureDate",
      minWidth: 150,
      tooltipField: "AnticipatedClosureDate",
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        return formatDateInDDMMYYYY(params.data.AnticipatedClosureDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return 0;
          }
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateCell = new Date(cellValue + " 00:00:00");

          if (dateCell < filterLocalDateAtMidnight) {
            return -1;
          } else if (dateCell > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      }
    },
    {
      headerName: "Fire Integration Service",
      field: "FireIntegrationService",
      minWidth: 150,
      tooltipField: "FireIntegrationService"
    },
    {
      headerName: "IST Observations",
      field: "ISTObservations",
      minWidth: 150,
      tooltipField: "ISTObservations"
    },
    {
      headerName: "Corrective Action",
      field: "CorrectiveAction",
      minWidth: 150,
      tooltipField: "CorrectiveAction",
    },
    {
      headerName: "Corrected Status",
      field: "CorrectedStatus",
      minWidth: 150,
      tooltipField: "CorrectedStatus",
    },
    {
      headerName: "Verified By",
      field: "VerifiedBy",
      minWidth: 150,
      tooltipField: "VerifiedBy",
    },
    {
      headerName: "Verified By Name",
      field: "VerifiedByName",
      minWidth: 150,
      tooltipField: "VerifiedByName",
    },
    {
      //date
      headerName: "Verified Date",
      field: "VerifiedDate",
      minWidth: 150,
      tooltipField: "VerifiedDate",
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        return formatDateInDDMMYYYY(params.data.VerifiedDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return 0;
          }
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateCell = new Date(cellValue + " 00:00:00");

          if (cellValue === "" || dateCell < filterLocalDateAtMidnight) {
            return -1;
          } else if (dateCell > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      }
    },
    {
      headerName: "Uploads",
      field: "Uploads",
      minWidth: 150,
      tooltipField: "Uploads",
    },
    {
      field: "",
      width: 120,
      cellRenderer: function (params) {
        return (
          <ActionRenderer
            selectedData={params}
            handleViewEvent={(params) => handleView(params.data)}
            handleEditEvent={(params) => handleEdit(params.data)}
            handleDeleteEvent={(params) => handleDelete(params.data)}
            actionName={actionButtons}
          />
        );
      },
      sortable: false,
      filter: false,
      pinned: "right",
    },
  ];

  const headerName = (field) => {
    let name;
    switch (field) {
      case "IssueDate":
        name = "Issue Date";
        break;
      case "RaisedBy":
        name = "Raised By";
        break;
      case "RaisedByName":
        name = "Raised By Name";
        break;
      case "FloorNo":
        name = "Floor No";
        break;
      case "Area":
        name = "Area";
        break;
      case "ServiceName":
        name = "Service Name";
        break;
      case "ServiceType":
        name = "Service Type";
        break;
      case "ChecklistCategory":
        name = "Checklist Category";
        break;
      case "EquipmentActivityName":
        name = "Equipment / Activity Name";
        break;
      case "EquipmentActivityTagSrNo":
        name = "Equipment / Activity Tag (Serial No.)";
        break;
      case "EquipmentActivityCapacity":
        name = "Equipment Activity Capacity";
        break;
      case "ResponsibleContractorOEM":
        name = "Responsible Contractor OEM";
        break;
      case "IssueDescription":
        name = "Issue Description";
        break;
      case "Remarks":
        name = "Remarks";
        break;
      case "Criticality":
        name = "Criticality";
        break;
      case "AnticipatedClosureDate":
        name = "Anticipated Closure Date";
        break;
      case "FireIntegrationService":
        name = "Fire Integration Service";
        break;
      case "ISTObservations":
        name = "IST Observations";
        break;
      case "CorrectiveAction":
        name = "Corrective Action";
        break;
      case "CorrectedStatus":
        name = "Corrected Status";
        break;
      case "VerifiedBy":
        name = "Verified By";
        break;
      case "VerifiedByName":
        name = "Verified By Name";
        break;
      case "VerifiedDate":
        name = "Verified Date";
        break;
      case "Uploads":
        name = "Uploads";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fieldName = (header) => {
    let name;
    switch (header) {
      case "Issue Date":
        name = "IssueDate";
        break;
      case "Raised By":
        name = "RaisedBy";
        break;
      case "Raised By Name":
        name = "RaisedByName";
        break;
      case "Floor No":
        name = "FloorNo";
        break;
      case "Area":
        name = "Area";
        break;
      case "Service Name":
        name = "ServiceName";
        break;
      case "Service Type":
        name = "ServiceType";
        break;
      case "Checklist Category":
        name = "ChecklistCategory";
        break;
      case "Equipment / Activity Name":
        name = "EquipmentActivityName";
        break;
      case "Equipment / Activity Tag (Serial No.)":
        name = "EquipmentActivityTagSrNo";
        break;
      case "Equipment Activity Capacity":
        name = "EquipmentActivityCapacity";
        break;
      case "Responsible Contractor OEM":
        name = "ResponsibleContractorOEM";
        break;
      case "Issue Description":
        name = "IssueDescription";
        break;
      case "Remarks":
        name = "Remarks";
        break;
      case "Criticality":
        name = "Criticality";
        break;
      case "Anticipated Closure Date":
        name = "AnticipatedClosureDate";
        break;
      case "Fire Integration Service":
        name = "FireIntegrationService";
        break;
      case "IST Observations":
        name = "ISTObservations";
        break;
      case "Corrective Action":
        name = "CorrectiveAction";
        break;
      case "Corrected Status":
        name = "CorrectedStatus";
        break;
      case "Verified By":
        name = "VerifiedBy";
        break;
      case "Verified By Name":
        name = "VerifiedByName";
        break;
      case "Verified Date":
        name = "VerifiedDate";
        break;
      case "Uploads":
        name = "Uploads";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const handleView = async (row) => {
    setAction("View");
    setRowData(row);
    setShowForm(true);
  };

  const handleEdit = async (row) => {
    if (row.CorrectedStatus === "Yes" || row.CorrectedStatus === "NA") {
      MySwal.fire({
        text: "Corrected status of the record is " + row.CorrectedStatus + ". So, record can not be modified.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
    }
    else {
      setAction("Edit");
      setRowData(row);
      setShowForm(true);
    }
  };

  const handleGlobalExport = async () => {
    if (excelData.length > 0) {

      let filename = "SiteObservationReport";
      let excelDownoadData = [];

      for (let dt of excelData) {
        excelDownoadData.push({
          "ProjectName": dt.ProjectName,
          "IssueDate": formatDateInDDMMYYYY(dt.IssueDate),
          "RaisedBy": dt.RaisedBy,
          "RaisedByName": dt.RaisedByName,
          "FloorNo": dt.FloorNo,
          "Area": dt.Area,
          "ServiceName": dt.ServiceName,
          "ServiceType": dt.ServiceType,
          "ChecklistCategory": dt.ChecklistCategory,
          "EquipmentActivityName": dt.EquipmentActivityName,
          "EquipmentActivityTagSrNo": dt.EquipmentActivityTagSrNo,
          "EquipmentActivityCapacity": dt.EquipmentActivityCapacity,
          "ResponsibleContractorOEM": dt.ResponsibleContractorOEM,
          "IssueDescription": dt.IssueDescription,
          "Remarks": dt.Remarks,
          "Criticality": dt.Criticality,
          "AnticipatedClosureDate": formatDateInDDMMYYYY(dt.AnticipatedClosureDate),
          "FireIntegrationService": dt.FireIntegrationService,
          "ISTObservations": dt.ISTObservations,
          "CorrectiveAction": dt.CorrectiveAction,
          "CorrectedStatus": dt.CorrectedStatus,
          "VerifiedBy": dt.VerifiedBy,
          "VerifiedByName": dt.VerifiedByName,
          "VerifiedDate": formatDateInDDMMYYYY(dt.VerifiedDate),
          "Uploads": dt.Uploads,
          "CreatedByName": dt.CreatedByName,
          "CreatedOn": formatDateInDDMMYYYY(dt.CreatedOn),
          "ModifiedByName": dt.ModifiedByName,
          "ModifiedOn": formatDateInDDMMYYYY(dt.ModifiedOn),
        });
      }

      let colName = ["Project", "Issue Date", "Raised By", "Raised By Name", "Floor No.", "Area", "Service Name", "Service Type", "Checklist Category", "Equipment / Activity Name", "Equipment / Activity Tag (Serial No.)", "Equipment / Activity Capacity", " Responsible Contractor / OEM", "Issue Description", "Remarks", "Criticality", "Anticipated Closure Date", "Fire Integration Service", "IST Observations", "Corrective Action", "Corrected Status", "Verified By", " Verified By Name", "Verified Date", "Uploads", "Inserted By", "Inserted On", "Modified By", "Modified On"];
      let Fieldname = ["ProjectName", "IssueDate", "RaisedBy", "RaisedByName", "FloorNo", "Area", "ServiceName", "ServiceType", "ChecklistCategory", "EquipmentActivityName", "EquipmentActivityTagSrNo", "EquipmentActivityCapacity", "ResponsibleContractorOEM", "IssueDescription", "Remarks", "Criticality", "AnticipatedClosureDate", "FireIntegrationService", "ISTObservations", "CorrectiveAction", "CorrectedStatus", "VerifiedBy", "VerifiedByName", "VerifiedDate", "Uploads", "CreatedByName", "CreatedOn", "ModifiedByName", "ModifiedOn"];
      exportToExcel(filename, excelDownoadData, SelectedProject, colName, Fieldname);
    }
  };

  const handleDelete = async (params) => {
    let result;
    if (params.EntryFromFlag === "Manual" && params.CorrectedStatus === "No") {
      MySwal.fire({
        text: "Do you want to Delete the Record?",
        showDenyButton: true,
        cancelButtonColor: "#fff",
        confirmButtonText: "Delete",
        reverseButtons: true,
        customClass: "swal-confirmation",
        title: "Delete SOR"
      }).then(async (result2) => {
        if (result2.isConfirmed) {
          setLoading(true);
          let deleteBody = { PK: params.PK, SK: params.SK };
          result = deleteSiteObservationRecord(deleteBody);
          result.then((resp) => {
            if (resp.status) {
              setLoading(false);
              MySwal.fire({
                text: resp.message,
                imageUrl: successImage,
                customClass: "swal-success",
                didClose: () => {
                  getSiteObservationReport(params.ProjectCode);
                },
              });
            }
            else {
              setLoading(false);
              MySwal.fire({
                text: resp.message,
                imageUrl: errorImage,
                customClass: "swal-success",
              });
            }
          })
            .catch((error) => {
              setLoading(false);
              MySwal.fire({
                text: "Unable to process request.",
                imageUrl: errorImage,
                customClass: "swal-success",
              });
            })
            .finally(() => {
              getSiteObservationReport(params.ProjectCode);
            });
        }
      });
    }
    else if (params.CorrectedStatus === "Yes" || params.CorrectedStatus === "NA") {
      MySwal.fire({
        text: "Corrected status of selected record is " + params.CorrectedStatus + ". So, record can not be deleted.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
    }
    else {
      MySwal.fire({
        text: "Selected record is of checklist transaction. So, record can not be deleted.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
    }
  };

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  useEffect(() => {
    if (state) {
      SelectedPopUpProject(state.SelectedProject);
    }
  }, [state])

  const SelectedPopUpProject = async (data) => {
    getSiteObservationReport(data.ProjectCode);
    setSelectedProject(data);
    if (data.DeleteIndication !== "Active") {
      setActionButtons({
        ViewAction: true,
        EditAction: false,
        DownloadAction: false,
        DeleteAction: false,
      });
    }
    else {
      setActionButtons({});
    }
  };

  const getSiteObservationReport = async (projCode) => {
    setLoading(true);
    const result = await getSiteObservationReportData(projCode);
    if (result?.length > 0) {
      const serviceMaster = await ServiceMasterAllData();
      for (let dt of result) {
        let serviceData = serviceMaster.filter((item) => item.ServiceCode === dt.ServiceCode);
        if (serviceData?.length > 0) {
          dt.ServiceName = serviceData[0].ServiceName;
        }
      }
    }
    result?.sort(sortByPropertyInAscending("SeqNo"))
    setMasterData(result);
    setFilteredResult(result);
    setExcelData(result);
    setResetFilter(!resetFilter);
    setLoading(false);
  };

  return (<>
    {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
    <Container fluid className="main-container">
      <BreadcrumbComp />
      <ActionComp
        label={"Add New SOR"}
        isProjectTrue={true}
        exportEvent={() => handleGlobalExport()}
        handleAdd={handleAdd}
        setSelectedProject={SelectedPopUpProject}
        setparams={SelectedProject}
      />
      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        getDataEvent={(getFilteredData) =>
          setFilteredResulttemp(getFilteredData)
        }
        getFilterColLength={(getLength) => setFilterColLength(getLength)}
        setExcelData={setExcelData}
        handleView={handleView}
        handleEdit={handleEdit}
        openConfirmBox={handleDelete}
        resetind={resetFilter}
        actionName={actionButtons}
      />
      {showForm && (
        <AddEditSiteObservationReport
          show={showForm}
          action={action}
          rowdata={rowData}
          masterData={masterData}
          SelectedProject={SelectedProject}
          onHide={() => setShowForm(false)}
          getSiteObservationReport={getSiteObservationReport}
        />
      )}
    </Container>
  </>
  );
};

export default SiteObservationReport;
