import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import "../masters/AddEditRoleMaster.css"
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import seccessImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import DragDropRender from "../utils/DragDropRender";
import { DesignReviewTrackerStatusMaster, deletS3Attachment } from "../../services/common-master-service"
import { BiSelectMultiple } from "react-icons/bi";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import { RenderIf, getCurrentDateTime, getTodaysDate, formatDateDD_MM_YYYY } from "../../functions/gbcs-functions";
import {
    getDesignReviewTrackerData,
    addDesignReviewTracker,
    updateDesignReviewTracker,
    downloadAttachment
} from "../../services/designreviewtracker-service"
import { isNumber, isFutureDate } from "../../functions/validations";
import Validate from "../utils/Validation";
import axios from "axios";
import { sortByPropertyInDescending } from "../../utils/Utility";
import { AuthContext } from "../../contexts/AuthContext";
import Autocomplete from "../../utils/Autocomplete"

const AddEditDesignReviewTracker = (props) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userId, userName: context.userName }
    let action = props.action;
    const [StatusList, setStatusList] = useState([]);
    let currentDate = getTodaysDate();
    const [showForm, setShowForm] = useState(false);
    const [file, setFile] = useState([]);
    const [FilesArr, setFilesArr] = useState({ OldFile: "", NewFile: "" });
    const [StatusArr, setStatusArr] = useState(["Closed", "Hold", "Dropped"]);
    const [delFileFlg, setdelFileFlg] = useState(false);
    const [newFileFlg, setnewFileFlg] = useState(false);
    const [dofRef, setDofRef] = useState([]);
    const [revNo, setRevNo] = useState([]);
    const [pgNo, setPgNo] = useState([]);
    const [secNo, setSecNo] = useState([]);
    const [comment, setComment] = useState([]);
    const [loading, setLoading] = useState(true);

    const [DesignReviewTrackerObj, setDesignReviewTrackerObj] = useState(
        action === "Edit" || action === "View"
            ? props.rowdata
            : {
                IssueDate: currentDate,
                DocumentRefrence: "",
                RefrenceDocument: "",
                RevisionNo: "",
                PageNo: "",
                ServiceName: "",
                ServiceCode: "",
                SectionNo: "",
                CompanyName: "",
                CompanyCode: "",
                Criticality: "Non-Critical",
                GodrejComment: "",
                ConsultantResponse: "",
                LastUpdateClosureDate: currentDate,
                ClosingRemark: "",
                DRTStatus: "",
                FreezeInd: ""
            }
    );

    const [error, setError] = useState({
        IssueDate: false,
        DocumentRefrence: false,
        RevisionNo: false,
        PageNo: false,
        ServiceName: false,
        SectionNo: false,
        CompanyName: false,
        Criticality: false,
        GodrejComment: false,
        LastUpdateClosureDate: false,
        DRTStatus: false,
        ClosingRemark: false,
        ConsultantResponse: false
    });

    const [selectedStatus, setSelectedStatus] = useState({
        ConsultantResponseRequired: "",
        ClosingRemarksRequired: "",
        StatusInAlert: "NA"
    });

    const getStatusMasterData = async () => {
        const result = await DesignReviewTrackerStatusMaster();
        setStatusList(result);
    };

    useEffect(() => {
        setLoading(true);
        getStatusMasterData();
        if ((action === "Edit" || action === "View")) {
            FilesArr.OldFile = props.rowdata.RefrenceDocument;
            // setDesignReviewTrackerObj((prevState) => ({
            //     ...prevState,
            //     IssueDate: formatDateInYYYYMMDD(props.rowdata.IssueDate),
            //     LastUpdateClosureDate: formatDateInYYYYMMDD(props.rowdata.LastUpdateClosureDate)
            // }));
        }
        if ((action === "Edit" || action === "Add")) {
            let tmp = [];
            let tmpRev = [];
            let tmpPg = [];
            let tmpSec = [];
            let tmpCom = [];
            for (let dt of props.masterData) {
                tmp.push(dt.DocumentRefrence)
                tmpRev.push(dt.RevisionNo)
                tmpPg.push(dt.PageNo)
                tmpSec.push(dt.SectionNo)
                tmpCom.push(dt.GodrejComment)
            }
            // console.log("dofRef",tmp)
            setDofRef([...new Set(tmp)])
            setRevNo([...new Set(tmpRev)])
            setPgNo([...new Set(tmpPg)])
            setSecNo([...new Set(tmpSec)])
            setComment([...new Set(tmpCom)])
        }
        setLoading(false);
    }, []);

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
    });

    const showPopUp = (name, type, key, value) => {
        setPopUp({ name, type, key, value });
        setShowForm(true);
    };

    const setPopUpData = async (data) => {
        if (data.name === "Service Master") {
            setDesignReviewTrackerObj((prevState) => ({
                ...prevState,
                ServiceName: data.result.ServiceName,
                ServiceCode: data.result.ServiceCode
            }));
            setError((prevState) => ({
                ...prevState,
                ServiceName: false,
            }));
        }
        if (data.name === "Company Master") {
            setDesignReviewTrackerObj((prevState) => ({
                ...prevState,
                CompanyCode: data.result.CompanyCode,
                CompanyName: data.result.CompanyName,

            }));
            setError((prevState) => ({
                ...prevState,
                CompanyName: false,
            }));
        }
    }

    const validateDetails = () => {
        let errorMsg = {
            IssueDate: false,
            DocumentRefrence: false,
            RevisionNo: false,
            PageNo: false,
            ServiceName: false,
            SectionNo: false,
            CompanyName: false,
            Criticality: false,
            GodrejComment: false,
            LastUpdateClosureDate: false,
            DRTStatus: false,
            ClosingRemark: false,
            ConsultantResponse: false
        };
        let isError = false;
        let err = isFutureDate(DesignReviewTrackerObj.IssueDate);
        if (err.status) {
            isError = true;
            errorMsg.IssueDate = err;
        }

        err = isNumber(DesignReviewTrackerObj.PageNo.toString());
        if (err.status) {
            DesignReviewTrackerObj.PageNo = "";
            isError = true;
            errorMsg.PageNo = err;
        }

        if (DesignReviewTrackerObj.DocumentRefrence.trim() === "") {
            isError = true;
            DesignReviewTrackerObj.DocumentRefrence = "";
            errorMsg.DocumentRefrence = { status: isError, message: "Document Refrence is required" };
        }

        if (DesignReviewTrackerObj.RevisionNo.trim() === "") {
            isError = true;
            DesignReviewTrackerObj.RevisionNo = "";
            errorMsg.RevisionNo = { status: isError, message: "Revision No is required" };
        }

        if (DesignReviewTrackerObj.PageNo.toString().trim() === "") {
            isError = true;
            DesignReviewTrackerObj.PageNo = "";
            errorMsg.PageNo = { status: isError, message: "Page No is required" };
        }
        else if (DesignReviewTrackerObj.PageNo < 1) {
            errorMsg.PageNo = { status: true, message: "Zero not allowed in Page No." };
        }

        if (DesignReviewTrackerObj.ServiceName.trim() === "") {
            isError = true;
            DesignReviewTrackerObj.ServiceName = "";
            errorMsg.ServiceName = { status: isError, message: "Service Name is required" };
        }

        if (DesignReviewTrackerObj.SectionNo.trim() === "") {
            isError = true;
            DesignReviewTrackerObj.SectionNo = "";
            errorMsg.SectionNo = { status: isError, message: "Section No is required" };
        }

        if (DesignReviewTrackerObj.CompanyName.trim() === "") {
            isError = true;
            DesignReviewTrackerObj.CompanyName = "";
            errorMsg.CompanyName = { status: isError, message: "Company/Division is required" };
        }

        if (DesignReviewTrackerObj.Criticality.trim() === "") {
            isError = true;
            DesignReviewTrackerObj.Criticality = "";
            errorMsg.Criticality = { status: isError, message: "Criticality is required" };
        }

        if (DesignReviewTrackerObj.GodrejComment.trim() === "") {
            isError = true;
            DesignReviewTrackerObj.GodrejComment = "";
            errorMsg.GodrejComment = { status: isError, message: "Godrej Comment is required" };
        }

        if (DesignReviewTrackerObj.DRTStatus.trim() === "") {
            isError = true;
            DesignReviewTrackerObj.DRTStatus = "";
            errorMsg.DRTStatus = { status: isError, message: "Status is required" };
        }

        if (selectedStatus.ConsultantResponseRequired === "Yes" && DesignReviewTrackerObj.ConsultantResponse === "") {
            isError = true;
            errorMsg.ConsultantResponse = { status: isError, message: "Consultant Response is required" };
        }

        err = isFutureDate(DesignReviewTrackerObj.LastUpdateClosureDate);
        if (err.status) {
            isError = true;
            errorMsg.LastUpdateClosureDate = err;
        }
        if (new Date(DesignReviewTrackerObj.LastUpdateClosureDate) < new Date(DesignReviewTrackerObj.IssueDate)) {
            isError = true;
            errorMsg.LastUpdateClosureDate = { status: isError, message: "Last Update / Closure Date should be greater than issue date" };
        }

        if (selectedStatus.ClosingRemarksRequired === "Yes" && DesignReviewTrackerObj.ClosingRemark === "") {
            isError = true;
            errorMsg.ClosingRemark = { status: isError, message: "Closing remark is required" };
        }
        console.log("errorMsg", errorMsg)
        setError(errorMsg);

        return isError;
    }

    const handleChange = (name, value) => {
        if (name === "PageNo") {
            let pageNoVal = Number(value);

            setDesignReviewTrackerObj((prevState) => ({
                ...prevState,
                [name]: pageNoVal,
            }));

            let err = isNumber(pageNoVal.toString());
            if (err.status) {
                setError((prevState) => ({
                    ...prevState,
                    PageNo: err,
                }));
                return;
            }
            else {
                setError((prevState) => ({
                    ...prevState,
                    PageNo: { status: false, message: "" }
                }));
            }
            if (pageNoVal < 1) {
                setError((prevState) => ({
                    ...prevState,
                    PageNo: { status: true, message: "Zero not allowed in Page No." },
                }));
                return;
            }
            else {
                setError((prevState) => ({
                    ...prevState,
                    PageNo: { status: false, message: "" }
                }));
            }
        }
        else {
            setDesignReviewTrackerObj((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

    };


    const handleChangeDRTStatus = (e) => {
        const { name, value } = e.target;
        console.log(value)
        let freezeInd = e.target[e.target.selectedIndex].getAttribute("freeze-id");
        let respInd = e.target[e.target.selectedIndex].getAttribute("resp-id");
        let remarkInd = e.target[e.target.selectedIndex].getAttribute("remark-id");
        let alertInd = e.target[e.target.selectedIndex].getAttribute("alert-id");

        setDesignReviewTrackerObj((prevState) => ({
            ...prevState,
            [name]: value,
            FreezeInd: freezeInd
        }));
        setSelectedStatus({
            ConsultantResponseRequired: respInd,
            ClosingRemarksRequired: remarkInd,
            StatusInAlert: alertInd
        })
    }

    const handleReset = () => {
        setDesignReviewTrackerObj((prevState) => ({
            ...prevState,
            IssueDate: currentDate,
            DocumentRefrence: "",
            RefrenceDocument: "",
            RevisionNo: "",
            PageNo: "",
            ServiceName: "",
            ServiceCode: "",
            SectionNo: "",
            CompanyName: "",
            CompanyCode: "",
            Criticality: "Non-Critical",
            GodrejComment: "",
            ConsultantResponse: "",
            LastUpdateClosureDate: currentDate,
            ClosingRemark: "",
            DRTStatus: "",
            FreezeInd: ""
        }));
        setError({
            IssueDate: false,
            DocumentRefrence: false,
            RevisionNo: false,
            PageNo: false,
            ServiceName: false,
            SectionNo: false,
            CompanyName: false,
            Criticality: false,
            GodrejComment: false,
            LastUpdateClosureDate: false,
            DRTStatus: false,
            ClosingRemark: false,
            ConsultantResponse: false
        });
        FilesArr.OldFile = "";
        FilesArr.NewFile = "";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (action == "View") {
            props.onHide();
        }
        else {
            let result;

            if (!validateDetails()) {
                setLoading(true);
                const result1 = await getDesignReviewTrackerData(props.SelectedProject.ProjectCode);
                console.log(result1)
                if (result1.length > 0 && result1.filter((item) =>
                    (action === "Add" || (action === "Edit" && item.SK.toLowerCase() !== DesignReviewTrackerObj.SK.toLowerCase()))
                    && item.DocumentRefrence.toLowerCase() === DesignReviewTrackerObj.DocumentRefrence.toLowerCase()
                    && item.RevisionNo.toLowerCase() === DesignReviewTrackerObj.RevisionNo.toLowerCase()
                    && item.PageNo === DesignReviewTrackerObj.PageNo
                    && item.ServiceCode.toLowerCase() === DesignReviewTrackerObj.ServiceCode.toLowerCase()
                    && item.SectionNo.toLowerCase() === DesignReviewTrackerObj.SectionNo.toLowerCase()
                    && item.GodrejComment.toLowerCase() === DesignReviewTrackerObj.GodrejComment.toLowerCase()).length > 0) {
                    setLoading(false);
                    MySwal.fire({
                        text: "Document is already exists.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
                setLoading(false);
                let confirmBoxText = "";
                if (action === "Add") {
                    confirmBoxText = "Are you sure, so you want to add new Document?";
                }
                else if (action == "Edit") {
                    confirmBoxText = "Are you sure, so you want to update the Document?";
                }
                if (selectedStatus.StatusInAlert !== "NA") {
                    confirmBoxText = "Do you want to " + selectedStatus.StatusInAlert + " the Record?";
                }
                MySwal.fire({
                    text: confirmBoxText,
                    showDenyButton: true,
                    cancelButtonColor: "#fff",
                    confirmButtonText: "Yes",
                    reverseButtons: true,
                    customClass: "swal-confirmation",
                    title: action + " Design Review Tracker"
                }).then(async (result2) => {
                    if (result2.isConfirmed) {
                        setLoading(true);
                        let todayDate = getCurrentDateTime("-");
                        let tempObj = Object.assign({}, DesignReviewTrackerObj);
                        delete tempObj.ServiceName;
                        if (action === "Add") {
                            let maxSeqNo = 0;
                            if (result1?.length > 0) {
                                let sortedArr = result1.sort(sortByPropertyInDescending("SeqNo"));
                                maxSeqNo = sortedArr[0].SeqNo
                            }
                            console.log(maxSeqNo);
                            let NewMaxSeqNo = parseInt(maxSeqNo) + 1;
                            tempObj.ProjectCode = props.SelectedProject.ProjectCode;
                            tempObj.ProjectName = props.SelectedProject.ProjectName;
                            // DesignReviewTrackerObj.PageNo = Number(DesignReviewTrackerObj.PageNo);
                            tempObj.CreatedBy = userDetails.email;
                            tempObj.CreatedByName = userDetails.userName;
                            tempObj.CreatedOn = todayDate;
                            tempObj.ModifiedBy = userDetails.email;
                            tempObj.ModifiedByName = userDetails.userName;
                            tempObj.ModifiedOn = todayDate;
                            tempObj.PK = "ProjectCode#" + props.SelectedProject.ProjectCode;
                            tempObj.SK = "DesignReviewTracker#SeqNo#" + NewMaxSeqNo;
                            tempObj.SeqNo = NewMaxSeqNo;
                            result = await addDesignReviewTracker(tempObj);
                        }
                        else if (action == "Edit") {
                            tempObj.ModifiedBy = userDetails.email;
                            tempObj.ModifiedByName = userDetails.userName;
                            tempObj.ModifiedOn = todayDate;
                            result = await updateDesignReviewTracker(tempObj, newFileFlg);
                        }
                        if (result.status) {
                            if (FilesArr.NewFile != "") {
                                let fileUploadMsg = uploadFiles(result.signedURL);//url generated
                            }
                            if (delFileFlg) {
                                let fileObj = { fileName: "DesignReviewTracker/" + props.rowdata.ProjectCode + "/" + props.rowdata.SeqNo + "/" + FilesArr.OldFile };
                                let result3 = deletS3Attachment(fileObj);
                            }
                            setLoading(false);
                            MySwal.fire({
                                text: result.message,
                                imageUrl: seccessImage,
                                customClass: "swal-success",
                                didClose: () => {
                                    props.onHide();
                                    props.getDesignReviewTracker(props.SelectedProject.ProjectCode)
                                },
                            });
                        }
                        else {
                            setLoading(false);
                            MySwal.fire({
                                text: "Unable to Process Request",
                                imageUrl: errorImage,
                                customClass: "swal-success"
                            });
                        }
                    }
                });
            }
        }
    }

    const uploadFiles = async (signedURL) => {
        try {
            if (signedURL) {
                await axios.put(signedURL, file);
            }
            return "";
        } catch (error) {
            return " Error in file upload.";
        }
    }

    const handleDownloadDocument = async (fileName) => {
        if (FilesArr.OldFile !== "") {
            downloadAttachment(
                "DesignReviewTracker/" + props.rowdata.ProjectCode + "/" + props.rowdata.SeqNo + "/" + fileName,
                fileName
            ).catch((err) => {
                MySwal.fire({
                    text: "Error in Fetching file.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            });
        }
    }

    const handleDeleteDocument = async (file) => {
        if (action == "View") {
            return;
        }
        FilesArr.NewFile = "";
        setDesignReviewTrackerObj((prevState) => ({
            ...prevState,
            RefrenceDocument: ""
        }));
        if (action == "Edit") {
            setdelFileFlg(true);
        }
    }

    const handleChangeStatus = async (files) => {
        let isError = false;
        const newFile = files[0];
        let fileError = Validate(newFile, "allFormats", 26214400);

        if (fileError === "") {
            setFile(newFile);
            setnewFileFlg(true);
            let fileName = files[0].name;
            setDesignReviewTrackerObj((prevState) => ({
                ...prevState,
                RefrenceDocument: fileName
            }));
            FilesArr.NewFile = fileName;
        } else {
            MySwal.fire({
                text: fileError,
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        return isError;
    };

    const handleChangeDate = (e) => {
        const { name, value } = e.target;

        setDesignReviewTrackerObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        let error = { status: false, message: "" }
        error = isFutureDate(value);
        if (error.status) {
            setError((prevState) => ({
                ...prevState,
                [name]: error
            }));
            return;
        }
        else {
            setError((prevState) => ({
                ...prevState,
                [name]: error
            }));
        }
        if (name === "LastUpdateClosureDate") {
            if (new Date(value) < new Date(DesignReviewTrackerObj.IssueDate)) {
                error = { status: true, message: "Last Update / Closure Date should be greater than issue date." }
                setError((prevState) => ({
                    ...prevState,
                    LastUpdateClosureDate: error
                }));
                return;
            }
            else {
                setError((prevState) => ({
                    ...prevState,
                    [name]: error
                }));
            }
        }
    };

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Modal
                {...props}
                size="lg"
                backdrop="static" centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {action} Design Review Tracker
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="designreviewtracker"
                        name="designreviewtracker"
                        // onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <Row >
                            <Col lg={4} md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="IssueDate"
                                        type="date"
                                        name="IssueDate"
                                        placeholder=" "
                                        required
                                        value={DesignReviewTrackerObj.IssueDate}
                                        onChange={handleChangeDate}
                                        readOnly={action === "Edit" || action === "View" ? true : false}
                                    />
                                    <Form.Label className="frm-label" for="IssueDate">Issue Date</Form.Label>
                                    {error.IssueDate.status && (
                                        <Form.Text className="text-danger">
                                            {error.IssueDate.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={8} md={8} xs={12}>
                                <Form.Group className="g-input">
                                    <Autocomplete
                                        id="DocumentRefrence"
                                        type="text"
                                        name="DocumentRefrence"
                                        maxLength="200"
                                        placeholder=" "
                                        required
                                        value={DesignReviewTrackerObj.DocumentRefrence}
                                        onChangeEv={handleChange}
                                        readonly={action === "View" ? true : false}
                                        options={dofRef} fieldName={"Document Refrence"} />
                                    {error.DocumentRefrence.status && (
                                        <Form.Text className="text-danger">
                                            {error.DocumentRefrence.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} md={2} xs={12}>
                                <Form.Group className="g-input">
                                    <Autocomplete
                                        id="RevisionNo"
                                        type="text"
                                        name="RevisionNo"
                                        maxLength="30"
                                        placeholder=" "
                                        required
                                        value={DesignReviewTrackerObj.RevisionNo}
                                        onChangeEv={handleChange}
                                        readonly={action === "View" ? true : false}
                                        options={revNo} fieldName={"Revision No."} />
                                    {error.RevisionNo.status && (
                                        <Form.Text className="text-danger">
                                            {error.RevisionNo.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={2} md={2} xs={12}>
                                <Form.Group className="g-input">
                                    <Autocomplete
                                        id="PageNo"
                                        type="Number"
                                        name="PageNo"
                                        maxLength="20"
                                        placeholder=" "
                                        required
                                        value={DesignReviewTrackerObj.PageNo}
                                        onChangeEv={handleChange}
                                        readonly={action === "View" ? true : false}
                                        options={pgNo} fieldName={"Page No."}
                                    />
                                    {error.PageNo.status && (
                                        <Form.Text className="text-danger">
                                            {error.PageNo.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            id="ServiceName"
                                            type="text"
                                            name="ServiceName"
                                            placeholder=" "
                                            required
                                            value={DesignReviewTrackerObj.ServiceName}
                                            onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            readOnly />
                                        <Form.Label className="frm-label" for="ServiceName">Service Name</Form.Label>
                                        {action !== "View" &&
                                            <BiSelectMultiple
                                                size={20}
                                                onClick={() => showPopUp("Service Master", "Single", "ServiceName", DesignReviewTrackerObj.ServiceName)}
                                                color={"gray"}
                                                style={{ marginLeft: "-2rem" }}
                                                backdrop="static"
                                            />
                                        }
                                    </div>
                                    {error.ServiceName.status && (
                                        <Form.Text className="text-danger">
                                            {error.ServiceName.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Autocomplete
                                        id="SectionNo"
                                        type="text"
                                        name="SectionNo"
                                        maxLength="20"
                                        placeholder=" "
                                        required
                                        value={DesignReviewTrackerObj.SectionNo}
                                        onChangeEv={handleChange}
                                        readonly={action === "View" ? true : false}
                                        options={secNo} fieldName={"Section No."} />
                                    {error.SectionNo.status && (
                                        <Form.Text className="text-danger">
                                            {error.SectionNo.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            id="CompanyName"
                                            type="text"
                                            name="CompanyName"
                                            maxLength="100"
                                            placeholder=" "
                                            required
                                            value={DesignReviewTrackerObj.CompanyName}
                                            // onBlur={(e) => { checkValidData(e) }}
                                            onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            readOnly
                                        />
                                        <Form.Label className="frm-label" for="CompanyName">Company/Division</Form.Label>
                                        {action !== "View" &&
                                            <BiSelectMultiple
                                                size={20}
                                                onClick={() => showPopUp("Company Master", "Single", "CompanyName", DesignReviewTrackerObj.CompanyName)}
                                                color={"gray"}
                                                style={{ marginLeft: "-2rem" }}
                                                backdrop="static"
                                            />
                                        }
                                    </div>
                                    {error.CompanyName && (
                                        <Form.Text className="text-danger">
                                            {error.CompanyName.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                                <Form.Group className="g-select">
                                    <Form.Select
                                        id="Criticality"
                                        name="Criticality"
                                        type="text"
                                        placeholder=" "
                                        required
                                        value={DesignReviewTrackerObj.Criticality}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        disabled={action === "View"}
                                    >
                                        <option value="Critical" >Critical</option>
                                        <option value="Non-Critical" >Non-Critical</option>
                                    </Form.Select>
                                    <Form.Label className="frm-label" for="Criticality">Criticality</Form.Label>
                                    {error.Criticality.status && (
                                        <Form.Text className="text-danger">
                                            {error.Criticality.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} xs={12}>
                                <Form.Group className="g-input">
                                    <Autocomplete
                                        id="GodrejComment"
                                        as="textarea"
                                        rows={2}
                                        type="textarea"
                                        name="GodrejComment"
                                        placeholder=" "
                                        maxLength="1000"
                                        required
                                        value={DesignReviewTrackerObj.GodrejComment}
                                        onChangeEv={handleChange}
                                        readonly={action === "View" ? true : false}
                                        options={comment} fieldName={"Godrej Comment"}
                                    />
                                    {error.GodrejComment.status && (
                                        <Form.Text className="text-danger">
                                            {error.GodrejComment.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="ConsultantResponse"
                                        as="textarea"
                                        rows={2}
                                        type="textarea"
                                        name="ConsultantResponse"
                                        maxLength="1000"
                                        placeholder=" "
                                        value={DesignReviewTrackerObj.ConsultantResponse}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        readOnly={action === "View" ? true : false}
                                        required={selectedStatus.ConsultantResponseRequired === "Yes" ? true : false}
                                    />
                                    <Form.Label className="frm-label" for="ConsultantResponse">Consultant Response <span className="italicFont"> (optional)</span></Form.Label>
                                    {error.ConsultantResponse.status && (
                                        <Form.Text className="text-danger">
                                            {error.ConsultantResponse.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} xs={12} className="pb-3">
                                <DragDropRender
                                    val={DesignReviewTrackerObj.RefrenceDocument}
                                    handleChangeEvent={(params) => handleChangeStatus(params)}
                                    handleDeleteDocEvent={(params) => handleDeleteDocument(params)}
                                    handleDownloadDocEvent={(params) => handleDownloadDocument(params)}
                                    action={action}
                                    supportedFilesLabel={"Supports: PDF, doc, docx, xls, xlsx, xlsb, jpg, jpeg, png, all PPT formats, all AutoCAD drawing formats Max File Size - upto 25MB"}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="LastUpdateClosureDate"
                                        type="date"
                                        name="LastUpdateClosureDate"
                                        placeholder=" "
                                        required
                                        value={DesignReviewTrackerObj.LastUpdateClosureDate}
                                        onChange={handleChangeDate}
                                        readOnly={action === "View" ? true : false}
                                    />
                                    <Form.Label className="frm-label" for="LastUpdateClosureDate">Last Update/Closure Date</Form.Label>
                                    {error.LastUpdateClosureDate.status && (
                                        <Form.Text className="text-danger">
                                            {error.LastUpdateClosureDate.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                                <Form.Group className="g-select">
                                    <Form.Select
                                        id="DRTStatus"
                                        name="DRTStatus"
                                        type="text"
                                        placeholder=" "
                                        required
                                        value={DesignReviewTrackerObj.DRTStatus}
                                        onChange={(e) => { handleChangeDRTStatus(e) }}
                                        disabled={action === "View" ? true : false}
                                    >
                                        <option value="" >Select</option>
                                        {StatusList?.map((item) => {
                                            return (<option key={item.Status} value={item.Status} freeze-id={item.FreezeIndication} resp-id={item.ConsultantResponseRequired} remark-id={item.ClosingRemarksRequired} alert-id={item.StatusInAlert}>{item.Status}</option>)
                                        })
                                        }
                                    </Form.Select>
                                    <Form.Label className="frm-label" for="DRTStatus">Status</Form.Label>
                                    {error.DRTStatus.status && (
                                        <Form.Text className="text-danger">
                                            {error.DRTStatus.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="ClosingRemark"
                                        as="textarea"
                                        rows={2}
                                        type="textarea"
                                        name="ClosingRemark"
                                        placeholder=" "
                                        maxLength="1000"
                                        value={DesignReviewTrackerObj.ClosingRemark}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        readOnly={action === "View" ? true : false}
                                        required={selectedStatus.ClosingRemarksRequired === "Yes" ? true : false}
                                    />
                                    <Form.Label className="frm-label" for="ClosingRemark">Closing Remark <span className="italicFont"> (optional)</span></Form.Label>
                                    {error.ClosingRemark.status && (
                                        <Form.Text className="text-danger">
                                            {error.ClosingRemark.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={9} xs={6}>
                                {action === "View" || action === "Edit" ? null :
                                    <Button className="semiBoldToggleButton"
                                        variant="outline-success"
                                        onClick={handleReset}
                                    >
                                        Reset
                                    </Button>
                                }
                            </Col>
                            <Col md={3} xs={6}>
                                {action === "View" ?
                                    <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={handleSubmit}>
                                        Done
                                    </Button>
                                    :
                                    <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={handleSubmit}>
                                        {action === "Edit" ? "Update" : "Add"}
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                    <br />
                </Modal.Body>
                <RenderIf isTrue={showForm}>
                    <SingleValuePopUp
                        show={showForm}
                        onHide={() => setShowForm(false)}
                        setSelected={setPopUpData}
                        mastername={popUp.name}
                        paramkey={popUp.key}
                        paramvalue={popUp.value}
                        backdrop="static"
                    />
                </RenderIf>
            </Modal>
        </>
    );
}

export default AddEditDesignReviewTracker;
