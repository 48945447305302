import React, { useEffect, useState, useRef, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../layouts/ActionComp.jsx";
import BreadcrumbComp from "../layouts/BreadcrumbComp.jsx";
import StatusRenderer from "../masters/StatusRenderer.jsx";
import GridMaster from "../masters/GridMaster.jsx";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice.js";
import { exportToExcel } from "../utils/ExcelCreation";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getProjectServiceEqui, getProjectServiceByEquipTag, AddSrvcEqpmntResponsive } from "../../services/project-service.js";
import { getUserInitials } from "../../utils/Utility.js";
import "./ProjectChecklist.css";
import { getSelectedEquipDetails } from "../../services/equipmentactivity-master-service.js";
import { ServiceMasterData } from "../../services/common-master-service.js";
import { searchActions } from "../../store/grid-slice.js";
import { FireIntegrationStatusRenderer } from "./FireIntegrationStatusRenderer.jsx";
import { getProjTranChklstofEquip } from "../../services/project-transaction-service";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { getSorFireData } from "../../services/siteobservationreport-service.js";
import { AuthContext } from "../../contexts/AuthContext";
import { formatDateInDDMMYYYY, getCurrentDateTime } from "../../functions/gbcs-functions";
import ActionRenderer from "../masters/ActionRenderer";

const FireIntegrationVerification = (props) => {
    const dispatch = useDispatch();
    dispatch(menuActions.setMenu("Fire Integration Verification"));
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, dataControl: context.dataControl, userId: context.userId }
    const { accessibleMenus } = useContext(AuthContext);
    const menuAccess = accessibleMenus.filter(item => item.SessionName === "Fire Integration Verification");
    const gridRef = useRef();
    const MySwal = withReactContent(Swal);
    const [filteredResult, setFilteredResult] = useState([]);
    const [filteredResulttemp, setFilteredResulttemp] = useState([]);
    const [filterColLength, setFilterColLength] = useState(0);
    const [excelData, setExcelData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [SelectedProject, setSelectedProject] = useState({});
    const [actionButtons, setActionButtons] = useState({});

    const AvatarCellRenderer = (params) => {
        let colourIndex = params.node.rowIndex % 5;
        let initials = getUserInitials(params.value);
        return (
            initials &&
            <div className="user-name-initials">
                <div className={"user-initials user-initials-color" + colourIndex}>
                    <span>{initials}</span>
                </div>
                <div className="user-info">
                    <span className="user-name">{params.value}</span>
                </div>
            </div>)
    }

    useEffect(() => {
        dispatch(searchActions.setSearch(""));
    }, []);

    const columnDefs = [
        {
            headerName: "Sr.No.",
            field: "SrNo",
            valueFormatter: (params) => {
                return params.node.rowIndex + 1;
            },
            valueGetter: params => {
                return params.node.rowIndex + 1;
            },
            minWidth: 92,
            width: 92,
            resizable: false,
            flex: 0,
            filter: false,
            comparator: (text, cellValue) => {
                console.log("text, cellValue", text, cellValue)
                if (parseFloat(cellValue) > parseFloat(text)) {
                    return -1;
                } else if (parseFloat(cellValue) < parseFloat(text)) {
                    return 1;
                }
                return 0;
            }
        },
        {
            headerName: "Project Equipment / Activity Status",
            field: "DeleteIndication",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            minWidth: 145,
            width: 145,
            resizable: false,
            tooltipField: "DeleteIndication",
            cellRenderer: StatusRenderer,
        },
        {
            headerName: "Service Name",
            field: "ServiceName",
            minWidth: 122,
            width: 122,
            flex: 0,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            tooltipField: "ServiceName",
        },
        {
            headerName: "Service Type",
            field: "ServiceType",
            tooltipField: "ServiceType",
            minWidth: 120,
            width: 120,
            flex: 0,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        {
            headerName: "Equipment / Activity Name",
            field: "EquipmentName",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            minWidth: 132,
            tooltipField: "EquipmentName",
        },
        {
            headerName: "Equipment / Activity Tag",
            field: "EquipmentTag",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            minWidth: 132,
            tooltipField: "EquipmentTag",
            sortable: true
        },
        {
            headerName: "Contractor / Vendor",
            field: "Contractor",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            minWidth: 162,
            width: 160,
            cellClass: "agCellCss",
            tooltipField: "Contractor",
            resizable: false,
            cellRenderer: AvatarCellRenderer
        },
        {
            headerName: "Fire Integration Service",
            field: "FireIntegrationFlag",
            minWidth: 200,
            width: 200,
            tooltipField: "",
            resizable: false,
            pinned: "right",
            filter: false,
            sortable: false,
            enableCellChangeFlash: false,
            cellRenderer: function (params) {
                return <FireIntegrationStatusRenderer selectedData={params} handleradiochange={handleFireChange} />
            }
        },
        {
            headerName: "",
            field: "",
            minWidth: 80,
            width: 80,
            tooltipField: "",
            resizable: false,
            pinned: "right",
            cellClass: "agCellCss",
            filter: false,
            sortable: false,
            cellRenderer: function (params) {
                return <ActionRenderer selectedData={params.data} actionName={actionButtons} handleSaveEvent={handleSaveFireInt} />
            }
        }
    ];

    const headerName = (field) => {
        let name;
        switch (field) {
            case "SrNo":
                name = "Sr.No.";
                break;
            case "DeleteIndication":
                name = "Project Equipment / Activity Status";
                break;
            case "ServiceName":
                name = "Service Name";
                break;
            case "ServiceType":
                name = "Service Type";
                break;
            case "EquipmentName":
                name = "Equipment / Activity Name";
                break;
            case "EquipmentTag":
                name = "Equipment/ Activity Tag";
                break;
            case "Contractor":
                name = "Contractor / Vendor";
                break;
            case "FireIntegrationFlag":
                name = "Fire Integration Service";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Sr.No.":
                name = "SrNo";
                break;
            case "Project Equipment / Activity Status":
                name = "DeleteIndication";
                break;
            case "Service Name":
                name = "ServiceName";
                break;
            case "Service Type":
                name = "ServiceType";
                break;
            case "Equipment / Activity Name":
                name = "EquipmentName";
                break;
            case "Equipment/ Activity Tag":
                name = "EquipmentTag";
                break;
            case "Contractor / Vendor":
                name = "Contractor";
                break;
            case "Fire Integration Service":
                name = "FireIntegrationFlag";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const handleFireChange = async (params, e) => {
        console.log(params, e.target.value);
        if (window.innerWidth > 768) {
            params.node.setDataValue(params.colDef.field, e.target.value)
        }
        else {
            let ids = [];
            ids = [...filteredResult];
            ids[params.node.rowIndex][params.colDef.field] = e.target.value;
            setFilteredResult(ids);
        }
    }

    const handleSaveFireInt = async (dt) => {
        console.log("handleSaveFireInt", dt);
        if (dt.DeleteIndication === "Inactive") {
            MySwal.fire({
                text: "Equipment Tag is Inactive.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        else if (dt.ChkFlag && dt.ChkFlagNA) {
            MySwal.fire({
                text: "Fire Integration status is already submitted.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        else {
            setLoading(true);
            let GlobalArr = {};
            let checklistErrorFlag = false;
            let sorErrorFlag = false;

            if (dt.FireIntegrationFlag !== "No") {
                if (dt.FATEquipApplicableChecklist !== undefined && dt.FATEquipApplicableChecklist !== "") {
                    let temp = dt.FATEquipApplicableChecklist.split(",");
                    GlobalArr.FAT = temp;
                }
                if (dt.SATEquipApplicableChecklist !== undefined && dt.SATEquipApplicableChecklist !== "") {
                    let temp = dt.SATEquipApplicableChecklist.split(",");
                    GlobalArr.SAT = temp;
                }

                for (let dt1 of Object.keys(GlobalArr)) {
                    for (let item of GlobalArr[dt1]) {
                        let result = await getProjTranChklstofEquip(dt1, dt.ProjectCode, item, dt.EquipmentTag);
                        //if checklist is filled but not submitted, set error
                        if (result?.length > 0) {
                            if (!result[0].IsFreeze) {
                                checklistErrorFlag = true;
                                break;
                            }
                        }
                        else {
                            //if checklist is not filled, set error
                            checklistErrorFlag = true;
                            break;
                        }
                    }
                }

                //Check all fire related lines are closed
                let sorFireResult = await getSorFireData(dt.ProjectCode, dt.EquipmentTag, "Yes");
                if (sorFireResult?.length > 0) {
                    for (let dt of sorFireResult) {
                        if (dt.CorrectedStatus === "No") {
                            sorErrorFlag = true;
                            break;
                        }
                    }
                }

                if (!checklistErrorFlag && !sorErrorFlag) {
                    let result = await getProjectServiceByEquipTag(dt.PK, dt.SK);
                    if (result[0].FireIntegrationFlag !== undefined && (result[0].FireIntegrationFlag === "Yes" || result[0].FireIntegrationFlag === "NA")) {
                        setLoading(false);
                        MySwal.fire({
                            text: "Fire Integration status is already submitted by other user.",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                        return;
                    }
                    result[0].ModifiedBy = userDetails.userId;
                    result[0].ModifiedByName = userDetails.userName;
                    result[0].ModifiedOn = getCurrentDateTime("-");
                    result[0].FireIntegrationFlag = dt.FireIntegrationFlag;
                    result[0].FireIntegrationFlagInsertedBy = userDetails.userId;
                    result[0].FireIntegrationFlagInsertedByName = userDetails.userName;
                    result[0].FireIntegrationFlagInsertedOn = getCurrentDateTime("-");
                    let res = await AddSrvcEqpmntResponsive(result[0]);
                    SelectedPopUpProject(dt);
                    if (res.status) {
                        setLoading(false);
                        MySwal.fire({
                            text: "Fire Integration Verification details updated successfully.",
                            imageUrl: successImage,
                            customClass: "swal-success"
                        });
                    }
                }
                else {
                    setLoading(false);
                    if (checklistErrorFlag) {
                        MySwal.fire({
                            text: "Project transaction checklist is not submitted ",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                    else {
                        MySwal.fire({
                            text: "SOR corrected status is No for selected equipment tag.",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                    return;
                }
            }
            else {
                setLoading(false);
                MySwal.fire({
                    text: "Kindly select fire integration service Yes or NA before saving the data.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
        }
    }

    const handleGlobalExport = async () => {
        if (excelData.length > 0) {
            let filename = "Fire Integration Verification";
            let excelDownoadData = [];
            let cnt = 1;
            for (let dt of excelData) {
                excelDownoadData.push({
                    "SrNo": cnt,
                    "ProjectEquipmentActivityStatus": dt.DeleteIndication,
                    "ServiceName": dt.ServiceName,
                    "ServiceType": dt.ServiceType,
                    "EquipementActivityName": dt.EquipmentName,
                    "EquipmentActivityTag": dt.EquipmentTag,
                    "ContractorVendor": dt.Contractor,
                    "FireIntegrationFlag": dt.FireIntegrationFlag,
                    "CreatedBy": dt.FireIntegrationFlagInsertedByName,
                    "CreatedOn": formatDateInDDMMYYYY(dt.FireIntegrationFlagInsertedOn),
                    "ModifiedBy": dt.FireIntegrationFlagInsertedByName,
                    "ModifiedOn": formatDateInDDMMYYYY(dt.FireIntegrationFlagInsertedOn)
                });
                cnt++;
            }
            let colName = ["Sr.No.", "Project Equipment / Activity Status", "Service Name", "Service Type", "Equipement / Activity Name", "Equipement / Activity Tag", "Contractor / Vendor", "Fire Integration Service", "Inserted By", "Inserted On", "Modified By", "Modified On"];
            let Fieldname = ["SrNo", "ProjectEquipmentActivityStatus", "ServiceName", "ServiceType", "EquipementActivityName", "EquipmentActivityTag", "ContractorVendor", "FireIntegrationFlag", "CreatedBy", "CreatedOn", "ModifiedBy", "ModifiedOn"];
            exportToExcel(filename, "Data", excelDownoadData, "A1:L1", colName, Fieldname);
        }
    };

    const SelectedPopUpProject = async (data) => {
        setLoading(true);
        setSelectedProject({
            ProjectCode: data.ProjectCode,
            ProjectName: data.ProjectName,
            DeleteIndication: data.DeleteIndication
        });

        if (data.DeleteIndication !== "Active") {
            setActionButtons({
                ViewAction: false,
                EditAction: false,
                DownloadAction: false,
                DeleteAction: false,
                SaveAction: false
            });
        }
        else {
            setActionButtons({
                ViewAction: false,
                EditAction: false,
                DownloadAction: false,
                DeleteAction: false,
                SaveAction: true
            });
        }
        let result = await getProjectServiceEqui(data.ProjectCode);
        let arr = [];
        for (let element of result) {
            if (arr.indexOf(element.EquipmentCode) === -1) {
                arr.push(element.EquipmentCode)
            }
        }

        const [serviceResult, equipMasterResult] = await Promise.all([
            ServiceMasterData(),
            getSelectedEquipDetails(arr)
        ]);

        for (let element of result) {
            let temp = equipMasterResult?.find(x => x.EquipmentCode === element.EquipmentCode);
            element["ServiceType"] = temp?.ServiceType;

            let serviceFound = serviceResult?.find(x => x.ServiceCode === element.ServiceCode);
            element["ServiceName"] = serviceFound.ServiceName;

            let tempFireFlag = "";
            if (!element["FireIntegrationFlag"]) {
                tempFireFlag = "No";
                element["ChkFlag"] = false;
                element["ChkFlagNA"] = false;
            }
            if (element["FireIntegrationFlag"] === "Yes" || element["FireIntegrationFlag"] === "NA") {
                tempFireFlag = element["FireIntegrationFlag"];
                element["ChkFlag"] = true;
                element["ChkFlagNA"] = true;
            }
            if (element.DeleteIndication === "Inactive") {
                tempFireFlag = "No";
                element["ChkFlag"] = true;
                element["ChkFlagNA"] = true;
            }
            if (!temp.FireIntegrationService && !element["FireIntegrationFlag"]) {
                tempFireFlag = "NA";
                element["ChkFlag"] = true;
                element["ChkFlagNA"] = false;
            }
            element["FireIntegrationFlag"] = tempFireFlag;
            element["FATEquipApplicableChecklist"] = temp?.FATEquipApplicableChecklist;
            element["SATEquipApplicableChecklist"] = temp?.SATEquipApplicableChecklist;
        }
        setFilteredResult(result);
        setExcelData(result);
        setLoading(false);
    }

    const handleAdd = (dt) => {
        SelectedPopUpProject(dt);
    };

    const resetData = () => {
        dispatch(searchActions.setSearch(""));
        setFilteredResult([]);
        setExcelData([]);
    }
    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="main-container">
                <BreadcrumbComp />
                <>
                    <ActionComp label={"Submit"}
                        isProjectTrue={true}
                        exportEvent={() => handleGlobalExport()}
                        addshowflag={false}
                        setparams={SelectedProject}
                        isDataShowOnButtonClick={true}
                        handleButtonClick={handleAdd}
                        resetData={resetData}
                    />
                    <GridMaster
                        ref={gridRef}
                        rowDetails={filteredResult}
                        colDetails={columnDefs}
                        fieldNames={fieldName}
                        headerNames={headerName}
                        getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
                        getFilterColLength={(getLength) => setFilterColLength(getLength)}
                        setExcelData={setExcelData}
                        handleSaveEvent={handleSaveFireInt}
                        actionName={actionButtons}
                    />
                </>
            </Container>
        </>
    );
}

export default FireIntegrationVerification;
