import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import seccessImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import DragDropRender from "../utils/DragDropRender";
import { AuthContext } from "../../contexts/AuthContext";
import { RenderIf, getCurrentDateTime, formatDateInYYYYMMDD, getTodaysDate, formatDateDD_MM_YYYY } from "../../functions/gbcs-functions";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import { BiSelectMultiple } from "react-icons/bi";
import Validate from "../utils/Validation";
import { downloadAttachment } from "../../services/designreviewtracker-service";
import { isFutureDate } from "../../functions/validations";
import {
    getSiteObservationReportData,
    addSiteObservationReport, updateSiteObservationReport
} from "../../services/siteobservationreport-service";
import { sortByPropertyInDescending } from "../../utils/Utility";
import { deletS3Attachment, ServiceMasterData } from "../../services/common-master-service"
import axios from "axios";
import Autocomplete from "../../utils/Autocomplete"
import { getActiveEquipmentData } from "../../services/equipmentactivity-master-service";
import { getProjectTeamData } from "../../services/project-service";


const AddEditSiteObservationReport = (props) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userId, userName: context.userName, userId: context.userId, userType: context.userType, companyName: context.companyName }
    let action = props.action;
    let newDt = new Date();
    newDt.setDate(newDt.getDate() + 5);
    let AnticipatedDate = formatDateInYYYYMMDD(formatDateDD_MM_YYYY(newDt), "-");
    let currentDate = getTodaysDate();
    const [showForm, setShowForm] = useState(false);
    const [file, setFile] = useState([]);
    const [FilesArr, setFilesArr] = useState({ OldFile: "", NewFile: "" });
    const [newFileFlg, setnewFileFlg] = useState(false);
    const [delFileFlg, setdelFileFlg] = useState(false);
    const editNdChecklistFlg = ((action === "Edit" && props.rowdata.EntryFromFlag === "Checklist") ? true : false);
    const [SVCMasterData, setSVCMasterData] = useState([]);
    const [EquipmentMasterData, setEquipmentMasterData] = useState([]);
    const [ProjectTeamDetails, setProjectTeamDetails] = useState([]);
    const [floorNo, setFloorNo] = useState([]);
    const [area, setArea] = useState([]);
    const [svcName, setSVCName] = useState([]);
    const [equipActTagSrNo, setEquipActTagSrNo] = useState([]);
    const [equipActCap, setEquipActCap] = useState([]);
    const [issueDesc, setIssueDesc] = useState([]);
    const [remarks, setRemarks] = useState([]);
    const [equipActivityName, setEquipActivityName] = useState([]);
    const [respContrOEM, setRespContrOEM] = useState([]);
    const [corrActions, setCorrActions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [SiteObservationReportObj, setSiteObservationReportObj] = useState(
        action === "Edit" || action === "View"
            ? props.rowdata
            : {
                IssueDate: currentDate,
                RaisedBy: userDetails.companyName === "Godrej ENE Division" && userDetails.userType === "Employee" ? "Godrej E&E" : "Business Partner",
                RaisedByUserId: userDetails.userId,
                RaisedByName: userDetails.userName,
                FloorNo: "",
                Area: "",
                ServiceName: "",
                ServiceCode: "",
                ServiceType: "Activity",
                ChecklistCategory: "NA",
                EquipmentActivityName: "",
                EquipmentActivityCode: "",
                EquipmentActivityTagSrNo: "",
                EquipmentActivityCapacity: "",
                ResponsibleContractorOEMUserId: "",
                ResponsibleContractorOEM: "",
                IssueDescription: "",
                Remarks: "",
                Criticality: "Non-Critical",
                AnticipatedClosureDate: AnticipatedDate,
                FireIntegrationService: "No",
                ISTObservations: "Yes",
                CorrectiveAction: "",
                CorrectedStatus: "No",
                VerifiedBy: userDetails.companyName === "Godrej ENE Division" && userDetails.userType === "Employee" ? "Godrej E&E" : "Business Partner",
                VerifiedByUserId: "",
                VerifiedByName: "",
                VerifiedDate: "",
                Uploads: ""
            }
    );


    const fetchServiceMasterData = async () => {
        setLoading(true);
        let result = await ServiceMasterData();
        setSVCMasterData(result);
        setLoading(false);
    }

    const fetchEquipMentMasterData = async () => {
        setLoading(true);
        let equipObj = {}
        let result = await getActiveEquipmentData(equipObj);
        setEquipmentMasterData(result);
        setLoading(false);
    }

    const fetchRespContrOEMData = async () => {
        setLoading(true);
        let result = await getProjectTeamData(props.SelectedProject.ProjectCode);
        setProjectTeamDetails(result);
        setLoading(false);
    }

    const fetchMasterData = async () => {
        await fetchServiceMasterData();
        await fetchEquipMentMasterData();
        await fetchRespContrOEMData();
    }

    useEffect(() => {
        if ((action === "Edit" || action === "View")) {
            FilesArr.OldFile = props.rowdata.Uploads;
        }

        fetchMasterData();

        if ((action === "Edit" || action === "Add")) {
            setLoading(true);
            let tmpFloorNo = [];
            let tmpArea = [];
            let tmpEquipActTagSrNo = [];
            let tmpEquipActCap = [];
            let tmpIssueDesc = [];
            let tmpRemarks = [];
            let tmpSVCName = [];
            let tmpEquipActivityName = [];
            let tmpRespContrOEM = [];
            let tmpCorrAction = [];

            for (let dt of props.masterData) {
                tmpFloorNo.push(dt.FloorNo)
                tmpArea.push(dt.Area)
                tmpEquipActTagSrNo.push(dt.EquipmentActivityTagSrNo)
                tmpEquipActCap.push(dt.EquipmentActivityCapacity)
                tmpIssueDesc.push(dt.IssueDescription)
                tmpRemarks.push(dt.Remarks)
                tmpSVCName.push(dt.ServiceName)
                tmpEquipActivityName.push(dt.EquipmentActivityName)
                tmpRespContrOEM.push(dt.ResponsibleContractorOEM)
                tmpCorrAction.push(dt.CorrectiveAction)

            }
            setFloorNo([...new Set(tmpFloorNo)])
            setArea([...new Set(tmpArea)])
            setEquipActTagSrNo([...new Set(tmpEquipActTagSrNo)])
            setEquipActCap([...new Set(tmpEquipActCap)])
            setIssueDesc([...new Set(tmpIssueDesc)])
            setRemarks([...new Set(tmpRemarks)])
            setSVCName([...new Set(tmpSVCName)])
            setEquipActivityName([...new Set(tmpEquipActivityName)])
            setRespContrOEM([...new Set(tmpRespContrOEM)])
            setCorrActions([...new Set(tmpCorrAction)])
            setLoading(false);
        }

    }, []);

    const [error, setError] = useState({
        IssueDate: false,
        ServiceName: false,
        IssueDescription: false,
        EquipmentActivityName: false,
        AnticipatedClosureDate: false,
        FireIntegrationService: false,
        ISTObservations: false,
        VerifiedByName: false,
        VerifiedDate: false,
        ResponsibleContractorOEM: false
    });

    function typedate(e) {
        e.target.type = "date";
    }

    function typedateBlur(e) {
        e.target.type = "text";
        setSiteObservationReportObj((prevState) => ({
            ...prevState,
            AnticipatedClosureDate: SiteObservationReportObj.AnticipatedClosureDate
        }));
    }

    const handleChangeDate = (e) => {
        const { name, value } = e.target;

        setSiteObservationReportObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        let error = { status: false, message: "" }
        error = validateIssueDate(value);

        setError((prevState) => ({
            ...prevState,
            [name]: error
        }));
        if (error.status) {
            return;
        }
        else {
            setAnticipatedClosureDate(value, SiteObservationReportObj.Criticality);
        }
    };

    const validateIssueDate = (issDt) => {
        let error = { status: false, message: "" }
        error = isFutureDate(issDt);
        if (error.status) {
            return error;
        }
        else if (new Date(issDt) < new Date(props.SelectedProject.ProjStartDate)) {
            error = { status: true, message: "Issue Date should be greater than or equal to Project Start Date." }
            return error;
        }
        else if (props.SelectedProject.ProjectClosureDate !== "" && new Date(issDt) > new Date(props.SelectedProject.ProjectClosureDate)) {
            error = { status: true, message: "Issue Date should be less than or equal to Project Closure Date." }
            return error;
        }
        // else if (new Date(issDt) > new Date(SiteObservationReportObj.AnticipatedClosureDate)) {
        //     error = { status: true, message: "Issue Date should be less than or equal to Anticipated Closure Date." }
        //     return error;
        // }        
        else if (SiteObservationReportObj.VerifiedDate !== "" && new Date(issDt) > new Date(SiteObservationReportObj.VerifiedDate)) {
            error = { status: true, message: "Issue Date should be less than or equal to Verified Date." }
            return error;
        }
        return error;
    }

    const handleChange = (name, value) => {
        setSiteObservationReportObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === "ServiceName") {
            let svcRecord = SVCMasterData.filter((item) => item.ServiceName.toLowerCase() === value.toLowerCase());
            if (svcRecord?.length > 0) {
                setSiteObservationReportObj((prevState) => ({
                    ...prevState,
                    ServiceCode: svcRecord[0].ServiceCode,
                    ServiceName: svcRecord[0].ServiceName
                }));
            }
            else {
                setSiteObservationReportObj((prevState) => ({
                    ...prevState,
                    ServiceCode: ""
                }));
            }
        }

        if (name === "EquipmentActivityName") {
            let equipRecord = EquipmentMasterData.filter((item) => item.EquipmentName.toLowerCase() === value.toLowerCase());
            if (equipRecord?.length > 0) {
                setSiteObservationReportObj((prevState) => ({
                    ...prevState,
                    EquipmentActivityCode: equipRecord[0].EquipmentCode,
                    EquipmentActivityName: equipRecord[0].EquipmentName
                }));
            }
            else {
                setSiteObservationReportObj((prevState) => ({
                    ...prevState,
                    EquipmentActivityCode: ""
                }));
            }
        }
        if (name === "ResponsibleContractorOEM") {
            let teamRecord = ProjectTeamDetails.filter((item) => item.UserName.toLowerCase() === value.toLowerCase());
            if (teamRecord?.length > 0) {
                setSiteObservationReportObj((prevState) => ({
                    ...prevState,
                    ResponsibleContractorOEMUserId: teamRecord[0].UserId,
                    ResponsibleContractorOEM: teamRecord[0].UserName
                }));
                setError((prevState) => ({
                    ...prevState,
                    ResponsibleContractorOEM: { status: false, message: "" }
                }));
            }
            else {
                setSiteObservationReportObj((prevState) => ({
                    ...prevState,
                    ResponsibleContractorOEMUserId: ""
                }));
                setError((prevState) => ({
                    ...prevState,
                    ResponsibleContractorOEM: { status: true, message: "Invalid Responsible Contractor / OEM." }
                }));
            }
        }
    }

    const handleChangeCorrStatus = (e) => {
        const { name, value } = e.target;
        let verifiedByInd = "";
        if (userDetails.companyName === "Godrej ENE Division" && userDetails.userType === "Employee") {
            verifiedByInd = "Godrej E&E";
        }
        else {
            verifiedByInd = "Business Partner";
        }
        if (value === "Yes" || value === "NA") {
            setSiteObservationReportObj((prevState) => ({
                ...prevState,
                VerifiedByName: userDetails.userName,
                VerifiedByUserId: userDetails.userId,
                VerifiedDate: currentDate,
                VerifiedBy: verifiedByInd,
                [name]: value,
            }));
        }
        else if (value === "No") {
            setSiteObservationReportObj((prevState) => ({
                ...prevState,
                VerifiedByName: "",
                VerifiedByUserId: "",
                VerifiedDate: "",
                VerifiedBy: verifiedByInd,
                [name]: value,
            }));
        }
    }

    const handleChangeCriticality = (e) => {
        const { name, value } = e.target;

        setSiteObservationReportObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setAnticipatedClosureDate(SiteObservationReportObj.IssueDate, value);
    }

    const handleAnticipatedChangeDate = (e) => {
        // e.target.type = "text";
        const { name, value } = e.target;
        let convertedAntiDate = formatDateDD_MM_YYYY(value);

        setSiteObservationReportObj((prevState) => ({
            ...prevState,
            [name]: value
        }));
        let Dt = value;
        let issueDt = SiteObservationReportObj.IssueDate;

        let DtErr = validateAnticipatedClosureDate(Dt, issueDt);
        if (DtErr.status) {
            setError((prevState) => ({
                ...prevState,
                AnticipatedClosureDate: DtErr
            }));
        }
        else {
            setError((prevState) => ({
                ...prevState,
                AnticipatedClosureDate: DtErr
            }));
        }
    }

    const setAnticipatedClosureDate = (issueDt, criticType) => {
        let newDt = new Date(issueDt)
        if (criticType === "Critical") {
            newDt.setDate(newDt.getDate() + 3);
        }
        else if (criticType === "Non-Critical") {
            newDt.setDate(newDt.getDate() + 5);
        }
        let AnticipatedDate = formatDateInYYYYMMDD(formatDateDD_MM_YYYY(newDt), "-");
        setSiteObservationReportObj((prevState) => ({
            ...prevState,
            AnticipatedClosureDate: AnticipatedDate
        }));
        let error = validateAnticipatedClosureDate(AnticipatedDate, issueDt);
        // if (error.status) {
        setError((prevState) => ({
            ...prevState,
            AnticipatedClosureDate: error
        }));
        // }
        // else {
        //     setError((prevState) => ({
        //         ...prevState,
        //         AnticipatedClosureDate: error
        //     }));
        // }
    }

    const validateAnticipatedClosureDate = (newDt, issueDt) => {
        let error = { status: false, message: "" }
        if (newDt === "" || newDt === undefined) {
            error = { status: true, message: "Anticipated Closure Date is required." }
            return error;
        }
        else if (new Date(newDt) < new Date(issueDt)) {
            error = { status: true, message: "Anticipated Closure Date should be greater than or equal to issue date." }
            return error;
        }
        else if (props.SelectedProject.ProjectClosureDate !== "" && new Date(newDt) > new Date(props.SelectedProject.ProjectClosureDate)) {
            error = { status: true, message: "Anticipated Closure Date should be less than or equal to Project Closure date." }
            return error;
        }
        else if (new Date(newDt) < new Date(props.SelectedProject.ProjStartDate)) {
            error = { status: true, message: "Anticipated Closure Date should be greater than or equal to Project Start date." }
            return error;
        }
        return error;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (action === "View") {
            props.onHide();
        }
        else {
            let result;
            if (!validateDetails()) {
                let confirmBoxText = "";

                if (action === "Add") {
                    confirmBoxText = "Are you sure, so you want to add new record?";
                }
                else if (action === "Edit") {
                    confirmBoxText = "Are you sure, so you want to update the record?";
                }
                if (SiteObservationReportObj.CorrectedStatus === "Yes" || SiteObservationReportObj.CorrectedStatus === "NA") {
                    confirmBoxText = "You are confirming the resolution of raised observation point. Once confirmed the selected line record of observation will be freeze & cannot be edited later."
                }
                MySwal.fire({
                    text: confirmBoxText,
                    showDenyButton: true,
                    cancelButtonColor: "#fff",
                    confirmButtonText: "Yes",
                    reverseButtons: true,
                    customClass: "swal-confirmation",
                    title: action + " SOR"
                }).then(async (result2) => {
                    if (result2.isConfirmed) {
                        setLoading(true);

                        const result1 = await getSiteObservationReportData(props.SelectedProject.ProjectCode);
                        if (result1?.length > 0) {
                            const existingSOR = result1.filter((item) => (action === "Add" || (action === "Edit" && item.SK.toLowerCase() !== SiteObservationReportObj.SK.toLowerCase())) && (item.EquipmentActivityTagSrNo === SiteObservationReportObj.EquipmentActivityTagSrNo));
                            let existingFloor = "";
                            let existingArea = "";
                            if (existingSOR?.length > 0) {
                                for (let dt of existingSOR) {
                                    if (dt.FloorNo !== "") {
                                        existingFloor = dt.FloorNo;
                                    }
                                    if (dt.Area !== "") {
                                        existingArea = dt.Area;
                                    }
                                }
                            }
                            if ((existingFloor !== "" && SiteObservationReportObj.FloorNo !== "" && existingFloor !== SiteObservationReportObj.FloorNo)
                                || (existingArea !== "" && SiteObservationReportObj.Area !== "" && existingArea !== SiteObservationReportObj.Area)) {
                                setLoading(false);
                                MySwal.fire({
                                    text: "Equipment Tag is already mapped for Floor " + existingFloor + " and Area " + existingArea + " in earlier records, you can not maintain same Equipment Tag for different Floor or Area.",
                                    imageUrl: errorImage,
                                    customClass: "swal-success",
                                });
                                return;
                            }
                        }

                        let todayDate = getCurrentDateTime("-");
                        if (action === "Add") {
                            let maxSeqNo = 0;
                            if (result1?.length > 0) {
                                let sortedArr = result1.sort(sortByPropertyInDescending("SeqNo"));
                                maxSeqNo = sortedArr[0].SeqNo
                            }
                            let NewMaxSeqNo = parseInt(maxSeqNo) + 1;
                            SiteObservationReportObj.ProjectCode = props.SelectedProject.ProjectCode;
                            SiteObservationReportObj.ProjectName = props.SelectedProject.ProjectName;
                            SiteObservationReportObj.CreatedBy = userDetails.userId;
                            SiteObservationReportObj.CreatedByName = userDetails.userName;
                            SiteObservationReportObj.CreatedOn = todayDate;
                            SiteObservationReportObj.ModifiedBy = userDetails.userId;
                            SiteObservationReportObj.ModifiedByName = userDetails.userName;
                            SiteObservationReportObj.ModifiedOn = todayDate;
                            SiteObservationReportObj.PK = "ProjectCode#" + props.SelectedProject.ProjectCode;
                            SiteObservationReportObj.SK = "SOR#SeqNo#" + NewMaxSeqNo;
                            SiteObservationReportObj.SeqNo = NewMaxSeqNo;
                            SiteObservationReportObj.EntryFromFlag = "Manual"
                            result = addSiteObservationReport(SiteObservationReportObj);
                        }
                        else if (action == "Edit") {
                            SiteObservationReportObj.ModifiedBy = userDetails.userId;
                            SiteObservationReportObj.ModifiedByName = userDetails.userName;
                            SiteObservationReportObj.ModifiedOn = todayDate;
                            SiteObservationReportObj.newFileFlg = newFileFlg;
                            result = updateSiteObservationReport(SiteObservationReportObj);
                        }
                        result.then(async (resp) => {
                            if (resp.status) {
                                if (FilesArr.NewFile != "") {
                                    let fileUploadMsg = await uploadSORFile(resp.signedURL);//url generated
                                }
                                //add in backend
                                if (delFileFlg) {
                                    let fileObj = { fileName: "SiteObservationReport/" + props.rowdata.ProjectCode + "/" + props.rowdata.SeqNo + "/" + FilesArr.OldFile };
                                    let result3 = await deletS3Attachment(fileObj);
                                }
                                setLoading(false);
                                MySwal.fire({
                                    text: resp.message,
                                    imageUrl: seccessImage,
                                    customClass: "swal-success",
                                    didClose: () => {
                                        props.onHide();
                                        props.getSiteObservationReport(props.SelectedProject.ProjectCode)
                                    },
                                });
                            }
                            else {
                                setLoading(false);
                                MySwal.fire({
                                    text: "Unable to Process Request",
                                    imageUrl: errorImage,
                                    customClass: "swal-success"
                                });
                            }
                        })
                            .catch((error) => {
                                setLoading(false);
                                MySwal.fire({
                                    text: "Unable to Process Request",
                                    imageUrl: errorImage,
                                    customClass: "swal-success"
                                });
                            })
                            .finally(() => {
                                props.getSiteObservationReport(props.SelectedProject.ProjectCode);
                            });
                    }
                });
            }

        }
    }

    const validateDetails = () => {
        let errorMsg = {
            IssueDate: false,
            ServiceName: false,
            IssueDescription: false,
            EquipmentActivityName: false,
            AnticipatedClosureDate: false,
            FireIntegrationService: false,
            ISTObservations: false,
            VerifiedByName: false,
            VerifiedDate: false,
            ResponsibleContractorOEM: false
        };
        let isError = false;
        let err = validateIssueDate(SiteObservationReportObj.IssueDate);
        if (err.status) {
            isError = true;
            errorMsg.IssueDate = err;
        }
        if (SiteObservationReportObj.ServiceName.trim() === "") {
            isError = true;
            SiteObservationReportObj.ServiceName = "";
            SiteObservationReportObj.ServiceCode = "";
            errorMsg.ServiceName = { status: isError, message: "Service is required" };
        }

        if (SiteObservationReportObj.EquipmentActivityName.trim() === "") {
            isError = true;
            SiteObservationReportObj.EquipmentActivityName = "";
            errorMsg.EquipmentActivityName = { status: isError, message: "Equipment / Activity Name is required" };
        }
        if (SiteObservationReportObj.ResponsibleContractorOEM.trim() === "") {
            isError = true;
            errorMsg.ResponsibleContractorOEM = { status: isError, message: "Responsible Contractor / OEM is required" };
        }
        console.log("SiteObservationReportObj", SiteObservationReportObj)
        let teamRecord = ProjectTeamDetails.filter((item) => item.UserName.toLowerCase() === SiteObservationReportObj.ResponsibleContractorOEM.toLowerCase());

        console.log("teamRecord", teamRecord)
        if (teamRecord?.length == 0) {
            setSiteObservationReportObj((prevState) => ({
                ...prevState,
                ResponsibleContractorOEMUserId: ""
            }));
            isError = true;
            errorMsg.ResponsibleContractorOEM = { status: true, message: "Invalid Responsible Contractor / OEM." };
        }
        if (SiteObservationReportObj.IssueDescription.toString().trim() === "") {
            isError = true;
            SiteObservationReportObj.IssueDescription = "";
            errorMsg.IssueDescription = { status: isError, message: "Issue Description is required" };
        }

        if (SiteObservationReportObj.AnticipatedClosureDate.trim() === "") {
            isError = true;
            SiteObservationReportObj.AnticipatedClosureDate = "";
            errorMsg.AnticipatedClosureDate = { status: isError, message: "Anticipated Closure Date is required" };
        }

        if (!SiteObservationReportObj.FireIntegrationService || SiteObservationReportObj.FireIntegrationService.trim() === "") {
            isError = true;
            errorMsg.FireIntegrationService = { status: isError, message: "Fire Integration Service is required" };
        }
        if (!SiteObservationReportObj.ISTObservations || SiteObservationReportObj.ISTObservations.trim() === "") {
            isError = true;
            errorMsg.ISTObservations = { status: isError, message: "IST Observations is required" };
        }

        if ((SiteObservationReportObj.CorrectedStatus === "Yes" || SiteObservationReportObj.CorrectedStatus === "NA") && SiteObservationReportObj.VerifiedBy.trim() === "") {
            isError = true;
            SiteObservationReportObj.VerifiedBy = "";
            errorMsg.VerifiedBy = { status: isError, message: "Verified By is required" };
        }

        if ((SiteObservationReportObj.CorrectedStatus === "Yes" || SiteObservationReportObj.CorrectedStatus === "NA") && SiteObservationReportObj.VerifiedByName.trim() === "") {
            isError = true;
            SiteObservationReportObj.VerifiedByName = "";
            errorMsg.VerifiedByName = { status: isError, message: "Verified By Name is required" };
        }
        if (SiteObservationReportObj.CorrectedStatus === "Yes" || SiteObservationReportObj.CorrectedStatus === "NA") {
            let Err = validateVerifiedDate();
            if (err.status) {
                isError = true;
                errorMsg.VerifiedDate = Err;
            }
        }

        let Error = validateAnticipatedClosureDate(SiteObservationReportObj.AnticipatedClosureDate, SiteObservationReportObj.IssueDate);
        if (Error.status) {
            isError = true;
            errorMsg.AnticipatedClosureDate = Error;
        }

        setError(errorMsg);
        return isError;
    }

    const validateVerifiedDate = () => {
        let error = { status: false, message: "" }
        if (SiteObservationReportObj.VerifiedDate.trim() === "" && (SiteObservationReportObj.CorrectedStatus === "Yes" || SiteObservationReportObj.CorrectedStatus === "NA")) {
            error = { status: true, message: "Verified Date is required." }
            return error;
        }
        error = isFutureDate(SiteObservationReportObj.VerifiedDate);
        if (error.status) {
            // error = { status: true, message: "Future Date is required." }
            return error;
        }

        if (new Date(SiteObservationReportObj.VerifiedDate) < new Date(SiteObservationReportObj.IssueDate)) {
            error = { status: true, message: "Verified Date should be greater than or equal to issue date" };
            return error;
        }
        return error;
    }

    const uploadSORFile = async (signedURL) => {
        try {
            if (signedURL) {
                await axios.put(signedURL, file);
            }
            return "";
        } catch (error) {
            return " Error in file upload.";
        }
    }

    const handleReset = () => {
        FilesArr.OldFile = "";
        FilesArr.NewFile = "";
        setSiteObservationReportObj({
            IssueDate: currentDate,
            RaisedBy: userDetails.companyName === "Godrej ENE Division" && userDetails.userType === "Employee" ? "Godrej E&E" : "Business Partner",
            RaisedByUserId: userDetails.userId,
            RaisedByName: userDetails.userName,
            FloorNo: "",
            Area: "",
            ServiceName: "",
            ServiceCode: "",
            ServiceType: "Activity",
            ChecklistCategory: "NA",
            EquipmentActivityName: "",
            EquipmentActivityCode: "",
            EquipmentActivityTagSrNo: "",
            EquipmentActivityCapacity: "",
            ResponsibleContractorOEMUserId: "",
            ResponsibleContractorOEM: "",
            IssueDescription: "",
            Remarks: "",
            Criticality: "Non-Critical",
            AnticipatedClosureDate: AnticipatedDate,
            FireIntegrationService: "No",
            ISTObservations: "Yes",
            CorrectiveAction: "",
            CorrectedStatus: "No",
            VerifiedBy: userDetails.companyName === "Godrej ENE Division" && userDetails.userType === "Employee" ? "Godrej E&E" : "Business Partner",
            VerifiedByUserId: "",
            VerifiedByName: "",
            VerifiedDate: "",
            Uploads: ""
        });
        setError({
            IssueDate: false,
            ServiceName: false,
            IssueDescription: false,
            EquipmentActivityName: false,
            AnticipatedClosureDate: false,
            FireIntegrationService: false,
            ISTObservations: false,
            VerifiedByName: false,
            VerifiedDate: false,
            ResponsibleContractorOEM: false
        });
    }

    const handleCancel = () => {
        props.onHide();
    }

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
        additionalParams: ""
    });

    const showPopUp = (name, type, key, value, additionalParams) => {
        let valueObj = value;
        // if (!(action === "View" || editNdChecklistFlg)) {
        setPopUp({ name, type, key, value, additionalParams });
        setShowForm(true);
        // }
    };

    const setPopUpData = async (data) => {
        if (data.name === "Service Master") {
            setSiteObservationReportObj((prevState) => ({
                ...prevState,
                ServiceName: data.result.ServiceName,
                ServiceCode: data.result.ServiceCode
            }));
        }
        else if (data.name === "Equipment Master") {
            setSiteObservationReportObj((prevState) => ({
                ...prevState,
                EquipmentActivityName: data.result.EquipmentName,
                EquipmentActivityCode: data.result.EquipmentCode
            }));
        }
        else if (data.name === "Project Team Details") {
            setSiteObservationReportObj((prevState) => ({
                ...prevState,
                ResponsibleContractorOEM: data.result.UserName,
                ResponsibleContractorOEMUserId: data.result.UserId
            }));
            setError((prevState) => ({
                ...prevState,
                ResponsibleContractorOEM: { status: false, message: "" }
            }));
        }
    }

    const handleChangeStatus = async (files) => {
        let isError = false;
        const newFile = files[0];
        let fileError = Validate(newFile, "allFormats", 26214400);

        if (fileError === "") {
            setFile(newFile);
            setnewFileFlg(true);
            let fileName = files[0].name;
            setSiteObservationReportObj((prevState) => ({
                ...prevState,
                Uploads: fileName
            }));
            FilesArr.NewFile = fileName;
        } else {
            MySwal.fire({
                text: fileError,
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        return isError;
    };

    const handleDeleteDocument = async (file) => {
        if (action == "View") {
            return;
        }
        FilesArr.NewFile = "";
        setSiteObservationReportObj((prevState) => ({
            ...prevState,
            Uploads: ""
        }));
        if (action == "Edit") {
            setdelFileFlg(true);
        }
    }

    const handleDownloadDocument = async (fileName) => {
        if (FilesArr.OldFile !== "") {
            downloadAttachment(
                "SiteObservationReport/" + props.rowdata.ProjectCode + "/" + props.rowdata.SeqNo + "/" + fileName,
                fileName
            ).catch((err) => {
                MySwal.fire({
                    text: "Error in Fetching file.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            });
        }
    }



    return (<>
        {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
        <Modal
            {...props}
            size="xl"
            backdrop="static" centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {action} SOR
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    id="siteobservationreport"
                    name="siteobservationreport"
                    autoComplete="off"
                >
                    <Row >
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="IssueDate"
                                    type="date"
                                    name="IssueDate"
                                    placeholder=" "
                                    required
                                    value={SiteObservationReportObj.IssueDate}
                                    // onFocus={action === "Add" ? typedate : () => { }}
                                    // onBlur={action === "Add" || action === "Edit" ? typedateBlur : () => { }}
                                    onChange={handleChangeDate}
                                    readOnly={action === "Edit" || action === "View"}
                                />
                                <Form.Label className="frm-label" htmlFor="IssueDate">Issue Date</Form.Label>
                                {error.IssueDate.status && (
                                    <Form.Text className="text-danger">
                                        {error.IssueDate.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Row>
                                <Form.Label className="fieldLabel locationType">Raised By</Form.Label>
                            </Row>
                            <Row>
                                <Col md={5} xs={5}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="RaisedBy"
                                        checked={SiteObservationReportObj.RaisedBy === "Godrej E&E"}
                                        label="Godrej E&E"
                                        value="Godrej E&E"
                                        disabled
                                    ></Form.Check>
                                </Col>
                                <Col md={7} xs={7}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="RaisedBy"
                                        checked={SiteObservationReportObj.RaisedBy === "Business Partner"}
                                        label="Business Partner"
                                        value="Business Partner"
                                        disabled
                                    ></Form.Check>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="RaisedByName"
                                    type="text"
                                    name="RaisedByName"
                                    maxLength="200"
                                    placeholder=" "
                                    required
                                    value={SiteObservationReportObj.RaisedByName}
                                    readOnly />
                                <Form.Label className="frm-label" htmlFor="RaisedByName">Raised By Name</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group className="g-input">
                                <Autocomplete
                                    id="FloorNo"
                                    type="text"
                                    name="FloorNo"
                                    maxLength="100"
                                    placeholder=" "
                                    value={SiteObservationReportObj.FloorNo}
                                    onChangeEv={handleChange}
                                    readonly={action === "View"}
                                    options={floorNo} fieldName={"Floor No."}
                                    optionalField={true} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group className="g-input">
                                <Autocomplete
                                    id="Area"
                                    type="text"
                                    name="Area"
                                    maxLength="100"
                                    placeholder=" "
                                    value={SiteObservationReportObj.Area}
                                    onChangeEv={handleChange}
                                    readonly={action === "View"}
                                    options={area} fieldName={"Area"}
                                    optionalField={true} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group class="g-input">
                                <Autocomplete
                                    id="ServiceName"
                                    type="text"
                                    name="ServiceName"
                                    maxLength="100"
                                    placeholder=" "
                                    value={SiteObservationReportObj.ServiceName}
                                    onChangeEv={handleChange}
                                    readonly={action === "View" || editNdChecklistFlg}
                                    options={svcName} fieldName={"Service Name"} />
                                {action !== "View" && !editNdChecklistFlg ?
                                    <BiSelectMultiple className="modalPopUpIconProjDetails"
                                        size={20}
                                        onClick={() => showPopUp("Service Master", "Single", "ServiceName", SiteObservationReportObj.ServiceName, {})}
                                        color={"gray"}
                                        style={{ marginLeft: "-2rem" }}
                                        backdrop="static"
                                    /> : <></>
                                }
                                {error.ServiceName.status && (
                                    <Form.Text className="text-danger">
                                        {error.ServiceName.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Row>
                                <Form.Label className="fieldLabel locationType">Service Type</Form.Label>
                            </Row>
                            <Row>
                                <Col md={5} xs={5}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="ServiceType"
                                        checked={SiteObservationReportObj.ServiceType === "Equipment"}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        label="Equipment"
                                        value="Equipment"
                                        disabled={action === "View" || editNdChecklistFlg}
                                    ></Form.Check>
                                </Col>
                                <Col md={7} xs={7}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="ServiceType"
                                        checked={SiteObservationReportObj.ServiceType === "Activity"}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        label="Activity"
                                        value="Activity"
                                        disabled={action === "View" || editNdChecklistFlg}
                                    ></Form.Check>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Row>
                                <Form.Label className="fieldLabel locationType">Checklist Category</Form.Label>
                            </Row>
                            <Row>
                                <Form.Label className="fieldLabel locationType semiBoldToggleButton">{SiteObservationReportObj.ChecklistCategory}</Form.Label>
                            </Row>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group class="g-input">
                                <Autocomplete
                                    id="EquipmentActivityName"
                                    type="text"
                                    name="EquipmentActivityName"
                                    maxLength="150"
                                    placeholder=" "
                                    value={SiteObservationReportObj.EquipmentActivityName}
                                    onChangeEv={handleChange}
                                    readonly={action === "View" || editNdChecklistFlg}
                                    options={equipActivityName} fieldName={"Equipment / Activity Name"} />
                                {action !== "View" && !editNdChecklistFlg ?
                                    <BiSelectMultiple className="modalPopUpIconProjDetails"
                                        size={20}
                                        onClick={() => {
                                            showPopUp(
                                                "Equipment Master",
                                                "Single",
                                                "EquipmentActivityName",
                                                SiteObservationReportObj.EquipmentActivityName,
                                                [{ ServiceCode: SiteObservationReportObj.ServiceCode, ServiceType: SiteObservationReportObj.ServiceType }]
                                            )
                                        }}
                                        color={"gray"}
                                        style={{ marginLeft: "-2rem" }}
                                        backdrop="static"
                                    /> : <></>
                                }
                                {error.EquipmentActivityName.status && (
                                    <Form.Text className="text-danger">
                                        {error.EquipmentActivityName.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group className="g-input">
                                <Autocomplete
                                    id="EquipmentActivityTagSrNo"
                                    type="text"
                                    name="EquipmentActivityTagSrNo"
                                    maxLength="200"
                                    placeholder=" "
                                    value={SiteObservationReportObj.EquipmentActivityTagSrNo}
                                    onChangeEv={handleChange}
                                    readonly={action === "View" || editNdChecklistFlg}
                                    options={equipActTagSrNo} fieldName={"Equipment / Activity Tag (Serial No.)"}
                                    optionalField={true} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group className="g-input">
                                <Autocomplete
                                    id="EquipmentActivityCapacity"
                                    type="text"
                                    name="EquipmentActivityCapacity"
                                    maxLength="100"
                                    placeholder=" "
                                    value={SiteObservationReportObj.EquipmentActivityCapacity}
                                    onChangeEv={handleChange}
                                    readonly={action === "View"}
                                    options={equipActCap} fieldName={"Equipment / Activity Capacity"}
                                    optionalField={true} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group class="g-input">
                                <Autocomplete
                                    id="ResponsibleContractorOEM"
                                    type="text"
                                    name="ResponsibleContractorOEM"
                                    maxLength="100"
                                    placeholder=" "
                                    value={SiteObservationReportObj.ResponsibleContractorOEM}
                                    onChangeEv={handleChange}
                                    readonly={action === "View" || editNdChecklistFlg}
                                    options={respContrOEM} fieldName={"Responsible Contractor / OEM"} />
                                {action !== "View" ?
                                    <BiSelectMultiple className="modalPopUpIconProjDetails"
                                        size={20}
                                        onClick={() => showPopUp("Project Team Details", "Single", "ResponsibleContractorOEM", SiteObservationReportObj.ResponsibleContractorOEM, props.SelectedProject.ProjectCode)}
                                        color={"gray"}
                                        style={{ marginLeft: "-2rem" }}
                                        backdrop="static"
                                    /> : <></>
                                }
                                {error.ResponsibleContractorOEM.status && (
                                    <Form.Text className="text-danger">
                                        {error.ResponsibleContractorOEM.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                            <Form.Group className="g-input">
                                <Autocomplete
                                    id="IssueDescription"
                                    type="textarea"
                                    name="IssueDescription"
                                    maxLength="300"
                                    placeholder=" "
                                    value={SiteObservationReportObj.IssueDescription}
                                    onChangeEv={handleChange}
                                    readonly={action === "View"}
                                    options={issueDesc} fieldName={"Issue Description"} />
                                {error.IssueDescription.status && (
                                    <Form.Text className="text-danger">
                                        {error.IssueDescription.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                            <Form.Group className="g-input">
                                <Autocomplete
                                    id="Remarks"
                                    type="textarea"
                                    name="Remarks"
                                    maxLength="500"
                                    placeholder=" "
                                    value={SiteObservationReportObj.Remarks}
                                    onChangeEv={handleChange}
                                    readonly={action === "View"}
                                    options={remarks} fieldName={"Remarks"}
                                    optionalField={true} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Row>
                                <Form.Label className="fieldLabel locationType">Criticality</Form.Label>
                            </Row>
                            <Row>
                                <Col md={5} xs={5}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="Criticality"
                                        checked={SiteObservationReportObj.Criticality === "Critical"}
                                        onChange={handleChangeCriticality}
                                        label="Critical"
                                        value="Critical"
                                        disabled={action === "View"}
                                    ></Form.Check>
                                </Col>
                                <Col md={7} xs={7}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="Criticality"
                                        checked={SiteObservationReportObj.Criticality === "Non-Critical"}
                                        onChange={handleChangeCriticality}
                                        label="Non-Critical"
                                        value="Non-Critical"
                                        disabled={action === "View"}
                                    ></Form.Check>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Row>
                                <Form.Label className="fieldLabel locationType">Fire Integration Service</Form.Label>
                            </Row>
                            <Row>
                                <Col md={5} xs={5}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="FireIntegrationService"
                                        checked={SiteObservationReportObj.FireIntegrationService === "Yes"}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        label="Yes"
                                        value="Yes"
                                        disabled={action === "View"}
                                    ></Form.Check>
                                </Col>
                                <Col md={7} xs={7}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="FireIntegrationService"
                                        checked={SiteObservationReportObj.FireIntegrationService === "No"}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        label="No"
                                        value="No"
                                        disabled={action === "View"}
                                    ></Form.Check>
                                </Col>
                                {error.FireIntegrationService.status && (
                                    <Form.Text className="text-danger">
                                        {error.FireIntegrationService.message}
                                    </Form.Text>
                                )}
                            </Row>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Row>
                                <Form.Label className="fieldLabel locationType">IST Observations</Form.Label>
                            </Row>
                            <Row>
                                <Col md={5} xs={5}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="ISTObservations"
                                        checked={SiteObservationReportObj.ISTObservations === "Yes"}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        label="Yes"
                                        value="Yes"
                                        disabled={action === "View"}
                                    ></Form.Check>
                                </Col>
                                <Col md={7} xs={7}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="ISTObservations"
                                        checked={SiteObservationReportObj.ISTObservations === "No"}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        label="No"
                                        value="No"
                                        disabled={action === "View"}
                                    ></Form.Check>
                                </Col>
                                {error.ISTObservations.status && (
                                    <Form.Text className="text-danger">
                                        {error.ISTObservations.message}
                                    </Form.Text>
                                )}
                            </Row>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Row>
                                <Form.Label className="fieldLabel locationType">Corrected Status</Form.Label>
                            </Row>
                            <Row>
                                <Col md={4} xs={4}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="CorrectedStatus"
                                        checked={SiteObservationReportObj.CorrectedStatus === "Yes"}
                                        onChange={handleChangeCorrStatus}
                                        label="Yes"
                                        value="Yes"
                                        disabled={action === "View"}
                                    ></Form.Check>
                                </Col>
                                <Col md={4} xs={4}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="CorrectedStatus"
                                        checked={SiteObservationReportObj.CorrectedStatus === "No"}
                                        onChange={handleChangeCorrStatus}
                                        label="No"
                                        value="No"
                                        disabled={action === "View"}
                                    ></Form.Check>
                                </Col>
                                <Col md={4} xs={4}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="CorrectedStatus"
                                        checked={SiteObservationReportObj.CorrectedStatus === "NA"}
                                        onChange={handleChangeCorrStatus}
                                        label="NA"
                                        value="NA"
                                        disabled={action === "View"}
                                    ></Form.Check>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="AnticipatedClosureDate"
                                    type="date"
                                    name="AnticipatedClosureDate"
                                    placeholder=" "
                                    required
                                    value={SiteObservationReportObj.AnticipatedClosureDate}
                                    onChange={(e) => handleAnticipatedChangeDate(e)}
                                    readOnly={action === "View"}
                                />
                                <Form.Label className="frm-label" htmlFor="AnticipatedClosureDate">Anticipated Closure Date</Form.Label>
                                {error.AnticipatedClosureDate.status && (
                                    <Form.Text className="text-danger">
                                        {error.AnticipatedClosureDate.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <Form.Group className="g-input">
                                <Autocomplete
                                    id="CorrectiveAction"
                                    type="textarea"
                                    name="CorrectiveAction"
                                    maxLength="200"
                                    placeholder=" "
                                    value={SiteObservationReportObj.CorrectiveAction}
                                    onChangeEv={handleChange}
                                    readonly={action === "View"}
                                    options={corrActions} fieldName={"Corrective Action"} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Row>
                                <Form.Label className="fieldLabel locationType">Verified By</Form.Label>
                            </Row>
                            <Row>
                                <Col md={5} xs={5}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="VerifiedBy"
                                        checked={SiteObservationReportObj.VerifiedBy === "Godrej E&E"}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        label="Godrej E&E"
                                        value="Godrej E&E"
                                        disabled
                                    ></Form.Check>
                                </Col>
                                <Col md={7} xs={7}>
                                    <Form.Check className="fieldLabel locationType"
                                        type="radio"
                                        name="VerifiedBy"
                                        checked={SiteObservationReportObj.VerifiedBy === "Business Partner"}
                                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        label="Business Partner"
                                        value="Business Partner"
                                        disabled
                                    ></Form.Check>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="VerifiedByName"
                                    type="text"
                                    name="VerifiedByName"
                                    placeholder=" "
                                    value={SiteObservationReportObj.VerifiedByName}
                                    readOnly
                                    required={SiteObservationReportObj.CorrectedStatus === "Yes" || SiteObservationReportObj.CorrectedStatus === "NA"}
                                />
                                <Form.Label className="frm-label" htmlFor="VerifiedByName">Verified By Name</Form.Label>
                                {error.VerifiedByName.status && (
                                    <Form.Text className="text-danger">
                                        {error.VerifiedByName.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="VerifiedDate"
                                    type="text"
                                    name="VerifiedDate"
                                    placeholder=" "
                                    value={formatDateDD_MM_YYYY(SiteObservationReportObj.VerifiedDate)}
                                    readOnly
                                    required={SiteObservationReportObj.CorrectedStatus === "Yes" || SiteObservationReportObj.CorrectedStatus === "NA"}
                                />
                                <Form.Label className="frm-label" htmlFor="VerifiedDate">Verified Date</Form.Label>
                                {error.VerifiedDate.status && (
                                    <Form.Text className="text-danger">
                                        {error.VerifiedDate.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                            <DragDropRender
                                val={SiteObservationReportObj.Uploads}
                                handleChangeEvent={(params) => handleChangeStatus(params)}
                                handleDeleteDocEvent={(params) => handleDeleteDocument(params)}
                                handleDownloadDocEvent={(params) => handleDownloadDocument(params)}
                                action={action}
                                supportedFilesLabel={"Supports: PDF, doc, docx, xls, xlsx, xlsb, jpg, jpeg, png, all PPT formats, all AutoCAD drawing formats Max File Size - upto 25MB"}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row >
                        <Col md={9} xs={6}>
                            <Row>
                                <Col md={3} xs={12}>{action === "View" || action === "Edit" ? null :
                                    <Button className="semiBoldToggleButton cancelBtn"
                                        variant="outline-success"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                }
                                </Col>
                                <Col xs={12} md={6} >{action === "View" || action === "Edit" ? <></> :
                                    <Button className="resetBtn semiBoldToggleButton"
                                        variant="link"
                                        onClick={handleReset}
                                    >Reset Fields
                                    </Button>
                                }
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} xs={6}>
                            {action === "View" ?
                                <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={handleSubmit} >
                                    Done
                                </Button>
                                :
                                <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={handleSubmit} >
                                    {action === "Edit" ? "Update" : "Add"}
                                </Button>
                            }
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <RenderIf isTrue={showForm}>
                <SingleValuePopUp
                    show={showForm}
                    onHide={() => setShowForm(false)}
                    setSelected={setPopUpData}
                    mastername={popUp.name}
                    paramkey={popUp.key}
                    paramvalue={popUp.value}
                    additionalParams={popUp.additionalParams}
                    backdrop="static"
                />
            </RenderIf>
        </Modal>
    </>
    )

}

export default AddEditSiteObservationReport;