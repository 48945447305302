import React, { useEffect, useState, useContext, forwardRef, useImperativeHandle } from "react";
import { Form, Modal, Row, Col, Tooltip, OverlayTrigger, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsPlusLg } from "react-icons/bs";
import Delete from "../../../icons/Delete_blue.svg"
import { Link } from 'react-router-dom';
import "../../utils/AddEditProjectMaster.css";
import { BiSelectMultiple } from "react-icons/bi";
import SingleValuePopUp from "../../utils/SingleValuePopUp";
import { RenderIf, formatDateYYYY_MM_DD, getCurrentDateTime } from "../../../functions/gbcs-functions";
import { isAlphanumericWeb, validDecimals, isEmail, validINRCurrency, validUSDCurrency, isNumberWithDecimalPM, isMobNumber } from "../../../functions/validations";
import { getAllProjects, AddProjectDetails, AddProjectTeam, UpdateProjectDetails, getProjectActiveServiceEqui } from "../../../services/project-service";
import { getStakeholderTab1, getZoneData, getSectorData, getLOBMaster, ServiceMasterAllData } from "../../../services/common-master-service";
import { getValidUserData, GetSelectedCompanyCodeUsers } from "../../../services/user-service";
import BackArrow from "../../../icons/BackArrow.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import infoImage from "../../../icons/infoblue.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import { getDesignReviewTrackerData } from "../../../services/designreviewtracker-service"
import { getSiteObservationReportData } from "../../../services/siteobservationreport-service"
import { getProjTranChklst } from "../../../services/project-transaction-service";
import { addLogDetails } from "../../../services/logger-entry-service";
import { getSelectedEquipDetails } from "../../../services/equipmentactivity-master-service";
import { getDocumentReviewTrackerProjectWise } from "../../../services/documentreviewtracker-service";
import { getIntegratedTabsData } from "../../../services/integrated-system-testing-service";
import { getAllDocumentsDataForProject } from "../../../services/project-document-service";
import { projectClosureDashboardCalc } from "../../../services/dashboard-service";

const ProjectDetails = forwardRef((props, ref) => {
    const MySwal = withReactContent(Swal);
    let action = props.action;
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, dataControl: context.dataControl, userId: context.userId, role: context.role, mobileNo: context.mobileNo, designation: context.designation, companyName: context.companyName, companyCode: context.companyCode };
    userDetails.dataControl = true;
    const [loading, setLoading] = useState(true);
    const [tabData, setTabData] = useState(
        [{
            SrNo: 1,
            StakeholderType: '',
            StakeCompanyDivision: '',
            StakeCompanyCode: '',
            PMCContactPerson: '',
            PMCOfficialContNo: '',
            PMCEmailId: ''
        }]);

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
        additionalParams: ""
    });

    const stakeobj = {
        StakeholderType: {
            status: false,
            message: ""
        },
        StakeCompanyDivision: {
            status: false,
            message: ""
        },
        PMCContactPerson: {
            status: false,
            message: ""
        },
        PMCOfficialContNo: {
            status: false,
            message: ""
        },
        PMCEmailId: {
            status: false,
            message: ""
        }
    };

    let todayDate = getCurrentDateTime("-");
    // const [compCode, setCompCode] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [stakeholderResult, setStakeholderResult] = useState([]);
    const [zoneResult, setZoneResult] = useState([]);
    const [sectorResult, setSectorResult] = useState([]);
    const [lobResult, setlobResult] = useState([]);
    const [customerDetailsDisplay, setCustomerDetailsDisplay] = useState(window.innerWidth > 768 ? true : false);
    const [contractorDisplay, setContractorDisplay] = useState(window.innerWidth > 768 ? true : false);
    const [errorStakeholder, setErrorStakeholder] = useState([stakeobj]);
    const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([]);
    const [USDCurrency, setUSDCurrency] = useState(0);
    const [inactiveStatus, setInactiveStatus] = useState(false);

    const [transactionData, setTransactionData] = useState({
        designReviewData: false,
        openDesignReviewData: false,
        checklistData: false,
        // checklistDataForAllEquipments: false
    })

    const fetchDesignReviewData = async () => {
        const [dsrData, FATChklstData, SATChklstData, drtData, istData] = await Promise.all([
            getDesignReviewTrackerData(props.rowdata.ProjectCode),
            getProjTranChklst("FAT", props.rowdata.ProjectCode),
            getProjTranChklst("SAT", props.rowdata.ProjectCode)
        ]);

        let dsrDataInd = false;
        let chklstDataInd = false;
        let openDataInd = false;
        if (dsrData?.length > 0) {
            dsrDataInd = true;
        }

        let openData = dsrData.filter((item) => item.FreezeInd === "No");
        if (openData?.length > 0) {
            openDataInd = true;
        }
        if (FATChklstData?.length > 0 || SATChklstData?.length > 0) {
            chklstDataInd = true;
        }
        setTransactionData((prevState) => ({
            ...prevState,
            designReviewData: dsrDataInd,
            openDesignReviewData: openDataInd,
            checklistData: chklstDataInd
        }));
    };

    const checkTransaction = async () => {
        fetchDesignReviewData();
    };

    const [showMobileNo, setShowMobileNo] = useState(
        action === "Edit" || action === "View" ? false : true
    );

    if (action === "Edit" || action === "View") {
        if (props.rowdata.ProjEndDate.length > 10) {
            props.rowdata.ProjEndDate = formatDateYYYY_MM_DD(props.rowdata.ProjEndDate.toString());
            props.rowdata.ProjStartDate = formatDateYYYY_MM_DD(props.rowdata.ProjStartDate.toString());
            props.rowdata.RevisedProjEndDate = formatDateYYYY_MM_DD(props.rowdata.RevisedProjEndDate.toString());
        }
    }

    const checkEditValues = async () => {
        if (action === "Edit" || action === "View") {
            let data = props.rowdata;
            if (data.StakeholderDetails.length > 0) {
                if (data.StakeholderDetails.length + 1 !== tabData.length) {
                    setTabData([]);
                    data.StakeholderDetails.forEach(item => {
                        setTabData((prev) => [...prev, item]);
                        setErrorStakeholder((prev) => [...prev, stakeobj])
                    });
                }
            }
            let usersdata = await GetSelectedCompanyCodeUsers(props.rowdata.CompanyCode);
            setSelectedCompanyUsers(usersdata);
        }
    }

    const [projectDetailsObj, setProjectDetailsObj] = useState(
        action === "Edit" || action === "View"
            ? props.rowdata
            : {
                SK: "",
                PK: "",
                DeleteIndication: "Active",
                ProjectClosureDate: "",
                LOB: "",
                ProjectType: "",
                ProjectName: "",
                ProjectCode: "",
                Country: "",
                LocationType: "India",
                LocationCode: "",
                Location: "",
                SiteAddress: "",
                Zone: "",
                OriginalOrderValue: 0.00,
                OriginalOrderUnit: "INR",
                RevisedOrderValue: 0.00,
                RevisedOrderUnit: "INR",
                GodrejEmpName: "",
                GodrejContactNo: "",
                GodrejEmailId: "",
                ProjArea: 0.00,
                Sector: "",
                RevisedProjEndDate: todayDate.substring(0, 10),
                ProjStartDate: todayDate.substring(0, 10),
                ProjEndDate: todayDate.substring(0, 10),
                ISTApplicable: "No",
                CustomerName: "",
                ClientContactPerson1: "",
                ClientContactNo1: "",
                ClientEmail1: "",
                ClientContactPerson2: "",
                ClientContactNo2: "",
                ClientEmail2: "",
                StakeholderDetails: "",
                CreatedOn: todayDate,
                CreatedBy: userDetails.userId,
                CreatedByName: userDetails.userName,
                ModifiedOn: todayDate,
                ModifiedBy: userDetails.userId,
                ModifiedByName: userDetails.userName
            }
    );

    const setPopUpData = async (data) => {
        if (data.name === "Godrej Employee Details") {
            setProjectDetailsObj((prevState) => ({
                ...prevState,
                GodrejEmpName: data.result.UserName,
                GodrejEmailId: data.result.EmailId,
                GodrejContactNo: data.result.MobileNo
            }));
            setError((prev) => ({
                ...prev,
                GodrejEmailId: {
                    status: false,
                    message: ""
                },

                GodrejContactNo: {
                    status: false,
                    message: ""
                }
            }))
        }
        else if (data.name === "Location Master") {
            setProjectDetailsObj((prevState) => ({
                ...prevState,
                LocationCode: data.result.LocationCode,
                Location: data.result.Location,
                Country: data.result.Country
            }));
        }
        else if (data.name === "Company Master without ENE") {
            setProjectDetailsObj((prevState) => ({
                ...prevState,
                CustomerName: data.result.CompanyName,
                CompanyCode: data.result.CompanyCode,
            }));
            // setCompCode(data.result.CompanyCode);
        }
        else if (data.name === "Company Division Master") {
            handleChangePerson("StakeCompanyDivision", data.result, data.value);
            // setCompCode(data.result.CompanyCode);
        }
        else if (data.name === "User Details") {
            handleChangePerson("StakeContactPerson", data.result, data.value.rowIndex);
        }
        else if (data.name === "Customer User Details1") {
            setProjectDetailsObj((prevState) => ({
                ...prevState,
                ClientContactPerson1: data.result.UserName,
                ClientContactNo1: data.result.MobileNo,
                ClientEmail1: data.result.EmailId,
            }));
        }
        else if (data.name === "Customer User Details2") {
            setProjectDetailsObj((prevState) => ({
                ...prevState,
                ClientContactPerson2: data.result.UserName,
                ClientContactNo2: data.result.MobileNo,
                ClientEmail2: data.result.EmailId,
            }));
        }
    };

    // const fetchStakeholderMaster = async () => {
    //     const result = await getStakeholderTab1();
    //     setStakeholderResult([]);
    //     result.forEach(element => {
    //         setStakeholderResult(prevState => [...prevState, { "StakeName": element.StakeholderName, "StakeCode": element.SK }]);
    //     });
    // };

    // const fetchZoneMaster = async () => {
    //     const result = await getZoneData();
    //     setZoneResult([]);
    //     result.forEach(element => {
    //         setZoneResult(prevState => [...prevState, { "ZoneName": element.ZoneName, "ZoneCode": element.SK }]);
    //     });
    // };

    // const fetchSectorMaster = async () => {
    //     const result = await getSectorData();
    //     setSectorResult([]);
    //     result.forEach(element => {
    //         setSectorResult(prevState => [...prevState, { "sectorName": element.SectorName, "sectorCode": element.SK }]);
    //     });
    // };

    // const fetchLobMaster = async () => {
    //     const result = await getLOBMaster();
    //     setlobResult([]);
    //     result.forEach(element => {
    //         setlobResult(prevState => [...prevState, { "LOBName": element.LOBName, "LOBCode": element.SK }]);
    //     });
    //     const resp = result.find((ele) => ele.DefaultValueIndication === "Y");
    //     setProjectDetailsObj((prevState) => ({
    //         ...prevState,
    //         LOB: resp.LOBName
    //     }));
    // };

    useEffect(() => {
        setLoading(true);
        checkEditValues();
        if (action === "Edit") {
            checkTransaction();
            let projStatusCheck = props.rowdata.DeleteIndication;
            if (projStatusCheck === "Inactive" || projStatusCheck === "Closed") {
                setInactiveStatus(true);
            }
        }
        if (action === "Edit" || action === "View") {
            addLogDetails(projectDetailsObj, "View", "SK#" + projectDetailsObj.SK, "ProjectMasterLog", userDetails, "Entry");
        }
        setLoading(false);
    }, []);


    // useEffect(() => {
    //     fetchLobMaster();
    //     fetchStakeholderMaster();
    //     fetchZoneMaster();
    //     fetchSectorMaster();
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const [lobData, stakeholderData, zoneData, sectorData] = await Promise.all([
                    getLOBMaster(),
                    getStakeholderTab1(),
                    getZoneData(),
                    getSectorData()
                ]);
                setlobResult(lobData);
                setStakeholderResult(stakeholderData);
                setZoneResult(zoneData);
                setSectorResult(sectorData);
                const resp = lobData.find((ele) => ele.DefaultValueIndication === "Y");
                setProjectDetailsObj((prevState) => ({
                    ...prevState,
                    LOB: resp.LOBName
                }));
            } catch (error) {
                console.error("error", error);
            }
            setLoading(false)
        };

        fetchData();
    }, []);

    const [error, setError] = useState({
        LOB: {},
        DeleteIndication: {},
        ProjectName: {},
        ProjectCode: {},
        ProjectType: {},
        LocationType: {},
        Location: {},
        SiteAddress: {},
        Zone: {},
        Sector: {},
        OriginalOrderValue: {},
        RevisedOrderValue: {},
        OriginalOrderUnit: {},
        RevisedOrderUnit: {},
        ISTApplicable: {},
        GodrejEmpName: {},
        GodrejContactNo: {},
        GodrejEmailId: {},
        ProjArea: {},
        RevisedProjEndDate: {},
        ProjStartDate: {},
        ProjEndDate: {},
        CustomerName: {},
        ClientContactPerson1: {},
        ClientContactNo1: {},
        ClientEmail1: {}
    });

    const [errorForNonMandatory, setErrorForNonMandatory] = useState({
        ClientContactPerson2: {},
        ClientContactNo2: {},
        ClientEmail2: {}
    });

    const handleSubmit = async () => {
        // e.preventDefault();
        setLoading(true);
        if (!validateDetails(action) && !validationStakeholder("submit")) {
            const projectresult = await getAllProjects(false);
            let projresult;
            if (action === "Add") {
                if (projectresult?.length > 0 && projectresult?.filter((item) => item.ProjectCode.toString().toLowerCase() === projectDetailsObj.ProjectCode.toString().toLowerCase()).length > 0) {
                    setLoading(false);
                    MySwal.fire({
                        text: "Project Code already exists.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
                else if (projectresult?.length > 0 && projectresult?.filter((item) => item.ProjectName.toString().toLowerCase() === projectDetailsObj.ProjectName.toString().toLowerCase()).length > 0) {
                    setLoading(false);
                    MySwal.fire({
                        text: "Project Name already exists.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
                else {
                    projectDetailsObj.CreatedBy = userDetails.userId;
                    projectDetailsObj.PK = "ProjectMaster";
                    projectDetailsObj.SK = "ProjectDetails#ProjectCode#" + projectDetailsObj.ProjectCode;
                    projectDetailsObj.StakeholderDetails = tabData;
                    projectDetailsObj.CreatedOn = todayDate;
                    projectDetailsObj.ModifiedOn = todayDate;
                    projectDetailsObj.ProjStartDate = projectDetailsObj.ProjStartDate.toLocaleString().slice(0, 19).replace(',', '');
                    projectDetailsObj.ProjEndDate = projectDetailsObj.ProjEndDate.toLocaleString().slice(0, 19).replace(',', '');
                    projectDetailsObj.RevisedProjEndDate = projectDetailsObj.RevisedProjEndDate.toLocaleString().slice(0, 19).replace(',', '');

                    projresult = await AddProjectDetails(projectDetailsObj);
                    let projTeamObj = {
                        SK: "ProjectTeam#SeqNo#1",
                        PK: "ProjectCode#" + projectDetailsObj.ProjectCode,
                        ProjectName: projectDetailsObj.ProjectName,
                        ProjectCode: projectDetailsObj.ProjectCode,
                        SeqNo: 1,
                        DeleteIndication: "Active",
                        CreatedOn: todayDate,
                        CreatedBy: userDetails.userId,
                        CreatedByName: userDetails.userName,
                        ModifiedOn: todayDate,
                        ModifiedBy: userDetails.userId,
                        ModifiedByName: userDetails.userName,
                        StakeholderType: 'Godrej ENE Employee',
                        UserName: userDetails.userName,
                        UserId: userDetails.userId,
                        Designation: userDetails.designation,
                        CompanyOrDivision: userDetails.companyName,
                        CompanyCode: userDetails.companyCode,
                        Role: userDetails.role,
                        MobileNo: userDetails.mobileNo,
                        EmailId: userDetails.email
                    }
                    let arr = [];
                    arr.push(projTeamObj);
                    let tempteam = await AddProjectTeam(arr);
                    let logresult = await addLogDetails(projTeamObj, action, "PK#" + projTeamObj.PK + "#SK#" + projTeamObj.SK, "ProjectMasterLog", userDetails, "Entry");
                }
            }
            else if (action === "Edit") {
                if (projectresult?.length > 0 && projectresult?.filter((item) => item.SK !== projectDetailsObj.SK && item.ProjectName.toString().toLowerCase() === projectDetailsObj.ProjectName.toString().toLowerCase()).length > 0) {
                    setLoading(false);
                    MySwal.fire({
                        text: "Project Name already exists.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    return;
                }
                if (projectDetailsObj.DeleteIndication === "Closed") {
                    let incompeteSessions = await validateProjectClosure();

                    if (incompeteSessions.length > 0) {
                        setLoading(false);
                        MySwal.fire({
                            text: "Complete data is not maintained in " + incompeteSessions.toString(),
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                        return;
                    }
                    else {
                        projectDetailsObj.ProjectClosureDate = todayDate;
                    }
                }

                projectDetailsObj.StakeholderDetails = tabData;
                projectDetailsObj.ModifiedOn = todayDate;
                projectDetailsObj.RevisedProjEndDate = projectDetailsObj.RevisedProjEndDate.toLocaleString().slice(0, 19).replace(',', '');
                projresult = await UpdateProjectDetails(projectDetailsObj);
            }
            props.projectCodeData(projectDetailsObj.ProjectCode);
            props.projectNameData(projectDetailsObj.ProjectName);
            if (projresult.status) {
                if (projectDetailsObj.DeleteIndication === "Closed") {
                    await projectClosureDashboardCalc(projectDetailsObj);
                }
                let logresult = await addLogDetails(projectDetailsObj, action, "SK#" + projectDetailsObj.SK, "ProjectMasterLog", userDetails, "Entry");
                // props.setCustomClass();
                setLoading(false);
                MySwal.fire({
                    text: projresult.message,
                    imageUrl: successImage,
                    customClass: "swal-success",
                    didClose: () => {
                        props.setreload(!props.reload);
                    }
                });
                return true;
            }
            else {
                setLoading(false);
                MySwal.fire({
                    text: "Unable to Process Request",
                    imageUrl: errorImage,
                    customClass: "swal-success"
                });
            }
        }
        else {
            setLoading(false);
            MySwal.fire({
                text: "Please enter all valid data.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    };

    const validateProjectClosure = async () => {
        let incompeteSessions = [];
        if (transactionData.openDesignReviewData) {
            incompeteSessions.push("Design Review Tracker");
        }
        const [sorData, FATChklstData, SATChklstData, activeEquipData, pendingDocReview, IstEquipData, projectDocumentData, srvData] = await Promise.all([
            getSiteObservationReportData(projectDetailsObj.ProjectCode),
            getProjTranChklst("FAT", projectDetailsObj.ProjectCode),
            getProjTranChklst("SAT", projectDetailsObj.ProjectCode),
            getProjectActiveServiceEqui(projectDetailsObj.ProjectCode),
            getDocumentReviewTrackerProjectWise({ ProjectCode: projectDetailsObj.ProjectCode }),
            getIntegratedTabsData({ "ProjectCode": projectDetailsObj.ProjectCode, "flag": "Equipment" }),
            getAllDocumentsDataForProject(projectDetailsObj.ProjectCode),
            ServiceMasterAllData(),
        ]);

        if (pendingDocReview.length > 0) {
            incompeteSessions.push("Document Review Tracker");
        }

        let fireIntVerDataForAllEquipmentsInd = false;
        let distEquip = [];
        let distService = [];
        let arr = [];
        for (let element of activeEquipData) {
            if (arr.indexOf(element.EquipmentCode) === -1) {
                arr.push(element.EquipmentCode)
                distEquip.push({ ServiceCode: element.ServiceCode, EquipmentCode: element.EquipmentCode });
            }
            if (distService.indexOf(element.ServiceCode) === -1) {
                distService.push({ ServiceCode: element.ServiceCode });
            }
        }
        const equipMasterResult = await getSelectedEquipDetails(arr);
        let fatError = false;
        let satError = false;
        for (let element of activeEquipData) {
            let temp = equipMasterResult.find(x => x.EquipmentCode === element.EquipmentCode);
            if (temp?.FATEquipApplicableChecklist !== "") {
                let chkListCategory = temp?.FATEquipApplicableChecklist.split(",");
                for (let dt of chkListCategory || []) {
                    let ftChk = FATChklstData.filter((item) => item.EquipmentCode === element.EquipmentCode && item.EquipmentTag === element.EquipmentTag && item.ChecklistCategory === dt);
                    if (ftChk.length === 0 || !ftChk[0].IsFreeze) {
                        fatError = true;
                        break;
                    }
                }
            }
            if (temp?.SATEquipApplicableChecklist !== "") {
                let chkListCategory = temp?.SATEquipApplicableChecklist.split(",");
                for (let dt of chkListCategory || []) {
                    let stChk = SATChklstData.filter((item) => item.EquipmentCode === element.EquipmentCode && item.EquipmentTag === element.EquipmentTag && item.ChecklistCategory === dt);
                    if (stChk.length === 0 || !stChk[0].IsFreeze) {
                        satError = true
                        break;
                    }
                }
            }
            if (!element.hasOwnProperty("FireIntegrationFlag")) {
                fireIntVerDataForAllEquipmentsInd = true;
            }
        }
        if (fatError) {
            incompeteSessions.push("Factory Acceptance Test");
        }
        if (satError) {
            incompeteSessions.push("Site Acceptance Test");
        }

        let openSORData = [];
        if (sorData?.length > 0) {
            openSORData = sorData?.filter((item) => item.CorrectedStatus === "No");
        }
        if (openSORData?.length > 0) {
            incompeteSessions.push("Site Observation Report");
        }

        if (fireIntVerDataForAllEquipmentsInd) {
            incompeteSessions.push("Fire Integration Verification");
        }
        //Project Document Verification
        let docVerification = {
            "T&C": [{ DocumentType: "Commissioning", flag: "ProjectLevel" },
            // { DocumentType: "Summary", flag: "ProjectLevel" },
            { DocumentType: "Tender", flag: "ServiceLevel", applicableFlag: "TenderInclusion" },
            { DocumentType: "Method", flag: "EquipmentLevel", applicableFlag: "MethodStatement", additionalFlag: false },
            { DocumentType: "Detailed SAT", flag: "EquipmentLevel", applicableFlag: "DetailedReport", additionalFlag: true, additionalFlagField: "SATEquipApplicableChecklist" },
            { DocumentType: "Detailed FAT", flag: "EquipmentLevel", applicableFlag: "DetailedReport", additionalFlag: true, additionalFlagField: "FATEquipApplicableChecklist" },
            { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTMethodStatementCritical", CriticalDoc: true },
            { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTMethodStatementNonCritical", CriticalDoc: false },
            { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTReportCritical", CriticalDoc: true },
            { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTReportNonCritical", CriticalDoc: false }
            ],
            "HOTO": [{ DocumentType: "Commissioning", flag: "ProjectLevel" },
            // { DocumentType: "HOTO", flag: "ProjectLevel" },
            { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTMethodStatementCritical", CriticalDoc: true },
            { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTMethodStatementNonCritical", CriticalDoc: false },
            { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTReportCritical", CriticalDoc: true },
            { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTReportNonCritical", CriticalDoc: false }]
        }
        let docVerErr = false;

        for (let docDt of docVerification[projectDetailsObj.ProjectType]) {
            if (docVerErr) {
                break;
            }
            if (docDt.flag === "ProjectLevel") {
                let docData = projectDocumentData.find((x) => x.DocumentType === docDt.DocumentType);
                if ((docData && docData.FreezeIndication !== "Freeze") || !docData) {
                    docVerErr = true;
                    break;
                }
            }
            if (docDt.flag === "ServiceLevel") {
                for (let srv of distService) {
                    let tmpSrv = srvData.filter(item => item.ServiceCode === srv.ServiceCode);
                    if (docDt.applicableFlag && tmpSrv.length > 0 && tmpSrv[0][docDt.applicableFlag]) {
                        let docData = projectDocumentData.find((x) => x.DocumentType === docDt.DocumentType && x.ServiceCode === srv.ServiceCode);
                        if ((docData && docData.FreezeIndication !== "Freeze") || !docData) {
                            docVerErr = true;
                            break;
                        }
                    }
                }
            }
            if (docDt.flag === "EquipmentLevel") {
                for (let eq of distEquip) {
                    let tmpEqu = equipMasterResult.filter(item => item.EquipmentCode === eq.EquipmentCode);
                    if (docDt.applicableFlag && tmpEqu.length > 0 && tmpEqu[0][docDt.applicableFlag] &&
                        (!docDt.additionalFlag || (docDt.additionalFlag && tmpEqu[0][docDt.additionalFlagField]))) {
                        let docData = projectDocumentData.find((x) => x.DocumentType === docDt.DocumentType && x.ServiceCode === eq.ServiceCode && x.EquipmentCode === eq.EquipmentCode);
                        if ((docData && docData.FreezeIndication !== "Freeze") || !docData) {
                            docVerErr = true;
                            break;
                        }
                    }
                }
            }

            if (docDt.flag === "IST") {
                if (docDt.CriticalDoc && projectDetailsObj.ISTApplicable === "Yes" || !docDt.CriticalDoc) {
                    let docData = projectDocumentData.find((x) => x.DocumentType === docDt.DocumentType && x.SubDocumentType === docDt.SubDocumentType);
                    if ((docData && docData.FreezeIndication !== "Freeze") || !docData) {
                        docVerErr = true;
                        break;
                    }
                }
            }
        }
        if (docVerErr) {
            incompeteSessions.push("Project Documents");
        }

        if (IstEquipData.length > 0) {
            for (let element of IstEquipData) {
                if (element.SystemReadyForIST === "No") {
                    incompeteSessions.push("Integrated System Test (IST)");
                    break;
                }
            }
        }
        return incompeteSessions;
    }

    useImperativeHandle(ref, () => ({
        funcOnNext: async () => {
            let res = false
            res = await handleSubmit();
            return res
        }
    }));


    // const checkErrorValidation = (name, value, error) => {
    //     let disableSubmitVal = false;
    //     if (name === "Location") {
    //         error.Location = {
    //             status: false,
    //             message: "",
    //         };
    //     }
    //     else if (name === "ProjArea") {
    //         if (error.status) {
    //             disableSubmitVal = true;
    //         }
    //         else {
    //             disableSubmitVal = false;
    //         }
    //     }
    //     else if (name === "ProjectCode" && value !== "") {
    //         if (error.status) {
    //             disableSubmitVal = true;
    //         }
    //         else {
    //             disableSubmitVal = false;
    //         }
    //     }
    //     else if (name === "ProjectName" && value !== "") {
    //         if (error.status) {
    //             disableSubmitVal = true;
    //         }
    //         else {
    //             disableSubmitVal = false;
    //         }
    //     }
    //     else if (name === "GodrejEmpName") {
    //         if (error.status) {
    //             disableSubmitVal = true;
    //         }
    //         else {
    //             disableSubmitVal = false;
    //         }
    //     }
    //     else if (name === "OriginalOrderValue") {
    //         if (error.status) {
    //             disableSubmitVal = true;
    //         }
    //         else {
    //             disableSubmitVal = false;
    //         }
    //     }
    //     else if (name === "RevisedOrderValue") {
    //         if (error.status) {
    //             disableSubmitVal = true;
    //         }
    //         else {
    //             disableSubmitVal = false;
    //         }
    //     }
    //     else if (name === "CustomerName") {
    //         if (error.status) {
    //             disableSubmitVal = true;
    //         }
    //         else {
    //             disableSubmitVal = false;
    //         }
    //     }
    //     else if (name === "ClientContactPerson1") {
    //         if (error.status) {
    //             disableSubmitVal = true;
    //         }
    //         else {
    //             disableSubmitVal = false;
    //         }
    //     }
    //     props.setDisableSubmit(disableSubmitVal);
    //     return disableSubmitVal;
    // }

    const checkCurrency = async (e, name) => {
        let curr = "";
        let str = e.toString().split(".");
        if (str[0].indexOf(",") >= 0) {
            e = str[0].replace(/\,/g, ""); // 1125, but a string, so convert it to number
        }
        if (str[1] !== undefined) {
            e = e + "." + str[1];
        }

        if (projectDetailsObj.LocationType === "India") {
            curr = await validINRCurrency(e);
        }
        else {
            curr = await validUSDCurrency(e);
        }


        if (name === "RevisedOrderValue") {
            setProjectDetailsObj(prevState => ({
                ...prevState,
                RevisedOrderValue: curr
            }));
        }
        else if (name === "OriginalOrderValue") {
            setProjectDetailsObj(prevState => ({
                ...prevState,
                OriginalOrderValue: curr,
                RevisedOrderValue: curr
            }));
        }
        else if (name === "ProjArea") {
            setProjectDetailsObj(prevState => ({
                ...prevState,
                ProjArea: curr
            }));
        }
    }

    const validateDetails = (action) => {
        let isError = false;
        const errorMsg = {
            LOB: {},
            DeleteIndication: {},
            ProjectType: {},
            ProjectName: {},
            ProjectCode: {},
            LocationType: {},
            Location: {},
            SiteAddress: {},
            Sector: {},
            Zone: {},
            OriginalOrderValue: {},
            RevisedOrderValue: {},
            OriginalOrderUnit: {},
            RevisedOrderUnit: {},
            ISTApplicable: {},
            GodrejEmpName: {},
            GodrejContactNo: {},
            GodrejEmailId: {},
            ProjArea: {},
            RevisedProjEndDate: {},
            ProjStartDate: {},
            ProjEndDate: {},
            CustomerName: {},
            ClientContactPerson1: {},
            ClientContactNo1: {},
            ClientEmail1: {}
        };
        if (projectDetailsObj.LOB.trim() === "") {
            projectDetailsObj.LOB = "";
            isError = true;
            errorMsg.LOB = {
                status: true,
                message: "LOB is required.",
            };
        }
        if (projectDetailsObj.DeleteIndication.trim() === "") {
            projectDetailsObj.DeleteIndication = "";
            isError = true;
            errorMsg.DeleteIndication = {
                status: true,
                message: "Status is required.",
            };
        }
        if (!projectDetailsObj.ProjectType || projectDetailsObj.ProjectType.trim() === "") {
            projectDetailsObj.ProjectType = "";
            isError = true;
            errorMsg.ProjectType = {
                status: true,
                message: "Project Type is required.",
            };
        }
        if (projectDetailsObj.ProjectCode.trim() === "") {
            projectDetailsObj.ProjectCode = "";
            isError = true;
            errorMsg.ProjectCode = {
                status: true,
                message: "Project Code is required.",
            };
        }
        else {
            errorMsg.ProjectCode = {
                status: false,
                message: "",
            };
        }
        if (projectDetailsObj.ProjectName.trim() === "") {
            projectDetailsObj.ProjectName = "";
            isError = true;
            errorMsg.ProjectName = {
                status: true,
                message: "Project Name is required.",
            };
        }
        if (projectDetailsObj.LocationType.trim() === "") {
            projectDetailsObj.LocationType = "";
            isError = true;
            errorMsg.LocationType = {
                status: true,
                message: "Location Type Name is required.",
            };
        }
        if (projectDetailsObj.Location.trim() === "") {
            projectDetailsObj.Location = "";
            isError = true;
            errorMsg.Location = {
                status: true,
                message: "Location Name is required.",
            };
        }
        if (!projectDetailsObj.SiteAddress || projectDetailsObj.SiteAddress.trim() === "") {
            projectDetailsObj.SiteAddress = "";
            isError = true;
            errorMsg.SiteAddress = {
                status: true,
                message: "Site Address is required.",
            };
        }
        if (projectDetailsObj.Sector.trim() === "") {
            projectDetailsObj.Sector = "";
            isError = true;
            errorMsg.Sector = {
                status: true,
                message: "Sector is required.",
            };
        }
        if (projectDetailsObj.Zone.trim() === "") {
            projectDetailsObj.Zone = "";
            isError = true;
            errorMsg.Zone = {
                status: true,
                message: "Zone is required.",
            };
        }
        if (projectDetailsObj.ProjStartDate.trim() === "") {
            projectDetailsObj.ProjStartDate = "";
            isError = true;
            errorMsg.ProjStartDate = {
                status: true,
                message: "Start Date is required.",
            };
        }
        if (projectDetailsObj.ProjEndDate.trim() === "") {
            projectDetailsObj.ProjEndDate = "";
            isError = true;
            errorMsg.ProjEndDate = {
                status: true,
                message: "End Date is required.",
            };
        }
        if (projectDetailsObj.RevisedProjEndDate.trim() === "") {
            projectDetailsObj.RevisedProjEndDate = "";
            isError = true;
            errorMsg.RevisedProjEndDate = {
                status: true,
                message: "Revised Date is required.",
            };
        }
        if (projectDetailsObj.ProjArea.toString().trim() === "") {
            projectDetailsObj.ProjArea = "";
            isError = true;
            errorMsg.ProjArea = {
                status: true,
                message: "Project Area is required.",
            };
        }
        if (projectDetailsObj.OriginalOrderValue.toString().trim() === "") {
            projectDetailsObj.OriginalOrderValue = "";
            isError = true;
            errorMsg.OriginalOrderValue = {
                status: true,
                message: "Project Order Value is required.",
            };
        }
        if (projectDetailsObj.OriginalOrderUnit.toString().trim() === "") {
            projectDetailsObj.OriginalOrderUnit = "";
            isError = true;
            errorMsg.OriginalOrderUnit = {
                status: true,
                message: "Project Order Value is required.",
            };
        }
        if (projectDetailsObj.RevisedOrderValue.toString().trim() === "") {
            projectDetailsObj.RevisedOrderValue = "";
            isError = true;
            errorMsg.RevisedOrderValue = {
                status: true,
                message: "Project Order Value is required.",
            };
        }
        if (projectDetailsObj.RevisedOrderUnit.toString().trim() === "") {
            projectDetailsObj.RevisedOrderUnit = "";
            isError = true;
            errorMsg.RevisedOrderUnit = {
                status: true,
                message: "Revised Order unit is required.",
            };
        }
        if (!projectDetailsObj.ISTApplicable || projectDetailsObj.ISTApplicable.trim() === "") {
            projectDetailsObj.ISTApplicable = "";
            isError = true;
            errorMsg.ISTApplicable = {
                status: true,
                message: "IST Applicable for Critical Area is required.",
            };
        }
        if (projectDetailsObj.GodrejEmpName.trim() === "") {
            projectDetailsObj.GodrejEmpName = "";
            isError = true;
            errorMsg.GodrejEmpName = {
                status: true,
                message: "Employee Name is required.",
            };
        }
        if (projectDetailsObj.GodrejContactNo.toString().trim() === "") {
            projectDetailsObj.GodrejContactNo = "";
            isError = true;
            errorMsg.GodrejContactNo = {
                status: true,
                message: "Contact no. is required.",
            };
        }
        if (projectDetailsObj.GodrejContactNo.toString().trim() !== "" && isMobNumber(projectDetailsObj.GodrejContactNo.trim())) {
            isError = true;
            errorMsg.GodrejContactNo = {
                status: true,
                message: "Enter valid contact no.",
            };
        }
        if (projectDetailsObj.GodrejEmailId.trim() === "") {
            projectDetailsObj.GodrejEmailId = "";
            isError = true;
            errorMsg.GodrejEmailId = {
                status: true,
                message: "Email id is required.",
            };
        }
        if (projectDetailsObj.GodrejEmailId.trim() !== "") {
            let res = isEmail(projectDetailsObj.GodrejEmailId.trim());
            if (res.status) {
                isError = true;
                errorMsg.GodrejEmailId = {
                    status: true,
                    message: "Enter valid Email Id.",
                };
            }
            else {
                errorMsg.GodrejEmailId = {
                    status: false,
                    message: "",
                };
            }
        }
        if (projectDetailsObj.CustomerName.trim() === "") {
            projectDetailsObj.CustomerName = "";
            isError = true;
            errorMsg.CustomerName = {
                status: true,
                message: "Customer Name is required.",
            };
        }
        if (projectDetailsObj.ClientContactPerson1.trim() === "") {
            projectDetailsObj.ClientContactPerson1 = "";
            isError = true;
            errorMsg.ClientContactPerson1 = {
                status: true,
                message: "Clinet name is required.",
            };
        }
        if (projectDetailsObj.ClientContactNo1 === "") {
            projectDetailsObj.ClientContactNo1 = "";
            isError = true;
            errorMsg.ClientContactNo1 = {
                status: true,
                message: "Client contact no. is required.",
            };
        }
        if (projectDetailsObj.ClientContactNo1 !== "" && isMobNumber(projectDetailsObj.ClientContactNo1.toString())) {
            isError = true;
            errorMsg.ClientContactNo1 = {
                status: true,
                message: "Enter valid contact no.",
            };
        }
        if (projectDetailsObj.ClientEmail1.trim() === "") {
            projectDetailsObj.ClientEmail1 = "";
            isError = true;
            errorMsg.ClientEmail1 = {
                status: true,
                message: "Client Email id is required.",
            };
        }
        if (projectDetailsObj.ClientEmail1.trim() !== "") {
            let res = isEmail(projectDetailsObj.ClientEmail1.trim());
            if (res.status) {
                isError = true;
                errorMsg.ClientEmail1 = {
                    status: true,
                    message: "Enter valid Email Id.",
                };
            }
            else {
                errorMsg.ClientEmail1 = {
                    status: false,
                    message: "",
                };
            }
        }
        if (projectDetailsObj.ClientContactNo2 !== "" && isMobNumber(projectDetailsObj.ClientContactNo2)) {
            isError = true;
            errorForNonMandatory.ClientContactNo2 = {
                status: true,
                message: "Client contact no. is required.",
            };
        }
        if (projectDetailsObj.ClientEmail2.trim() !== "") {
            let res = isEmail(projectDetailsObj.ClientEmail2.trim());
            if (res.status) {
                isError = true;
                errorForNonMandatory.ClientEmail2 = {
                    status: true,
                    message: "Enter valid Email Id..",
                };
            }
            else {
                errorForNonMandatory.ClientEmail2 = {
                    status: false,
                    message: "",
                };
            }
        }
        setError(errorMsg);
        return isError;
    }

    const validationStakeholder = (event) => {
        let isError = false;
        let errormsg = [];

        if (event === "submit" && tabData.length === 1 && tabData[0].StakeholderType === "" && tabData[0].StakeCompanyDivision === "" && tabData[0].PMCOfficialContNo === "" && tabData[0].PMCEmailId === "" && tabData[0].PMCContactPerson === "") {
            isError = false;
            setErrorStakeholder([stakeobj]);
        }
        else if (tabData.length > 0) {
            tabData.forEach((element, index) => {
                let temp = {
                    StakeholderType: {
                        status: false,
                        message: ""
                    },
                    StakeCompanyDivision: {
                        status: false,
                        message: ""
                    },
                    PMCContactPerson: {
                        status: false,
                        message: ""
                    },
                    PMCOfficialContNo: {
                        status: false,
                        message: ""
                    },
                    PMCEmailId: {
                        status: false,
                        message: ""
                    }
                };
                if (element.StakeholderType === "") {
                    element.StakeholderType = "";
                    isError = true;
                    temp.StakeholderType = {
                        status: true,
                        message: "Stakeholder Type is required."
                    };
                }
                if (element.StakeCompanyDivision === "") {
                    element.StakeCompanyDivision = "";
                    isError = true;
                    temp.StakeCompanyDivision = {
                        status: true,
                        message: "Company is required."
                    };
                }
                if (element.PMCContactPerson.trim() === "") {
                    element.PMCContactPerson = "";
                    isError = true;
                    temp.PMCContactPerson = {
                        status: true,
                        message: "Stakeholder Details - Contact person name is required.",
                    };
                }
                if (element.PMCOfficialContNo === "") {
                    element.PMCOfficialContNo = '';
                    isError = true;
                    temp.PMCOfficialContNo = {
                        status: true,
                        message: "Stakeholder Details - Contact Number is required.",
                    };
                }
                else if (element.PMCOfficialContNo !== "" && isMobNumber(element.PMCOfficialContNo)) {
                    isError = true;
                    temp.PMCOfficialContNo = {
                        status: true,
                        message: "Stakeholder Details - Enter valid Contact Number Eg. +91 8888888888",
                    };
                }
                if (element.PMCEmailId.trim() === "") {
                    element.PMCEmailId = "";
                    isError = true;
                    temp.PMCEmailId = {
                        status: true,
                        message: "Stakeholder Details - Email id is required."
                    };
                }
                if (element.PMCEmailId.trim() !== "") {
                    let resp = isEmail(element.PMCEmailId)
                    if (resp.status) {
                        isError = true;
                        temp.PMCEmailId = {
                            status: true,
                            message: "Stakeholder Details - Enter valid Email Id.",
                        };
                    }
                    else {
                        temp.PMCEmailId = {
                            status: false,
                            message: "",
                        };
                    }
                }
                errormsg.push(temp);
            });
            setErrorStakeholder(errormsg);
        }
        return isError;
    }

    const validateStake = () => {
        if (action !== "View") {
            let isError = false;
            let errormsg = [];
            tabData.forEach((element, index) => {
                let temp = {
                    StakeholderType: {
                        status: false,
                        message: ""
                    },
                    StakeCompanyDivision: {
                        status: false,
                        message: ""
                    },
                    PMCContactPerson: {
                        status: false,
                        message: ""
                    },
                    PMCOfficialContNo: {
                        status: false,
                        message: ""
                    },
                    PMCEmailId: {
                        status: false,
                        message: ""
                    }
                };
                if (element.PMCOfficialContNo !== "" && isMobNumber(element.PMCOfficialContNo)) {
                    isError = true;
                    temp.PMCOfficialContNo = {
                        status: true,
                        message: "Stakeholder Details - Enter valid Contact Number Eg. +91 8888888888",
                    };
                }
                if (element.PMCEmailId !== "") {
                    let resp = isEmail(element.PMCEmailId)
                    if (resp.status) {
                        isError = true;
                        temp.PMCEmailId = {
                            status: true,
                            message: "Stakeholder Details - Enter valid Email Id.",
                        };
                    }
                    else {
                        isError = false;
                        temp.PMCEmailId = {
                            status: false,
                            message: "",
                        };
                    }
                }
                errormsg.push(temp);
            });
            setErrorStakeholder(errormsg);
            return isError;
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProjectDetailsObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const checkDate = (e) => {
        const { name, value } = e.target;
        if (e.target.name === 'ProjEndDate') {
            if (new Date(projectDetailsObj.ProjStartDate) > new Date(projectDetailsObj.ProjEndDate)) {
                setError((prevState) => ({
                    ...prevState,
                    ProjEndDate: {
                        status: true,
                        message: "Please select greater end date.",
                    }
                }));
            }
            else {
                setProjectDetailsObj((prevState) => ({
                    ...prevState,
                    [name]: value,
                    RevisedProjEndDate: value
                }));
                setError((prevState) => ({
                    ...prevState,
                    ProjEndDate: {
                        status: false,
                        message: "",
                    },
                    RevisedProjEndDate: {
                        status: false,
                        message: "",
                    }
                }));
            }
        }
        else if (e.target.name === 'RevisedProjEndDate') {
            if (new Date(projectDetailsObj.ProjStartDate) > new Date(projectDetailsObj.RevisedProjEndDate)) {
                setError((prevState) => ({
                    ...prevState,
                    RevisedProjEndDate: {
                        status: true,
                        message: "Please select greater end date.",
                    }
                }));
            }
        }
        else if (e.target.name === "ProjStartDate") {
            if (new Date(projectDetailsObj.ProjStartDate) > new Date(projectDetailsObj.RevisedProjEndDate)) {
                setError((prevState) => ({
                    ...prevState,
                    RevisedProjEndDate: {
                        status: true,
                        message: "Please select greater end date.",
                    }
                }));
            }
            else {
                setError((prevState) => ({
                    ...prevState,
                    RevisedProjEndDate: {
                        status: false,
                        message: "",
                    }
                }));
            }
            if (new Date(projectDetailsObj.ProjStartDate) > new Date(projectDetailsObj.ProjEndDate)) {
                setError((prevState) => ({
                    ...prevState,
                    ProjEndDate: {
                        status: true,
                        message: "Please select greater end date.",
                    }
                }));
            }
            else {
                setError((prevState) => ({
                    ...prevState,
                    ProjEndDate: {
                        status: false,
                        message: "",
                    }
                }));
                setProjectDetailsObj((prevState) => ({
                    ...prevState,
                    RevisedProjEndDate: projectDetailsObj.ProjEndDate
                }));
            }
        }
    };

    const deleteStakeholder = (e) => {
        let confirmBoxText = "Are you sure, do you want to delete Stakeholder Details?";
        let confirmButtonText = "Delete";

        let ids = [...tabData];
        let errIds = [...errorStakeholder];
        ids.forEach(element => {
            if (element.SrNo === e.SrNo) {
                let idx = ids.indexOf(element);
                if (idx != -1) {
                    ids.splice(idx, 1);
                    errIds.splice(idx, 1);
                }
            }
        });

        MySwal.fire({
            text: confirmBoxText,
            showDenyButton: true,
            cancelButtonColor: "#fff",
            confirmButtonColor: "#16911B",
            confirmButtonText: confirmButtonText,
            reverseButtons: true,
            customClass: "swal-confirmation",
            title: "Delete Stakeholder Details"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setTabData(ids);
                setErrorStakeholder(errIds);
                return;
            }
        });
        return;
    }

    const removeComa1 = (e) => {
        let str = e.toString().split(".");
        setUSDCurrency(0);
        if (str[0].indexOf(",") >= 0) {
            let value = str[0].replace(/\,/g, ""); // 1125, but a string, so convert it to number
            value = parseInt(value, 10);
            setUSDCurrency(0);
        }
    };

    const handleChangePerson = (prop, event, index) => {
        const old = tabData[index];
        let updated = {};
        if (prop === "StakeCompanyDivision") {
            updated = { ...old, [prop]: event.CompanyName, "StakeCompanyCode": event.CompanyCode }
        }
        else if (prop === "StakeContactPerson") {
            updated = {
                ...old,
                "PMCContactPerson": event.UserName,
                "PMCOfficialContNo": event.MobileNo,
                "PMCEmailId": event.EmailId
            }
        }
        else {
            updated = { ...old, [prop]: event.target.value }
        }
        const clone = [...tabData];
        clone[index] = updated;
        setTabData(clone);
    }

    const showPopUp = (name, type, key, value, additionalParams) => {
        setPopUp({ name, type, key, value, additionalParams });
        setShowForm(true);
    };

    const addNewRow = () => {
        if (!validationStakeholder("newRow")) {
            if (tabData.length >= 5) {
                MySwal.fire({
                    text: "User can add total 5 records.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
            else {
                setTabData((prev) => [...prev, { SrNo: tabData.length + 1, StakeholderType: '', StakeCompanyDivision: '', PMCContactPerson: '', PMCOfficialContNo: '', PMCEmailId: '' }]);
                setErrorStakeholder((prev) => [...prev, {
                    StakeholderType: {
                        status: false,
                        message: ""
                    },
                    StakeCompanyDivision: {
                        status: false,
                        message: ""
                    },
                    PMCContactPerson: {
                        status: false,
                        message: ""
                    },
                    PMCOfficialContNo: {
                        status: false,
                        message: ""
                    },
                    PMCEmailId: {
                        status: false,
                        message: ""
                    }
                }]);
            }
        }
    };

    useEffect(() => {
        checkCurrency(projectDetailsObj.ProjArea, "ProjArea");
        checkCurrency(projectDetailsObj.OriginalOrderValue, "OriginalOrderValue");
        checkCurrency(projectDetailsObj.RevisedOrderValue, "RevisedOrderValue");
    }, [projectDetailsObj.LocationType])

    const handleChangeRadio = e => {
        if (e.target.value === "Overseas") {
            setProjectDetailsObj(prevState => ({
                ...prevState,
                LocationType: e.target.value,
                LocationCode: "",
                Location: "",
                Country: "",
                RevisedOrderUnit: "USD",
                OriginalOrderUnit: "USD"
            }));
        }
        else {
            setProjectDetailsObj(prevState => ({
                ...prevState,
                LocationType: e.target.value,
                LocationCode: "",
                Location: "",
                Country: "",
                RevisedOrderUnit: "INR",
                OriginalOrderUnit: "INR"
            }));
        }
    };

    const projstatus = [{ label: 'Active' }, { label: 'Inactive' }, { label: 'Closed' }];

    const tempfn = () => {
        if (selectedCompanyUsers !== undefined) {
            showPopUp(
                "Customer User Details1",
                "Single",
                "UserName",
                projectDetailsObj.ClientContactPerson1,
                projectDetailsObj.CompanyCode
            )
            setError((prevState) => ({
                ...prevState,
                ClientContactPerson1: error,
                ClientContactNo1: error,
                ClientEmail1: error
            }))
        }
        else {
            MySwal.fire({
                text: "Please select customer company.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    }

    const checkValidData = async (e) => {
        const { name, value } = e.target;
        if (name === "Location") {
            const data = await getValidUserData("LocationMaster", "Location", value.trim());
            if (data !== undefined && (data.length > 0)) {
                if (data[0].LocationType === projectDetailsObj.LocationType) {
                    setProjectDetailsObj((prevState) => ({
                        ...prevState,
                        LocationCode: data[0].LocationCode,
                        Location: data[0].Location,
                        Country: data[0].Country
                    })
                    )
                    setError((prevState) => ({
                        ...prevState,
                        Location: error,
                    }));
                    return;
                }
                else {
                    setProjectDetailsObj((prevState) => ({
                        ...prevState,
                        LocationCode: "",
                        Location: "",
                        Country: ""
                    })
                    )
                    setError((prevState) => ({
                        ...prevState,
                        Location: {
                            status: true,
                            message: "Enter valid Location Name",
                        }
                    }));
                }
            } else {
                setProjectDetailsObj((prevState) => ({
                    ...prevState,
                    LocationCode: "",
                    Location: "",
                    Country: ""
                })
                )
                setError((prevState) => ({
                    ...prevState,
                    Location: {
                        status: true,
                        message: "Enter valid Location Name",
                    }
                }));
            }
        }
    }

    const checkStakeDetails = (rowIndex) => {
        const old = tabData[rowIndex];
        if (old.StakeCompanyDivision !== "") {
            showPopUp(
                "User Details",
                "Single",
                "UserName",
                old.PMCContactPerson,
                { compCode: old.StakeCompanyCode, rowIndex }
            );
        }
        else {
            MySwal.fire({
                text: "Please select customer company.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    }

    const tooltip = (
        <Tooltip id="tooltip" className="isttooltip">
            Mention INFOR Project Code only.
        </Tooltip>
    );


    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Form
                id="projectdetails"
                name="projectdetails"
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Modal.Body>
                    <Row>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-select">
                                <Form.Select
                                    id="LOB"
                                    name="LOB"
                                    type="text"
                                    placeholder=" "
                                    required
                                    value={projectDetailsObj.LOB}
                                    onChange={(e) => { handleChange(e) }}
                                    disabled={action === "View" ? true : inactiveStatus ? true : false}
                                >
                                    {lobResult ? lobResult.map((lobResult) => (
                                        <option key={lobResult.SK} value={lobResult.LOBName}>{lobResult.LOBName}</option>
                                    )) : null
                                    }
                                </Form.Select>
                                <Form.Label className="frm-label" htmlFor="LOB">LOB</Form.Label>
                                {error.LOB.status && (
                                    <Form.Text className="text-danger">
                                        {error.LOB.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-select">
                                <Form.Select
                                    id="DeleteIndication"
                                    name="DeleteIndication"
                                    type="text"
                                    placeholder=" "
                                    required
                                    value={projectDetailsObj.DeleteIndication}
                                    onChange={(e) => { handleChange(e) }}
                                    disabled={action === "View" || props.rowdata.DeleteIndication === "Closed" ? true : false}
                                >
                                    {projstatus ? projstatus.map((projstatus) => (
                                        <option key={projstatus.label} value={projstatus.label}>{projstatus.label}</option>
                                    )) : null
                                    }
                                </Form.Select>
                                <Form.Label className="frm-label" htmlFor="DeleteIndication">Project Status</Form.Label>
                                {error.DeleteIndication.status && (
                                    <Form.Text className="text-danger">
                                        {error.DeleteIndication.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group>

                                {action === "View" || action === "Edit" ?
                                    <div className="flex-column">
                                        <Form.Label className="fieldLabel locationType">Project Type</Form.Label>
                                        <Form.Label className="semiBoldToggleButton">{projectDetailsObj.ProjectType}</Form.Label>
                                    </div>
                                    : <>
                                        <Row><Form.Label className="fieldLabel locationType">Project Type</Form.Label></Row>
                                        <Row>
                                            <Col lg={6} xs={6}>
                                                <Form.Check className="fieldLabel locationType"
                                                    name="ProjectType"
                                                    value="T&C"
                                                    type="radio"
                                                    label="T&C"
                                                    onChange={handleChange}
                                                    checked={projectDetailsObj.ProjectType === "T&C"}
                                                />
                                            </Col>
                                            <Col lg={6} xs={4}>
                                                <Form.Check className="fieldLabel locationType"
                                                    name={"ProjectType"}
                                                    value="HOTO"
                                                    type="radio"
                                                    label="HOTO"
                                                    onChange={handleChange}
                                                    checked={projectDetailsObj.ProjectType === "HOTO"}
                                                />
                                            </Col>
                                            {error.ProjectType.status && (
                                                <Form.Text className="text-danger">
                                                    {error.ProjectType.message}
                                                </Form.Text>
                                            )}
                                        </Row>
                                    </>
                                }
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjectCode"
                                    type="text"
                                    name="ProjectCode"
                                    placeholder=" "
                                    value={projectDetailsObj.ProjectCode}
                                    onChange={handleChange}
                                    readOnly={action === "View" || action === "Edit" ? true : inactiveStatus ? true : false}
                                    maxLength="20"
                                    onBlur={(e) => {
                                        let error = isAlphanumericWeb(e.target.value);
                                        setError((prevState) => ({
                                            ...prevState,
                                            ProjectCode: error,
                                        }));
                                        // checkErrorValidation("ProjectCode", projectDetailsObj.ProjectCode, error)
                                    }}
                                />
                                <Form.Label htmlFor="ProjectCode">Project Code</Form.Label>
                                {/* <img
                                    src={infowarning}
                                    id="info"
                                    className="imgInfowarning"
                                    title="Mention INFOR Project Code only"
                                >
                                </img> */}
                                <OverlayTrigger placement="bottom-start" overlay={tooltip}>
                                    <img
                                        src={infoImage}
                                        id="info"
                                        className="IstScriptInfo"
                                    />
                                </OverlayTrigger>
                                {error.ProjectCode.status && (
                                    <Form.Text className="text-danger">
                                        {error.ProjectCode.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={8} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjectName"
                                    type="text"
                                    name="ProjectName"
                                    maxLength="250"
                                    placeholder=" "
                                    value={projectDetailsObj.ProjectName}
                                    onChange={handleChange}
                                    readOnly={action === "View" ? true : inactiveStatus ? true : false}
                                    className={
                                        error.ProjectName.status
                                            ? "placeHolderInput inputText text-field-with-error"
                                            : "placeHolderInput inputText"
                                    }
                                    onBlur={e => {
                                        setError((prevState) => ({
                                            ...prevState,
                                            ProjectName: error,
                                        }));
                                        // checkErrorValidation("ProjectName", projectDetailsObj.ProjectName, error)
                                    }}
                                />
                                <Form.Label htmlFor="ProjectName">Project</Form.Label>
                                {error.ProjectName.status && (
                                    <Form.Text className="text-danger">
                                        {error.ProjectName.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Row><Form.Label className="fieldLabel locationType">Location Type</Form.Label></Row>
                            {action === "View" || action === "Edit" || inactiveStatus ?
                                <Row>
                                    <Col>
                                        <Form.Label className="fieldLabel semiBoldToggleButton">{projectDetailsObj.LocationType === "India" ? "India" : "Overseas"}</Form.Label>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col lg={6} xs={6}>
                                        <Form.Check className="fieldLabel locationType"
                                            value="India"
                                            type="radio"
                                            aria-label="radio 1"
                                            label="India"
                                            onChange={handleChangeRadio}
                                            checked={projectDetailsObj.LocationType === "India"}
                                        />
                                    </Col>
                                    <Col lg={6} xs={4}>
                                        <Form.Check className="fieldLabel locationType"
                                            value="Overseas"
                                            type="radio"
                                            aria-label="radio 2"
                                            label="Overseas"
                                            onChange={handleChangeRadio}
                                            checked={projectDetailsObj.LocationType === "Overseas"}
                                        />
                                    </Col>
                                    {error.LocationType.status && (
                                        <Form.Text className="text-danger">
                                            {error.LocationType.message}
                                        </Form.Text>
                                    )}
                                </Row>
                            }
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="Location"
                                    type="text"
                                    name="Location"
                                    maxLength="50"
                                    placeholder=" "
                                    readOnly={action === "View" || action === "Edit" || inactiveStatus ? true : false}
                                    value={projectDetailsObj.Location}
                                    onBlur={(e) => inactiveStatus ? false : checkValidData(e)}
                                    onChange={handleChange}
                                />
                                <Form.Label htmlFor="Location">Location</Form.Label>
                                <BiSelectMultiple
                                    className={["modalPopUpIconProjDetails",
                                        action === "View" || action === "Edit" || inactiveStatus ? "d-none" : ""
                                    ].join(" ")}
                                    size={20}
                                    onClick={() => {
                                        showPopUp(
                                            "Location Master",
                                            "Single",
                                            "Location",
                                            projectDetailsObj.Location,
                                            projectDetailsObj.LocationType
                                        )
                                        setError((prevState) => ({
                                            ...prevState,
                                            Location: error,
                                        }));
                                    }}
                                />
                                {error.Location.status && (
                                    <Form.Text className="text-danger">
                                        {error.Location.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="Country"
                                    type="text"
                                    name="Country"
                                    maxLength="50"
                                    placeholder=" "
                                    value={projectDetailsObj.Country}
                                    onChange={handleChange}
                                    readOnly
                                />
                                <Form.Label htmlFor="Country">Country</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="SiteAddress"
                                    type="text"
                                    name="SiteAddress"
                                    maxLength="500"
                                    placeholder=" "
                                    value={projectDetailsObj.SiteAddress}
                                    onChange={handleChange}
                                    readOnly={action === "View" || inactiveStatus ? true : false}
                                />
                                <Form.Label htmlFor="SiteAddress">Site Address</Form.Label>
                                {error.SiteAddress.status && (
                                    <Form.Text className="text-danger">
                                        {error.SiteAddress.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-select">
                                <Form.Select
                                    id="Sector"
                                    name="Sector"
                                    type="text"
                                    placeholder=" "
                                    required
                                    value={projectDetailsObj.Sector}
                                    onChange={(e) => { handleChange(e) }}
                                    disabled={action === "View" ? true : inactiveStatus ? true : false}
                                >
                                    <option value="">Select</option>
                                    {sectorResult ? sectorResult.map((sectorResult) => (
                                        <option key={sectorResult.SK} value={sectorResult.SectorName}>{sectorResult.SectorName}</option>
                                    )) : null
                                    }
                                </Form.Select>
                                <Form.Label className="frm-label" htmlFor="Sector">Sector</Form.Label>
                                {error.Sector.status && (
                                    <Form.Text className="text-danger">
                                        {error.Sector.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjArea"
                                    type="text"
                                    name="ProjArea"
                                    maxLength="20"
                                    placeholder=" "
                                    value={projectDetailsObj.ProjArea}
                                    readOnly={action === "View" ? true : inactiveStatus ? true : false}
                                    onBlur={(e) => {
                                        let error = isNumberWithDecimalPM(e.target.value);
                                        if (error.status) {
                                            setError((prevState) => ({
                                                ...prevState,
                                                ProjArea: error,
                                            }));
                                        } else {
                                            error = validDecimals(e.target.value);
                                            setError((prevState) => ({
                                                ...prevState,
                                                ProjArea: error,
                                            }));
                                        }
                                        // checkErrorValidation("ProjArea", projectDetailsObj.ProjArea, error)
                                        checkCurrency(e.target.value, "ProjArea");
                                    }}
                                    onClick={(e) => {
                                        removeComa1(e.target.value);
                                    }}
                                    onChange={handleChange}
                                />
                                <Form.Label htmlFor="ProjArea">Project Area (Sq.Ft.)</Form.Label>
                                {error.ProjArea.status && (
                                    <Form.Text className="text-danger">
                                        {error.ProjArea.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-select">
                                <Form.Select
                                    id="Zone"
                                    name="Zone"
                                    type="text"
                                    placeholder=" "
                                    required
                                    value={projectDetailsObj.Zone}
                                    onChange={(e) => { handleChange(e) }}
                                    disabled={action === "View" || inactiveStatus ? true : false}
                                >
                                    <option value="">Select</option>
                                    {zoneResult ? zoneResult.map((zoneResult) => (
                                        <option key={zoneResult.SK} value={zoneResult.ZoneName}>{zoneResult.ZoneName}</option>
                                    )) : null
                                    }
                                </Form.Select>
                                <Form.Label className="frm-label" htmlFor="Zone">Zone</Form.Label>
                                {error.Zone.status && (
                                    <Form.Text className="text-danger">
                                        {error.Zone.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjStartDate"
                                    type="date"
                                    name="ProjStartDate"
                                    maxLength="50"
                                    placeholder="Date"
                                    value={projectDetailsObj.ProjStartDate == "" ? todayDate.substring(0, 10) : projectDetailsObj.ProjStartDate}
                                    onChange={handleChange}
                                    readOnly={action === "View" || inactiveStatus || (action === "Edit" && (transactionData.designReviewData || transactionData.checklistData)) ? true : false}
                                    onBlur={checkDate}
                                />
                                <Form.Label htmlFor="ProjStartDate">Project Start Date</Form.Label>
                                {error.ProjStartDate.status && (
                                    <Form.Text className="text-danger">
                                        {error.ProjStartDate.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="ProjEndDate"
                                    type="date"
                                    name="ProjEndDate"
                                    maxLength="100"
                                    placeholder=""
                                    value={projectDetailsObj.ProjEndDate}
                                    onChange={handleChange}
                                    readOnly={action === "View" || inactiveStatus || (action === "Edit" && (transactionData.designReviewData || transactionData.checklistData)) ? true : false}
                                    onBlur={!inactiveStatus ? checkDate : null}
                                />
                                <Form.Label htmlFor="ProjEndDate">Project End Date</Form.Label>
                                {error.ProjEndDate.status && (
                                    <Form.Text className="text-danger">
                                        {error.ProjEndDate.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="RevisedProjEndDate"
                                    type="date"
                                    name="RevisedProjEndDate"
                                    maxLength="100"
                                    placeholder=""
                                    value={projectDetailsObj.RevisedProjEndDate}
                                    onBlur={inactiveStatus ? null : checkDate}
                                    onChange={handleChange}
                                    readOnly={action === "View" || inactiveStatus ? true : false}
                                />
                                <Form.Label htmlFor="RevisedProjEndDate">Revised Project End Date</Form.Label>
                                {error.RevisedProjEndDate.status && (
                                    <Form.Text className="text-danger">
                                        {error.RevisedProjEndDate.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={3} xs={9}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="OriginalOrderValue"
                                    type="text"
                                    name="OriginalOrderValue"
                                    maxLength="20"
                                    placeholder=" "
                                    value={projectDetailsObj.OriginalOrderValue}
                                    readOnly={action === "View" || (action === "Edit" && (transactionData.designReviewData || transactionData.checklistData)) || inactiveStatus ? true : false}
                                    onBlur={(e) => {
                                        let error = isNumberWithDecimalPM(e.target.value);
                                        if (error.status) {
                                            setError((prevState) => ({
                                                ...prevState,
                                                OriginalOrderValue: error,
                                            }));
                                        } else {
                                            error = validDecimals(e.target.value);
                                            setError((prevState) => ({
                                                ...prevState,
                                                OriginalOrderValue: error,
                                            }));
                                        }
                                        // checkErrorValidation("OriginalOrderValue", projectDetailsObj.OriginalOrderValue, error)
                                        checkCurrency(e.target.value, "OriginalOrderValue");
                                    }}
                                    onClick={(e) => {
                                        removeComa1(e.target.value);
                                    }}
                                    onChange={handleChange}
                                />
                                <Form.Label htmlFor="OriginalOrderValue">Original Order Value</Form.Label>
                                {error.OriginalOrderValue.status && (
                                    <Form.Text className="text-danger">
                                        {error.OriginalOrderValue.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={1} xs={3}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="OriginalOrderUnit"
                                    type="text"
                                    name="OriginalOrderUnit"
                                    placeholder=" "
                                    value={projectDetailsObj.OriginalOrderUnit}
                                    onChange={handleChange}
                                    readOnly
                                    className={
                                        error.OriginalOrderUnit.status
                                            ? "placeHolderInput inputText text-field-with-error"
                                            : "placeHolderInput inputText"
                                    }
                                />
                                <Form.Label htmlFor="OriginalOrderUnit">Unit</Form.Label>
                                {error.OriginalOrderUnit.status && (
                                    <Form.Text className="text-danger">
                                        {error.OriginalOrderUnit.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={3} xs={9}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="RevisedOrderValue"
                                    type="text"
                                    name="RevisedOrderValue"
                                    maxLength="20"
                                    placeholder=" "
                                    value={projectDetailsObj.RevisedOrderValue}
                                    readOnly={action === "View" || inactiveStatus ? true : false}
                                    onBlur={(e) => {
                                        let error = isNumberWithDecimalPM(e.target.value);
                                        if (error.status) {
                                            setError((prevState) => ({
                                                ...prevState,
                                                RevisedOrderValue: error,
                                            }));
                                        } else {
                                            error = validDecimals(e.target.value);
                                            setError((prevState) => ({
                                                ...prevState,
                                                RevisedOrderValue: error,
                                            }));
                                        }
                                        // checkErrorValidation("RevisedOrderValue", projectDetailsObj.RevisedOrderValue, error);
                                        checkCurrency(e.target.value, "RevisedOrderValue");
                                    }}
                                    onChange={handleChange}
                                />
                                <Form.Label htmlFor="RevisedOrderValue">Revised Order Value</Form.Label>
                                {error.RevisedOrderValue.status && (
                                    <Form.Text className="text-danger">
                                        {error.RevisedOrderValue.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={1} xs={3}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="RevisedOrderUnit"
                                    type="text"
                                    name="RevisedOrderUnit"
                                    placeholder=" "
                                    value={projectDetailsObj.RevisedOrderUnit}
                                    onChange={handleChange}
                                    readOnly
                                />
                                <Form.Label htmlFor="RevisedOrderUnit">Unit</Form.Label>
                                {error.RevisedOrderUnit.status && (
                                    <Form.Text className="text-danger">
                                        {error.RevisedOrderUnit.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group>
                                {action === "View" ?
                                    <div className="flex-column">
                                        <Form.Label className="fieldLabel locationType">IST Applicable for Critical Area</Form.Label>
                                        <Form.Label className="semiBoldToggleButton">{projectDetailsObj.ISTApplicable}</Form.Label>
                                    </div>
                                    :
                                    <>
                                        <Row><Form.Label className="fieldLabel locationType">IST Applicable for Critical Area</Form.Label></Row>
                                        <Row>
                                            <Col lg={6} xs={6}>
                                                <Form.Check className="fieldLabel locationType"
                                                    name="ISTApplicable"
                                                    value="Yes"
                                                    type="radio"
                                                    label="Yes"
                                                    onChange={handleChange}
                                                    checked={projectDetailsObj.ISTApplicable === "Yes"}
                                                />
                                            </Col>
                                            <Col lg={6} xs={4}>
                                                <Form.Check className="fieldLabel locationType"
                                                    name={"ISTApplicable"}
                                                    value="No"
                                                    type="radio"
                                                    label="No"
                                                    onChange={handleChange}
                                                    checked={projectDetailsObj.ISTApplicable === "No"}
                                                />
                                            </Col>
                                            {error.ISTApplicable.status && (
                                                <Form.Text className="text-danger">
                                                    {error.ISTApplicable.message}
                                                </Form.Text>
                                            )}
                                        </Row>
                                    </>
                                }
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="GodrejEmpName"
                                    type="text"
                                    name="GodrejEmpName"
                                    maxLength="50"
                                    placeholder=" "
                                    value={projectDetailsObj.GodrejEmpName}
                                    onChange={handleChange}
                                    readOnly
                                    onBlur={(e) => {
                                        setError((prevState) => ({
                                            ...prevState,
                                            GodrejEmpName: error,
                                        }));
                                        // checkErrorValidation("GodrejEmpName", projectDetailsObj.GodrejEmpName, error)
                                        checkValidData(e)
                                    }}
                                />
                                <Form.Label htmlFor="GodrejEmpName">Godrej ENE Employee</Form.Label>
                                <BiSelectMultiple
                                    className={["modalPopUpIconProjDetails",
                                        action === "View" || inactiveStatus ? "d-none" : ""
                                    ].join(" ")}
                                    size={20}
                                    onClick={() => {
                                        showPopUp(
                                            "Godrej Employee Details",
                                            "Single",
                                            "UserName",
                                            projectDetailsObj.GodrejEmpName,
                                            {}
                                        );
                                        setError((prevState) => ({
                                            ...prevState,
                                            GodrejEmpName: error,
                                        }));
                                        // checkErrorValidation("GodrejEmpName", projectDetailsObj.GodrejEmpName, error)
                                    }}
                                />
                                {error.GodrejEmpName.status && (
                                    <Form.Text className="text-danger">
                                        {error.GodrejEmpName.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="GodrejContactNo"
                                    type="text"
                                    name="GodrejContactNo"
                                    maxLength="20"
                                    placeholder=" "
                                    value={projectDetailsObj.GodrejContactNo}
                                    onChange={handleChange}
                                    readOnly={action === "View" || inactiveStatus ? true : false}
                                    onBlur={(e) => {
                                        let error = isMobNumber(e.target.value);
                                        if (error) {
                                            setError((prevState) => ({
                                                ...prevState,
                                                GodrejContactNo: {
                                                    status: true,
                                                    message: "Enter valid Contact No. Eg. +91 8888888888"
                                                },
                                            }));
                                        }
                                        else {
                                            setError((prevState) => ({
                                                ...prevState,
                                                GodrejContactNo: {
                                                    status: false,
                                                    message: ""
                                                }
                                            }));
                                        }
                                        // checkErrorValidation("GodrejContactNo", projectDetailsObj.GodrejContactNo, error);
                                    }}
                                />
                                <Form.Label htmlFor="GodrejContactNo">Contact No. Eg. <span className="telephone-no"> +91 8888888888</span></Form.Label>
                                {error.GodrejContactNo.status && (
                                    <Form.Text className="text-danger">
                                        {error.GodrejContactNo.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="GodrejEmailId"
                                    type="text"
                                    name="GodrejEmailId"
                                    maxLength="100"
                                    placeholder=" "
                                    value={projectDetailsObj.GodrejEmailId}
                                    onChange={handleChange}
                                    readOnly={action === "View" || inactiveStatus ? true : false}
                                    onBlur={(e) => {
                                        let error = isEmail(e.target.value);
                                        setError((prevState) => ({
                                            ...prevState,
                                            GodrejEmailId: error,
                                        }));
                                    }}
                                />
                                <Form.Label htmlFor="GodrejEmailId">Email ID</Form.Label>
                                {error.GodrejEmailId.status && (
                                    <Form.Text className="text-danger">
                                        {error.GodrejEmailId.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="SubHeaderProjectDetails">
                        <Col md={10} xs={10}>Customer Details<br /></Col>
                        <Col md={2} xs={2} className="prevHideButton customerArrowResponsive">
                            <img src={BackArrow}
                                alt="BackArrow"
                                onClick={() => setCustomerDetailsDisplay(!customerDetailsDisplay)}
                            />
                        </Col>
                    </Row>
                    {
                        customerDetailsDisplay && (
                            <Row>
                                <Col lg={4} xs={12}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id="CustomerName"
                                            type="text"
                                            name="CustomerName"
                                            maxLength="50"
                                            placeholder=" "
                                            value={projectDetailsObj.CustomerName}
                                            onChange={handleChange}
                                            readOnly
                                            onBlur={(e) => {
                                                setError((prevState) => ({
                                                    ...prevState,
                                                    CustomerName: error,
                                                }));
                                                // checkErrorValidation("CustomerName", projectDetailsObj.CustomerName, error)
                                            }}
                                        />
                                        <Form.Label htmlFor="CustomerName">Customer</Form.Label>
                                        <BiSelectMultiple
                                            className={["modalPopUpIconProjDetails",
                                                action === "View" || inactiveStatus ? "d-none" : ""
                                            ].join(" ")}
                                            size={20}
                                            onClick={() => {
                                                showPopUp(
                                                    "Company Master without ENE",
                                                    "Single",
                                                    "CompanyName",
                                                    projectDetailsObj.CustomerName,
                                                    {}
                                                );
                                                setError((prevState) => ({
                                                    ...prevState,
                                                    CustomerName: error
                                                }));
                                            }}
                                        />
                                        {error.CustomerName.status && (
                                            <Form.Text className="text-danger">
                                                {error.CustomerName.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={7}></Col>
                                <Col lg={4} xs={12}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id="ClientContactPerson1"
                                            type="text"
                                            name="ClientContactPerson1"
                                            maxLength="50"
                                            placeholder=" "
                                            value={projectDetailsObj.ClientContactPerson1}
                                            onChange={handleChange}
                                            readOnly={action === "View" || inactiveStatus ? true : false}
                                            onBlur={(e) => {
                                                setError((prevState) => ({
                                                    ...prevState,
                                                    ClientContactPerson1: error,
                                                }));
                                                // checkErrorValidation("ClientContactPerson1", projectDetailsObj.ClientContactPerson1, error)
                                            }}
                                        />
                                        <Form.Label htmlFor="ClientContactPerson1">Customer Contact Person - 1</Form.Label>
                                        <BiSelectMultiple
                                            className={["modalPopUpIconProjDetails",
                                                action === "View" || inactiveStatus ? "d-none" : ""
                                            ].join(" ")}
                                            size={20}
                                            onClick={
                                                tempfn
                                            }
                                        />
                                        {error.ClientContactPerson1.status && (
                                            <Form.Text className="text-danger">
                                                {error.ClientContactPerson1.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={4} xs={12}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id="ClientContactNo1"
                                            type="text"
                                            name="ClientContactNo1"
                                            maxLength="20"
                                            placeholder=" "
                                            value={projectDetailsObj.ClientContactNo1}
                                            onChange={handleChange}
                                            readOnly={action === "View" || inactiveStatus ? true : false}
                                            onBlur={(e) => {
                                                if (e.target.value !== "" && action !== "View") {
                                                    let error = isMobNumber(e.target.value);
                                                    if (error) {
                                                        setError((prevState) => ({
                                                            ...prevState,
                                                            ClientContactNo1: {
                                                                status: true,
                                                                message: "Enter valid Contact No. Eg. +91 8888888888"
                                                            },
                                                        }));
                                                    }
                                                    else {
                                                        setError((prevState) => ({
                                                            ...prevState,
                                                            ClientContactNo1: {
                                                                status: false,
                                                                message: ""
                                                            }
                                                        }));
                                                    }
                                                }
                                                else {
                                                    setError((prevState) => ({
                                                        ...prevState,
                                                        ClientContactNo1: {
                                                            status: false,
                                                            message: ""
                                                        }
                                                    }));
                                                }
                                            }} />
                                        <Form.Label htmlFor="ClientContactNo1">Contact No. - 1 <span className="telephone-no"> +91 8888888888</span></Form.Label>
                                        {error.ClientContactNo1.status && (
                                            <Form.Text className="text-danger">
                                                {error.ClientContactNo1.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={4} xs={12}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id="ClientEmail1"
                                            type="text"
                                            name="ClientEmail1"
                                            maxLength="100"
                                            placeholder=" "
                                            value={projectDetailsObj.ClientEmail1}
                                            onChange={handleChange}
                                            readOnly={action === "View" || inactiveStatus ? true : false}
                                            onBlur={(e) => {
                                                if (action !== "View") {
                                                    if (e.target.value !== "") {
                                                        let error = isEmail(e.target.value);
                                                        setError((prevState) => ({
                                                            ...prevState,
                                                            ClientEmail1: error,
                                                        }));
                                                    }
                                                    else {
                                                        setError((prevState) => ({
                                                            ...prevState,
                                                            ClientEmail1: {
                                                                status: false,
                                                                message: ""
                                                            },
                                                        }));
                                                    }
                                                }
                                            }}
                                        />
                                        <Form.Label htmlFor="ClientEmail1">Email - 1</Form.Label>
                                        {error.ClientEmail1.status && (
                                            <Form.Text className="text-danger">
                                                {error.ClientEmail1.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={4} xs={12}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id="ClientContactPerson2"
                                            type="text"
                                            name="ClientContactPerson2"
                                            maxLength="50"
                                            placeholder=" "
                                            value={projectDetailsObj.ClientContactPerson2}
                                            onChange={handleChange}
                                            readOnly={action === "View" || action === "Edit" || inactiveStatus ? true : false}
                                        />
                                        <Form.Label className="frm-label" htmlFor="ClientContactPerson2" title="Customer Contact Person - 2 (optional)">Customer Contact Person - 2 <span className="italicFont"> (optional)</span></Form.Label>
                                        <BiSelectMultiple
                                            className={["modalPopUpIconProjDetails",
                                                action === "View" || inactiveStatus ? "d-none" : ""
                                            ].join(" ")}
                                            size={20}
                                            onClick={() => {
                                                showPopUp(
                                                    "Customer User Details2",
                                                    "Single",
                                                    "UserName",
                                                    projectDetailsObj.ClientContactPerson2,
                                                    projectDetailsObj.CompanyCode
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={4} xs={12}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id="ClientContactNo2"
                                            type="text"
                                            name="ClientContactNo2"
                                            maxLength="20"
                                            placeholder=" "
                                            value={projectDetailsObj.ClientContactNo2}
                                            onChange={handleChange}
                                            readOnly={action === "View" || inactiveStatus ? true : false}
                                            onBlur={(e) => {
                                                if (e.target.value !== "") {
                                                    let error = isMobNumber(e.target.value);
                                                    if (error) {
                                                        setErrorForNonMandatory((prevState) => ({
                                                            ...prevState,
                                                            ClientContactNo2: {
                                                                status: true,
                                                                message: "Enter valid Contact No. Eg. +91 8888888888"
                                                            },
                                                        }));
                                                    }
                                                    else {
                                                        setErrorForNonMandatory((prevState) => ({
                                                            ...prevState,
                                                            ClientContactNo2: {
                                                                status: false,
                                                                message: ""
                                                            }
                                                        }));
                                                    }
                                                }
                                                else {
                                                    setErrorForNonMandatory((prevState) => ({
                                                        ...prevState,
                                                        ClientContactNo2: {
                                                            status: false,
                                                            message: ""
                                                        }
                                                    }));
                                                }
                                            }}
                                        />
                                        <Form.Label htmlFor="ClientContactNo2">Contact No. - 2 <span className="telephone-no"> +91 8888888888</span> <span className="italicFont"> (optional)</span></Form.Label>
                                        {errorForNonMandatory.ClientContactNo2.status && (
                                            <Form.Text className="text-danger">
                                                {errorForNonMandatory.ClientContactNo2.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col lg={4} xs={12}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id="ClientEmail2"
                                            type="text"
                                            name="ClientEmail2"
                                            maxLength="100"
                                            placeholder=" "
                                            value={projectDetailsObj.ClientEmail2}
                                            onChange={handleChange}
                                            readOnly={action === "View" || inactiveStatus ? true : false}
                                            onBlur={(e) => {
                                                if (e.target.value !== "") {
                                                    let error = isEmail(e.target.value);
                                                    if (error.status) {
                                                        setErrorForNonMandatory((prevState) => ({
                                                            ...prevState,
                                                            ClientEmail2: {
                                                                status: true,
                                                                message: "Enter valid Email Id."
                                                            },
                                                        }));
                                                    }
                                                    else {
                                                        setErrorForNonMandatory((prevState) => ({
                                                            ...prevState,
                                                            ClientEmail2: {
                                                                status: false,
                                                                message: ""
                                                            }
                                                        }));
                                                    }
                                                }
                                                else {
                                                    setErrorForNonMandatory((prevState) => ({
                                                        ...prevState,
                                                        ClientEmail2: {
                                                            status: false,
                                                            message: ""
                                                        }
                                                    }));
                                                }
                                            }}
                                        />
                                        <Form.Label htmlFor="ClientEmail2">Email - 2 <span className="italicFont"> (optional)</span></Form.Label>
                                        {errorForNonMandatory.ClientEmail2.status && (
                                            <Form.Text className="text-danger">
                                                {errorForNonMandatory.ClientEmail2.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                    <hr className="d-md-none d-lg-none" />
                    <Row className="SubHeaderProjectDetails">
                        <Col md={10} xs={10}><span>PMC / MEP Consultants / Contractor Details
                            <span className="optional"> (optional)</span></span></Col>
                        <Col md={2} xs={2} className="prevHideButton customerArrowResponsive">
                            <img src={BackArrow}
                                alt="BackArrow"
                                onClick={() => setContractorDisplay(!contractorDisplay)}
                            />
                        </Col>
                    </Row>
                    {
                        contractorDisplay && tabData.map((item, index) => {
                            return (
                                <Row key={index}>
                                    <Col lg={4} xs={12} className="prevHideButton showDisplay">
                                        {action === "View" || inactiveStatus ?
                                            <></>
                                            :
                                            <div className="addNewRow deleteStakeRowResponsive">
                                                <img src={Delete}
                                                    className="stakeDeleteIcon"
                                                    alt="BackArrow"
                                                    onClick={() => deleteStakeholder(item)}
                                                />
                                                <Link className="deletelinklabel" onClick={() => deleteStakeholder(item)}>Delete Record</Link>
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <Form.Group className="g-select">
                                            <Form.Select
                                                id="StakeholderType"
                                                name="StakeholderType"
                                                type="text"
                                                placeholder=" "
                                                value={item.StakeholderType}
                                                onChange={e => handleChangePerson('StakeholderType', e, index)}
                                                disabled={action === "View" || inactiveStatus ? true : false}
                                            >
                                                <option value="">Select</option>
                                                {stakeholderResult ? stakeholderResult.map((stakeholderResult) => (
                                                    <option key={stakeholderResult.SK} value={stakeholderResult.StakeholderName}>{stakeholderResult.StakeholderName}</option>
                                                )) : null
                                                }
                                            </Form.Select>
                                            <Form.Label className="frm-label">Stakeholder Type</Form.Label>
                                            {errorStakeholder[index].StakeholderType.status && (
                                                <Form.Text className="text-danger">
                                                    {errorStakeholder[index].StakeholderType.message}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <Form.Group className="g-input">
                                            <Form.Control
                                                id="StakeCompanyDivision"
                                                type="text"
                                                name="StakeCompanyDivision"
                                                maxLength="50"
                                                placeholder=" "
                                                value={item.StakeCompanyDivision}
                                                readOnly
                                            />
                                            <Form.Label htmlFor="StakeCompanyDivision">Company / Division</Form.Label>
                                            <BiSelectMultiple
                                                className={["modalPopUpIconProjDetails",
                                                    action === "View" || inactiveStatus ? "d-none" : ""
                                                ].join(" ")}
                                                size={20}
                                                onClick={() => {
                                                    showPopUp(
                                                        "Company Division Master",
                                                        "Single",
                                                        "emailid",
                                                        item.StakeCompanyDivision,
                                                        index
                                                    );
                                                }}
                                            />
                                            {errorStakeholder[index].StakeCompanyDivision.status && (
                                                <Form.Text className="text-danger">
                                                    {errorStakeholder[index].StakeCompanyDivision.message}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} xs={12} className="hstack hideDisplay">
                                        {action === "View" || inactiveStatus ?
                                            <></>
                                            :
                                            <div className="addNewRow" style={{ width: "100%", paddingLeft: "51%" }}>
                                                <img src={Delete}
                                                    style={{ float: "right", height: "32px", width: "32px" }}
                                                    alt="BackArrow"
                                                    onClick={() => deleteStakeholder(item)}
                                                />
                                                <Link className="deletelinklabel" onClick={() => deleteStakeholder(item)}>Delete Record</Link>
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <Form.Group className="g-input">
                                            <Form.Control
                                                id="PMCContactPerson"
                                                type="text"
                                                name="PMCContactPerson"
                                                maxLength="50"
                                                placeholder=" "
                                                value={item.PMCContactPerson === undefined ? "" : item.PMCContactPerson}
                                                onChange={e => handleChangePerson('PMCContactPerson', e, index)}
                                                readOnly={action === "View" || inactiveStatus ? true : false}
                                            />
                                            <Form.Label htmlFor="PMCContactPerson">Contact Person</Form.Label>
                                            <BiSelectMultiple
                                                className={["modalPopUpIconProjDetails",
                                                    action === "View" || inactiveStatus ? "d-none" : ""
                                                ].join(" ")}
                                                size={20}
                                                onClick={() => checkStakeDetails(index)}
                                            />
                                            {errorStakeholder[index].PMCContactPerson.status && (
                                                <Form.Text className="text-danger">
                                                    {errorStakeholder[index].PMCContactPerson.message}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <Form.Group className="g-input">
                                            <Form.Control
                                                id="PMCOfficialContNo"
                                                type="text"
                                                name="PMCOfficialContNo"
                                                maxLength="100"
                                                placeholder=" "
                                                value={item.PMCOfficialContNo}
                                                onChange={e => handleChangePerson('PMCOfficialContNo', e, index)}
                                                readOnly={action === "View" || inactiveStatus ? true : false}
                                                onBlur={validateStake}
                                            />
                                            <Form.Label className="frm-label" htmlFor="PMCOfficialContNo">Contact No.
                                                {showMobileNo && showMobileNo === true ?
                                                    <span className="telephone-no"> +91 8888888888</span>
                                                    : null
                                                }
                                            </Form.Label>
                                            {errorStakeholder[index].PMCOfficialContNo.status && (
                                                <Form.Text className="text-danger">
                                                    {errorStakeholder[index].PMCOfficialContNo.message}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <Form.Group className="g-input">
                                            <Form.Control
                                                id="PMCEmailId"
                                                type="text"
                                                name="PMCEmailId"
                                                maxLength="100"
                                                placeholder=" "
                                                value={item.PMCEmailId}
                                                readOnly={action === "View" || inactiveStatus ? true : false}
                                                onBlur={validateStake}
                                                onChange={e => handleChangePerson('PMCEmailId', e, index)}
                                            />
                                            <Form.Label htmlFor="PMCEmailId">Email ID</Form.Label>
                                            {errorStakeholder[index].PMCEmailId.status && (
                                                <Form.Text className="text-danger">
                                                    {errorStakeholder[index].PMCEmailId.message}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            );
                        }
                        )}
                    <Row className={action === "View" || inactiveStatus ? "prevHideButton" : userDetails.dataControl ? "hstack" : "prevHideButton"}>
                        <div className="addNewRow">
                            <BsPlusLg size={20} className="plusIcon" />
                            &nbsp;
                            <Link onClick={() => addNewRow()} className="blueColor">Add more</Link>
                        </div>
                    </Row>
                </Modal.Body>
            </Form>
            <RenderIf isTrue={popUp.type === "Single" && showForm}>
                <SingleValuePopUp
                    show={showForm}
                    onHide={() => setShowForm(false)}
                    setSelected={setPopUpData}
                    mastername={popUp.name}
                    paramkey={popUp.key}
                    paramvalue={popUp.value}
                    additionalParams={popUp.additionalParams}
                    backdrop="static"
                />
            </RenderIf>

        </>
    );
});

export default ProjectDetails;
