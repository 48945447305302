import React, { useContext } from "react";
import Edit from "../../icons/Edit.svg";
import Delete from "../../icons/Delete.svg";
import View from "../../icons/View.svg";
import Edit_green from "../../icons/Edit_green.svg";
import Delete_green from "../../icons/Delete_green.svg";
import View_green from "../../icons/View_green.svg";
import { useSelector } from "react-redux";
import { AuthContext } from "../../contexts/AuthContext";
import download from "../../icons/download.svg";
import download_green from "../../icons/download_green.svg";
import save_hover from "../../icons/Save_Hover.svg";
import save_default from "../../icons/Save_Default.svg";

export default (props) => {
  const { accessibleMenus } = useContext(AuthContext);
  const menuName = useSelector((state) => state.menu.menuName);
  const menuAccess = accessibleMenus.filter(item => item.SessionName === menuName);
  let temp = { ViewAction: false, EditAction: false, DownloadAction: false, DeleteAction: false, SaveAction: false };
  if (props.hasOwnProperty("actionName") && Object.keys(props.actionName).length > 0) {
    temp = props.actionName;
  }
  else {
    temp = { ViewAction: menuAccess[0]?.Display, EditAction: menuAccess[0]?.Modify, DownloadAction: false, SaveAction: false, DeleteAction: menuAccess[0]?.Delete };
  }
  return (
    <div>
      {temp.DownloadAction ?
        <img
          onMouseOver={e => (e.currentTarget.src = download_green)}
          onMouseOut={e => (e.currentTarget.src = download)}
          src={download}
          alt="#"
          onClick={() => props.handleDownloadEvent(props.selectedData)} /> :
        <></>
      }
      {temp.ViewAction ?
        <img
          onMouseOver={e => (e.currentTarget.src = View_green)}
          onMouseOut={e => (e.currentTarget.src = View)}
          src={View}
          alt="view"
          onClick={() => props.handleViewEvent(props.selectedData)} /> :
        <></>
      }
      {temp.EditAction ?
        <img
          onMouseOver={e => (e.currentTarget.src = Edit_green)}
          onMouseOut={e => (e.currentTarget.src = Edit)}
          src={Edit}
          alt="edit"
          onClick={() => props.handleEditEvent(props.selectedData)}
        /> : <></>
      }
      {temp.DeleteAction ?
        <img
          onMouseOver={e => (e.currentTarget.src = Delete_green)}
          onMouseOut={e => (e.currentTarget.src = Delete)}
          src={Delete}
          alt="delete"
          onClick={() => props.handleDeleteEvent(props.selectedData)}
        /> : <></>
      }
      {temp.SaveAction ?
        <img
          onMouseOver={e => (e.currentTarget.src = save_hover)}
          onMouseOut={e => (e.currentTarget.src = save_default)}
          src={save_default}
          alt="save_default"
          onClick={() => props.handleSaveEvent(props.selectedData)}
        />
        : <></>
      }
    </div>
  );
};
