import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./GridMaster.css";
import { defaultColParas } from "../../functions/gbcs-functions";
import { useSelector } from "react-redux";
import { resetFilter, resetAllFilter, FilterChange, getData } from "../../functions/gbcs-functions";
import { BsX } from "react-icons/bs";
import { Container, Button, Card, Row, Col } from "react-bootstrap";
import ActionRenderer from "./ActionRenderer";
import StatusRenderer from "./StatusRenderer";
import Pagination from "../utils/Pagination"
import { ChecklistCategoryStatusRenderer } from "../transaction/ChecklistCategoryStatusRenderer";

const GridMaster = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [filteredCol, setFilteredCol] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState(props.rowDetails);
  const searchReText = useSelector((state) => state.grid.searchQuery);
  const gridRef = useRef();
  const [pageSize] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  // const getRowId = useMemo(() => {
  //   return (params) => params.data.RoleCode;
  // }, []);
  let tmpvar = {};
  let actionDisplay = false;
  if (props.hasOwnProperty("actionName")) {
    actionDisplay = true;
    tmpvar = props.actionName;
  }

  let pagination = true;
  if (props.hasOwnProperty("pagination")) {
    pagination = props.pagination;
  }
  let hideActionBtns = props.hasOwnProperty("noActionButtons") ? props.noActionButtons : false;

  useEffect(() => {
    // console.log("props.rowDetails",props.rowDetails)
    setData(props.rowDetails)
  }, [props.rowDetails]);

  const defaultColDef = useMemo(() => {
    return defaultColParas();
  }, []);

  function onGridReady(params) {
    let temp = [];
    params.api.forEachNodeAfterFilter(node => {
      temp.push(node.data);
    });
    // console.log("temp",temp)
    setRowData(temp);
    setGridApi(params.api);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  const filteredResult = useMemo(() => {
    let firstPageIndex = 0;
    if (currentPage > 1) {
      firstPageIndex = (currentPage - 1) * pageSize;
    }
    if (firstPageIndex >= data.length) {
      firstPageIndex = 0;
      const totalPageCount = Math.ceil(data.length / pageSize);
      if (totalPageCount > 0) {
        setCurrentPage(totalPageCount)
      } else {
        setCurrentPage(1)
      }
    }
    let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
    return data ? data.slice(firstPageIndex, lastPageIndex) : [];
  }, [currentPage, data, pageSize]);

  useEffect(() => {
    setData(props.rowDetails)
    if (gridRef.current.api) {
      gridRef.current.api.setQuickFilter(searchReText);
      let temp = getData(gridRef.current.api);
      props.setExcelData(temp);
      setData(temp);
    }
  }, [gridRef.current, searchReText]);

  useEffect(() => {
    if (gridRef.current.api)
      resetAllFilter(gridRef);
  }, [props.resetind])

  return (
    <Container fluid className="grid-comp-container">
      <div className="d-none">
        {props.getFilterColLength(filteredCol.length)}
      </div>
      {filteredCol.length > 0 && (
        <div className="filterList" >
          {filteredCol.map((item, index) => {
            props.getDataEvent(rowData);
            return (
              <div key={`FilterCol${index}`} className="filter-col pr-0 me-2 mb-2">
                <span className="ps-2 btn-text">{item}</span>
                <div onClick={() => resetFilter(gridRef, item, props.fieldNames)}>
                  <BsX size={25} />
                </div>
              </div>
            );
          })}
          <div>
            <Button
              variant="outline-primary"
              className="ms-2 lh-1 clear-all-btn"
              style={{ marginTop: "2%" }}
              onClick={() => {
                resetAllFilter(gridRef);
              }}>
              Clear All
            </Button>
          </div>
        </div>
      )}

      <div className="ag-theme-alpine agGridContainer d-none d-md-block d-lg-block">
        <AgGridReact
          ref={gridRef}
          rowData={props.rowDetails}
          columnDefs={props.colDetails}
          defaultColDef={defaultColDef}
          // getRowId={getRowId}
          rowHeight={43}
          onGridReady={onGridReady}
          pagination={pagination}
          paginationPageSize={7}
          rowSelection={"multiple"}
          enableBrowserTooltips={true}
          onFilterChanged={(params) => {
            FilterChange(params, setFilteredCol, props.headerNames, setRowData, props.setExcelData);
          }}
        />
      </div>
      <div className="market-wrapper d-md-none d-lg-none table-container1">
        {data.length > 0 && <> {
          filteredResult.map((selection, index) =>
            <Card key={index} className="cardContainer">
              <Card.Body className="cardbodycontainer">
                {selection.hasOwnProperty("AppliedChecklist") || hideActionBtns ? <></> :
                  <div style={{ textAlign: "right", marginBottom: "0.4rem" }}>
                    <ActionRenderer selectedData={selection} actionName={tmpvar} handleViewEvent={props.handleView} handleEditEvent={props.handleEdit} handleDeleteEvent={props.openConfirmBox} handleDownloadEvent={props.handleDownload} handleSaveEvent={props.handleSaveEvent} />
                  </div>
                }
                {props.colDetails.map((header) => {
                  // console.log("header", header, header.field + "" + index)
                  let formattedValue = header.valueFormatter ? header.valueFormatter({ "data": selection, "colDef": { field: header.field, headerName: header.headerName }, "node": { "rowIndex": (pageSize * (currentPage - 1)) + index }, "value": selection[header.field] }) : selection[header.field];
                  let displayValue = header.cellRenderer ? header.cellRenderer({ "data": selection, "colDef": { field: header.field, headerName: header.headerName }, "node": { "rowIndex": (pageSize * (currentPage - 1)) + index }, "value": selection[header.field] }) : formattedValue;
                  return (header.field && header.field !== "" &&
                    <Row key={header.field + "" + index} className="responsive-row">
                      {header.field === "CategoryStatus" ?
                        <Col xs={12} className="colChklstStatus"><ChecklistCategoryStatusRenderer selectedData={selection} handlePrefunctionalEvent={(selection) => props.handleView(selection, "Prefunctional")} handleFunctionalEvent={(selection) => props.handleView(selection, "Functional")} /></Col>
                        :
                        <>
                          <Col className="colHeader">{header.headerName}</Col>
                          <Col className="colData">{displayValue}</Col>
                        </>
                      }
                    </Row>
                  )
                }
                )}
              </Card.Body>
            </Card>
          )}
          <Pagination
            currentPage={currentPage}
            totalCount={data ? data.length : 0}
            data={data}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
        }
      </div>
    </Container>
  );
};
export default GridMaster;
