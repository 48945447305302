import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
import { Container, Row, Col, Form, Button, Card, Spinner, Accordion } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AddProjTransChklst, AddProjTransChklstParameter, deleteProjTranChklstParameter, getProjTranChklstofEquip, getProjTranChklstParameterData } from "../../services/project-transaction-service";
import { getChecklistParameterDetails } from "../../services/checklist-master-service";
import { defaultEditableCol, getCurrentDateTime, formatDateInDDMMYYYY, RenderIf, formatDateDD_MM_YYYY, formatDateInYYYYMMDD } from "../../functions/gbcs-functions";
import godrejLogo from "../../icons/Godrej_logo_1.svg";
import { DragDropRender } from "../masters/ProjectMaster/DragDropRender";
import Validate from "../utils/Validation";
import errorImage from "../../icons/error-tick-logo.svg";
import successImage from "../../icons/success-tick-logo.svg";
import blueInfoSign from "../../icons/blueInfoSign.svg";
import Delete_green from "../../icons/Delete_green.svg";
import Delete from "../../icons/Delete.svg";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import { BiSelectMultiple } from "react-icons/bi";
import { AuthContext } from "../../contexts/AuthContext";
import StatusRenderer from "../masters/StatusRenderer";
import backArrowPage from "../../icons/backArrowPage.svg";
import { validDecimals, isNumberWithDecimalPM } from "../../functions/validations";
import { ConstChecklistHeader, ConstParameterDetailsHeader } from "../masters/ChecklistMaster/ParameterFile";
import axios from "axios";
import { downloadAttachment } from "../../services/designreviewtracker-service";
import { deletS3Attachment } from "../../services/common-master-service";
import { getSiteObservationReportData, addSiteObservationReport } from "../../services/siteobservationreport-service";
import view_download from "../../icons/view_download.svg";
import { Link } from "react-router-dom";
import ChecklistPDF from "../masters/ChecklistMaster/ChecklistPDF";
import { sortByPropertyInAscending } from "../../utils/Utility";
import { getProjectActiveTeamData } from "../../services/project-service";

const ProjectChecklistVersionList = (props) => {
    const checklistType = props.checklistType;
    let tmpSessionName = "";
    if (checklistType === "FAT") {
        tmpSessionName = "Factory Acceptance Test";
    }
    if (checklistType === "SAT") {
        tmpSessionName = "Site Acceptance Test";
    }
    const dispatch = useDispatch();
    dispatch(menuActions.setMenu(tmpSessionName));
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, dataControl: context.dataControl, userId: context.userId, userType: context.userType, companyName: context.companyName }
    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);
    const [parameterDetails, setParameterDetails] = useState([]);
    let versionMismatch = props.versionMismatchFlag;
    let chklistresult = props.chklistresult;
    let todayDate = getCurrentDateTime("-");
    const [versionResult, setVersionResult] = useState(props.chklistresult);
    const [files, setFiles] = useState([]);
    const [showForm, setShowForm] = useState(false);
    let currChklstData = [];
    if (props.rowdata.projCreationVersion === "Not defined") {
        currChklstData = props.chklistresult[0];
    }
    else {
        currChklstData = props.chklistresult.find((item) => item.ChecklistVersion === props.rowdata.CurrChklstVersion);
    }
    const [currentChecklist, setCurrentChecklist] = useState(currChklstData);

    const [open, setOpen] = useState([]);
    const [initialJs, setInitialJs] = useState([]);
    const [showPDF, setShowPDF] = useState(false);
    const [displayPDFData, setDisplayPDFData] = useState({});
    const [parameterdataResp, setParameterdataResp] = useState(currChklstData.ParameterDetailsHeader);
    const parameterdataRespMandatory = currChklstData.ParameterDetailsHeaderMandatory ? currChklstData.ParameterDetailsHeaderMandatory : [];
    let newDt = new Date();
    newDt.setDate(newDt.getDate() + 5);
    let AnticipatedDate = formatDateInYYYYMMDD(formatDateDD_MM_YYYY(newDt), "-");
    const [VersionCode, setVersionCode] = useState(currChklstData.ChecklistVersion);
    const [showLoading, setShowLoading] = useState(true);

    const [error, setError] = useState({
        Contractor: false,
        PMC: false,
        "MEP Consultant": false,
        "T&C": false
    });

    const tranObj = {
        EquipmentName: props.rowdata.EquipmentName,
        EquipmentCode: props.rowdata.EquipmentCode,
        EquipmentTag: props.rowdata.EquipmentTag,
        ProjectCode: props.rowdata.ProjectCode,
        ProjectName: props.rowdata.ProjectName,
        ServiceCode: props.rowdata.ServiceCode,
        ServiceName: props.rowdata.ServiceName,
        ServiceType: props.rowdata.ServiceType,
        Location: props.rowdata.Location,
        Date: formatDateInDDMMYYYY(todayDate).substring(0, 10),
        ChecklistVersion: props.rowdata.CurrChklstVersion,
        ChecklistCategory: props.rowdata.ChecklistCategory,
        Remarks: "",
        IsFreeze: false
    }

    const [projChecklistObj, setProjChecklistObj] = useState(props.action === "Edit" ? props.transData : tranObj);

    const arrDynamicObj = (check, obj) => {
        setShowLoading(true);
        let arr = [];
        let loopArr;
        if (check === "header") {
            loopArr = obj.ChecklistHeader;
        }
        else if (check === "footer") {
            loopArr = obj.ChecklistFooter;
        }
        let dataObj = {};
        for (let item of loopArr) {
            if (item.IsSelected) {
                if (check === "header") {
                    let fieldLength = ConstChecklistHeader.filter(dt => dt.FieldName === item.FieldName);
                    if (fieldLength?.length > 0) {
                        if (fieldLength[0].hasOwnProperty("maxLength")) {
                            item.maxLength = fieldLength[0].maxLength
                        }
                        else {
                            item.maxLength = "100";
                        }
                        if (!fieldLength[0].hasOwnProperty("entryInd") || (fieldLength[0].hasOwnProperty("entryInd") && fieldLength[0].entryInd)) {
                            item.entryInd = true;
                        }
                        else {
                            item.entryInd = false;
                        }
                        if (fieldLength[0].hasOwnProperty("autoPopulate")) {
                            dataObj[fieldLength[0].FieldName] = props.rowdata[fieldLength[0].autoPopulate];
                        }

                    }
                    else {
                        item.maxLength = "100";
                        item.entryInd = true;
                    }
                }
                arr.push(item);

            }
        }
        if (check === "header") {
            setProjChecklistObj((prevState) => ({ ...prevState, ...dataObj }));
        }
        setShowLoading(false);
        return arr;
    }

    const [chklstHeader, setChklstHeader] = useState(currentChecklist.ChecklistHeader?.length > 0 ? () => arrDynamicObj("header", currentChecklist) : []);
    const [chklstFooter, setChklstFooter] = useState(currentChecklist.ChecklistFooter?.length > 0 ? () => arrDynamicObj("footer", currentChecklist) : []);

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
        additionalParams: ""
    });

    const defaultColDef = useMemo(() => {
        return defaultEditableCol();
    }, []);


    function onGridReady(params) {
        setGridApi(params.api);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api;// <= assigned gridApi value on Grid ready
    }

    const sortParameterListResp = async (paralist) => {
        setShowLoading(true);
        let temp = [];
        let temp3 = Object.assign([], ConstParameterDetailsHeader);
        let temp1 = temp3.sort(sortByPropertyInAscending("displaySrNo"));
        let colArr = [...columnDefs];

        for (let item of temp1) {
            let isIncluded = false;
            if (paralist?.indexOf(item.FieldName) !== -1) {
                temp.push(item);
                isIncluded = true;
            }
            let find = colArr.find(x => item.FieldName === x.headerName);
            if (find && item.displayInd && isIncluded) {
                find.hide = false;
            } else {
                find.hide = true;
            }
        }
        setParameterdataResp(temp);
        setColumnDefs(colArr);
        setShowLoading(false);
    }

    const loadData = async () => {
        await sortParameterListResp(parameterdataResp);
        await fetchParameterDetails(currentChecklist.ChecklistCode, currentChecklist.ChecklistVersion, currentChecklist);
    }
    useEffect(() => {
        loadData();
    }, [])

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Sr.No.",
            field: "SrNo",
            valueFormatter: (params) => {
                return params.node.rowIndex + 1;
            },
            minWidth: 80,
            width: 80,
            flex: 0,
            editable: false
        },
        {
            headerName: "Parameter Category",
            field: "Parameter Category",
            minWidth: 180,
            width: 180,
            tooltipField: "Parameter Category",
            sortable: true,
            editable: false,
            hide: true
        },
        {
            headerName: "Parameter",
            field: "Parameter",
            minWidth: 200,
            width: 200,
            cellEditorParams: { maxLength: 200 },
            flex: 0,
            tooltipField: "Parameter",
            cellClass: "agCellCss",
            editable: false
        },
        {
            headerName: "UOM",
            field: "UOM",
            minWidth: 100,
            width: 100,
            cellEditorParams: { maxLength: 10 },
            tooltipField: "UOM",
            editable: !projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active",
            cellClass: "agCellCss",
            hide: true,
            valueGetter: (params) => {
                return params.data.UOM;
            },
            valueSetter: (params) => {
                if (params.data.UOM !== undefined) {
                    params.data.UOM = params.newValue;
                }
            },
            valueFormatter: params => {
                if (params.data.UOM === undefined) {
                    params.data.UOM = 'NA';
                    return 'NA';
                }
            }
        },
        {
            headerName: "Status",
            field: "Status",
            tooltipField: "Status",
            minWidth: 180,
            width: 180,
            editable: false,
            cellClass: "agCellCss",
            cellRenderer: function (params) {
                if (!projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active") {
                    return (
                        <Form.Group>
                            <Form.Select
                                className="ddinputselect"
                                id="Status"
                                name="Status"
                                placeholder=" "
                                value={params.value}
                                onChange={(e) => handleChangeOnDD(e, params.node.rowIndex, params)}
                            >
                                <option value="" ></option>
                                {params.data.StatusArr ? params.data.StatusArr.map((item) => (
                                    <option key={item.name} result={item.result} value={item.name}>{item.name}</option>
                                )) : null
                                }
                            </Form.Select>
                        </Form.Group>
                    )
                }
                else {
                    return <span>{params.value}</span>
                }
            }
        },
        {
            headerName: "Fire Integration Service",
            field: "Fire Integration Service",
            tooltipField: "Fire Integration Service",
            hide: true
        },
        {
            headerName: "Result",
            field: "Result",
            minWidth: 120,
            width: 120,
            tooltipField: "Result",
            sortable: true,
            editable: false,
            hide: true,
            cellRenderer: StatusRenderer
        },
        {
            headerName: "Remarks",
            field: "Remarks",
            minWidth: 180,
            width: 180,
            cellEditorParams: { maxLength: 500 },
            tooltipField: "Remarks",
            editable: !projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active",
            cellClass: "agCellCss",
            hide: false
        },
        {
            headerName: "Readings",
            field: "Readings",
            minWidth: 80,
            width: 80,
            cellEditorParams: { maxLength: 13 },
            tooltipField: "Readings",
            sortable: true,
            editable: !projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active",
            cellClass: "agCellCss",
            valueGetter: (params) => {
                return params.data.Readings;
            },
            // valueSetter: (params) => {
            //     //to make sure user entered number only                                    
            //     let error = isNumberWithDecimalPM(params.newValue.toString());
            //     if (error.status) {
            //         setError((prevState) => ({
            //             ...prevState,
            //             Readings: error,
            //         }));
            //     }
            //     else if (params.newValue.toString() === "0") {
            //         error = { status: true, message: "Enter Non-zero value" };
            //         setError((prevState) => ({
            //             ...prevState,
            //             Readings: error,
            //         }));
            //     }
            //     else {
            //         error = validDecimals(params.newValue.toString());
            //         setError((prevState) => ({
            //             ...prevState,
            //             Readings: error,
            //         }));
            //     }
            //     if (!error.status) {
            //         params.data.Readings = Number(params.newValue);
            //     }
            //     else {
            //         MySwal.fire({
            //             text: error.message,
            //             imageUrl: errorImage,
            //             customClass: "swal-success",
            //         });
            //         return;
            //     }
            //     return;
            // },
        },
        {
            headerName: "Threshold Min",
            field: "Threshold Min",
            tooltipField: "Threshold Min",
            hide: true
        },
        {
            headerName: "Threshold Max",
            field: "Threshold Max",
            tooltipField: "Threshold Max",
            hide: true
        },
        {
            headerName: "Attachment",
            field: "Attachment",
            tooltipField: "Attachment",
            minWidth: 250,
            editable: false,
            cellClass: "agAttachmentCellCss",
            hide: true,
            cellRenderer: function (params) {
                if (params.value === "" || params.value === undefined) {
                    return <DragDropRender val={params.node.rowIndex} winType={"WEB"} itemData={params} handleChangeEvent={handleChangeStatus} optionalFlag={params.data.AttachmentFlag && params.data.AttachmentFlag === "Yes" ? false : true} disabled={projChecklistObj.IsFreeze || props.rowdata.ProjectDeleteIndication === "Inactive" || props.rowdata.ProjectDeleteIndication === "Closed"} />
                }
                else {
                    return <><span className="deleteIconSpan" onClick={() => handleDownloadDocument(params.data)}>{params.value}</span>
                        <img
                            onMouseOver={e => (e.currentTarget.src = Delete_green)}
                            onMouseOut={e => (e.currentTarget.src = Delete)}
                            src={Delete}
                            alt="delete"
                            onClick={() => handleDeleteGrid(params)}
                        /> </>
                }
            }
        }
    ]);

    const headerName = (field) => {
        let name;
        switch (field) {
            case "Parameter Category":
                name = "Parameter Category";
                break;
            case "Parameter":
                name = "Parameter";
                break;
            case "Status":
                name = "Status";
                break;
            case "Result":
                name = "Result";
                break;
            case "Remarks":
                name = "Remarks";
                break;
            case "Readings":
                name = "Readings";
                break;
            case "Attachment":
                name = "Attachment";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Parameter Category":
                name = "Parameter Category";
                break;
            case "Parameter":
                name = "Parameter";
                break;
            case "Status":
                name = "Status";
                break;
            case "Result":
                name = "Result";
                break;
            case "Remarks":
                name = "Remarks";
                break;
            case "Readings":
                name = "Readings";
                break;
            case "Attachment":
                name = "Attachment";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const handleChangeStatus = async (file, index, winType, params) => {
        let isError = false;
        const newFile = file[0];
        let fileError = Validate(newFile, "allFormats", 26214400);
        let existFiles = files;
        if (fileError === "") {
            // let temp1 = [];
            // gridRef.current.gridOptionsService.api.forEachNode(node => temp1.push(node.data));
            let fileJs = { "SeqNo": params.data.SeqNo, file: newFile };
            existFiles.push(fileJs);
            setFiles(existFiles);
            // let rowData = [];
            if (winType === "WEB") {
                // gridRef.current.gridOptionsService.api.forEachNode(node => rowData.push(node.data));
                params.node.setDataValue("Attachment", file[0].name);
                params.data["NewAttachmentFlag"] = true;
            }
            else if (winType === "MOBILE") {
                let rowData = [...parameterDetails];
                // gridRef.current.gridOptionsService.api.forEachNode(node => rowData.push(node.data));

                rowData[index].Attachment = file[0].name;
                rowData[index].NewAttachmentFlag = true;
                setParameterDetails(rowData);
            }
            // rowData[index].Attachment = file[0].name;
            // rowData[index].NewAttachmentFlag = true;
            // setParameterDetails(rowData);
        }
        else {
            isError = true;
            MySwal.fire({
                text: fileError,
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        return isError;
    };

    const handleDeleteGrid = async (params) => {
        if (!projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active") {
            // let ids = [];
            // gridRef.current.gridOptionsService.api.forEachNode(node => ids.push(node.data));
            // ids[index].Attachment = "";
            // ids[index].NewAttachmentFlag = false;
            // setParameterDetails(ids);
            params.node.setDataValue(params.colDef.field, "");
            params.data["NewAttachmentFlag"] = false;
        }
    }

    const handleDeleteEvent = async (index) => {
        if (!projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active") {
            let ids = [];
            ids = [...parameterDetails];
            ids[index].Attachment = "";
            ids[index].NewAttachmentFlag = false;
            setParameterDetails(ids);
        }
    }

    const getResult = async (paramDt, fieldName, fieldValue) => {
        let statusResult = "";
        let readingResult = "";

        if (fieldName === "Status" || paramDt.Status) {
            let selectedStatus = "";
            if (fieldName === "Status") {
                selectedStatus = fieldValue;
            }
            else if (paramDt.Status) {
                selectedStatus = paramDt.Status;
            }
            statusResult = paramDt.StatusArr.find(x => x.name === selectedStatus)?.result;
        }

        if (fieldName === "Readings" || paramDt.Readings) {
            let selectedReading = "";
            if (fieldName === "Readings") {
                selectedReading = fieldValue;
            }
            else if (paramDt.Readings) {
                selectedReading = paramDt.Readings;
            }

            if (isNaN(selectedReading)) {
                readingResult = "Rejected";
            }
            else {
                if (paramDt["Threshold Max"] && Number(selectedReading) > Number(paramDt["Threshold Max"])) {
                    readingResult = "Rejected";
                }
                if (paramDt["Threshold Min"] && Number(selectedReading) < Number(paramDt["Threshold Min"])) {
                    readingResult = "Rejected";
                }
            }
        }

        if (statusResult === "Rejected" || readingResult === "Rejected") {
            return "Rejected";
        }
        else {
            return "Accepted";
        }
    }


    const handleChangeOnDD = async (event, index, params) => {
        let { name, value } = event.target;
        let resultVal = await getResult(params.data, name, value);
        params.node.setDataValue(name, value)
        params.node.setDataValue("Result", resultVal)
        // params.data["Result"] = resultVal;

        // if (event.target.name === "Status") {
        //     let ids = [];
        //     gridRef.current.gridOptionsService.api.forEachNode(node => ids.push(node.data));
        //     ids[index].Status = event.target.value;
        //     ids[index].Result = await getResult(params.data, event.target.name, event.target.value);
        //     setParameterDetails(ids);
        // }
    }

    const handleCellChange = async (event) => {
        // let index = event.node.rowIndex;
        // let ids = [...parameterDetails];
        if (event.colDef.field === "Readings" && event.value.toString() !== "") {
            let error = isNumberWithDecimalPM(event.value.toString());
            if (!error.status) {
                if (event.value.toString() === "0") {
                    error = { status: true, message: "Enter Non-zero value" };
                }
                else {
                    error = validDecimals(event.value.toString());
                }
            }
            if (error.status) {
                event.node.setDataValue(event.colDef.field, "")
                MySwal.fire({
                    text: error.message,
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
            let resultVal = await getResult(event.data, event.colDef.field, event.value);
            event.node.setDataValue("Result", resultVal)
            // event.data["Result"] = resultVal;
        }
        console.log("event", event)
        event.node.setDataValue(event.colDef.field, event.value)
        // setParameterDetails(ids);
    }

    const handleChangeCard = async (event, index) => {
        const { name, value } = event.target;
        let ids = [];
        ids = [...parameterDetails];
        if (name === "Readings") {
            let error = isNumberWithDecimalPM(value.toString());
            if (!error.status) {
                if (value.toString() === "0") {
                    error = { status: true, message: "Enter Non-zero value" };
                }
                else {
                    error = validDecimals(value.toString());
                }
            }
            if (error.status) {
                MySwal.fire({
                    text: error.message,
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
        }
        ids[index][name] = value;
        if (name === "Status" || name === "Readings") {
            ids[index].Result = await getResult(ids[index], name, value);
        }

        setParameterDetails(ids);
    }

    const handleVersionChange = async () => {
        if (projChecklistObj.ChecklistVersion !== VersionCode) {
            MySwal.fire({
                text: tmpSessionName + " checklist data will be lost if you change version. Are you sure, do you want to change the version?",
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonColor: "#16911B",
                confirmButtonText: "Yes",
                reverseButtons: true,
                customClass: "swal-confirmation",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let foundChklst;
                    foundChklst = chklistresult.find(x => x.ChecklistVersion === VersionCode);

                    if (foundChklst) {
                        // setParameterdataResp(foundChklst);
                        setCurrentChecklist(foundChklst);
                        await sortParameterListResp(foundChklst.ParameterDetailsHeader);
                        await fetchParameterDetails(foundChklst.ChecklistCode, foundChklst.ChecklistVersion, foundChklst);
                        setChklstHeader(() => arrDynamicObj("header", foundChklst));
                        setChklstFooter(() => arrDynamicObj("footer", foundChklst));
                        let tmpTranObj = Object.assign({}, tranObj)
                        tmpTranObj.ChecklistVersion = foundChklst.ChecklistVersion;

                        if (props.rowdata.chklstStatus !== "Not started") {
                            tmpTranObj.CreatedBy = projChecklistObj.CreatedBy;
                            tmpTranObj.CreatedByName = projChecklistObj.CreatedByName;
                            tmpTranObj.CreatedOn = projChecklistObj.CreatedOn;
                        }
                        setProjChecklistObj(tmpTranObj);
                    }
                }
                else {
                    setVersionCode(projChecklistObj.ChecklistVersion);
                }
            });
        }
    }

    const openPDF = async () => {
        let pdfData = {};
        pdfData.header = projChecklistObj;
        let paramArr = []
        for (let dt of parameterdataResp) {
            paramArr.push(dt.FieldName);
        }
        pdfData.header.ParameterDetailsHeader = paramArr;
        pdfData.header.ChecklistHeader = chklstHeader;
        pdfData.header.ChecklistFooter = chklstFooter;
        pdfData.header.ServiceName = props.rowdata.ServiceName;
        pdfData.paramData = parameterDetails;
        setDisplayPDFData(pdfData);
        setShowPDF(true);

    }

    const fetchParameterDetails = async (chklstCode, chklstVersion, ChklstData) => {
        setShowLoading(true);
        let tmpparameterresult = [];
        let parameterresult = [];

        tmpparameterresult = await getProjTranChklstParameterData(checklistType, props.rowdata.ProjectCode, props.rowdata.ChecklistCategory, props.rowdata.EquipmentTag);
        parameterresult = tmpparameterresult?.filter((item) => item.ChecklistVersion === chklstVersion);

        if (parameterresult?.length === 0 || parameterresult[0].ChecklistVersion !== chklstVersion) {
            let obj = {
                PK:
                    "ChecklistCode#" +
                    chklstCode +
                    "#ChecklistVersion#" +
                    chklstVersion,
                SK: "SeqNo#",
            };
            parameterresult = await getChecklistParameterDetails(obj);
            for (let ele of parameterresult) {
                if (ele.Attachment) {
                    ele.AttachmentFlag = ele.Attachment;
                    ele.Attachment = "";
                }
                if (!ele.UOM) {
                    ele.UOM = "NA";
                }
            }
        }

        // let statDefault = [{ "result": "select", "name": "Select" }];
        // let statValues = { allStatus: [statDefault] };
        let statValues = { allStatus: [] };
        if (!projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active") {
            for (let item of ChklstData.StatusDetails) {
                let arr = { "result": item.Result, "name": item.Status }
                if (item.ParameterCategory && item.ParameterCategory.length > 0) {
                    for (let dt of item.ParameterCategory) {
                        if (statValues[dt]) {
                            statValues[dt].push(arr);
                        }
                        else {
                            // statValues[dt] = [statDefault, arr];
                            statValues[dt] = [arr];
                        }
                    }
                }
                else {
                    statValues.allStatus.push(arr);
                }
            }
        }

        let JsonRecords = {};
        let counter = 0;
        for (let ele of parameterresult) {
            if (ele.Attachment !== "" && ele.Attachment !== undefined) {
                ele.OldFile = ele.Attachment;
            }
            if (!projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active") {
                if (ele["Parameter Category"]) {
                    ele.StatusArr = statValues[ele["Parameter Category"]];
                }
                else {
                    ele.StatusArr = statValues.allStatus;
                }
            }
            JsonRecords[counter] = false;
            counter++;
        }

        let sortedResult = JSON.parse(JSON.stringify(parameterresult));
        sortedResult.sort(sortByPropertyInAscending("SeqNo"));
        setInitialJs(JsonRecords);
        setOpen(JsonRecords);
        console.log("sortedResult", sortedResult)
        setParameterDetails(sortedResult);
        setShowLoading(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProjChecklistObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateDetails = async () => {
        const errorMsg = {};
        let isError = false;
        let isParameterError = false;
        let activeTeamMembers = await getProjectActiveTeamData(projChecklistObj.ProjectCode);
        for (let dt of chklstFooter) {
            errorMsg[dt.FieldName] = "";
            if ((!projChecklistObj.hasOwnProperty(dt.FieldName) || projChecklistObj[dt.FieldName] === "") && dt.IsMandatory) {
                isError = true;
                errorMsg[dt.FieldName] = {
                    status: true,
                    message: dt.FieldName + " is required.",
                };
            }
            if (dt.FieldName !== "Remarks" && projChecklistObj[dt.FieldName]) {
                let validUser = activeTeamMembers.filter(x => x.UserName?.toLowerCase() === projChecklistObj[dt.FieldName]?.toLowerCase());
                if (validUser.length === 0) {
                    isError = true;
                    errorMsg[dt.FieldName] = {
                        status: true,
                        message: "Invalid User details."
                    };
                }
            }
        }
        for (let dt of chklstHeader) {
            errorMsg[dt.FieldName] = "";
            if ((!projChecklistObj.hasOwnProperty(dt.FieldName) || projChecklistObj[dt.FieldName] === "") && dt.IsMandatory) {
                isError = true;
                errorMsg[dt.FieldName] = {
                    status: true,
                    message: dt.FieldName + " is required.",
                };
            }
        }

        for (let dt of currentChecklist.ParameterDetailsHeader) {
            let tmpMandatory = false;
            let tmpParamData = [];
            let chk = parameterdataRespMandatory.indexOf(dt);
            if (chk >= 0) {
                tmpMandatory = true;
            }
            if (dt === "Attachment") {
                let obj = {
                    PK:
                        "ChecklistCode#" +
                        currentChecklist.ChecklistCode +
                        "#ChecklistVersion#" +
                        VersionCode,
                    SK: "SeqNo#",
                };
                tmpParamData = await getChecklistParameterDetails(obj);
            }
            for (let item of parameterDetails) {
                if (dt === "Attachment") {
                    let tmpParam = tmpParamData.find(x => x.SeqNo === item.SeqNo);
                    console.log("item", item, dt)
                    if (tmpParam) {
                        if (tmpParam.Attachment && tmpParam.Attachment === "Yes" && !item[dt]) {
                            console.log("item", item, dt)
                            isError = true;
                            isParameterError = true;
                            break;
                        }
                    }
                }
                else if ((!item.hasOwnProperty(dt) || item[dt] === "") && tmpMandatory) {
                    console.log("item", item, dt)
                    isError = true;
                    isParameterError = true;
                    break;
                }
            }
        }
        return { isError, isParameterError, errorMsg };
    }

    const showPopUp = (name, type, key, value, additionalParams) => {
        setPopUp({ name, type, key, value, additionalParams });
        setShowForm(true);
    };

    const setPopUpData = async (data) => {
        if (data.name === "Project Team Details") {
            setProjChecklistObj((prevState) => ({
                ...prevState,
                [data.key]: data.result.UserName,
                [data.key + " Id"]: data.result.UserId,
                [data.key + " EmailId"]: data.result.EmailId
            }));
            setError((prevState) => ({
                ...prevState,
                [data.key]: { status: false, message: "" }
            }));
        }
    }

    const uploadFiles = async (signedURL, seqNo) => {
        const fileToUpload = files.filter(item => item.SeqNo === seqNo);
        try {
            if (signedURL) {
                await axios.put(signedURL, fileToUpload[0].file);
            }
            return "";
        } catch (error) {
            console.log("Error in File Upload to S3: ", error);
            return " Error in file upload.";
        }
    }

    const AddSor = async () => {
        let Seq_No = 1;
        let find = parameterDetails.find(x => x.Result === "Rejected")
        if (find !== undefined) {
            const result = await getSiteObservationReportData(projChecklistObj.ProjectCode);
            if (result?.length > 0) {
                result.forEach(element => {
                    if (element.SeqNo > Seq_No) {
                        Seq_No = element.SeqNo;
                    }
                });
            }
        }
        let counter = 0;
        let array = [];
        for (let item of parameterDetails) {
            if (item.Result !== undefined && item.Result === "Rejected") {
                counter++;
                Seq_No = Seq_No + 1;
                let SiteObservationReportObj = {
                    PK: "ProjectCode#" + projChecklistObj.ProjectCode,
                    SK: "SOR#SeqNo#" + Seq_No,
                    IssueDate: todayDate.substring(0, 10),
                    RaisedBy: userDetails.companyName === "Godrej ENE Division" && userDetails.userType === "Employee" ? "Godrej E&E" : "Business Partner",
                    RaisedByUserId: userDetails.userId,
                    RaisedByName: userDetails.userName,
                    FloorNo: projChecklistObj.Floor !== undefined ? projChecklistObj.Floor : "",
                    Area: projChecklistObj.Area !== undefined ? projChecklistObj.Area : "",
                    ServiceName: projChecklistObj.ServiceName,
                    ServiceCode: projChecklistObj.ServiceCode,
                    ServiceType: projChecklistObj.ServiceType,
                    ChecklistCategory: checklistType + " (" + projChecklistObj.ChecklistCategory + ")",
                    EquipmentActivityName: projChecklistObj.EquipmentName,
                    EquipmentActivityCode: projChecklistObj.EquipmentCode,
                    EquipmentActivityTagSrNo: projChecklistObj.EquipmentTag,
                    EquipmentActivityCapacity: projChecklistObj.Capacity !== undefined ? projChecklistObj.Capacity : "",
                    ResponsibleContractorOEMUserId: projChecklistObj.ContractorId !== undefined ? projChecklistObj.ContractorId : "",
                    ResponsibleContractorOEM: projChecklistObj.Contractor !== undefined ? projChecklistObj.Contractor : "",
                    IssueDescription: "Parameter:" + item.Parameter + (" Readings:" + (item.Readings !== undefined ? item.Readings : "Not Defined ")) + (" Threshold Min:" + (item["Threshold Min"] !== undefined ? item["Threshold Min"] : "Not Defined ")) + (" Threshold Max:" + (item["Threshold Max"] !== undefined ? item["Threshold Max"] : "Not Defined ")),
                    Remarks: item.Remarks !== undefined ? item.Remarks : "",
                    Criticality: "Non-Critical",
                    AnticipatedClosureDate: AnticipatedDate,
                    FireIntegrationService: item["Fire Integration Service"],
                    ISTObservations: "Yes",
                    CorrectiveAction: "",
                    CorrectedStatus: "No",
                    VerifiedBy: userDetails.companyName === "Godrej ENE Division" && userDetails.userType === "Employee" ? "Godrej E&E" : "Business Partner",
                    VerifiedByUserId: "",
                    VerifiedByName: "",
                    VerifiedDate: "",
                    Uploads: "",
                    CreatedBy: userDetails.userId,
                    CreatedByName: userDetails.userName,
                    CreatedOn: todayDate,
                    ModifiedOn: todayDate,
                    ModifiedBy: userDetails.userId,
                    ModifiedByName: userDetails.userName,
                    ProjectCode: projChecklistObj.ProjectCode,
                    ProjectName: projChecklistObj.ProjectName,
                    SeqNo: Seq_No,
                    EntryFromFlag: "Checklist"
                }
                const resSor = addSiteObservationReport(SiteObservationReportObj);
                array.push(resSor);

                if (counter === 10) {
                    await Promise.all(array);
                    counter = 0;
                    array = [];
                }
            }
        }
        if (array.length > 0) {
            await Promise.all(array);
        }
    }

    const SaveParamterList = async (tempFlag) => {
        if (tempFlag) {
            let parameterresult = [];
            parameterresult = await getProjTranChklstParameterData(checklistType, props.rowdata.ProjectCode, props.rowdata.ChecklistCategory, props.rowdata.EquipmentTag);

            if (parameterresult?.length > 0 && parameterresult?.length > parameterDetails.length) {
                let array = [];
                let counter = 0;
                for (let i = 0; i < parameterresult?.length; i++) {
                    if (i > parameterDetails.length - 1) {
                        counter++;
                        let obj = { PK: parameterresult[i].PK, SK: parameterresult[i].SK }
                        const addParam = deleteProjTranChklstParameter(obj);
                        array.push(addParam);
                        if (counter === 10) {
                            await Promise.all(array);
                            counter = 0;
                            array = [];
                        }
                    }
                }
                if (array.length > 0) {
                    await Promise.all(array);
                }
            }
        }
        let resultArr = [];
        let deleteArr = [];
        let uploadArr = [];
        let counter = 0;

        for (let item of parameterDetails) {
            counter++;
            let tmpObj = structuredClone(item);
            tmpObj.PK = checklistType + "#ProjectCode#" + projChecklistObj.ProjectCode + "#ChecklistCategory#" + props.rowdata.ChecklistCategory + "#EquipmentTag#" + projChecklistObj.EquipmentTag;
            tmpObj["SeqNo"] = Number(tmpObj.SK.split("#")[1]);
            tmpObj.ChecklistVersion = projChecklistObj.ChecklistVersion;
            tmpObj.CreatedBy = userDetails.userId;
            tmpObj.CreatedByName = userDetails.userName;
            tmpObj.CreatedOn = todayDate;
            tmpObj.ModifiedOn = todayDate;
            tmpObj.ModifiedBy = userDetails.userId;
            tmpObj.ModifiedByName = userDetails.userName;
            // if (tempFlag && tmpObj.hasOwnProperty("StatusArr")) {
            delete tmpObj.StatusArr;
            // }
            let result = AddProjTransChklstParameter(tmpObj);
            resultArr.push(result);
            if (resultArr.length === 10) {
                let resultDataArr = [];
                for (let dt of resultArr) {
                    let resultData = await Promise.resolve(dt);
                    if (resultData?.status) {
                        resultDataArr.push(resultData);
                    }
                }
                for (let dt of resultDataArr) {
                    let obj = dt.paramData;
                    if (obj.OldFile !== undefined && obj.OldFile !== "" && obj.OldFile !== obj.Attachment) {
                        let fileObj = { fileName: "ProjTranChecklist/" + projChecklistObj.ProjectCode + "/" + checklistType + "/" + projChecklistObj.ChecklistCategory + "/" + projChecklistObj.EquipmentTag + "/" + obj.SeqNo + "/" + obj.OldFile };
                        let result3 = deletS3Attachment(fileObj);
                        deleteArr.push(result3);
                    }
                    if (obj.NewAttachmentFlag) {
                        let fileUploadMsg = uploadFiles(dt.signedURL, obj.SeqNo);
                        uploadArr.push(fileUploadMsg);
                    }
                }
                await Promise.all(deleteArr);
                await Promise.all(uploadArr);
                counter = 0;
                resultArr = [];
                deleteArr = [];
                uploadArr = [];
            }
        }
        if (resultArr.length > 0) {
            let resultDataArr = [];
            for (let dt of resultArr) {
                let resultData = await Promise.resolve(dt);
                if (resultData?.status) {
                    resultDataArr.push(resultData);
                }
            }
            for (let dt of resultDataArr) {
                let obj = dt.paramData;
                if (obj.OldFile !== undefined && obj.OldFile !== "" && obj.OldFile !== obj.Attachment) {
                    let fileObj = { fileName: "ProjTranChecklist/" + projChecklistObj.ProjectCode + "/" + checklistType + "/" + projChecklistObj.ChecklistCategory + "/" + projChecklistObj.EquipmentTag + "/" + obj.SeqNo + "/" + obj.OldFile };
                    let result3 = deletS3Attachment(fileObj);
                    deleteArr.push(result3);
                }
                if (obj.NewAttachmentFlag) {
                    let fileUploadMsg = uploadFiles(dt.signedURL, obj.SeqNo);
                    uploadArr.push(fileUploadMsg);
                }
            }
            await Promise.all(deleteArr);
            await Promise.all(uploadArr);
        }
        return true;
    }

    const handleSubmit = async (flag) => {
        gridRef.current.gridOptionsService.api.stopEditing();

        if (props.rowdata.chklstStatus === "Not started") {
            projChecklistObj.CreatedBy = userDetails.userId;
            projChecklistObj.CreatedByName = userDetails.userName;
            projChecklistObj.CreatedOn = todayDate;
        }
        projChecklistObj.ModifiedOn = todayDate;
        projChecklistObj.ModifiedBy = userDetails.userId;
        projChecklistObj.ModifiedByName = userDetails.userName;
        projChecklistObj.ChecklistCategory = currentChecklist.ChecklistCategory;
        projChecklistObj.PK = "ProjTransChklst#" + checklistType + "#ProjectCode#" + projChecklistObj.ProjectCode;
        projChecklistObj.SK = "ChecklistCategory#" + currentChecklist.ChecklistCategory + "#EquipmentTag#" + projChecklistObj.EquipmentTag;
        // projChecklistObj["ChecklistHeader"] = currentChecklist.ChecklistHeader;
        // projChecklistObj["ChecklistFooter"] = currentChecklist.ChecklistFooter;
        // projChecklistObj["StatusDetails"] = currentChecklist.StatusDetails;
        // projChecklistObj["ParameterDetailsHeader"] = currentChecklist.ParameterDetailsHeader;

        // let isError = false;
        // let isParameterError = false;
        // if (flag === "Submit") {
        //     const { isTmpError, isTmpParameterError } = validateDetails();

        //     console.log(isTmpError, isTmpParameterError)
        //     isError = isTmpError;
        //     isParameterError = isTmpParameterError;

        //     console.log(isError, isParameterError)
        // }

        setShowLoading(true);
        let { isError, isParameterError, errorMsg } = await validateDetails();
        if (flag === "Submit") {
            setError(errorMsg);
        }

        if (flag === "TempSave" || !isError) {
            setShowLoading(false);
            let confirmBoxText = "Are you sure, do you want to save " + tmpSessionName + " checklist?";
            let confirmButtonText = "Yes";
            MySwal.fire({
                text: confirmBoxText,
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonColor: "#16911B",
                confirmButtonText: confirmButtonText,
                reverseButtons: true,
                customClass: "swal-confirmation",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setShowLoading(true);
                    if (flag === "TempSave") {
                        projChecklistObj.IsFreeze = false;
                    }
                    else {
                        projChecklistObj.IsFreeze = true;
                        projChecklistObj.frozenDate = getCurrentDateTime("-");
                    }
                    let res1 = await SaveParamterList(projChecklistObj.IsFreeze);
                    if (res1) {
                        let obj = { projChecklistObj, chklstFooter }
                        let resultData = await AddProjTransChklst(obj);
                        if (resultData.status) {
                            if (projChecklistObj.IsFreeze) {
                                let resSOR = await AddSor();
                                props.onHide(props.rowdata);
                            }
                            setShowLoading(false);
                            MySwal.fire({
                                text: tmpSessionName + " checklist details added successfully.",
                                imageUrl: successImage,
                                customClass: "swal-success"
                            });
                        }
                        else {
                            setShowLoading(false);
                            setProjChecklistObj((prevState) => ({
                                ...prevState,
                                IsFreeze: false
                            }));
                            MySwal.fire({
                                text: "Unable to Process Request",
                                imageUrl: errorImage,
                                customClass: "swal-success"
                            });
                        }
                    }
                    else {
                        setShowLoading(false);
                        setProjChecklistObj((prevState) => ({
                            ...prevState,
                            IsFreeze: false
                        }));
                        MySwal.fire({
                            text: "Unable to Process Request",
                            imageUrl: errorImage,
                            customClass: "swal-success"
                        });
                    }
                }
            });
        }
        else {
            setShowLoading(false);
            if (isParameterError) {
                MySwal.fire({
                    text: "Please enter all Parameter Details.",
                    imageUrl: errorImage,
                    customClass: "swal-success"
                });
            }
            else {
                MySwal.fire({
                    text: "Please check errors.",
                    imageUrl: errorImage,
                    customClass: "swal-success"
                });
            }
            projChecklistObj.IsFreeze = false;
        }
    }

    const handleCardClick = (event) => {
        const id = event.target.id;
        let tmpObj = Object.assign({}, initialJs);
        tmpObj[id] = !tmpObj[id];
        setOpen(tmpObj);
    }

    const handleDownloadDocument = async (itemData) => {
        if (itemData.Attachment !== "" && (itemData.Attachment === itemData.OldFile)) {
            downloadAttachment(
                "ProjTranChecklist/" + projChecklistObj.ProjectCode + "/" + checklistType + "/" + projChecklistObj.ChecklistCategory + "/" + projChecklistObj.EquipmentTag + "/" + itemData.SeqNo + "/" + itemData.OldFile,
                itemData.OldFile
            ).catch((err) => {
                MySwal.fire({
                    text: "Error in Fetching file.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            });
        }
    }

    const CheckValidation = (e, fieldData) => {
        let errorMsg = {};
        if (fieldData.IsMandatory) {
            if (projChecklistObj.hasOwnProperty(e.target.name) && projChecklistObj[e.target.name] !== "") {
                errorMsg = {
                    status: false,
                    message: ""
                };
                setError((prevState) => ({
                    ...prevState,
                    [e.target.name]: errorMsg
                }));
            }
            else {
                errorMsg = {
                    status: true,
                    message: e.target.name + " is required."
                };
                setError((prevState) => ({
                    ...prevState,
                    [e.target.name]: errorMsg
                }));
            }
        }
    }

    const validateUser = async (e) => {
        setShowLoading(true);
        let { name, value } = e.target;

        if (value) {
            let activeTeamMembers = await getProjectActiveTeamData(projChecklistObj.ProjectCode);

            let validUser = activeTeamMembers.filter(x => x.UserName?.toLowerCase() === value?.toLowerCase());
            if (validUser.length === 0) {
                setError((prevState) => ({
                    ...prevState,
                    [name]: {
                        status: true,
                        message: "Invalid User details."
                    }
                }));
            }
            else {
                setProjChecklistObj((prevState) => ({
                    ...prevState,
                    [name]: validUser[0].UserName,
                    [name + " Id"]: validUser[0].UserId,
                    [name + " EmailId"]: validUser[0].EmailId
                }));
                setError((prevState) => ({
                    ...prevState,
                    [name]: { status: false, message: "" }
                }));
            }
        }
        setShowLoading(false);
    }

    return (<>
        {showLoading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
        <Form
            id={"projecttran" + VersionCode}
            name={"projecttran" + VersionCode}
            // onSubmit={handleSubmit}
            autoComplete="off"
        >
            <Container fluid className="action-checklist-container">
                <Row>
                    <Col lg={4} md={3} xs={12} className="versionCodeCss">
                        <Form.Group className="g-select">
                            <Form.Select
                                id="VersionCode"
                                name="VersionCode"
                                placeholder=" "
                                required
                                value={VersionCode}
                                onChange={(e) => setVersionCode(e.target.value)}
                                disabled={projChecklistObj.IsFreeze}
                            >
                                {versionResult?.map((item) => {
                                    return (<option key={item.SK} value={item.ChecklistVersion}>{item.ChecklistVersion}</option>)
                                })}
                            </Form.Select>
                            <Form.Label className="frm-label" htmlFor="version">Version</Form.Label>
                        </Form.Group>&nbsp;
                        {(!projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active") &&
                            <Button variant="success" id="VersionSubmit" name="VersionSubmit" type="button" onClick={handleVersionChange} className="btn-add-color semiBoldToggleButton">
                                Submit
                            </Button>
                        }
                    </Col>
                    <Col lg={8} md={3} xs={12}>
                        <div className="modal-title">
                            <div className="viewDownload mt-2">
                                <span>
                                    <img src={view_download} alt={view_download}></img>
                                </span>
                                &nbsp;
                                <Link className="viewExcelLink" onClick={() => openPDF()}>Download Project Transaction Checklist</Link>
                            </div>
                            <div className="viewDownload mt-2 rightAlignedButton">
                                <span>
                                    <img src={backArrowPage} alt={backArrowPage} />
                                </span>
                                &nbsp;
                                <Link className="viewExcelLink" onClick={() => props.onHide(props.rowdata)}>
                                    Back to Project Transaction Checklist
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={versionMismatch ? "checklistNoteInfo" : "hideDiv"}>
                <span><img className="statusImg"
                    src={blueInfoSign}
                    alt="blueInfoSign"
                />&nbsp;</span>
                <span className="noteCss">NOTE</span>
                <br />
                <span className="font12Css">Checklist Version assigned to
                    <span className="alertFont"> {projChecklistObj.EquipmentName} </span>
                    and
                    <span className="alertFont"> {projChecklistObj.EquipmentTag} </span>
                    is
                    <span className="alertFont"> {props.rowdata.projCreationVersion} </span>
                    at the time of Project creation, but now new checklist versions are available.<br />
                    Kindly check if you wish to opt for latest Checklist Versions.
                    You can change Checklist Version by clicking on Checklist Version drop down provided in the Checklist Form.</span>
            </div>
            <div className="chklstMainContainer">
                <div className="checklistVersion-container marginLeftCss">
                    <Row>
                        <Col lg={11} md={10} xs={12} className="marginBottomCss sectionTitle">
                            {projChecklistObj.ChecklistVersion} check list for {projChecklistObj.EquipmentTag}
                        </Col>
                        <Col lg={1} md={1} xs={12} className="marginBottomCss">
                            <img
                                src={godrejLogo}
                                alt="godrejLogo"
                                className="ImageGodrej"
                            />
                        </Col>
                    </Row>
                    <Row>{
                        chklstHeader.map((item, idx) => {
                            return (
                                <Col lg={3} md={3} xs={12} key={idx}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id={item.FieldName}
                                            type="text"
                                            name={item.FieldName}
                                            maxLength={item.maxLength}
                                            placeholder=" "
                                            //value={item.FieldName === "Project Name"?projChecklistObj.ProjectName :projChecklistObj[item.FieldName]}
                                            value={projChecklistObj[item.FieldName] ? projChecklistObj[item.FieldName] : ""}
                                            readOnly={(!item.entryInd || projChecklistObj.IsFreeze)}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={e => CheckValidation(e, item)}
                                        />
                                        <Form.Label className="frm-label" htmlFor={item.FieldName}>{item.FieldName}</Form.Label>
                                        {error[item.FieldName] === undefined ? false
                                            : error[item.FieldName].status && (
                                                <Form.Text className="text-danger">
                                                    {error[item.FieldName].message}
                                                </Form.Text>
                                            )
                                        }
                                    </Form.Group>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
                <div className="ag-theme-alpine agGridContainer d-none d-md-block d-lg-block">
                    <AgGridReact
                        ref={gridRef}
                        rowData={parameterDetails}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        fieldNames={fieldName}
                        headerNames={headerName}
                        onGridReady={onGridReady}
                        // rowSelection={"multiple"}
                        enableBrowserTooltips={true}
                        // singleClickEdit={true}
                        onCellValueChanged={e => handleCellChange(e)}
                    />
                </div>
                <div className="d-md-none d-lg-none mt-1">
                    <Accordion className="projChecklist">
                        {parameterDetails?.map((item, index) =>
                            <Accordion.Item eventKey={index} key={item.SK}>
                                <Accordion.Header>Sr. No. {index + 1}</Accordion.Header>
                                {/* <Collapse in={open[index]} key={index}> */}
                                <Accordion.Body>
                                    {/* <div id="example-collapse-text">
                                        <Card key={index} className="cardContainer">
                                            <Card.Body className="cardbodycontainer"> */}
                                    {
                                        parameterdataResp.map((itm, cnt) => {
                                            return (<>
                                                {itm.displayInd && itm.dataType === "dropdown" &&
                                                    <Col xs={12} key={"dropdown" + index + "" + cnt}>
                                                        <Form.Group className="g-select">
                                                            <Form.Select
                                                                id={itm.FieldName + index + "" + cnt}
                                                                name={itm.FieldName}
                                                                placeholder=" "
                                                                value={item[itm.FieldName] ? item[itm.FieldName] : ""}
                                                                onChange={(e) => { handleChangeCard(e, index) }}
                                                                disabled={item.IsFreeze}>
                                                                <option value="" >Status</option>
                                                                {item.StatusArr?.map((opt) => (
                                                                    <option result={opt.Result} value={opt.name}>{opt.name}</option>
                                                                ))
                                                                }
                                                            </Form.Select>
                                                            <Form.Label className="frm-label" htmlFor={itm.FieldName}>{itm.FieldName}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                }
                                                {itm.displayInd && itm.dataType === "upload" &&
                                                    (
                                                        item.Attachment && item.Attachment !== "" ?
                                                            <Row xs={12} key={"upload" + index + "" + cnt}>
                                                                <Col xs={10}>
                                                                    <span className="deleteIconSpan" onClick={() => handleDownloadDocument(item)}>{item.Attachment}</span>
                                                                </Col>
                                                                <Col xs={2} className="customerArrowResponsive">
                                                                    <img
                                                                        onMouseOver={e => (e.target.src = Delete_green)}
                                                                        onMouseOut={e => (e.target.src = Delete)}
                                                                        src={Delete}
                                                                        alt="delete"
                                                                        onClick={() => handleDeleteEvent(index)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            :
                                                            <Col xs={12} key={"upload" + index + "" + cnt}>
                                                                <DragDropRender val={index} winType={"MOBILE"} itemData={{ data: item }} handleChangeEvent={handleChangeStatus} optionalFlag={item.AttachmentFlag && item.AttachmentFlag === "Yes" ? false : true} disabled={projChecklistObj.IsFreeze || props.rowdata.ProjectDeleteIndication === "Inactive" || props.rowdata.ProjectDeleteIndication === "Closed"} />
                                                            </Col>
                                                    )

                                                }
                                                {itm.displayInd && (itm.dataType === "text" || itm.dataType === "number") &&
                                                    <Col xs={12} key={"text" + index + "" + cnt}>
                                                        <Form.Group className="g-input ">
                                                            <Form.Control
                                                                id={itm.FieldName + index + "" + cnt}
                                                                type={itm.dataType}
                                                                name={itm.FieldName}
                                                                placeholder=" "
                                                                value={itm.FieldName === "UOM" && item[itm.FieldName] === undefined ? "NA" : item[itm.FieldName] ? item[itm.FieldName] : ""}
                                                                onChange={(e) => { handleChangeCard(e, index) }}
                                                                readOnly={!itm.transEntryInd || projChecklistObj.IsFreeze}
                                                            />
                                                            <Form.Label className="frm-label" htmlFor={itm.FieldName + index + "" + cnt}>{itm.FieldName}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                }

                                            </>)
                                        }
                                        )
                                    }
                                    {/* </Card.Body>
                                        </Card>
                                    </div> */}
                                    {/* </Collapse> */}
                                </Accordion.Body>
                            </Accordion.Item>
                            // </Row>
                        )

                        }

                    </Accordion>
                </div>
                <div className="contectFooterCss">
                    <Row className="rowFooter">{
                        chklstFooter.map((item, idx) => {
                            return (
                                item.FieldName === "Remarks" ?
                                    <Col lg={12} md={12} xs={12} key={idx}>
                                        <Form.Group className="g-input">
                                            <Form.Control
                                                id="Remarks"
                                                type="text"
                                                name="Remarks"
                                                maxLength="500"
                                                placeholder=" "
                                                value={projChecklistObj.Remarks ? projChecklistObj.Remarks : ""}
                                                onChange={(e) => handleChange(e)}
                                                readOnly={projChecklistObj.IsFreeze}
                                                onBlur={e => CheckValidation(e, item)}
                                            />
                                            <Form.Label className="frm-label" htmlFor="Remarks">Remarks</Form.Label>
                                            {error[item.FieldName] === undefined ? false
                                                : error[item.FieldName].status && (
                                                    <Form.Text className="text-danger">
                                                        {error[item.FieldName].message}
                                                    </Form.Text>
                                                )
                                            }
                                        </Form.Group>
                                    </Col>
                                    : <Col lg={6} md={6} xs={12} key={idx}>
                                        <Form.Group className="g-input">
                                            <Form.Control
                                                id={item.FieldName}
                                                type="text"
                                                name={item.FieldName}
                                                maxLength="100"
                                                placeholder=" "
                                                readOnly={projChecklistObj.IsFreeze}
                                                value={projChecklistObj[item.FieldName] ? projChecklistObj[item.FieldName] : ""}
                                                onChange={handleChange}
                                                onBlur={async (e) => { CheckValidation(e, item); await validateUser(e) }}
                                            />
                                            <Form.Label htmlFor={item.FieldName}>{item.FieldName}</Form.Label>
                                            {(!projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active") ?
                                                <BiSelectMultiple className="modalPopUpIconProjchklst"
                                                    size={20}
                                                    onClick={() => {
                                                        showPopUp("Project Team Details",
                                                            "Single",
                                                            "UserId",
                                                            item.FieldName,
                                                            props.rowdata.ProjectCode
                                                        );
                                                    }}
                                                    backdrop="static"
                                                /> : <></>}
                                            {error[item.FieldName] === undefined ? false
                                                : error[item.FieldName].status && (
                                                    <Form.Text className="text-danger">
                                                        {error[item.FieldName].message}
                                                    </Form.Text>
                                                )
                                            }
                                        </Form.Group>
                                    </Col>
                            )
                        })
                    }
                    </Row>
                </div>
                {
                    (!projChecklistObj.IsFreeze && props.rowdata.ProjectDeleteIndication === "Active") ?
                        <Row className="buttonsCss">
                            <Col>
                                <Button variant="outline-success" id="TempSave" name="TempSave" className="semiBoldToggleButton cancelBtn tempSaveResp" onClick={() => { handleSubmit("TempSave") }} >
                                    Temp Save
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="success" id="Submit" name="Submit" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={() => { handleSubmit("Submit") }}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                        : <></>
                }
            </div>
        </Form >
        <RenderIf isTrue={showForm}>
            <SingleValuePopUp
                show={showForm}
                onHide={() => setShowForm(false)}
                setSelected={setPopUpData}
                mastername={popUp.name}
                paramkey={popUp.key}
                paramvalue={popUp.value}
                additionalParams={popUp.additionalParams}
                projcode={projChecklistObj.ProjectCode}
                backdrop="static"
            />
        </RenderIf>
        {
            showPDF && (
                <ChecklistPDF
                    show={showPDF}
                    onHide={() => setShowPDF(false)}
                    displayPDFData={displayPDFData}
                    calledFrom={checklistType}
                />
            )
        }
    </>
    );

}

export default ProjectChecklistVersionList;
