import React, { useEffect, useState, useContext } from "react";
import { Button, Container, Col, Form, Row, Spinner } from "react-bootstrap";
import { menuActions } from "../../store/menu-slice";
import ActionComp from "../layouts/ActionComp";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import StatusRenderer from "../masters/StatusRenderer.jsx";
import ActionRenderer from "../masters/ActionRenderer";
import GridMaster from "../masters/GridMaster";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { BiSelectMultiple } from "react-icons/bi";
import {
    getDocumentReviewTracker,
    getDocReviewEquipmentTagData,
    deleteDocumentReviewTracker,
    addDocumentReviewTracker,
    getExistingSnapShotData,
    addSnapShot
} from "../../services/documentreviewtracker-service";
import { getEquipmentParameterData } from "../../services/equipmentactivity-master-service";
import { getEquipmentTagDataForEquipment } from "../../services/project-service"
import { RenderIf, getCurrentDateTime } from "../../functions/gbcs-functions";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import AddEditDocumentReviewTracker from "./AddEditDocumentReviewTracker";
import { exportToExcel } from "../utils/ExcelCreation";
import { formatDateInDDMMYYYY } from "../../functions/gbcs-functions";
import { getUserInitials, sortByPropertyInAscending } from "../../utils/Utility";
import { searchActions } from "../../store/grid-slice";
import { AuthContext } from "../../contexts/AuthContext";


const DocumentReviewTracker = () => {
    const dispatch = useDispatch();
    dispatch(menuActions.setMenu("Document Review Tracker"));
    const MySwal = withReactContent(Swal);
    const [rowData, setRowData] = useState({});
    const [filteredResult, setFilteredResult] = useState([]);
    const [filteredResulttemp, setFilteredResulttemp] = useState([]);
    const [filterColLength, setFilterColLength] = useState(0);
    const [action, setAction] = useState("");
    const [showPopUpForm, setshowPopUpForm] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resetFilter, setResetFilter] = useState(true);
    const [enableSearch, setEnableSearch] = useState(false);
    const [actionButtons, setActionButtons] = useState({});
    const context = useContext(AuthContext);
    const userDetails = { userName: context.userName, userId: context.userId };


    const [SelectedProjEquipObj, setSelectedProjEquipObj] = useState(
        {
            ProjectCode: "",
            ProjectName: "",
            ProjStartDate: "",
            ProjClosureDate: "",
            DeleteIndication: "",
            ProjEndDate: "",
            EquipmentCode: "",
            EquipmentName: "",
            ServiceCode: "",
            ServiceName: ""
        }
    );

    useEffect(() => {
        dispatch(searchActions.setSearch(""));
    }, []);

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
    });

    const showPopUp = (name, type, key, value, additionalParams) => {
        if (!SelectedProjEquipObj.ProjectCode && name != "Project Master") {
            MySwal.fire({
                text: "Please select the project first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        else {
            setPopUp({ name, type, key, value, additionalParams });
            setshowPopUpForm(true);
        }
    };

    const setPopUpData = async (data) => {
        if (data.name === "Project Master") {
            setSelectedProjEquipObj((prevState) => ({
                ...prevState,
                ProjectCode: data.result.ProjectCode,
                ProjectName: data.result.ProjectName,
                ProjStartDate: data.result.ProjStartDate,
                ProjEndDate: data.result.ProjEndDate,
                ProjClosureDate: data.result.ProjClosureDate,
                DeleteIndication: data.result.DeleteIndication,
                EquipmentName: "",
                EquipmentCode: "",
                ServiceCode: "",
                ServiceName: ""
            }));
            if (data.result.DeleteIndication !== "Active") {
                setActionButtons({
                    ViewAction: true,
                    EditAction: false,
                    DownloadAction: false,
                    DeleteAction: false,
                });
            }
            else {
                setActionButtons({});
            }
        }
        else if (data.name === "Project Equipment Details") {
            setSelectedProjEquipObj((prevState) => ({
                ...prevState,
                EquipmentName: data.result.EquipmentName,
                EquipmentCode: data.result.EquipmentCode,
                ServiceCode: data.result.ServiceCode,
                ServiceName: data.result.ServiceName
            }));
        }

        setFilteredResult([]);
        setExcelData([]);
        setEnableSearch(false);
        setResetFilter(!resetFilter);
    }

    const handleSubmit = async (e) => {
        if (!SelectedProjEquipObj.ProjectCode || !SelectedProjEquipObj.EquipmentCode) {
            MySwal.fire({
                text: "Please select the project & equipment.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        else {
            getDocumentReviewTrackerData(SelectedProjEquipObj);
        }
    }

    const getDocumentReviewTrackerData = async (projEquipObj) => {
        setLoading(true);
        console.log("inside getDocumentReviewTrackerData")
        let temp = [];
        let equipDocsData = []
        let snapShotDataArr = [];
        let result = [];
        result = await getDocumentReviewTracker(projEquipObj);
        if (result.length <= 0 && SelectedProjEquipObj.DeleteIndication === "Active") {
            let snapshotParams = {
                primekey: "DocumentReviewTracker#ProjectCode#" + SelectedProjEquipObj.ProjectCode,
                sortkey: "SnapShot#EquipmentCode#" + SelectedProjEquipObj.EquipmentCode
            }
            console.log("snapshotParams ===> ", snapshotParams);
            [equipDocsData, snapShotDataArr] = await Promise.all([
                await getEquipmentParameterData({ "code": SelectedProjEquipObj.EquipmentCode, "tabName": "DocumentReview" }, "master"),
                await getExistingSnapShotData(snapshotParams)
            ]);
            if (equipDocsData?.length > 0) {
                let counter = 0;
                let array = [];
                for (let item of equipDocsData) {
                    let objTemp = {};
                    objTemp["CreatedOn"] = getCurrentDateTime("-");
                    objTemp["CreatedBy"] = userDetails.userId;
                    objTemp["CreatedByName"] = userDetails.userName;
                    objTemp["ModifiedBy"] = userDetails.userId;
                    objTemp["ModifiedByName"] = userDetails.userName;
                    objTemp["ModifiedOn"] = getCurrentDateTime("-");
                    objTemp["ProjectName"] = SelectedProjEquipObj.ProjectName;
                    objTemp["ProjectCode"] = SelectedProjEquipObj.ProjectCode;
                    objTemp["EquipmentCode"] = SelectedProjEquipObj.EquipmentCode;
                    objTemp["EquipmentName"] = SelectedProjEquipObj.EquipmentName;
                    objTemp["ServiceCode"] = SelectedProjEquipObj.ServiceCode;
                    objTemp["ServiceName"] = SelectedProjEquipObj.ServiceName;
                    objTemp["DocumentAvailable"] = "No";
                    objTemp["DocumentName"] = item["Document Name"];
                    objTemp["DocumentCategory"] = item["Document Category"];
                    objTemp["DocumentSubmissionStatus"] = "Pending";
                    objTemp["ReSubmissionStatus"] = "Pending";
                    objTemp["ApprovalStatus"] = "Pending";
                    objTemp["PK"] = "DocumentReviewTracker#ProjectCode#" + SelectedProjEquipObj.ProjectCode;
                    objTemp["SK"] = "EquipmentCode#" + SelectedProjEquipObj.EquipmentCode + "#DocumentName#" + item["Document Name"];

                    temp.push(objTemp);
                    let tmpRes = addDocumentReviewTracker(objTemp);
                    array.push(tmpRes);
                    counter++;

                    if (counter === 5) {
                        await Promise.all(array);
                        counter = 0;
                        array = [];
                    }
                }
                if (array.length > 0) {
                    await Promise.all(array);
                }
                temp?.sort(sortByPropertyInAscending("CreatedOn"));
                setFilteredResult(temp);
                setExcelData(temp);
            }
            if (snapShotDataArr.length <= 0) {
                snapShotDataArr = equipDocsData;
                let cnt = 1;
                let snapArray = [];
                for (let snap of snapShotDataArr) {
                    let snapObj = {
                        PK: "DocumentReviewTracker#ProjectCode#" + SelectedProjEquipObj.ProjectCode,
                        SK: "SnapShot#EquipmentCode#" + SelectedProjEquipObj.EquipmentCode + "#SeqNo#" + cnt,
                        SeqNo: cnt,
                        "Document Category": snap["Document Category"],
                        "Document Name": snap["Document Name"],
                        CreatedOn: getCurrentDateTime("-"),
                        CreatedBy: userDetails.userId,
                        CreatedByName: userDetails.userName
                    }
                    let res = addSnapShot(snapObj);
                    snapArray.push(res);

                    if (cnt === 5) {
                        await Promise.all(snapArray);
                        cnt = 0;
                        snapArray = [];
                    }
                }
                if (snapArray.length > 0) {
                    await Promise.all(snapArray);
                }
            }
        }
        else {
            result?.sort(sortByPropertyInAscending("CreatedOn"));
            setFilteredResult(result);
            setExcelData(result);
        }
        setEnableSearch(true);
        setResetFilter(!resetFilter);
        setLoading(false);
    }

    const columnDefs = [
        {
            headerName: "Sr.No.",
            field: "SrNo",
            valueFormatter: (params) => {
                return params.node.rowIndex + 1;
            },
            minWidth: 100
        },
        {
            headerName: "Document Category",
            field: "DocumentCategory",
            tooltipField: "DocumentCategory",
            minWidth: 200,
        },
        {
            headerName: "Document Name",
            field: "DocumentName",
            tooltipField: "DocumentName",
            minWidth: 200,
        },
        {
            headerName: "Vendor",
            field: "Vendor",
            tooltipField: "Vendor",
            minWidth: 200,
        },
        {
            headerName: "Document Available",
            field: "DocumentAvailable",
            tooltipField: "DocumentAvailable",
            minWidth: 200,
        },
        {
            headerName: "Document Submission Status",
            field: "DocumentSubmissionStatus",
            flex: false,
            width: 200,
            resizable: false,
            tooltipField: "DocumentSubmissionStatus",
            cellRenderer: StatusRenderer
        },
        {
            headerName: "Re Submission Status",
            field: "ReSubmissionStatus",
            flex: false,
            width: 200,
            resizable: false,
            tooltipField: "ReSubmissionStatus",
            cellRenderer: StatusRenderer,
        },
        {
            headerName: "Approval Status",
            field: "ApprovalStatus",
            flex: false,
            width: 200,
            resizable: false,
            tooltipField: "ApprovalStatus",
            cellRenderer: StatusRenderer,
        },
        {
            field: "",
            width: 120,
            cellRenderer: function (params) {
                return <ActionRenderer selectedData={params.data} handleViewEvent={handleView} handleEditEvent={handleEdit} handleDeleteEvent={handleDelete} actionName={actionButtons} />
            },
            sortable: false,
            filter: false,
            pinned: 'right'
        },
    ];

    const headerName = (field) => {
        let name;
        switch (field) {
            case "SrNo":
                name = "Sr.No.";
                break;
            case "DocumentCategory":
                name = "Document Category";
                break;
            case "DocumentName":
                name = "Document Name";
                break;
            case "Vendor":
                name = "Vendor";
                break;
            case "DocumentAvailable":
                name = "Document Available";
                break;
            case "DocumentSubmissionStatus":
                name = "Document Submission Status";
                break;
            case "ReSubmissionStatus":
                name = "Re Submission Status";
                break;
            case "ApprovalStatus":
                name = "Approval Status";
                break;
        }
        return name;
    }

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Sr.No.":
                name = "SrNo";
                break;
            case "Document Category":
                name = "DocumentCategory";
                break;
            case "Document Name":
                name = "DocumentName"
                break;
            case "Vendor":
                name = "Vendor"
                break;
            case "Document Available":
                name = "DocumentAvailable"
                break;
            case "Document Submission Status":
                name = "DocumentSubmissionStatus"
                break;
            case "Re Submission Status":
                name = "ReSubmissionStatus"
                break;
            case "Approval Status":
                name = "ApprovalStatus"
                break;
        }
        return name;
    }

    const handleAdd = () => {
        if (!SelectedProjEquipObj.ProjectCode || !SelectedProjEquipObj.EquipmentCode) {
            MySwal.fire({
                text: "Please select the Project and Equipment first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        else if (SelectedProjEquipObj.DeleteIndication !== "Active") {
            MySwal.fire({
                text: "Project status is " + SelectedProjEquipObj.DeleteIndication + ", so you can not add new records.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        else {
            setAction("Add");
            setShowForm(true);
        }
    };

    const handleGlobalExport = async () => {
        let filename = "DocumentReviewTracker";
        let excelDownoadData = [];

        if (excelData.length > 0) {
            setLoading(true);
            let tgSvcBody = {
                primekey: "ProjectCode#" + SelectedProjEquipObj.ProjectCode,
                sortkey: "ProjectService#SeqNo#",
                EquipmentCode: SelectedProjEquipObj.EquipmentCode
            }
            const projEqipTgData = await getEquipmentTagDataForEquipment(tgSvcBody);

            for (let element of excelData) {
                let obj = {
                    primekey: "DocumentReviewTracker#ProjectCode#" + element.ProjectCode + "#EquipmentCode#" + element.EquipmentCode,
                    sortkey: "DocumentName#" + element.DocumentName + "#EquipmentTag#"
                }
                let docReviewTgData = await getDocReviewEquipmentTagData(obj);
                let docObj = {
                    "ProjectName": element.ProjectName,
                    "ServiceName": element.ServiceName,
                    "EquipmentName": element.EquipmentName,
                    "DocumentCategory": element.DocumentCategory,
                    "DocumentName": element.DocumentName,
                    "Vendor": element.Vendor,
                    "DocumentAvailable": element.DocumentAvailable,
                    "SrNo": "",
                    "EquipmentTag": "",
                    "DocumentSubmissionStatus": "",
                    "DocumentSubmissionReviewedBy": "",
                    "DocumentSubmissionReviewedDate": "",
                    "ReSubmissionStatus": "",
                    "ReSubmissionReviewedBy": "",
                    "ReSubmissionReviewedDate": "",
                    "ApprovalStatus": "",
                    "ApprovalReviewedBy": "",
                    "ApprovalReviewedDate": "",
                    "Remarks": ""
                }
                if (projEqipTgData?.length > 0) {
                    let count = 0;
                    for (let tagRecord of projEqipTgData) {
                        let tempObj = Object.assign({}, docObj);
                        count++
                        tempObj.SrNo = count;
                        tempObj.EquipmentTag = tagRecord.EquipmentTag;
                        let existRecord = docReviewTgData?.filter((item) => item.EquipmentTag === tagRecord.EquipmentTag);
                        if (existRecord?.length > 0) {
                            tempObj.DocumentSubmissionStatus = existRecord[0].DocumentSubmissionStatus;
                            tempObj.DocumentSubmissionReviewedBy = existRecord[0].DocumentSubmissionReviewedBy;
                            tempObj.DocumentSubmissionReviewedDate = formatDateInDDMMYYYY(existRecord[0].DocumentSubmissionReviewedDate ? existRecord[0].DocumentSubmissionReviewedDate : "");
                            tempObj.ReSubmissionStatus = existRecord[0].ReSubmissionStatus;
                            tempObj.ReSubmissionReviewedBy = existRecord[0].ReSubmissionReviewedBy;
                            tempObj.ReSubmissionReviewedDate = formatDateInDDMMYYYY(existRecord[0].ReSubmissionReviewedDate ? existRecord[0].ReSubmissionReviewedDate : "");
                            tempObj.ApprovalStatus = existRecord[0].ApprovalStatus;
                            tempObj.ApprovalReviewedBy = existRecord[0].ApprovalReviewedBy;
                            tempObj.ApprovalReviewedDate = formatDateInDDMMYYYY(existRecord[0].ApprovalReviewedDate ? existRecord[0].ApprovalReviewedDate : "");
                            tempObj.Remarks = existRecord[0].Remarks;
                        }
                        else {
                            tempObj.DocumentSubmissionStatus = "Pending";
                        }
                        excelDownoadData.push(tempObj);
                    }
                }
            }
            if (excelDownoadData.length > 0) {
                let colName = ["Project", "Service Name", "Equipment / Activity Name", "Document Category", "Document Name", "Vendor", "Document Available", "Sr No.", "Equipment Tag", "Document Submission Status", "Document Submission Reviewed By", "Document Submission Reviewed Date", "Re Submission Status", "Re Submission Reviewed By", "Re Submission Reviewed Date", "Approval Status", "Approval Reviewed By", "Approval Reviewed Date", "Remarks"];
                let Fieldname = ["ProjectName", "ServiceName", "EquipmentName", "DocumentCategory", "DocumentName", "Vendor", "DocumentAvailable", "SrNo", "EquipmentTag", "DocumentSubmissionStatus", "DocumentSubmissionReviewedBy", "DocumentSubmissionReviewedDate", "ReSubmissionStatus", "ReSubmissionReviewedBy", "ReSubmissionReviewedDate", "ApprovalStatus", "ApprovalReviewedBy", "ApprovalReviewedDate", "Remarks"];
                exportToExcel(filename, "Data", excelDownoadData, "A1:S1", colName, Fieldname);
            }
            setLoading(false);
        }
        else {
            MySwal.fire({
                text: "No records to download.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    };

    const handleEdit = async (row) => {
        setAction("Edit");
        setRowData(row);
        setShowForm(true);
    }

    const handleDelete = async (params) => {
        let result;
        MySwal.fire({
            text: "Do you want to Delete the Record?",
            showDenyButton: true,
            cancelButtonColor: "#fff",
            confirmButtonText: "Delete",
            reverseButtons: true,
            customClass: "swal-confirmation",
            title: "Delete Document Review Tracker"
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                setLoading(true);
                let equipTgparam = {
                    EquipmentCode: SelectedProjEquipObj.EquipmentCode,
                    ServiceCode: SelectedProjEquipObj.ServiceCode,
                    primekey: "DocumentReviewTracker#ProjectCode#" + SelectedProjEquipObj.ProjectCode + "#EquipmentCode#" + SelectedProjEquipObj.EquipmentCode,
                    sortkey: "DocumentName#" + params.DocumentName + "#EquipmentTag#"
                }
                const [docEquipTgData] = await Promise.all([
                    getDocReviewEquipmentTagData(equipTgparam)
                ]);

                let addData = docEquipTgData;
                let counter = 0;
                let array = [];
                for (let item of addData) {
                    counter++;
                    let obj = { PK: item.PK, SK: item.SK };
                    const delRes = deleteDocumentReviewTracker(obj);
                    array.push(delRes);

                    if (counter === 5) {
                        await Promise.all(array);
                        counter = 0;
                        array = [];
                    }
                }
                if (array.length > 0) {
                    await Promise.all(array);
                }
                let delObj = { PK: params.PK, SK: params.SK };
                result = deleteDocumentReviewTracker(delObj);
                result.then((resp) => {
                    if (resp.status) {
                        setLoading(false);
                        MySwal.fire({
                            text: resp.message,
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                getDocumentReviewTrackerData(SelectedProjEquipObj);
                            },
                        });
                    }
                    else {
                        setLoading(false);
                        MySwal.fire({
                            text: "Unable to process request.",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                }).catch((error) => {
                    setLoading(false);
                    MySwal.fire({
                        text: "Unable to process request.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                })
            }
        });

    }

    const handleView = async (row) => {
        setAction("View");
        setRowData(row);
        setShowForm(true);
    }


    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <BreadcrumbComp />
            <Container fluid className="main-container">
                <ActionComp label={"Add New"} exportEvent={() => handleGlobalExport()} handleAdd={handleAdd} enablesearch={enableSearch} />
                <div className="action-comp-container">
                    <Row className="docRevTopMargin">
                        <Col lg={3} md={3} xs={12}>
                            <Form.Group class="g-input docRevInputMargin">
                                <Form.Control className="inputHeight"
                                    id="ProjectName"
                                    type="text"
                                    name="ProjectName"
                                    placeholder=" "
                                    required
                                    value={SelectedProjEquipObj.ProjectName}
                                    readOnly
                                />
                                <Form.Label className="frm-label input-label" for="ProjectName">Project</Form.Label>
                                <BiSelectMultiple className="modalPopUpIconActionComp"
                                    size={18}
                                    onClick={() => showPopUp("Project Master",
                                        "Single",
                                        "ProjectName",
                                        SelectedProjEquipObj.ProjectName,
                                        {})}
                                    color={"gray"}
                                    style={{ marginLeft: "-2rem" }}
                                    backdrop="static"
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={3} xs={12}>
                            <Form.Group class="g-input docRevInputMargin">
                                <Form.Control className="inputHeight"
                                    id="EquipmentName"
                                    type="text"
                                    name="EquipmentName"
                                    placeholder=" "
                                    required
                                    value={SelectedProjEquipObj.EquipmentName}
                                    readOnly
                                />
                                <Form.Label className="frm-label input-label" for="ProjectName">Equipment</Form.Label>
                                <BiSelectMultiple className="modalPopUpIconActionComp"
                                    size={18}
                                    onClick={() => showPopUp("Project Equipment Details",
                                        "Single",
                                        "EquipmentName",
                                        SelectedProjEquipObj.EquipmentName,
                                        { ProjectCode: SelectedProjEquipObj.ProjectCode })}
                                    color={"gray"}
                                    style={{ marginLeft: "-2rem" }}
                                    backdrop="static"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2} xs={12}>
                            <Button variant="success" className="btn-add-color semiBoldToggleButton filterSubmit rightAlignedButtonInResp" onClick={handleSubmit} >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </div>
                <GridMaster
                    rowDetails={filteredResult}
                    colDetails={columnDefs}
                    fieldNames={fieldName}
                    headerNames={headerName}
                    getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
                    getFilterColLength={(getLength) => setFilterColLength(getLength)}
                    setExcelData={setExcelData}
                    handleView={handleView}
                    handleEdit={handleEdit}
                    openConfirmBox={handleDelete}
                    resetind={resetFilter}
                    actionName={actionButtons}
                />
                {showForm && (
                    <AddEditDocumentReviewTracker
                        show={showForm}
                        action={action}
                        rowdata={rowData}
                        SelectedProjEquipObj={SelectedProjEquipObj}
                        onHide={() => setShowForm(false)}
                        getdocumentreviewtracker={getDocumentReviewTrackerData}
                    />
                )}
                <RenderIf isTrue={showPopUpForm}>
                    <SingleValuePopUp
                        show={showPopUpForm}
                        onHide={() => setshowPopUpForm(false)}
                        setSelected={setPopUpData}
                        mastername={popUp.name}
                        paramkey={popUp.key}
                        paramvalue={popUp.value}
                        additionalParams={popUp.additionalParams}
                        backdrop="static"
                    />
                </RenderIf>
            </Container>
        </>
    );
}

export default DocumentReviewTracker;
