import React, { useEffect, useState, useContext, useRef } from "react";
import { Row, Tabs, Tab, Container, Spinner } from "react-bootstrap";
import ChecklistDetails from "./ChecklistDetails";
import ParameterDetails from "./ParameterDetails";
import StatusDetails from "./StatusDetails";
import ChecklistFooter from "./ChecklistFooter";
import view_download from "../../../icons/view_download.svg";
import { Link } from "react-router-dom";
import "./ChecklistMaster.css";
import "../../utils/AddEditProjectMaster.css";
import { getChecklistMasterLimitdata } from "../../../services/common-master-service";
import { AddChecklistDetails, AddChecklistParameterDetails, DeleteChecklistDetails, DeleteChecklistParameterDetails, RenewChecklistEdit, getActiveChklstVersion, getChecklistMasterDetails, getChecklistParameterDetails } from "../../../services/checklist-master-service";
import backicon from "../../../icons/backicon.svg";
import ChecklistPDF from "./ChecklistPDF";
import TabButtons from "../../utils/TabButtons";
import { AuthContext } from "../../../contexts/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import { getCurrentDateTime } from "../../../functions/gbcs-functions";

const AddEditChecklistMaster = (props) => {
  let action = props.action;
  const context = useContext(AuthContext);
  const userDetails = {
    userName: context.userName,
    userId: context.userId,
  };
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(true);

  let tab0Ref = useRef();
  let tab1Ref = useRef();
  let tab2Ref = useRef();
  let tab3Ref = useRef();
  let tab4Ref = useRef();
  const [showPDF, setShowPDF] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [formSubmit, setFormSubmit] = useState("");
  const [primeKey, setPrimeKey] = useState(
    action === "Add" ? "" : props.rowdata.PK
  );
  const [secondKey, setSecondKey] = useState(
    action === "Add" ? "" : props.rowdata.SK
  );
  const [ChecklistCode, setChecklistCode] = useState(
    action === "Add" ? "" : props.rowdata.ChecklistCode
  );
  const [ChecklistVersion, setChecklistVersion] = useState(
    props.action === "Add" ? "" : props.rowdata.ChecklistVersion
  );
  const [equipCode, setEquipCode] = useState(
    action === "Add" ? "" : props.rowdata.EquipmentCode
  );
  const [checklistCat, setChecklistCat] = useState(
    action === "Add" ? "" : props.rowdata.ChecklistCategory
  );
  const [isParameterCategory, setIsParameterCategory] = useState(false);
  const [headerLimitVal, setHeaderLimitVal] = useState(0);
  const [parameterDetailsLimitVal, setParameterDetailsLimitVal] = useState(0);
  const [statusDetailsLimitVal, setStatusDetailsLimitVal] = useState(0);
  const [footerLimitVal, setFooterLimitVal] = useState(0);
  const [distinctCategories, setDistinctCategories] = useState([]);
  const [parameterDetailsData, setParameterDetailsData] = useState([]);
  const [displayPDFData, setDisplayPDFData] = useState({});

  useEffect(() => {
    if (action === "Edit" && props.rowdata.ChecklistVersion.startsWith("TMP")) {
      const interval = setInterval(() => {
        console.log('Logs every minute');
        MySwal.fire({
          text: "Are you sure, do you want to continue checklist modification?",
          showDenyButton: true,
          cancelButtonColor: "#fff",
          confirmButtonColor: "#16911B",
          confirmButtonText: "Yes",
          reverseButtons: true,
          customClass: "swal-confirmation",
          title: "Edit Checklist"
        }).then(async (result2) => {
          if (result2.isConfirmed) {
            setLoading(true);
            let obj = {
              PK: props.rowdata.PK,
              SK: props.rowdata.SK,
              RenewDateTime: getCurrentDateTime("-"),
              ModifiedBy: userDetails.userId,
              ModifiedByName: userDetails.userName
            }
            let result = await RenewChecklistEdit(obj);
            if (result.status) {
              MySwal.fire({
                text: result.message,
                imageUrl: successImage,
                customClass: "swal-success",
              });
            }
            setLoading(false);
          }
          else {
            handleBack();
          }
        });
      }, 900000);
      // }, 30000);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [])

  useEffect(() => {
    if (action !== "View") {
      fetchLimitData();
    }
    if (action !== "Add") {
      getParameterDetails();
    }
  }, []);

  const getParameterDetails = async () => {
    setLoading(true);
    let obj = {
      PK:
        "ChecklistCode#" +
        ChecklistCode +
        "#ChecklistVersion#" +
        ChecklistVersion,
      SK: "SeqNo#",
    };
    let result = await getChecklistParameterDetails(obj);
    if (result?.length > 0) {
      setParameterDetailsData(result);
      getDistinctCategories(result);
    }
    setLoading(false);
  };

  const getDistinctCategories = (allData) => {
    let uniqueArray = [];
    let uniqueArr = [];
    for (let dt of allData) {
      if (dt.hasOwnProperty("Parameter Category") && uniqueArr.indexOf(dt["Parameter Category"].toLowerCase()) === -1) {
        uniqueArray.push(dt["Parameter Category"]);
        uniqueArr.push(dt["Parameter Category"].toLowerCase());
      }
    }
    if (uniqueArray?.length > 0) {
      console.log("inside uniqueArray");
      setDistinctCategories(uniqueArray);
      setIsParameterCategory(true);
    }
  };

  const fetchLimitData = async () => {
    setLoading(true);
    let limitData = await getChecklistMasterLimitdata();
    if (limitData?.length > 0) {
      setHeaderLimitVal(limitData[0].HeaderLimitValue);
      setParameterDetailsLimitVal(limitData[0].ParameterDetailLimitValue);
      setStatusDetailsLimitVal(limitData[0].StatusLimitValue);
      setFooterLimitVal(limitData[0].FooterLimitValue);
    }
    setLoading(false);
  };

  const handleSelect = async (e, direction) => {
    let res = false;
    if ((currentTab === 0 && direction === "prev") || (currentTab === 4 && direction === "next")) {
      return;
    }
    let saveFlag = true;
    if (action === "View" || (currentTab !== 0 && ChecklistVersion.startsWith("VER"))) {
      saveFlag = false;
      res = true;
    }

    if (saveFlag) {
      setLoading(true);
      switch (currentTab) {
        case 0:
          res = await tab0Ref.current.funcOnNext();
          break;
        case 1:
          res = await tab1Ref.current.funcOnNext();
          break;
        case 2:
          res = await tab2Ref.current.funcOnNext();
          break;
        case 3:
          res = await tab3Ref.current.funcOnNext(direction);
          break;
        case 4:
          res = await tab4Ref.current.funcOnNext();
          break;
        default:
          break;
      }
      setLoading(false);
    }
    if (res) {
      if (direction == "prev") {
        if (currentTab !== 0) setCurrentTab(currentTab - 1)
      }
      else if (direction == "next") {
        if (currentTab !== 4) setCurrentTab(currentTab + 1)
      }
      else if (direction == "save") {
        setCurrentTab(currentTab)
      }
      else {
        setCurrentTab(Number(e))
      }
    }
  };

  const handleBack = async () => {
    if (action === "Edit" && props.rowdata.ChecklistVersion.startsWith("TMP")) {
      setLoading(true);
      let obj = {
        PK: props.rowdata.PK,
        SK: props.rowdata.SK,
        RenewDateTime: "",
        ModifiedBy: userDetails.userId,
        ModifiedByName: userDetails.userName
      }
      let result = await RenewChecklistEdit(obj);
      if (result.status) {
        props.setReload(!props.reload);
        props.onHide();
      } else {
        MySwal.fire({
          text: "Unable to reset datetime.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }
      setLoading(false);
    }
    else {
      props.setReload(!props.reload);
      props.onHide();
    }
  };

  const openPDF = async () => {
    setLoading(true);
    let pdfData = {};
    let nextVersionData = await getChecklistMasterDetails(equipCode, secondKey);
    if (nextVersionData?.length > 0) {
      pdfData.header = nextVersionData[0];
      pdfData.header.ServiceName = props.rowdata.ServiceName;
      let objs = {
        PK:
          "ChecklistCode#" +
          ChecklistCode +
          "#ChecklistVersion#" +
          ChecklistVersion,
        SK: "SeqNo#",
      };
      let nextParamData = await getChecklistParameterDetails(objs);
      if (nextParamData?.length > 0) {
        pdfData.paramData = nextParamData;
      }
      else {
        pdfData.paramData = [];
      }
      setDisplayPDFData(pdfData);
      setShowPDF(true);
      setLoading(false);
    }
    else {
      MySwal.fire({
        text: "Unable to load details. Kindly try again.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
      return;
    }
  }

  const handleReset = () => {
    tab0Ref.current.funcOnReset();
  }

  const handleFreeze = async () => {
    if (ChecklistVersion.startsWith("VER")) {
      MySwal.fire({
        text: "Checklist version is already frozen.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
      return;
    }
    else {
      setLoading(true);
      let activeVersions = await getActiveChklstVersion(equipCode);
      let tmpVersionData = activeVersions.filter((item) => item.ChecklistCategory === checklistCat && item.ChecklistVersion.startsWith("TMP"));
      if (tmpVersionData?.length > 0) {
        if (!tmpVersionData[0].hasOwnProperty("ChecklistHeader") || tmpVersionData[0].ChecklistHeader.length === 0) {
          setLoading(false);
          MySwal.fire({
            text: "Checklist header not maintained.",
            imageUrl: errorImage,
            customClass: "swal-success",
          });
          return;
        }
        else if (!tmpVersionData[0].hasOwnProperty("StatusDetails") || tmpVersionData[0].StatusDetails.length === 0) {
          setLoading(false);
          MySwal.fire({
            text: "Status details not maintained.",
            imageUrl: errorImage,
            customClass: "swal-success",
          });
          return;
        }
        else if (!tmpVersionData[0].hasOwnProperty("ChecklistFooter") || tmpVersionData[0].ChecklistFooter.length === 0) {
          setLoading(false);
          MySwal.fire({
            text: "Checklist footer not maintained.",
            imageUrl: errorImage,
            customClass: "swal-success",
          });
          return;
        }
      }
      let objs = {
        PK:
          "ChecklistCode#" +
          ChecklistCode +
          "#ChecklistVersion#" +
          ChecklistVersion,
        SK: "SeqNo#",
      };
      let nextParamData = await getChecklistParameterDetails(objs);
      if (nextParamData?.length === 0) {
        setLoading(false);
        MySwal.fire({
          text: "Parameter details are not maintained.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }
      let res = await tab3Ref.current.funcOnNext("save");
      if (res) {
        let todayDate = getCurrentDateTime("-");
        let finalVersion = "VER.0001";
        let currResultInd = true;
        // let activeVersions = await getActiveChklstVersion(equipCode);
        if (activeVersions?.length > 0) {
          let currentVersionData = activeVersions.filter((item) => item.ChecklistCategory === checklistCat && item.ChecklistVersion.startsWith("VER"));
          if (currentVersionData?.length > 0) {
            let currVer = currentVersionData[0].ChecklistVersion;
            let verArr = currVer.split(".");
            let nextVer = parseInt(verArr[1]) + 1;
            finalVersion = "VER." + nextVer.toString().padStart(4, '0')

            //update current active version
            let obj = currentVersionData[0];
            obj.DeleteIndication = "Inactive";
            obj.ModifiedBy = userDetails.userId;
            obj.ModifiedByName = userDetails.userName;
            obj.ModifiedOn = todayDate;
            let currResult = await AddChecklistDetails(obj);
            if (currResult?.status) {
              currResultInd = true;
            } else {
              setLoading(false);
              MySwal.fire({
                text: "Unable to update current active version",
                imageUrl: errorImage,
                customClass: "swal-success",
              });
            }
          }
        }

        if (currResultInd) {
          console.log("finalVersion", finalVersion);
          let nextVersionData = await getChecklistMasterDetails(equipCode, secondKey);

          if (nextVersionData?.length > 0) {
            let newVerObj = nextVersionData[0];
            newVerObj.SK = "ChecklistCategory#" + newVerObj.ChecklistCategory + "#ChecklistVersion#" + finalVersion;
            newVerObj.ChecklistVersion = finalVersion;
            newVerObj.DeleteIndication = "Active";
            newVerObj.ModifiedBy = userDetails.userId;
            newVerObj.ModifiedByName = userDetails.userName;
            newVerObj.ModifiedOn = todayDate;
            newVerObj.chklstFreezeDate = getCurrentDateTime("-");
            let newVerObjResult = await AddChecklistDetails(newVerObj);
            if (newVerObjResult?.status) {
              if (nextParamData?.length > 0) {
                let successInd = false;
                let errMsgLine = "";
                for (let dt of nextParamData) {
                  let newParam = Object.assign({}, dt);
                  newParam.PK = "ChecklistCode#" + ChecklistCode + "#ChecklistVersion#" + finalVersion;
                  newParam.ModifiedBy = userDetails.userId;
                  newParam.ModifiedByName = userDetails.userName;
                  newParam.ModifiedOn = todayDate;
                  let newParamResult = await AddChecklistParameterDetails(newParam);
                  if (newParamResult?.status) {
                    successInd = true;
                  } else {
                    errMsgLine += dt.SeqNo + ", ";
                  }
                }
                if (errMsgLine === "" && successInd) {
                  let delSuccessInd = false;
                  let delErrMsgLine = "";
                  for (let dt of nextParamData) {
                    let delParamResult = await DeleteChecklistParameterDetails(dt);
                    if (delParamResult?.status) {
                      delSuccessInd = true;
                    } else {
                      delErrMsgLine += dt.SeqNo + ", ";
                    }
                  }
                  if (errMsgLine === "" && delSuccessInd) {
                    let detailObj = { PK: primeKey, SK: secondKey }
                    let delResult = await DeleteChecklistDetails(detailObj);
                    if (delResult?.status) {
                      props.setReload(!props.reload);
                      props.onHide();
                      setLoading(false);
                      MySwal.fire({
                        text: "Checklist Details are frozen for Version " + finalVersion + ".",
                        imageUrl: successImage,
                        customClass: "swal-success",

                      });
                    } else {
                      setLoading(false);
                      MySwal.fire({
                        text: "Unable to delete checklist details for temp version",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                      });
                    }
                  }
                  else {
                    setLoading(false);
                    MySwal.fire({
                      text: "Unable to delete Sr No " + delErrMsgLine.substring(0, delErrMsgLine.length - 2) + " for temp version",
                      imageUrl: errorImage,
                      customClass: "swal-success",
                    });
                  }

                }
                else {
                  setLoading(false);
                  MySwal.fire({
                    text: "Unable to add Sr No " + errMsgLine.substring(0, errMsgLine.length - 2) + " for " + finalVersion,
                    imageUrl: errorImage,
                    customClass: "swal-success",
                  });
                }
              }
            }
            else {
              setLoading(false);
              MySwal.fire({
                text: "Unable to freeze version",
                imageUrl: errorImage,
                customClass: "swal-success",
              });
            }
          }
        }
      }
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <Container className="add-container">
        <br />
        <div>
          <Row>
            <div className="modal-title">
              <span>{action} Checklist</span>
              <div className="viewDownload">
                <span>
                  <img src={view_download} alt={view_download}></img>
                </span>
                &nbsp;
                <Link className="viewExcelLink" onClick={() => openPDF()}>Download Checklist</Link>
              </div>
              <div className="viewDownload rightAlignedButton">
                <span>
                  <img src={backicon} alt={backicon} />
                </span>
                &nbsp;
                <Link className="viewExcelLink" onClick={() => handleBack()}>
                  Back to Checklist Master
                </Link>
              </div>
            </div>
          </Row>
          <Tabs
            activeKey={currentTab}
            onSelect={(e) => { handleSelect(e, "") }}
            className="tabCssheader"
          >
            <Tab
              className="headerTabKey"
              eventKey={0}
              title={
                <>
                  <span className="numberbg">1</span>
                  <span>Checklist Details</span>
                </>
              }
            >
              <ChecklistDetails
                ref={tab0Ref}
                action={props.action}
                tabKey={(e) => { handleSelect(e, "next") }}
                masterData={props.masterData}
                rowdata={props.rowdata}
                primeKeyData={setPrimeKey}
                secondKeyData={setSecondKey}
                checklistCodeData={setChecklistCode}
                checklistVersionData={setChecklistVersion}
                setEquipCodeData={setEquipCode}
                setChecklistCat={setChecklistCat}
              />
            </Tab>
            <Tab
              eventKey={1}
              title={
                <>
                  <span className="numberbg">2</span>
                  <span>Checklist Header</span>
                </>
              }
            >
              <ChecklistFooter
                ref={tab1Ref}
                action={props.action}
                tabKey={(e) => { handleSelect(e, "next") }}
                primeKey={primeKey}
                secondKey={secondKey}
                limitValue={headerLimitVal}
                rowdata={props.rowdata}
                checklistVersion={ChecklistVersion}
                tabName="ChecklistHeader"
              />
            </Tab>
            <Tab
              eventKey={2}
              title={
                <>
                  <span className="numberbg">3</span>
                  <span>Parameter Details</span>
                </>
              }
            >
              <ParameterDetails
                ref={tab2Ref}
                action={props.action}
                tabKey={(e) => { handleSelect(e, "next") }}
                primeKey={primeKey}
                secondKey={secondKey}
                checklistCode={ChecklistCode}
                checklistVersion={ChecklistVersion}
                limitValue={parameterDetailsLimitVal}
                rowdata={props.rowdata}
                isParameterCategorySelected={setIsParameterCategory}
                getDistinctCategories={getDistinctCategories}
                parameterDetailsDt={parameterDetailsData}
              />
            </Tab>
            <Tab
              eventKey={3}
              title={
                <>
                  <span className="numberbg">4</span>
                  <span>Status Details</span>
                </>
              }
            >
              <StatusDetails
                ref={tab3Ref}
                action={props.action}
                tabKey={(e) => { handleSelect(e, "next") }}
                primeKey={primeKey}
                secondKey={secondKey}
                limitValue={statusDetailsLimitVal}
                rowdata={props.rowdata}
                distinctCategories={distinctCategories}
                IsParameterCategory={isParameterCategory}
                checklistVersion={ChecklistVersion}
              />
            </Tab>
            <Tab
              eventKey={4}
              title={
                <>
                  <span className="numberbg">5</span>
                  <span>Checklist Footer</span>
                </>
              }
            >
              <ChecklistFooter
                ref={tab4Ref}
                action={props.action}
                tabKey={(e) => { handleSelect(e, "next") }}
                primeKey={primeKey}
                secondKey={secondKey}
                limitValue={footerLimitVal}
                rowdata={props.rowdata}
                checklistVersion={ChecklistVersion}
                tabName="ChecklistFooter"
              />
            </Tab>
          </Tabs>
        </div>
        <br /><br />
        <Row className="tabButtonPosition">
          <TabButtons
            currentTab={currentTab}
            lastTab={4}
            handleSelect={handleSelect}
            handleFreeze={handleFreeze}
            showFreeze={!ChecklistVersion.startsWith("VER")}
            handleBack={props.onHide}
            handleReset={handleReset}
            action={action}
            showSave={true}
          />
        </Row>
      </Container>
      {showPDF && (
        <ChecklistPDF
          show={showPDF}
          onHide={() => setShowPDF(false)}
          displayPDFData={displayPDFData}
          calledFrom={"ChecklistMaster"}
        />
      )}
    </>
  );
};

export default AddEditChecklistMaster;
