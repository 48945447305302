import React, { useEffect, useState, useRef, useMemo } from "react";
import { Row, Col, Container, Card, Spinner, Button } from "react-bootstrap";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "../masters/GridMaster.css";
import { AgGridReact } from "ag-grid-react";
import { defaultColParas, formatDateInDDMMYYYY, formatDateInYYYYMMDD, getCurrentDateTime } from "../../functions/gbcs-functions";
import { deleteEquipmentDetails, addEquipmentParameterDetails } from "../../services/equipmentactivity-master-service";
import { getProjDocTabsData } from "../../services/project-document-service";
import { BsPlusLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import ActionRenderer from "../masters/ActionRenderer";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import Pagination from "../utils/Pagination";
import AddEquipmentParam from "../masters/AddEquipmentParameters";
import { downloadAttachment } from "../../services/designreviewtracker-service"
import { deletS3Attachment } from "../../services/common-master-service"

const ProjectDocumentRightPanel = (props) => {
    const MySwal = withReactContent(Swal);
    const [gridApi, setGridApi] = useState(null);
    const gridRef = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(3);
    const [columnDefs, setColumnDefs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showParameterForm, setShowParameterForm] = useState(false);
    const [paramGridData, setParamGridData] = useState([]);
    const [rowData, setRowData] = useState({});
    const [parameterAction, setParameterAction] = useState("");
    let tabName = props.paramtabname;
    const EquipmentCode = props.equipobj.EquipmentCode;
    const EquipmentName = props.equipobj.EquipmentName;

    const fieldTemp = {
        "Prerequisite": [
            {
                "FieldName": "Pre-Requisite Description",
                "DataType": "Text",
                "MaxLen": "500",
                "UniqueInd": true,
                "isMandatory": true,
                "minWidth": 250,
            },
            {
                "FieldName": "FAT/SAT Applicable",
                "DataType": "Checkbox",
                "optionValues": ["FAT Applicable", "SAT Applicable"],
                "validation": { "FAT Applicable": "FATEquipApplicableChecklist", "SAT Applicable": "SATEquipApplicableChecklist" }
            }
        ],
        "Instruments": [
            {
                "FieldName": "Testing Instrument Name",
                "DataType": "Text",
                "MaxLen": "200",
                "UniqueInd": true,
                "isMandatory": true,
                "minWidth": 220,
            },
            {
                "FieldName": "Quantity",
                "DataType": "Number",
                "MaxLen": "10",
                "UniqueInd": false,
                "isMandatory": true,
                "minWidth": 100
            },
            {
                "FieldName": "Model No",
                "DataType": "Text",
                "MaxLen": "50",
                "UniqueInd": false,
                "isMandatory": true,
                "minWidth": 120
            },
            {
                "FieldName": "Serial No",
                "DataType": "Text",
                "MaxLen": "20",
                "UniqueInd": false,
                "isMandatory": true,
                "minWidth": 120
            },
            {
                "FieldName": "Calibration Date",
                "DataType": "Date",
                "MaxLen": "10",
                "UniqueInd": false,
                "isMandatory": true,
                "minWidth": 120,
                "validation": { "NotFutureToDate": "Current Date" }
            },
            {
                "FieldName": "Calibration Validity Date",
                "DataType": "Date",
                "MaxLen": "10",
                "UniqueInd": false,
                "isMandatory": true,
                "minWidth": 120,
                "validation": { "FutureToDate": "Calibration Date" }
            },
            {
                "FieldName": "Remarks",
                "DataType": "Text",
                "MaxLen": "200",
                "UniqueInd": false,
                "isMandatory": true,
                "minWidth": 120
            }
        ],
        "Testing": [
            {
                "FieldName": "Test Description",
                "DataType": "Text",
                "MaxLen": "500",
                "UniqueInd": true,
                "isMandatory": true,
                "minWidth": 250
            },
            {
                "FieldName": "FAT/SAT Applicable",
                "DataType": "Checkbox",
                "optionValues": ["FAT Applicable", "SAT Applicable"],
                "validation": { "FAT Applicable": "FATEquipApplicableChecklist", "SAT Applicable": "SATEquipApplicableChecklist" }
            },
        ],
        "DocumentReview": [
            {
                "FieldName": "Document Category",
                "DataType": "Text",
                "MaxLen": "100",
                "UniqueInd": false,
                "isMandatory": true,
                "minWidth": 300
            },
            {
                "FieldName": "Document Name",
                "DataType": "Text",
                "MaxLen": "250",
                "UniqueInd": true,
                "isMandatory": true,
                "minWidth": 300
            }
        ],
        "AdditionalInformation": [
            {
                "FieldName": "Physical Inspection and technical specification verification",
                "DataType": "Document",
                "isMandatory": true,
                "FileSize": 5242880,
                "SupportedFiles": "Supports: All Word formats (e.g., doc, docs, docx), Max File Size – up to 5MB per file",
                "sampleDocDownloadAvailable": false,
            },
            {
                "FieldName": "Equipment Schedule",
                "DataType": "Document",
                "isMandatory": true,
                "FileSize": 10485760,
                "SupportedFiles": "Supports: All Word formats (e.g., doc, docs, docx), Max File Size – up to 10MB per file",
                "sampleDocDownloadAvailable": false,
            },
            {
                "FieldName": "Details of Test Applicable",
                "DataType": "Document",
                "isMandatory": true,
                "FileSize": 10485760,
                "SupportedFiles": "Supports: All Word formats (e.g., doc, docs, docx), Max File Size – up to 10MB per file",
                "sampleDocDownloadAvailable": false,
            },
            {
                "FieldName": "Sample Document for Detailed Report",
                "DataType": "Document",
                "isMandatory": false,
                "FileSize": 31457280,
                "SupportedFiles": "Supports: All Word formats (e.g., doc, docs, docx), Max File Size – up to 30MB per file",
                "Action": "View",
                "sampleDocDownloadAvailable": true,
            },
        ],
    }

    const [tabFields, setTabFields] = useState(fieldTemp[tabName]);
    const [actionButtons, setActionButtons] = useState({});

    ///Check grid master
    function onGridReady(params) {
        setGridApi(params.api);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api;// <= assigned gridApi value on Grid ready
    }

    const fetchEquipmentParameterData = async () => {
        if (EquipmentCode) {
            setLoading(true);
            let keyObj = { "PK": "ProjectDocuments#ProjectCode#" + props.projectdata.ProjectCode, "SK": tabName + "#EquipmentCode#" + EquipmentCode + "#SeqNo#" }
            let equipPrereqbuisiteData = await getProjDocTabsData(keyObj);
            setParamGridData(equipPrereqbuisiteData);
            setLoading(false);
        }
    }

    const handleDelete = async (row) => {
        MySwal.fire({
            text: "Are you sure, do you want to delete Equipment / Activity " + props.headername + "?",
            showDenyButton: true,
            cancelButtonColor: "#fff",
            confirmButtonColor: "#16911B",
            confirmButtonText: "Delete",
            reverseButtons: true,
            customClass: "swal-confirmation",
            title: "Delete Equipment / Activity"
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                setLoading(true);
                let delObj = { PK: row.PK, SK: row.SK };
                let result = await deleteEquipmentDetails(delObj, "ProjectDocuments");
                if (result?.status) {
                    if (tabName === "AdditionalInformation") {
                        let documentNamesJs = {};
                        documentNamesJs["Physical Inspection and technical specification verification"] = row["Physical Inspection and technical specification verification"];
                        documentNamesJs["Equipment Schedule"] = row["Equipment Schedule"];
                        documentNamesJs["Details of Test Applicable"] = row["Details of Test Applicable"];

                        for (let property in documentNamesJs) {
                            let fileObj = { fileName: "ProjectDocuments/" + props.projectdata.ProjectCode + "/" + tabName + "/" + EquipmentCode + "/" + documentNamesJs[property] };
                            await deletS3Attachment(fileObj);
                        }
                    }
                    setLoading(false);
                    MySwal.fire({
                        text: "Equipment / Activity " + props.headername + " has beed deleted successfully.",
                        imageUrl: successImage,
                        customClass: "swal-success",
                        didClose: () => {
                            fetchEquipmentParameterData();
                        },
                    });
                }
                else {
                    setLoading(false);
                    MySwal.fire({
                        text: "Unable to Process Request",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                }
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        let tempArr = [];
        let counter = 0;
        if (tabFields !== undefined) {
            for (let dt of tabFields) {
                if (dt.DataType === "Text" || dt.DataType === "Number") {
                    tempArr.push({
                        headerName: dt.FieldName,
                        headerTooltip: dt.FieldName,
                        tooltipField: dt.FieldName,
                        field: dt.FieldName,
                        minWidth: dt.minWidth,
                        rowDrag: counter === 0
                    });
                }
                else if (dt.DataType === "Date") {
                    tempArr.push({
                        headerName: dt.FieldName,
                        headerTooltip: dt.FieldName,
                        tooltipField: dt.FieldName,
                        field: dt.FieldName,
                        minWidth: dt.minWidth,
                        filter: "agDateColumnFilter",
                        valueFormatter: (params) => {
                            return formatDateInDDMMYYYY(params.data[dt.FieldName]);
                        },
                        filterParams: {
                            comparator: (filterLocalDateAtMidnight, cellValue) => {
                                const dateAsString = cellValue;
                                if (dateAsString == null) {
                                    return 0;
                                }
                                const dateCell = new Date(formatDateInYYYYMMDD(cellValue) + " 00:00:00");

                                if (dateCell < filterLocalDateAtMidnight) {
                                    return -1;
                                } else if (dateCell > filterLocalDateAtMidnight) {
                                    return 1;
                                }
                                return 0;
                            },
                        }
                    });
                }
                else if (dt.DataType === "Checkbox") {
                    for (let item of dt.optionValues) {
                        tempArr.push({
                            headerName: item,
                            field: item,
                            minWidth: 120,
                            cellRenderer: function (params) {
                                return (
                                    <input
                                        type="checkbox"
                                        disabled
                                        checked={params.data[item]}
                                    />
                                )
                            }
                        });
                    }
                }
                else if (dt.DataType === "Document") {
                    tempArr.push({
                        headerName: dt.FieldName,
                        headerTooltip: dt.FieldName,
                        tooltipField: dt.FieldName,
                        field: dt.FieldName,
                        minWidth: dt.minWidth,
                        cellRenderer: fileDownloadComponent
                    });
                }
                counter++;
            }

            tempArr.push({
                field: "",
                width: 80,
                cellRenderer: function (params) {
                    return <ActionRenderer
                        selectedData={params}
                        actionName={actionButtons}
                        handleDeleteEvent={(params) => handleDelete(params.data)}
                        handleEditEvent={(params) => handleEdit(params.data)}
                    />
                },
                sortable: false,
                filter: false,
                pinned: 'right',
                resizable: false
            });
        }
        setColumnDefs(tempArr);
        fetchEquipmentParameterData();
        if (props.projectdata.DeleteIndication !== "Active") {
            setActionButtons({
                ViewAction: false,
                EditAction: false,
                DownloadAction: false,
                DeleteAction: false,
            });
        }
        else {
            const tmpAction =
            {
                ViewAction: false,
                EditAction: true,
                DownloadAction: false,
                DeleteAction: true,
            };
            if (tabName === "AdditionalInformation") {
                tmpAction.EditAction = false;
            }
            setActionButtons(tmpAction);
        }
    }, [tabFields, props.equipobj]);

    const defaultColDef = useMemo(() => {
        return defaultColParas();
    }, []);

    function getRowData() {
        let tabData = [];
        gridRef.current.gridOptionsService.api.forEachNode((node) =>
            tabData.push(node.data)
        );
        return tabData;
    }

    const handleSubmit = async () => {
        setLoading(true);
        let gridData = getRowData();
        console.log("gridData", gridData)
        if (gridData?.length > 0) {
            let counter = 1;
            let updatedData = [];
            for (let dt of gridData) {
                let obj = Object.assign({}, dt);
                let delObj = { PK: dt.PK, SK: dt.SK, SeqNo: dt.SeqNo };
                let updateInd = false;
                let deleteInd = false;

                if (counter !== obj.SeqNo) {
                    obj.SeqNo = counter;
                    obj.SK = tabName + "#EquipmentCode#" + EquipmentCode + "#SeqNo#" + counter;
                    obj.ProjectCode = props.projectdata.ProjectCode;
                    updateInd = true;
                    deleteInd = true;
                }
                updatedData.push(obj);
                if (updateInd) {
                    let tmpRes = await addEquipmentParameterDetails(obj, tabName, EquipmentCode, "existing", "ProjectDocuments");
                    if (tmpRes?.status) {
                        if (deleteInd && delObj.SeqNo > gridData?.length) {
                            let delResult = await deleteEquipmentDetails(delObj, "ProjectDocuments");
                        }
                    }
                }
                counter++;
            }
            setParamGridData(updatedData);

            setLoading(false);
            MySwal.fire({
                text: props.headername + " details added successfully.",
                imageUrl: successImage,
                customClass: "swal-success"
            });
        }
        else {
            setLoading(false);
            MySwal.fire({
                text: "Please add at least one record first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    };

    const handleOnCellClick = (params) => {
        let fileName = params.value;
        if (tabName === "AdditionalInformation") {
            downloadAttachment(
                "ProjectDocuments/" + props.projectdata.ProjectCode + "/" + tabName + "/" + EquipmentCode + "/" + fileName,
                fileName
            ).catch((err) => {
                setLoading(false);
                MySwal.fire({
                    text: "Error in Fetching file.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            });

        }
    };

    const fileDownloadComponent = (params) => {
        if (params.value) {
            return (
                <Link className="App-link" onClick={() => handleOnCellClick(params)}>{params.value}</Link>
            );
        }
        else {
            return (<></>)
        }
    }

    const addNewRow = () => {
        if (!EquipmentCode) {//use prop var
            MySwal.fire({
                text: "Please select equipment first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        else if (props.hasOwnProperty("limitValue")) {
            if (paramGridData.length < props.limitValue) {
                setParameterAction("Add");
                setShowParameterForm(true);
            } else {
                MySwal.fire({
                    text: "You can add only " + props.limitValue + " records for " + props.headername + ".",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            }
        }
        else {
            setParameterAction("Add");
            setShowParameterForm(true);
        }
    };

    const handleEdit = (row) => {
        setParameterAction("Edit");
        setRowData(row);
        setShowParameterForm(true);
    };

    const filteredResult = useMemo(() => {
        let firstPageIndex = 0;
        if (currentPage > 1) {
            firstPageIndex = (currentPage - 1) * pageSize;
        }
        if (firstPageIndex >= paramGridData.length) {
            firstPageIndex = 0;
            const totalPageCount = Math.ceil(paramGridData.length / pageSize);
            if (totalPageCount > 0) {
                setCurrentPage(totalPageCount)
            } else {
                setCurrentPage(1)
            }
        }
        let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
        return paramGridData ? paramGridData.slice(firstPageIndex, lastPageIndex) : [];
    }, [currentPage, paramGridData, pageSize]);

    function moveBack() {
        props.panelrespshow(true);
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="projdoc-comp-container">
                <Row className="projDocRightPanel">
                    <Col xs={6}>
                        <span className="wordBreak">{props.headername}</span>
                    </Col>
                    <Col xs={6} className="addNewRow d-md-none d-lg-none">
                        <Link onClick={() => moveBack()} className="blueColor">Back</Link>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={6}>
                        <span className="projDocRightPanelEquipName wordBreak">{EquipmentName}</span>
                    </Col>
                    {props.projectdata.DeleteIndication === "Active" &&
                        <Col xs={6} className="addNewRow">
                            <BsPlusLg size={20} className="plusIcon" />
                            &nbsp;
                            <Link onClick={() => addNewRow()} className="blueColor">Add More</Link>
                        </Col>
                    }
                </Row>
                <div className="ag-theme-alpine agGridLeftContainerEquip d-none d-md-none d-lg-block">
                    <AgGridReact
                        ref={gridRef}
                        rowData={paramGridData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        enableBrowserTooltips={true}
                        onGridReady={onGridReady}
                        rowDragManaged={true}
                    />
                </div>
                <div className="market-wrapper d-md-none d-lg-none cardContainerResponsive">
                    {
                        filteredResult.map((selection, index) =>
                            <Card className="cardContainer" key={index}>
                                <Card.Body className="cardContainerResponsive">
                                    <div className="actionIcons">
                                        <ActionRenderer selectedData={selection}
                                            actionName={actionButtons}
                                            handleDeleteEvent={(selection) => handleDelete(selection)}
                                            handleEditEvent={(selection) => handleEdit(selection)} />
                                    </div>
                                    {columnDefs.map((header, index) => {
                                        let formattedValue = header.valueFormatter ? header.valueFormatter({ "data": selection, "colDef": { field: header.field, headerName: header.headerName }, "node": { "rowIndex": (pageSize * (currentPage - 1)) + index }, "value": selection[header.field] }) : selection[header.field];
                                        let displayValue = header.cellRenderer ? header.cellRenderer({ "data": selection, "colDef": { field: header.field, headerName: header.headerName }, "node": { "rowIndex": (pageSize * (currentPage - 1)) + index }, "value": selection[header.field] }) : formattedValue;
                                        return (header.field && header.field !== "" &&
                                            <Row key={index} className="responsive-row">
                                                <Col className="colHeader">{header.headerName}</Col>
                                                <Col className="colData">{displayValue}</Col>
                                            </Row>
                                        )
                                    }
                                    )}
                                </Card.Body>
                            </Card>
                        )}
                    {filteredResult?.length > 0 && (
                        <Pagination
                            currentPage={currentPage}
                            totalCount={paramGridData ? paramGridData.length : 0}
                            data={paramGridData}
                            pageSize={pageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        />)}
                </div>
                <br />
                {tabName !== "AdditionalInformation" && props.projectdata.DeleteIndication === "Active" &&
                    <Button variant="outline-success" className="btn-update-sequence semiBoldToggleButton rightAlignedButton d-none d-md-none d-lg-block" onClick={() => handleSubmit()}>
                        Update Sequence
                    </Button>
                }
            </Container>
            {showParameterForm && (
                <AddEquipmentParam
                    show={showParameterForm}
                    rowdata={rowData}
                    action={parameterAction}
                    tabflds={tabFields}
                    paramTabName={props.paramtabname}
                    onHide={() => setShowParameterForm(false)}
                    fetchEquipmentParameterData={fetchEquipmentParameterData}
                    equipcode={EquipmentCode}
                    projdata={props.projectdata}
                    screenname={"ProjectDocuments"}
                    headername={props.headername}
                    equipData={props.equipobj}
                />
            )
            }
        </>
    );
};

export default ProjectDocumentRightPanel;
